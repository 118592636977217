export const BrandColor = '#389084';

const BasePalette = {
  oxfordBlue: '#0B132B',
  spaceCadet: '#1C2541',
  independence: '#3A506B',
  maximumBlueGreen: '#5BC0BE',
  darkCornflowerBlue: '#023E7D',
  bdazzledBlue: '#345995',
  turquoiseBlue: '#6FFFE9',
  turquoiseGreen: '#9DD2B9',
  caribbeanGreen: '#03CEA4',
  illuminatingEmerald: '#028D71',
  ghostWhite: '#EFF2FB',
  cultured: '#F2F4F8',
  azurex11: '#E2F4F3',
  imperialRed: '#FF0035',
  tartOrange: '#FE4A49',
  orangeYellowCrayola: '#FED766',
  oldMauve: '#603140',
  russianViolet: '#3C1B43',
  richBlack: '#02040F',
};

const {
  oxfordBlue,
  spaceCadet,
  independence,
  maximumBlueGreen,
  darkCornflowerBlue,
  bdazzledBlue,
  turquoiseBlue,
  turquoiseGreen,
  caribbeanGreen,
  illuminatingEmerald,
  ghostWhite,
  cultured,
  azurex11,
  imperialRed,
  tartOrange,
  orangeYellowCrayola,
  oldMauve,
  russianViolet,
  richBlack,
} = BasePalette;

const DefaultTheme = {
  light: {
    colors: {
      brand: BrandColor,
      primary: independence,
      accent: cultured,
      highlight: cultured,
      background: cultured,
      sidebarBackground: spaceCadet,
      sideBarText: ghostWhite,
      card: spaceCadet,
      text: ghostWhite,
      border: oxfordBlue,
      error: imperialRed,
      warning: orangeYellowCrayola,
      warning2: tartOrange,
      special: oxfordBlue,
      white: ghostWhite,
      black: richBlack,
    },
  },
};
DefaultTheme.dark = DefaultTheme.light;

export const COLORS_BAK = {
  brand: '#389084',
  primary: independence,
  primaryLight: azurex11,
  highlight: maximumBlueGreen,
  standard: spaceCadet,
  darkGray: spaceCadet,
  primaryGray: '#2e3138',
  highlightGray: '#3f434d',
  lightGray: '#aaa',
  lightGray2: cultured,
  error: imperialRed,
  warning: orangeYellowCrayola,
  warning2: tartOrange,
  special: oxfordBlue,
  white: ghostWhite,
  black: richBlack,
  completed: darkCornflowerBlue,
  tabled: oldMauve,
  active: oxfordBlue,
  yellow: orangeYellowCrayola,
};

const Themes = {
  default: DefaultTheme,
};

export const getSiteTheme = (scheme = 'default', mode = 'light') => {
  if (Themes[scheme] && Themes[scheme][mode]) {
    return Themes[scheme][mode];
  }
  return Themes.default.light;
};

// TODO Prepare for theming
export const COLORS = COLORS_BAK || getSiteTheme().colors;

export const TIMER_COLORS = {
  ample: caribbeanGreen,
  headsup: orangeYellowCrayola,
  headsup2: tartOrange,
  fleeting: imperialRed,
  exceeded: russianViolet,
  exceededCircle: imperialRed,
};

// Bak COLORS:
// export const COLORS = {
//   primary: '#389084',
//   primaryLight: '#f4fbfa',
//   highlight: '#21ba45',
//   standard: '#333',
//   darkGray: '#272a31',
//   primaryGray: '#2e3138',
//   highlightGray: '#3f434d',
//   lightGray: '#aaa',
//   lightGray2: '#eee',
//   error: 'red',
//   warning: 'yellow',
//   warning2: 'orange',
//   special: 'purple',
//   white: '#fff',
//   black: '#000',
// };

// Palettes
// https://coolors.co/0466c8-0353a4-023e7d-002855-001845-001233-33415c-5c677d-7d8597-979dac

// https://coolors.co/0e131f-38405f-59546c-8b939c-ff0035

// https://coolors.co/0b132b-1c2541-3a506b-5bc0be-6fffe9

// https://coolors.co/01baef-0cbaba-380036-26081c-150811
