import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'unistore/react';
import styled from 'styled-components';
import _get from 'lodash/get';
import _debounce from 'lodash/debounce';
import _isEqual from 'lodash/isEqual';
import Common from 'Common';
import { COLORS } from '~/styles/Consts';
import Actions from '~/state/Actions';

const { TextArea, Icon, Popup } = Common;

const Label = styled.label`
  font-weight: 900;
  display: flex;
  margin-bottom: 2px;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.span`
  color: ${COLORS.lightGray};
`;

const TextAreaStyled = styled(TextArea)`
  background-color: ${COLORS.yellow};
`;

const Notes = (props) => {
  const {
    store, debounce, autoFocus, minRows, maxRows, agendaId, title, meetingId, onSetNote,
  } = props;
  const { notes } = store;

  const [value, setValue] = useState({});
  const [prevValue, setPrevValue] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isInit, setIsInit] = useState(false);

  const handleInProgress = (bool) => {
    setIsSubmitting(bool);
  };

  const onInProgress = useCallback(handleInProgress, []);

  const handleFieldTouch = (bool) => {
    setIsDirty(bool);
  };

  const onFieldTouch = useCallback(handleFieldTouch, []);

  const handleSave = async () => {
    if (!_isEqual(value, prevValue)) {
      onInProgress(true);
      if (onSetNote) await onSetNote(meetingId, agendaId, value[agendaId]);
      setPrevValue(value);
      onInProgress(false);
      onFieldTouch(false);
    }
  };

  const autoSaveChanges = (_debounce(handleSave, debounce));

  const handleSetNote = async (e) => {
    const note = _get(e, 'target.value', '');
    setValue({ ...value, [agendaId]: note });
  };

  useEffect(() => {
    // Init notes state
    const n = _get(notes, meetingId, {});
    if (!isInit && !_isEqual(n, value)) {
      setIsInit(true);
      setPrevValue(n);
      setValue(n);
    }
  }, [notes[meetingId]]);

  useEffect(() => {
    if (!_isEqual(value, prevValue)) {
      onInProgress(false);
      onFieldTouch(true);
      autoSaveChanges();
    }
  }, [value]);

  if (!agendaId || !meetingId) return null;

  const getSavedState = () => {
    if (isSubmitting) return 'sync';
    if (isDirty) return 'write';
    return 'check';
  };

  const renderIcon = () => (
    <Icon
      name={getSavedState()}
      loading={isSubmitting}
      size="small"
      className="right.aligned"
    />
  );

  const renderPopupContent = () => (
    <>
      Take note: This section is your personal scratchpad, linked to each agenda item. Your notes are
      {' '}
      <b>not</b>
      {' '}
      visible to anyone else.
    </>
  );

  const renderTitle = () => title
    ? (
      <Title>
        {title}
      </Title>
    )
    : null;

  const renderLabel = () => (
    <>
      <span key="note-label">
        My Notes
        {' '}
        <Popup
          size="small"
          trigger={(
            <span>
              <Icon
                name="info circle"
                color="blue"
                size="small"
              />
            </span>
          )}
        >
          {renderPopupContent()}
        </Popup>
        {renderTitle()}
      </span>
      {renderIcon()}
    </>
  );

  return (
    <React.Fragment key={`${agendaId}-note`}>
      <Label htmlFor={`note-${agendaId}`}>
        {renderLabel()}
      </Label>
      <TextAreaStyled
        id={`note-${agendaId}`}
        name="note"
        minRows={minRows}
        maxRows={maxRows}
        onChange={handleSetNote}
        value={value[agendaId]}
        autoFocus={autoFocus}
      />
    </React.Fragment>
  );
};

Notes.defaultProps = {
  debounce: 500,
  minRows: 4,
  maxRows: 4,
  autoFocus: false,
};

export default connect(
  (store) => ({ store }),
  Actions,
)(Notes);
