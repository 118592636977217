import React from 'react';
import Message from '../Message';
import Icon from '../Icon';

const Error = ({
  content, header, type, icon, children, ...rest
}) => {
  const renderIcon = () => icon ? <Icon name={icon} /> : null;

  const renderHeader = () => header ? <Message.Header>{header}</Message.Header> : null;

  const renderContent = () => (
    <>
      {renderIcon()}
      <Message.Content>
        {renderHeader()}
        {children || content}
      </Message.Content>
    </>
  );

  const getType = () => type ? ({ [type]: true }) : {};

  return children || content
    ? (
      <Message {...getType()} icon={!!icon} {...rest}>
        {renderContent()}
      </Message>
    )
    : null;
};

Error.defaultProps = {
  type: 'error', // success | warning | error
  icon: 'warning sign',
};

export default Error;
