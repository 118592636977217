import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import _remove from 'lodash/remove';
import _trim from 'lodash/trim';
import _findIndex from 'lodash/findIndex';
import Common from 'Common';
import { USER_ROLES, USER_ROLES_LABELS, JOB_TYPES } from 'Utils/Consts';

const {
  Form, Modal, Heading, Grid, Label, Icon, Button, TextArea,
} = Common;

const { VIEWER, DELEGATE } = USER_ROLES;

const ROLE_OPTIONS = [VIEWER, DELEGATE];

const MESSAGE_MAX_CHARS = 300;

const InviteeSchema = Yup.object().shape({
  emailAddress: Yup.string()
    .email()
    .required(),
  roles: Yup.array().min(1).of(Yup.string().oneOf(ROLE_OPTIONS)),
});

const JobSchema = Yup.object().shape({
  type: Yup.string().required(),
  meetingId: Yup.string(),
  groupId: Yup.string(),
  inviter: Yup.object().shape({
    id: Yup.string().required(),
    emailAddress: Yup.string().email().required(),
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    message: Yup.string(),
  }),
  invitees: Yup.array().min(1).of(InviteeSchema),
});

const EmailAddress = styled.div`
  margin-bottom: .5rem;
  margin-right: .5rem;
`;

const INITIAL_STATE = {
  role: VIEWER,
  invitees: '',
  message: '',
};

const getRoleOptions = () => {
  const options = [];
  ROLE_OPTIONS.forEach((val) => {
    options.push({ value: val, text: USER_ROLES_LABELS[val] });
  });
  return options;
};

const InvitesForm = ({
  onSendInvites,
  onToggleInvitesFormModal,
  showModal,
  meetingId,
  groupId,
  inModal,
  me,
  title,
  inviteesData,
}) => {
  const isEditMode = !!inviteesData;
  const [loading, setLoading] = useState(false);
  const [invitees, setInvitees] = useState(inviteesData);
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);

  if (!groupId && !meetingId) {
    console.warn('Missing groupId or meetingId in InvitesForm');
  }
  if (groupId && meetingId) {
    console.warn('Received both groupId and meetingId in InvitesForm (expected one of these)');
  }

  const handleSetMessage = async (val) => {
    setMessage(val);
    if (error) setError(null);
  };

  const handleToggleModal = () => onToggleInvitesFormModal();

  const handleSubmitInvites = async () => {
    setLoading(true);
    const {
      uid, firstName, lastName, emailAddress,
    } = me;
    const job = {
      invitees,
      inviter: {
        id: uid,
        emailAddress,
        firstName,
        lastName,
      },
    };
    if (message) {
      if (message.length <= MESSAGE_MAX_CHARS) job.inviter.message = message;
      else {
        setLoading(false);
        setError('Custom message is too long');
        return;
      }
    }
    if (meetingId) job.meetingId = meetingId;
    else if (groupId) job.groupId = groupId;
    job.type = job.groupId ? JOB_TYPES.GROUP_INVITE : JOB_TYPES.MEETING_INVITE;
    const isValid = await JobSchema.isValid(job);
    if (isValid) {
      await onSendInvites(job);
      setLoading(false);
      setInvitees([]);
      handleToggleModal();
    } else setLoading(false);
  };

  const handleAddInvites = async (vals) => {
    const { invitees: formInvitees, role } = vals;
    const emails = `${formInvitees}`.split(',');
    const queue = emails.map((e) => {
      const emailAddress = _trim(e);
      return ({ emailAddress, roles: [role] });
    });
    let isValid = true;
    const invalid = [];
    await Promise.all(queue.map(async (val) => {
      const isThisValid = await InviteeSchema.isValid(val);
      if (!isThisValid) {
        invalid.push(val.emailAddress);
        isValid = isThisValid;
      }
      return Promise.resolve();
    }));

    if (!isValid) return;
    const newInvitees = [...invitees];
    queue.forEach((invitee) => {
      const { emailAddress } = invitee;
      const idx = _findIndex(newInvitees, { emailAddress });
      if (idx < 0) newInvitees.push(invitee);
      else newInvitees[idx] = invitee;
    });
    setInvitees(newInvitees);
  };

  const handleRemoveInvite = (emailAddress) => {
    const newInvitees = _remove(invitees, (v) => v.emailAddress !== emailAddress);
    setInvitees(newInvitees);
    return {};
  };

  const renderMessageField = () => (
    <>
      <Form.Label htmlFor="message">{`Custom Message (${MESSAGE_MAX_CHARS - message.length} characters remaining)`}</Form.Label>
      <TextArea
        id="message"
        maxRows={5}
        value={message}
        onChange={(e) => handleSetMessage(e.target.value)}
        placeholder="Optionally add a custom message to include with the email invite from you"
      >
      </TextArea>
      {!!error && <Form.Error>{error}</Form.Error>}
    </>
  );

  const fields = useMemo(() => {
    const iFields = [
      {
        id: 'invitees',
        label: 'Invitee Email Addresses',
        placeholder: `Enter one or more email addresses (comma-separated) to invite others to join ${title}`,
        initialValue: INITIAL_STATE.invitees,
        type: 'textarea',
        autoFocus: !isEditMode,
      },
      {
        id: 'role',
        label: 'Access Role',
        type: 'dropdown',
        initialValue: INITIAL_STATE.role,
        controlProps: {
          placeholder: 'Select access level',
          fluid: true,
          selection: true,
          options: getRoleOptions(),
        },
      },
    ];
    return iFields;
  }, [inviteesData]);

  const renderInvitee = ({ emailAddress, roles }) => {
    const [role] = roles;
    return (
      <EmailAddress key={emailAddress}>
        <Label color={role === VIEWER ? 'blue' : 'red'}>
          {emailAddress}
          <Label.Detail>{USER_ROLES_LABELS[role]}</Label.Detail>
          <Icon name="delete" onClick={() => handleRemoveInvite(emailAddress)} />
        </Label>
      </EmailAddress>
    );
  };

  const renderSubmitButton = () => {
    if (!invitees.length) return null;
    return (
      <Button
        primary
        loading={loading}
        onClick={() => handleSubmitInvites()}
      >
        Send Invites
      </Button>
    );
  };

  const renderForm = () => (
    <Grid columns="equal">
      <Grid.Column>
        {renderMessageField()}
        <Form
          onSubmit={handleAddInvites}
          fields={fields}
          buttons={[
            {
              text: 'Cancel',
              params: {
                onClick: handleToggleModal,
                disabled: loading,
              },
            },
            {
              text: 'Add to list …',
              params: {
                type: 'submit',
                disabled: loading,
              },
            },
          ]}
        />
      </Grid.Column>
      <Grid.Column stretched>
        <Grid.Row stretched>
          {invitees.map(renderInvitee)}
        </Grid.Row>
        <Grid.Row stretched verticalAlign="bottom">
          {renderSubmitButton()}
        </Grid.Row>
      </Grid.Column>
    </Grid>
  );

  return inModal ? (
    <Modal
      open={showModal}
      // onClose={handleToggleModal}
      // dimmer="blurring"
    >
      <Heading
        icon="share alternate"
        content={isEditMode ? `Send Invites to ${title}` : `Update Invites for ${title}`}
      />
      <Modal.Content>
        {renderForm()}
      </Modal.Content>
    </Modal>
  ) : renderForm();
};

InvitesForm.defaultProps = {
  inModal: true,
  inviteesData: [],
};

export default InvitesForm;
