import React, { useState } from 'react';
import {
  Form as FinalForm, Field as FinalFormField, FormSpy as FinalFormSpy,
} from 'react-final-form';
import _kebabCase from 'lodash/kebabcase';
import _forEach from 'lodash/forEach';
import styled from 'styled-components';
import Button from 'Common/Button';
import Select from 'Common/Select';
import Dropdown from 'Common/Dropdown';
import Input from 'Common/Input';
import Checkbox from 'Common/Checkbox';
import Radio from 'Common/Radio';
import TextArea from 'Common/TextArea';
import { COLORS } from '~/styles/Consts';
import { styleConstructor } from '~/styles/Utils';

const ButtonWrapper = styled.div`
  margin-top: 1rem;
`;

const ButtonStyled = styled(Button)`
  margin-right: 4px;
  ${({ styling }) => styleConstructor(styling)}
`;

const FieldStyled = styled(FinalFormField)`
  font-family: 'Roboto', sans-serif;
  margin-bottom: 0.5rem;
  width: 100%;
  ${({ styling }) => styleConstructor(styling)}
`;

const Label = styled.label`
  font-weight: 900;
  display: block;
  margin-bottom: 2px;
  ${({ styling }) => styleConstructor(styling)}
`;

const Error = styled.p`
  color: ${COLORS.error};
  ${({ styling }) => styleConstructor(styling)}
`;

const Field = (params) => <FieldStyled {...params} />;

const Form = (props) => {
  const {
    validationSchema, onSubmit, mutators, fields, buttons, validate,
  } = props;

  const [errState, setErrState] = useState({});

  const initialValues = {};
  fields.forEach(({ id, initialValue }) => {
    if (initialValue) initialValues[id] = initialValue;
  });

  const handleFormSubmit = async (vals) => {
    const isValid = await validateForm(vals);
    if (!isValid) return;
    if (onSubmit) onSubmit(vals);
  };

  const validateForm = async (vals) => {
    let isValid = true;
    if (validationSchema) {
      isValid = await validationSchema.isValid(vals);
      try {
        await validationSchema.validate(vals, { abortEarly: false });
      } catch (err) {
        const errors = err.inner.reduce((formError, innerError) => ({
          ...formError,
          [innerError.path]: innerError.message,
        }), {});
        setErrState(errors);
        return errors;
      }
    }
    return isValid;
  };

  const getComponent = (params) => {
    const {
      type, component, controlProps,
    } = params;
    let c = null;
    switch (true) {
      case (!!component): {
        c = component;
        break;
      }
      case (type === 'select'): {
        c = controlProps ? (p) => (
          <Select
            name={p.input.name}
            value={p.input.value}
            onChange={(e, { value }) => p.input.onChange(value)}
            {...controlProps}
          />
        )
          : Select;
        break;
      }
      case (type === 'dropdown'): {
        c = (p) => (
          <Dropdown
            name={p.input.name}
            value={p.input.value}
            onChange={(e, { value }) => p.input.onChange(value)}
            {...controlProps}
          />
        );
        break;
      }
      case (type === 'checkbox'): {
        c = (p) => (
          <Checkbox
            name={p.input.name}
            checked={!!p.input.checked}
            onChange={(e, { checked }) => p.input.onChange(checked)}
            {...controlProps}
          />
        );
        break;
      }
      case (type === 'radio'): {
        c = Radio;
        break;
      }
      case (type === 'textarea'): {
        c = (p) => (
          <TextArea
            onChange={(e) => {
              const val = e.target.value || '';
              p.input.onChange(val);
            }}
            defaultValue={p.input.value}
            {...p}
          />
        );
        break;
      }
      case (type === 'time'): {
        c = (p) => (
          <Input
            type={type}
            onChange={({ value }) => p.input.onChange(value)}
            value={p.input.value}
            {...p}
          />
        );
        break;
      }
      default: {
        c = Input;
        break;
      }
    }
    return c;
  };

  const renderError = (errorText, errorStyling) => {
    if (!errorText) return null;
    return <Error styling={errorStyling}>{errorText}</Error>;
  };

  const renderField = (params) => {
    const {
      id, label, autoSave, labelStyling, errorStyling, ...rest
    } = params;
    return (
      <React.Fragment key={id}>
        <Label htmlFor={id} styling={labelStyling}>
          {label}
        </Label>
        <Field
          id={id}
          name={id}
          component={getComponent(rest)}
          {...rest}
        />
        {renderError(errState[id], errorStyling)}
      </React.Fragment>
    );
  };

  const renderButton = ({
    id, text, styling, params, component,
  }) => {
    if (component) return component;
    const key = id || `${_kebabCase(text)}-btn`;
    return (
      <ButtonStyled
        key={key}
        id={key}
        content={text}
        styling={styling}
        {...params}
        disabled={params && params.loading}
      />
    );
  };

  return (
    <FinalForm
      initialValues={initialValues}
      mutators={mutators}
      onSubmit={handleFormSubmit}
      validate={validate}
      subscription={{}}
      render={({
        handleSubmit, form, submitting, pristine, values,
      }) => {
        window.setFormValue = form.mutators.setValue;
        return (
          <form onSubmit={handleSubmit}>
            {fields.map(renderField)}
            {!!buttons.length && (
              <ButtonWrapper>
                {buttons.map(renderButton)}
              </ButtonWrapper>
            )}
          </form>
        );
      }}
    />
  );
};

Form.Field = Field;
Form.FieldSpy = FinalFormSpy;
Form.Label = Label;
Form.Error = Error;

Form.defaultProps = {
  fields: [],
  buttons: [],
  mutators: {
    setValue: ([fieldName, value], state, { changeValue }) => {
      changeValue(state, fieldName, () => value);
    },
  },
};

export default Form;
