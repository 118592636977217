import { getIsAdmin, processFormData, forEach } from 'Utils';
import { fsTimestamp } from '../firebase';
import {
  upsertSchedule, getScheduleById, subscribeMySchedules, subscribeGroupSchedules,
} from '../firebase/schedule';

const enrichSchedule = (s, me) => {
  const isAdmin = getIsAdmin(s, me);
  s.isAdmin = isAdmin;
  return s;
};

export const handleUpsertSchedule = async (
  Store,
  state,
  scheduleData,
  scheduleId,
  cb,
) => {
  const { me, schedules } = state;
  const { uid: userId } = me;
  const updateStore = async ({ data }) => {
    const s = enrichSchedule(data, me);
    if (cb) cb({ res: s });
    const { id } = data;
    if (!schedules[id]) schedules[id] = { ...s };
    Store.setState({ schedules: { ...schedules } });
  };
  const pSchedule = processFormData(scheduleData, userId, fsTimestamp, !!scheduleId);
  await upsertSchedule(pSchedule, scheduleId, updateStore);
};

export const handleGetScheduleById = async (Store, state, scheduleId) => {
  const { schedules, me } = state;
  const schedule = await getScheduleById(scheduleId);
  const savedSchedules = {
    schedules: {
      ...schedules,
      [scheduleId]: enrichSchedule(schedule, me),
    },
  };
  return savedSchedules;
};

export const handleSubscribeGroupSchedules = async (Store, state, scheduleId, cb) => {
  const { me } = state;
  const updateStore = ({ data, unsubscribe }) => {
    if (cb) cb({ res: data });
    const { schedules, subscriptions } = Store.getState();
    subscriptions[`${scheduleId}-schedules`] = { unsubscribe };
    forEach(data, ({ id, ...schedule }) => {
      schedules[id] = enrichSchedule(schedule, me);
    });
    Store.setState({ schedules: { ...schedules }, subscriptions });
  };
  await subscribeGroupSchedules(scheduleId, updateStore);
};

export const handleSubscribeMySchedules = async (Store, state, uid, cb) => {
  const { me } = state;
  const updateStore = ({ data, unsubscribe }) => {
    if (cb) cb({ res: data });
    const { schedules, subscriptions } = Store.getState();
    subscriptions[`${me.uid}-schedules`] = { unsubscribe };
    forEach(data, ({ id, ...schedule }) => {
      schedules[id] = enrichSchedule(schedule, me);
    });
    Store.setState({ schedules: { ...schedules }, subscriptions });
  };
  await subscribeMySchedules(uid || me.uid, updateStore);
};
