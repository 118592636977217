import React from 'react';
import styled from 'styled-components';
import ReactAvatar from 'react-avatar';
import { styleConstructor } from '~/styles/Utils';
import Image from '../Image';

const RoundImage = styled(Image)`
    border-radius: 50% !important;
`;

const ImageStyled = styled(({ styling, ...rest }) => <RoundImage {...rest} />)`
    width: 1.8rem !important;
    height: 1.8rem !important;
    border-radius: 50% !important;
    ${({ styling }) => styleConstructor(styling)}
`;

const ReactAvatarStyled = styled(({ styling, ...rest }) => <ReactAvatar {...rest} />)`
    ${({ styling }) => styleConstructor(styling)}
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  display: inline-block;
`;

const Name = styled.span`
  margin-left: 0.5rem;
  vertical-align: middle;
`;

const Avatar = (props) => {
  const {
    showName, src: srcProp, size, round, name, title, initials, styling: srcStyling, children, ...rest
  } = props;
  const src = srcProp || children?.props?.src;
  const styling = srcStyling || children?.props?.styling;

  const getSizeEnum = () => {
    // ["mini","tiny","small","medium","large","big","huge","massive"
    let sizeEnum = 'mini';
    switch (size) {
      case size > 127:
        sizeEnum = 'massive';
        break;
      case size > 63:
        sizeEnum = 'huge';
        break;
      case size > 47:
        sizeEnum = 'large';
        break;
      case size > 39:
        sizeEnum = 'medium';
        break;
      case size > 23:
        sizeEnum = 'small';
        break;
      case size > 15:
        sizeEnum = 'tiny';
        break;
      default:
        break;
    }
    return sizeEnum;
  };

  const renderAvatar = () => {
    if (!src && (name || title || initials)) {
      return <ReactAvatarStyled {...props} />;
    }
    if (size && !styling) {
      const isEnum = typeof (size) === 'string';
      const El = round ? RoundImage : Image;
      return <El avatar spaced circular={round} size={isEnum ? size : getSizeEnum()} verticalAlign="middle" {...rest} src={src} />;
    }
    return <ImageStyled avatar spaced src={src} circular={round} verticalAlign="middle" styling={styling} {...rest} />;
  };

  const renderName = () => <Name>{name || title}</Name>;

  const renderAvatarAndName = () => (
    <Wrapper>
      {renderAvatar()}
      {renderName()}
    </Wrapper>
  );

  return showName ? renderAvatarAndName() : renderAvatar();
};

Avatar.defaultProps = {
  round: true,
  showName: false,
};

export default Avatar;
