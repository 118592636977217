import { MEETING_TYPE, AGENDA_TYPE } from 'Utils/Consts';
import {
  handleSignIn,
  handleSignUp,
  handleSignOut,
  handlePasswordForgot,
  handleSetAuthUser,
} from './auth';
import {
  handleGetMe, handleGetUserById, handleUpsertUser, handleSubscribeProvisionedUsers,
} from './user';
import {
  handleSetActiveMeeting,
  handleGetMyMeetings,
  handleGetGroupMeetings,
  handleSubscribeMeeting,
  handleUnsubscribe,
  handleSetActiveAgendaId,
  handleSetSelectedAgendaId,
  handleUpsertMeeting,
  handleUpsertAgenda,
  handleSortAgenda,
  handleSubscribeMyMeetings,
  handleSubscribeGroupMeetings,
  handleGetMeetingByScheduleKey,
} from './meeting';
import {
  handleSubscribeTimer,
  handleUnsubscribeTimer,
  handleAddTimerEvent,
} from './timer';
import { handleCreateJob } from './job';
import {
  handleUpsertTemplate, handleGetTemplateById, handleSubscribeTemplates, handleSubscribeMyTemplates,
} from './template';
import {
  handleSetMinutes, handleGetMinutes, handleUpsertMinutes, handleSubscribeMinutes,
} from './minutes';
import { handleSetNote, handleGetNotes } from './note';
import {
  handleUpsertTask,
  handleSubscribeMeetingTasks,
  handleSubscribeGroupTasks,
  handleSubscribeMyTasks,
  handleGetTaskById,
} from './task';
import {
  handleSetPresence, handleSubscribeParticipants, handleUnsubscribeParticipants,
} from './presence';
import { handleSubscribeAccounts, handleGetAccountById } from './account';
import {
  handleUpsertGroup, handleGetGroupById, handleGetGroupByHandle, handleSubscribeGroups,
} from './group';
import {
  handleUpsertResponse, handleSubscribeResponses, handleGetRsvpResponses,
} from './response';
import {
  handleUpsertComment, handleSubscribeCommentsByTopic,
} from './comment';
import { handleGetProjectsByAccountId } from './project';
import {
  handleUpsertSchedule, handleGetScheduleById, handleSubscribeGroupSchedules, handleSubscribeMySchedules,
} from './schedule';
import { handleGetSignedUri } from './signedUri';

const Actions = (Store) => ({
  onCreateJob: (state, job) => handleCreateJob(state, job),
  setAuthUser: (state, authUser) => handleSetAuthUser(state, authUser),
  onGetMe: (state, uid) => handleGetMe(Store, state, uid),
  onGetUserById: (state, id) => handleGetUserById(Store, state, id),
  onGetScheduleById: (state, id) => handleGetScheduleById(Store, state, id),
  onUpsertSchedule: (state, scheduleData, scheduleId, cb) => handleUpsertSchedule(Store, state, scheduleData, scheduleId, cb),
  onSubscribeGroupSchedules: (state, groupId, cb) => handleSubscribeGroupSchedules(Store, state, groupId, cb),
  onUnsubscribeGroupSchedules: (state, groupId) => handleUnsubscribe(Store, state, `${groupId}-schedules`),
  onSubscribeMySchedules: (state, uid, cb) => handleSubscribeMySchedules(Store, state, uid, cb),
  onUnsubscribeMySchedules: (state, uid) => handleUnsubscribe(Store, state, `${uid}-schedules`),
  onGetMeetingByScheduleKey: (state, scheduleKey) => handleGetMeetingByScheduleKey(Store, state, scheduleKey),
  onSignIn: (state, vals, cb) => handleSignIn(state, vals, cb),
  onSignUp: (state, vals, cb) => handleSignUp(state, vals, cb),
  onUpsertUser: (state, vals, cb, merge, isSignUp) => handleUpsertUser(state, vals, cb, merge, isSignUp),
  onPasswordForgot: (state, vals, cb) => handlePasswordForgot(state, vals, cb),
  onSignOut: () => handleSignOut(Store),
  onGetProjectsByAccountId: (state, accountId) => handleGetProjectsByAccountId(Store, state, accountId),
  onSetActiveMeeting: (state, id) => handleSetActiveMeeting(state, id),
  onGetMyMeetings: (state, params, cb) => handleGetMyMeetings(state, params, cb),
  onGetGroupMeetings: (state, groupId, params, cb) => handleGetGroupMeetings(state, groupId, params, cb),
  onSubscribeMeeting: (state, id, cb) => handleSubscribeMeeting(Store, state, id, cb),
  onUnsubscribeMeeting: (state, id) => handleUnsubscribe(Store, state, id),
  onSubscribeMyMeetings: (state, uid, cb) => handleSubscribeMyMeetings(Store, state, uid, cb),
  onUnsubscribeMyMeetings: (state, uid) => handleUnsubscribe(Store, state, `${uid}-meetings`),
  onSubscribeGroupMeetings: (state, groupId, cb) => handleSubscribeGroupMeetings(Store, state, groupId, cb),
  onUnsubscribeGroupMeetings: (state, groupId) => handleUnsubscribe(Store, state, `${groupId}-meetings`),
  onSubscribeMeetingTimer: (state, id) => handleSubscribeTimer(Store, state, id, MEETING_TYPE),
  onUnsubscribeMeetingTimer: (state, id) => handleUnsubscribeTimer(Store, state, id, MEETING_TYPE),
  onSubscribeAgendaTimer: (state, id) => handleSubscribeTimer(Store, state, id, AGENDA_TYPE),
  onUnsubscribeAgendaTimer: (state, id) => handleUnsubscribeTimer(Store, state, id, AGENDA_TYPE),
  onAddMeetingTimerEvent: (state, id, event) => handleAddTimerEvent(Store, state, id, MEETING_TYPE, event),
  onAddAgendaTimerEvent: (state, id, event) => handleAddTimerEvent(Store, state, id, AGENDA_TYPE, event),
  onSetActiveAgendaId: (state, id, meetingId) => handleSetActiveAgendaId(state, id, meetingId),
  onSetSelectedAgendaId: (state, id, meetingId) => handleSetSelectedAgendaId(state, id, meetingId),
  onSetNote: (state, meetingId, agendaId, note) => handleSetNote(Store, state, meetingId, agendaId, note),
  onSetMinutes: (state, meetingId, agendaId, minutes) => handleSetMinutes(Store, state, meetingId, agendaId, minutes),
  onGetNotes: (state, meetingId) => handleGetNotes(Store, state, meetingId),
  onGetMinutes: (state, meetingId) => handleGetMinutes(Store, state, meetingId),
  onSubscribeMinutes: (state, meetingId) => handleSubscribeMinutes(Store, state, meetingId),
  onUnsubscribeMinutes: (state, meetingId) => handleUnsubscribe(Store, state, `${meetingId}-minutes`),
  onUpsertMeeting: (state, meetingData, meetingId, cb) => handleUpsertMeeting(Store, state, meetingData, meetingId, cb),
  onUpsertMinutes: (state, minutesData, minutesId, cb) => handleUpsertMinutes(Store, state, minutesData, minutesId, cb),
  onUpsertAgenda: (state, agendaData, agendaId, cb) => handleUpsertAgenda(Store, state, agendaData, agendaId, cb),
  onSortAgenda: (state, agendaItems, meetingId, cb) => handleSortAgenda(Store, state, agendaItems, meetingId, cb),
  onUpsertTask: (state, taskData, taskId, cb) => handleUpsertTask(Store, state, taskData, taskId, cb),
  onGetTaskById: (state, taskId, cb) => handleGetTaskById(Store, state, taskId, cb),
  onSubscribeMeetingTasks: (state, meetingId, cb) => handleSubscribeMeetingTasks(Store, state, meetingId, cb),
  onUnsubscribeMeetingTasks: (state, meetingId) => handleUnsubscribe(Store, state, `${meetingId}-tasks`),
  onSubscribeGroupTasks: (state, groupId, cb) => handleSubscribeGroupTasks(Store, state, groupId, cb),
  onUnsubscribeGroupTasks: (state, groupId) => handleUnsubscribe(Store, state, `${groupId}-tasks`),
  onSetPresence: (state, meetingId) => handleSetPresence(Store, state, meetingId),
  onSubscribeParticipants: (state, meetingId) => handleSubscribeParticipants(Store, state, meetingId),
  onUnsubscribeParticipants: (state, meetingId) => handleUnsubscribeParticipants(Store, state, meetingId),
  onUpsertTemplate: (state, templateData, templateId) => handleUpsertTemplate(Store, state, templateData, templateId),
  onGetTemplateById: (state, templateId) => handleGetTemplateById(Store, state, templateId),
  onSubscribeGroupTemplates: (state, meetingId, cb) => handleSubscribeTemplates(Store, state, meetingId, cb),
  onUnsubscribeGroupTemplates: (state, groupId) => handleUnsubscribe(Store, state, groupId),
  onSubscribeMyTemplates: (state, uid, cb) => handleSubscribeMyTemplates(Store, state, uid, cb),
  onUnsubscribeMyTemplates: (state, uid) => handleUnsubscribe(Store, state, `${uid}-templates`),
  onSubscribeMyTasks: (state, uid, cb) => handleSubscribeMyTasks(Store, state, uid, cb),
  onUnsubscribeMyTasks: (state, uid) => handleUnsubscribe(Store, state, `${uid}-tasks`),
  onSubscribeAccounts: (state, uid, cb) => handleSubscribeAccounts(Store, state, uid, cb),
  onUnsubscribeAccounts: (state, uid) => handleUnsubscribe(Store, state, `${uid}-accounts`),
  onGetAccountById: (state, accountId) => handleGetAccountById(Store, state, accountId),
  onSubscribeGroups: (state, uid, cb) => handleSubscribeGroups(Store, state, uid, cb),
  onUnsubscribeGroups: (state, uid) => handleUnsubscribe(Store, state, `${uid}-groups`),
  onSubscribeProvisionedUsers: (state, accountId, cb) => handleSubscribeProvisionedUsers(Store, state, accountId, cb),
  onUnsubscribeProvisionedUsers: (state, accountId) => handleUnsubscribe(Store, state, `${accountId}-users`),
  onUpsertGroup: (state, groupData, groupId, cb) => handleUpsertGroup(Store, state, groupData, groupId, cb),
  onGetGroupById: (state, groupId) => handleGetGroupById(Store, state, groupId),
  onGetGroupByHandle: (state, handle) => handleGetGroupByHandle(Store, state, handle),
  onUpsertResponse: (state, responseData, responseId, cb) => handleUpsertResponse(Store, state, responseData, responseId, cb),
  onSubscribeResponses: (state, { responseId, entityId }, cb) => handleSubscribeResponses(Store, state, responseId, entityId, cb),
  onUnsubscribeResponses: (state, { responseId, entityId }) => handleUnsubscribe(Store, state, `${responseId || entityId}-responses`),
  onGetRsvpResponses: (state, meetingId, cb) => handleGetRsvpResponses(Store, state, meetingId, cb),
  onGetSignedUri: (state, params, attachmentId, cb) => handleGetSignedUri(Store, state, params, attachmentId, cb),
  onUpsertComment: (state, commentData, commentId, cb) => handleUpsertComment(Store, state, commentData, commentId, cb),
  onSubscribeCommentsByTopic: (state, topic, cb) => handleSubscribeCommentsByTopic(Store, state, topic, cb),
  onUnsubscribeComments: (state, topic) => handleUnsubscribe(Store, state, `${topic}-comments`),
});

export default Actions;
