import { getIsAdmin, forEach } from 'Utils';
import {
  getAccountById,
  subscribeAccounts,
} from '../firebase/account';
import { handleAsyncUsersBatch } from './user';
import { handleGetProjectsByAccountId } from './project';

const enrichAccount = (m, me) => {
  const isAdmin = getIsAdmin(m, me);
  m.isAdmin = isAdmin;
  return m;
};

export const handleGetAccountById = async (Store, state, accountId) => {
  const {
    accounts, features, me, projects,
  } = state;
  const account = await getAccountById(accountId);
  const a = enrichAccount(account, me);
  if (!projects[accountId]) handleGetProjectsByAccountId(Store, state, accountId);
  const savedAccounts = {
    accounts: {
      ...accounts,
      [accountId]: a,
    },
    features: {
      ...features,
      [accountId]: {
        isAdmin: a.isAdmin,
        handle: a.handle,
        toggles: a.isActive ? a.features : [],
        name: a.organization ? a.organization.name : null,
      },
    },
  };
  return savedAccounts;
};

export const handleSubscribeAccounts = async (Store, state, uid, cb) => {
  const { me } = state;
  const userId = uid || me.uid;
  const updateStore = ({ data, unsubscribe }) => {
    if (cb) cb({ res: data });
    const {
      accounts, features, subscriptions, projects,
    } = Store.getState();
    subscriptions[`${userId}-accounts`] = { unsubscribe };
    forEach(data, ({ id, ...account }) => {
      const a = enrichAccount(account, me);
      if (!projects[id]) handleGetProjectsByAccountId(Store, state, id);
      accounts[id] = a;
      features[id] = {
        isAdmin: a.isAdmin,
        handle: a.handle,
        toggles: a.isActive ? a.features : [],
        name: a.organization ? a.organization.name : null,
      };
    });
    Store.setState({ accounts: { ...accounts }, features: { ...features }, subscriptions });
    handleAsyncUsersBatch(Store, state, data);
  };
  await subscribeAccounts(userId, updateStore);
};
