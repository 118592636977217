import { getUserFullName } from 'Utils';
import { subscribePresence, unsubscribePresence, setPresence } from '../firebase/presence';

export const handleSetPresence = (Store, state, meetingId) => {
  const { me } = Store.getState();
  const {
    uid, firstName, lastName, avatarUri, pronouns = '',
  } = me;
  const userData = {
    uid, firstName, lastName, avatarUri, pronouns, fullName: getUserFullName(me),
  };
  setPresence(userData, meetingId);
};

export const handleSubscribeParticipants = (
  Store, state, id, cb,
) => {
  const updateParticipants = (data, key) => {
    const { participants } = Store.getState();
    if (!participants[id]) {
      participants[id] = {};
    }
    if (key) {
      participants[id][key] = { ...data };
    } else {
      participants[id] = { ...data };
    }

    if (cb) cb({ res: participants[id] });
    Store.setState({
      participants: { ...participants },
    });
  };
  if (!id) {
    console.error('Unable to subscribe to presence:', { id });
  }
  subscribePresence(id, updateParticipants);
};

export const handleUnsubscribeParticipants = (Store, state, id) => {
  const { participants } = Store.getState();
  delete participants[id];
  unsubscribePresence(id);
  return { participants };
};
