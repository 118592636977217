import { forEach } from 'Utils';
import { getInitialState } from './initialState';
import { auth } from '../firebase';

const {
  createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, passwordReset,
} = auth;

export const handleSignIn = (state, { emailAddress, password }, cb) => signInWithEmailAndPassword(emailAddress, password)
  .then(() => {
    const user = {
      ...state.user,
    };
    if (cb) cb({ res: user });
    return { user };
  })
  .catch((err) => {
    const { code, message } = err;
    if (cb) cb({ err: { code, message } });
  });

export const handleSignUp = async (state, { emailAddress, password, ...rest }, cb) => createUserWithEmailAndPassword(emailAddress, password)
  .then(async (authUser) => {
    if (!authUser) {
      console.error('Cannot get authUser');
      return;
    }
    const { user: { uid } } = authUser;
    const { confirmPassword, ...user } = rest;
    const me = {
      uid,
      emailAddress,
      ...user,
    };
    if (cb) cb({ res: me });
    return { authUser };
  })
  .catch((err) => {
    const { code, message } = err;
    if (cb) cb({ err: { code, message } });
  });

export const handlePasswordForgot = (state, { emailAddress }, cb) => passwordReset(emailAddress)
  .then(() => {
    if (cb) cb({ res: true });
    return true;
  })
  .catch((err) => {
    const { code, message } = err;
    if (cb) cb({ err: { code, message } });
  });

export const handleSignOut = (Store) => {
  const { subscriptions } = Store.getState();
  forEach(subscriptions, (_, subId) => {
    if (subscriptions[subId]) {
      const { unsubscribe } = subscriptions[subId];
      if (unsubscribe) {
        unsubscribe();
      }
      delete subscriptions[subId];
    }
  });
  Store.setState({ ...getInitialState() }, true);
  signOut();
};

export const handleSetAuthUser = async (state, authUser) => {
  const isAuthenticated = !!authUser;
  const uid = authUser ? authUser.uid : null;
  const me = {
    ...state.me,
    uid,
    isAuthenticated,
  };
  return { authUser, me };
};
