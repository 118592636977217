import _forEach from 'lodash/forEach';
import { getSignedDownloadUri } from '../firebase/functions';

export const handleGetSignedUri = async (Store, state, params, attachmentId, cb) => {
  const { signedUris } = Store.getState();
  if (signedUris[attachmentId]) return;
  const { signedUri } = await getSignedDownloadUri(params);
  if (!signedUri) return;
  if (cb) cb({ res: signedUri });
  const { signedUris: latestSignedUris } = Store.getState();
  Store.setState({ signedUris: { ...latestSignedUris, [attachmentId]: signedUri } });
};
