import { USER_ROLES } from 'Utils/Consts';
import { firestore } from './firebase';

const {
  OWNER, VIEWER, DELEGATE, MEMBER,
} = USER_ROLES;

const roles = [OWNER, VIEWER, DELEGATE, MEMBER];

const AccountsCollection = firestore.collection('accounts');

export const getAccountById = async (accountId) => AccountsCollection.doc(accountId).get()
  .then((doc) => {
    if (doc.exists) {
      const data = doc.data();
      return data;
    }
  });

export const subscribeAccounts = (uid, cb) => {
  if (!uid) return;
  const accountsRef = AccountsCollection.where(`users.${uid}.roles`, 'array-contains-any', roles);
  return new Promise((resolve, reject) => {
    const unsubscribe = accountsRef.onSnapshot(
      (docs) => {
        const data = {};
        docs.forEach((doc) => {
          data[doc.id] = { ...doc.data(), id: doc.id };
        });
        if (cb) cb({ data, unsubscribe });
        return resolve({ data, unsubscribe });
      },
      (err) => {
        console.log('Error getting accounts', err);
        reject();
      },
    );
  });
};
