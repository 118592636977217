import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { find } from 'lodash';
import Common from 'Common';
import {
  calcHumanFileSize, getHumanTime, getUrlDomain, forEach,
} from 'Utils';
import { EXTERNAL_FRAME, constructRoute } from '~/routes';
import { COLORS } from '~/styles/Consts';
import AttachmentViewer from '../AttachmentViewer';
import EmptyState from '../EmptyState';

const {
  Image, Table, Icon, Heading, Modal, Button, Confirm, Popup, Menu, Grid,
} = Common;

const FileName = styled.div`
    cursor: zoom-in;
    display: inline-block;
    margin-right: 0.5rem;
    :hover {
        color: ${COLORS.highlight};
    }
`;

const DocPreview = styled.div`
    cursor: pointer;
`;

const PopupStyled = styled(Popup)`
  padding: 0 !important;
`;

const MenuStyled = styled(Menu)`
  box-shadow: none !important;
  line-height: 1.2 !important;
  .header {
    margin-top: 0.5rem !important;
    color: ${COLORS.lightGray} !important;
  }
  .item {
    padding: 0 !important;
    a, div {
      padding: 0.5rem 0.8rem !important;
    }
  }
`;

const ActionsWrapper = styled.div`
  cursor: context-menu;
  text-align: center;
`;

const DeleteWrapper = styled.span`
  color: ${COLORS.error} !important;
  font-weight: bold;
`;

// TODO Refactor to be more generic (kludging task attachment support, but should be generic)
const Attachments = ({
  attachments, agendaId, taskId, agendaEvents, isAdmin, onDeleteAttachment, onAddAgendaEvent, signedUris, onGetSignedUri,
}) => {
  const [preview, setPreview] = useState(null);
  const [popupState, setPopupState] = useState({});
  const [deleteItem, setDeleteItem] = useState(null);

  const popupRef = useRef({});

  useEffect(async () => {
    const attachmentQueue = [];

    forEach(attachments, (val, id) => {
      if (!val) return;
      const attachmentId = val.id || id;
      const { location, url, isDeleted } = val;
      if (isDeleted || !location) return;
      // TODO Temp preserve backward-compatibility for files in Google Storage
      if (`${url}`.includes('https://firebasestorage.googleapis.com')) return;
      const filePathArr = location.split('/');
      const fileName = filePathArr.pop();
      attachmentQueue.push({
        id: attachmentId, filePath: filePathArr.join('/'), fileName,
      });
    });
    if (attachmentQueue.length > 0) {
      await Promise.all(attachmentQueue.map(async ({ id: attachmentId, ...params }) => onGetSignedUri(params, attachmentId)));
    }
  }, [attachments]);

  const handlePopupState = (id, bool = false) => {
    setPopupState(bool ? { [id]: bool } : {});
  };

  const handleCreateAttachmentEvent = (event) => {
    if (onAddAgendaEvent && agendaId) onAddAgendaEvent(agendaId, event);
  };

  const handlePreview = (id = null) => {
    if (!id) setPreview(null);
    else {
      const a = find(attachments, { id });
      if (popupState[id]) {
        handlePopupState(id);
        popupRef.current[id].click();
      }
      if (signedUris[id]) a.url = signedUris[id];
      setPreview(a || null);
    }
  };

  // const handleDownloadFile = (attachment) => {
  //   const { id } = attachment;
  //   downloadFile(attachment);
  //   popupRef.current[id].click();
  // };

  const handleSetDeleteItem = (id, name) => {
    setDeleteItem(id ? { id, name } : null);
    if (id && popupRef.current[id]) {
      popupRef.current[id].click();
    }
  };

  const handleOnDeleteAttachment = (id) => {
    onDeleteAttachment(id);
    handleSetDeleteItem();
  };

  const handleOnCancelDeleteAttachment = () => {
    handleSetDeleteItem();
  };

  const getDocType = (type) => {
    let contentType = 'image';
    switch (true) {
      case `${type}`.includes('image'): {
        contentType = 'image';
        break;
      }
      case `${type}`.includes('link'): {
        contentType = 'link';
        break;
      }
      case `${type}`.includes('pdf'): {
        contentType = 'pdf';
        break;
      }
      case `${type}`.includes('audio'): {
        contentType = 'audio';
        break;
      }
      case `${type}`.includes('video'): {
        contentType = 'video';
        break;
      }
      default:
        break;
    }
    return contentType;
  };

  const getIcon = (type) => {
    const contentType = getDocType(type);
    let icon = 'file outline';
    switch (contentType) {
      case 'pdf': {
        icon = 'file pdf outline';
        break;
      }
      case 'image': {
        icon = 'file image outline';
        break;
      }
      case 'video': {
        icon = 'file video outline';
        break;
      }
      case 'audio': {
        icon = 'file audio outline';
        break;
      }
      case 'link': {
        icon = 'linkify';
        break;
      }
      default:
        break;
    }
    return icon;
  };

  const renderDocPreview = ({
    id, thumbnailUrl, url, type,
  }) => {
    const contentType = getDocType(type);
    if (contentType === 'image') {
      return <Image src={signedUris[id] || thumbnailUrl || url} size="mini" centered />;
    }
    if (contentType === 'pdf') {
      return <Icon name="file pdf" size="big" />;
    }
    if (contentType === 'video') {
      return <Icon name="file video" size="big" />;
    }
    if (contentType === 'audio') {
      return <Icon name="file audio" size="big" />;
    }
    if (contentType === 'link') {
      return <Icon name="linkify" size="big" />;
    }
    return <Icon name="file" size="big" />;
  };

  const renderDeleteAttachment = () => {
    if (!deleteItem || !isAdmin) return null;
    const { id, name } = deleteItem;
    return (
      <Confirm
        elementProps={{
          content: 'Delete',
          icon: 'trash alternate',
          size: 'tiny',
          fluid: true,
          styling: { backgroundColor: COLORS.error, color: COLORS.white },
        }}
        header={`Delete '${name}'`}
        content={`Are you sure you want to delete '${name}'?`}
        confirmButton="Delete"
        cb={() => handleOnDeleteAttachment(id)}
        cancelCb={handleOnCancelDeleteAttachment}
        element={() => null}
        isOpen={!!deleteItem}
      />
    );
  };

  const renderMenu = (attachment) => {
    const {
      id, name, url, type,
    } = attachment;
    const tabUrl = constructRoute({
      route: EXTERNAL_FRAME,
      queries: { attachment: btoa(JSON.stringify({ ...attachment, url: signedUris[id] || url })) },
    });
    const isLink = type === 'link';
    return (
      <MenuStyled size="small" vertical compact fluid>
        <Menu.Item>
          <Menu.Header>
            <Icon name={getIcon(type)} />
            {isLink ? `${name} (${getUrlDomain(url)})` : name}
          </Menu.Header>
          <Menu.Item onClick={() => handlePreview(id)}>
            <Icon name="search plus" />
            Preview
          </Menu.Item>
          <Menu.Item href={isLink ? url : tabUrl} target="_blank">
            <Icon name="external alternate" />
            Open in new tab
          </Menu.Item>
          {!isLink && (
            <Menu.Item as="a" href={signedUris[id] || url} download={attachment.name}>
              <Icon name="download" />
              Download
            </Menu.Item>
          )}
          {isAdmin && (
            <Menu.Item onClick={() => handleSetDeleteItem(id, name)}>
              <Icon name="trash alternate" color={COLORS.error} />
              <DeleteWrapper>
                Delete …
              </DeleteWrapper>
            </Menu.Item>
          )}
        </Menu.Item>
      </MenuStyled>
    );
  };

  const renderMoreActionsIcon = (attachment) => {
    const { id } = attachment;

    return (
      <PopupStyled
        content={renderMenu(attachment)}
        trigger={(
          <ActionsWrapper
            ref={(ref) => {
              popupRef.current[id] = ref;
            }}
          >
            <Icon name="ellipsis vertical" />
          </ActionsWrapper>
        )}
        size="mini"
        position="top center"
        on="click"
        open={popupState[id]}
        onOpen={() => handlePopupState(id, true)}
        onClose={() => handlePopupState(id)}
        hideOnScroll
      />
    );
  };

  const renderAttachment = (attachment) => {
    const {
      id, name, url, thumbnailUrl, size, type, lastModified, uploadedAt, location,
    } = attachment;
    return (
      <Table.Row key={id}>
        <Table.Cell textAlign="center" verticalAlign="middle">
          <DocPreview onClick={() => handlePreview(id)}>
            {renderDocPreview({
              thumbnailUrl, url: signedUris[id] || url, type, location,
            })}
          </DocPreview>
        </Table.Cell>
        <Table.Cell>
          <FileName onClick={() => handlePreview(id)}>
            <Heading as="h5">
              <Icon name={getIcon(type)} />
              {type === 'link' ? `${name} (${getUrlDomain(url)})` : name}
            </Heading>
          </FileName>
        </Table.Cell>
        <Table.Cell>{calcHumanFileSize(size)}</Table.Cell>
        {/* <Table.Cell>
        {getHumanTime(lastModified, DATE_FORMAT)}
        <br />
        {getHumanTime(lastModified, TIME_FORMAT)}
      </Table.Cell>
      <Table.Cell>
        {getHumanTime(uploadedAt, DATE_FORMAT)}
        <br />
        {getHumanTime(uploadedAt, TIME_FORMAT)}
      </Table.Cell> */}
        <Table.Cell>
          {renderMoreActionsIcon(attachment)}
        </Table.Cell>
      </Table.Row>
    );
  };

  const renderPreviewContent = () => (
    <AttachmentViewer
      attachment={preview}
      onMediaAction={handleCreateAttachmentEvent}
      isAdmin={isAdmin}
      events={agendaEvents}
    />
  );

  const renderEmptyState = () => !attachments.length
    ? <EmptyState icon="file" message="No attachments here" />
    : null;

  const renderPreview = () => preview ? (
    <Modal
      open
      // dimmer="blurring"
      onClose={() => handlePreview()}
    >
      <Modal.Header>
        <Grid columns={2}>
          <Grid.Column>{preview.name}</Grid.Column>
          <Grid.Column textAlign="right" verticalAlign="middle">
            <Icon link name="close" onClick={() => handlePreview()} />
          </Grid.Column>
        </Grid>
      </Modal.Header>
      <Modal.Content>
        {renderPreviewContent()}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => handlePreview()}>Close</Button>
      </Modal.Actions>
    </Modal>
  ) : null;

  return attachments.length
    ? (
      <>
        <Table basic="very" celled collapsing>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Preview</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Size</Table.HeaderCell>
              {/* <Table.HeaderCell>Modified Date</Table.HeaderCell>
              <Table.HeaderCell>Uploaded Date</Table.HeaderCell> */}
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {attachments.map(renderAttachment)}
          </Table.Body>
        </Table>
        {renderPreview()}
        {deleteItem && renderDeleteAttachment()}
      </>
    )
    : (
      <>
        {renderEmptyState()}
        {renderPreview()}
      </>
    );
};

export default Attachments;
