import React, { lazy, memo } from 'react';
import BusyIndicator from 'react-busy-indicator';
import { NotFoundBoundary, useLoadingRoute } from 'react-navi';

const NotFound = lazy(() => import('~/containers/_NotFound'));

const RouteWrapper = ({ children }) => {
  const loadingRoute = useLoadingRoute();

  const renderNotFound = () => <NotFound />;
  return (
    <>
      <BusyIndicator isBusy={!!loadingRoute} delayMs={200} />
      <NotFoundBoundary render={renderNotFound}>
        {children}
      </NotFoundBoundary>
    </>
  );
};

export default memo(RouteWrapper);
