import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';
import _isEqual from 'lodash/isEqual';
import Common from 'Common';
import {
  makeArray, sort, getLocalDateTime, getTimerContext,
} from 'Utils';
import { MEETING_STATUSES, MEETING_STATUS_LABELS } from 'Utils/Consts';
// import { useWorker, WORKER_STATUS } from 'Hooks/useWorker';
import { COLORS } from '~/styles/Consts';
import { media } from '~/styles/Utils';

const {
  Heading, Label, Button, Icon, Divider, Popup, Spinner,
} = Common;

const Wrapper = styled.div`
  margin-left: 1rem;
`;

const MeetingListStyled = styled.div`
  padding-right: 1rem;
  max-height: 42vh;
  overflow-y: auto;
  ${media.s`
    max-height: unset;
    overflow-y: unset;
  `}
`;

const Meeting = styled.div`
  cursor: pointer;
  border-radius: 6px;
  padding: 1rem;
  background-color: ${COLORS.primaryLight};
  color: ${COLORS.primary};
  margin-bottom: 1rem;
  border: 2px solid ${COLORS.highlight};
  border-left: 9px solid ${COLORS.highlight};
  :hover {
    border: 2px solid ${COLORS.primary};
    border-left: 9px solid ${COLORS.primary};
  }
`;

const MeetingStatus = styled(Meeting)`
  color: ${COLORS.active};
  border: 2px solid ${COLORS.active};
  border-left: 9px solid ${COLORS.active};
`;

const MeetingTitle = styled.div`
  font-size: 1rem;
  font-weight: 600;
`;

const MeetingSummary = styled.div`
  margin: 2px 0;
`;

const MeetingDescription = styled.div`
  font-size: 0.85rem;
`;

const Status = styled(Label)`
  float: right !important;
  /* background-color: ${COLORS.primary} !important;
  color: ${COLORS.white} !important; */
`;

const MenuIcon = styled.div`
  float: right !important;
  margin-top: 1rem;
  margin-right: 0.2rem;
`;

const IconStyled = styled(Icon)`
  cursor: pointer;
  opacity: 0.3 !important;
  :hover {
    opacity: 1 !important;
  }
`;

const MeetingList = ({
  meetings,
  groups,
  isAdmin,
  loading,
  selectedMonth,
  onGoToMeeting,
  onGoToScheduledMeeting,
  onGoToNote,
  onCreateMeeting,
}) => {
  const [sortedMeetingIds, setSortedMeetingIds] = useState({});
  const [prevSortedMeetingIds, setPrevSortedMeetingIds] = useState({});
  const [meetingDates, setMeetingDates] = useState([]);
  const [prevMeetingDates, setPrevMeetingDates] = useState([]);
  const [showPrevious, setShowPrevious] = useState(false);
  const { current: localTimes } = useRef({});
  const { current: currentMonth } = useRef(moment().format('Y-MM'));
  // const workerTimer = useRef(null);

  const processMeetingData = (mData) => {
    const sortedMeetings = {};
    const prevSortedMeetings = {};
    const dateTimes = [];
    const prevDateTimes = [];
    const today = moment().startOf('day');
    makeArray(mData, (data) => sort(data, ['startDateTime', 'title'], ['asc', 'asc']))
      .filter(({ isDeleted }) => !isDeleted)
      .forEach(({
        id, startDateTime, endDateTime, timeZone,
      }) => {
        const { isAfter } = getTimerContext({ startDateTime, endDateTime }, today);
        localTimes[id] = getLocalDateTime(startDateTime, endDateTime, timeZone);
        const {
          year, month, day, weekDay,
        } = localTimes[id];
        const dateTime = `${weekDay} ${month} ${day} ${year}`;
        if (isAfter) {
          if (prevDateTimes.indexOf(dateTime) < 0) prevDateTimes.push(dateTime);
          if (!prevSortedMeetings[dateTime]) prevSortedMeetings[dateTime] = [];
          prevSortedMeetings[dateTime].push(id);
        } else {
          if (dateTimes.indexOf(dateTime) < 0) dateTimes.push(dateTime);
          if (!sortedMeetings[dateTime]) sortedMeetings[dateTime] = [];
          sortedMeetings[dateTime].push(id);
        }
      });
    return ({
      sortedMeetings, prevSortedMeetings, dateTimes, prevDateTimes: prevDateTimes.reverse(),
    });
  };

  // const [
  //   processMeetingDataWorker,
  //   { status: processMeetingDataWorkerStatus, kill: processMeetingDataWorkerStop },
  // ] = useWorker(processMeetingData, { autoTerminate: true });

  useEffect(() => {
    let pMeetingData = {};
    // if (_isEqual(meetings, {})) {
    //   return;
    // }
    pMeetingData = processMeetingData(meetings);
    const {
      sortedMeetings, prevSortedMeetings, dateTimes, prevDateTimes,
    } = pMeetingData;
    setSortedMeetingIds(sortedMeetings);
    setPrevSortedMeetingIds(prevSortedMeetings);
    setMeetingDates(dateTimes);
    setPrevMeetingDates(prevDateTimes);

    if (currentMonth < selectedMonth) {
      setShowPrevious(false);
    } else if (currentMonth > selectedMonth) {
      setShowPrevious(true);
    }

    // const stopWorker = () => {
    //   if (workerTimer.current && (processMeetingDataWorkerStatus !== WORKER_STATUS.RUNNING)) {
    //     clearInterval(workerTimer);
    //     workerTimer.current = null;
    //     console.log(workerTimer.current, processMeetingDataWorkerStatus);
    //   } else {
    //     processMeetingDataWorkerStop();
    //     console.log('KILL', workerTimer.current, processMeetingDataWorkerStatus);
    //   }
    // };

    // processMeetingDataWorkerStop();
    // const processData = async () => {
    //   const hasRunningWorker = (processMeetingDataWorkerStatus === WORKER_STATUS.RUNNING);

    //   if (hasRunningWorker) {
    //     if (!workerTimer.current) workerTimer.current = setInterval(processData, 1050);
    //     stopWorker();
    //   } else {
    //     console.log('PROCESS');
    //     pMeetingData = await processMeetingDataWorker(meetings).then((res) => console.log({ res })).catch((err) => console.error(err)) || {};

    //     console.log('##########', pMeetingData, processMeetingDataWorkerStatus);
    //     const {
    //       sortedMeetings, prevSortedMeetings, dateTimes, prevDateTimes,
    //     } = pMeetingData;
    //     setSortedMeetingIds(sortedMeetings);
    //     setPrevSortedMeetingIds(prevSortedMeetings);
    //     setMeetingDates(dateTimes);
    //     setPrevMeetingDates(prevDateTimes);
    //   }
    // };
    // processData();
  }, [meetings, selectedMonth]);

  // TODO Dropdown menu <IconStyled name="ellipsis vertical" />;
  const renderMenu = (mId) => (
    <Popup
      basic
      inverted
      size="mini"
      position="top center"
      trigger={(
        <span>
          <IconStyled name="clipboard outline" onClick={() => onGoToNote(mId)} />
        </span>
      )}
    >
      Go to My Notes
    </Popup>
  );

  const renderStatus = (status) => {
    let c = null;
    switch (status) {
      case MEETING_STATUSES.PENDING:
        c = 'grey';
        break;
      case MEETING_STATUSES.COMMENCED:
        c = 'teal';
        break;
      case MEETING_STATUSES.RECESS:
        c = 'orange';
        break;
      case MEETING_STATUSES.ADJOURNED:
        c = 'blue';
        break;
      case MEETING_STATUSES.CANCELLED:
        c = 'red';
        break;
      default:
        break;
    }
    // redorangeyellowolivegreentealbluevioletpurplepinkbrowngreyblack;
    return (
      <Status size="tiny" color={c}>
        {MEETING_STATUS_LABELS[status]}
      </Status>
    );
  };

  const renderMeetingItem = (mId) => {
    if (!meetings[mId]) return null;
    const {
      title, description, status, groupId, isScheduled,
    } = meetings[mId];
    const time = localTimes[mId];
    const isPending = status === MEETING_STATUSES.PENDING;
    const El = isPending ? Meeting : MeetingStatus;
    return (
      <React.Fragment key={mId}>
        {!isScheduled && (
          <MenuIcon>
            {renderMenu(mId)}
          </MenuIcon>
        )}
        <El key={mId} onClick={() => isScheduled ? onGoToScheduledMeeting(mId) : onGoToMeeting(mId)}>
          <MeetingTitle>
            {title}
            {!isPending && renderStatus(status)}
          </MeetingTitle>
          {groups && groupId && groups[groupId] && (
            <MeetingDescription>
              <Icon name="group" />
              {groups[groupId].title}
            </MeetingDescription>
          )}
          <MeetingSummary>
            {`${time.timeRange} ${time.zone}`}
          </MeetingSummary>
          <MeetingDescription>
            {description}
          </MeetingDescription>
        </El>
      </React.Fragment>
    );
  };

  const renderMeetingGroup = (group) => (
    <div key={group}>
      <Heading as="h4" block>{group}</Heading>
      {(sortedMeetingIds[group] || prevSortedMeetingIds[group]).map(renderMeetingItem)}
    </div>
  );

  const renderMeetingList = () => (
    <MeetingListStyled>
      {(showPrevious ? prevMeetingDates : meetingDates).map(renderMeetingGroup)}
    </MeetingListStyled>
  );

  const renderTogglePrevious = () => (
    <Button.Group size="mini" fluid>
      <Button
        basic
        disabled={showPrevious}
        content="Look Back"
        onClick={() => setShowPrevious(!showPrevious)}
        size="mini"
        styling={{
          margin: '0 auto 0.5rem', display: 'block', fontWeight: !showPrevious ? '' : 600,
        }}
      />
      <Button
        basic
        disabled={!showPrevious}
        content="Look Ahead"
        onClick={() => setShowPrevious(!showPrevious)}
        size="mini"
        styling={{
          margin: '0 auto 0.5rem', display: 'block', opacity: 1, fontWeight: showPrevious ? '' : 600,
        }}
      />
    </Button.Group>
  );

  const renderCreateMeeting = () => (
    <Button
      onClick={() => onCreateMeeting(new Date())}
      fluid
    >
      <Icon name="calendar alternate outline" />
      Create Meeting
    </Button>
  );

  const renderLoading = () => <Spinner />;

  return (
    <Wrapper>
      <Heading as="h3">Meetings</Heading>
      {isAdmin && (
        <>
          {renderCreateMeeting()}
          <Divider />
        </>
      )}
      {currentMonth === selectedMonth && renderTogglePrevious()}
      {loading ? renderLoading() : renderMeetingList()}
    </Wrapper>
  );
};

export default MeetingList;
