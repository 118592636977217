const req = require.context('.', true, /\.js$/);

const components = {};

req.keys().forEach((key) => {
  const componentName = key.replace(/^.+\/([^/]+)\/index\.js/, '$1');
  if (key !== './index.js') {
    // Exclude self
    components[componentName] = req(key).default;
  }
});

export default components;
