import React, { useEffect, useRef } from 'react';
import _isEqual from 'lodash/isEqual';

const Debug = (props) => {
  const p = useRef({});

  useEffect(() => {
    if (!_isEqual(p.current, props)) {
      p.current = props;
      console.log(props);
    }
  }, [props]);

  return (
    <div>
      <pre>{JSON.stringify(props, null, 2)}</pre>
    </div>
  );
};

export default Debug;
