import _get from 'lodash/get';
import _merge from 'lodash/merge';
import {
  processFormData, docId, sleep, getIsAdmin, forEach,
} from 'Utils';
import { VISIBILITY_ENUMS } from 'Utils/Consts';
import { fsTimestamp } from '../firebase';
import {
  // upsertTask,
  getTaskById,
  subscribeMeetingTasks,
  subscribeGroupTasks,
  subscribeMyTasks,
} from '../firebase/task';
import { handleAsyncUsersBatch } from './user';
import { upsertTask as upsertTaskApi } from '../firebase/functions';

const enrichTask = (t, me, m = {}, g = {}) => {
  const isAdmin = getIsAdmin(t, me) || getIsAdmin(m, me) || getIsAdmin(g, me);
  t.isAdmin = isAdmin;
  return t;
};

export const handleUpsertTask = async (
  Store,
  state,
  taskData,
  taskId,
  cb
) => {
  const {
    tasks,
    meetings,
    groups,
    accounts,
    me: { uid: userId },
  } = state;
  const {
    meetingId, createdBy, visibility,
  } = taskData;
  const taskFragment = createdBy ? taskData : {
    ...taskData,
    createdBy: userId,
  };

  if (!visibility) {
    taskFragment.visibility = VISIBILITY_ENUMS.PRIVATE;
  }
  if (!meetingId) {
    taskFragment.meetingId = '';
  }
  if (!taskFragment.groupId) {
    const groupId = _get(meetings, `${meetingId}.groupId`, '');
    taskFragment.groupId = groupId;
  }
  if (!taskFragment.accountId) {
    let accountId = meetingId ? _get(meetings, `${meetingId}.accountId`) : _get(groups, `${taskFragment.groupId}.accountId`, '');
    if (!accountId) {
      forEach(accounts, (val, key) => {
        if (val.isActive) {
          accountId = key;
        }
      });
    }
    taskFragment.accountId = accountId;
  }
  const pTask = processFormData(taskFragment, userId, fsTimestamp, !!taskId);
  // await upsertTask(taskId, pTask, cb);
  const tId = taskId || docId();
  const parentId = pTask.meetingId || userId;
  const cachedTask = _get(tasks, `${parentId}.${tId}`, {});
  const payload = taskId ? { id: tId, task: pTask } : { docId: tId, task: pTask };
  await upsertTaskApi(payload)
    .then((res) => {
      if (cb) cb({ res });
      return res;
    }).catch((err) => {
      console.error('Error upserting task:', err);
      // TODO Restore cached state; have way to re-try???
    });
  // await sleep(180); // Fake a bit of time for spinner to run since we don't wait on API call to return
  // const newTasks = {
  //   tasks: {
  //     ...tasks,
  //     [parentId]: { ...tasks[parentId], [tId]: _merge(cachedTask, pTask) },
  //   },
  // };
  // Store.setState(newTasks); // Optimistically update state
  // // TODO Handle optimistic upsert failures??
  // // TODO Update in response, avoid setState above race condition
  // handleGetTaskById(Store, state, tId);
};

export const handleGetTaskById = async (Store, state, taskId) => {
  const { tasks, me } = state;
  const task = await getTaskById(taskId);
  const { meetingId } = task;
  const parentId = meetingId || me.uid;
  const savedTasks = {
    tasks: {
      ...tasks,
      [parentId]: {
        ...tasks[parentId], [taskId]: enrichTask(task, me),
      },
    },
  };
  return savedTasks;
};

export const handleSubscribeMeetingTasks = async (Store, state, meetingId, cb) => {
  const { me } = state;
  const updateStore = ({ data, unsubscribe }) => {
    if (cb) cb({ res: data });
    const { tasks, subscriptions } = Store.getState();
    subscriptions[`${meetingId}-tasks`] = { unsubscribe };
    forEach(data, ({ id, ...task }) => {
      if (!tasks[meetingId]) tasks[meetingId] = {};
      tasks[meetingId][id] = enrichTask(task, me);
    });
    Store.setState({ tasks: { ...tasks }, subscriptions });
    handleAsyncUsersBatch(Store, state, data);
  };
  await subscribeMeetingTasks(meetingId, updateStore);
};

export const handleSubscribeGroupTasks = async (Store, state, groupId, cb) => {
  const { me } = state;
  const updateStore = ({ data, unsubscribe }) => {
    if (cb) cb({ res: data });
    const { tasks, subscriptions } = Store.getState();
    subscriptions[`${groupId}-tasks`] = { unsubscribe };
    forEach(data, ({ id, ...task }) => {
      const { meetingId } = task;
      if (meetingId) {
        if (!tasks[meetingId]) tasks[meetingId] = {};
        tasks[meetingId][id] = enrichTask(task, me);
      } else {
        if (!tasks[groupId]) tasks[groupId] = {};
        tasks[groupId][id] = enrichTask(task, me);
      }
    });
    Store.setState({ tasks: { ...tasks }, subscriptions });
    handleAsyncUsersBatch(Store, state, data);
  };
  await subscribeGroupTasks(groupId, updateStore);
};

export const handleSubscribeMyTasks = async (Store, state, uid, cb) => {
  const { me } = state;
  const updateStore = ({ data, unsubscribe }) => {
    if (cb) cb({ res: data });
    const { tasks, subscriptions } = Store.getState();
    subscriptions[`${me.uid}-tasks`] = { unsubscribe };
    forEach(data, ({ id, ...task }) => {
      const { meetingId, groupId } = task;
      if (meetingId) {
        if (!tasks[meetingId]) tasks[meetingId] = {};
        tasks[meetingId][id] = enrichTask(task, me);
      } else if (groupId) {
        if (!tasks[groupId]) tasks[groupId] = {};
        tasks[groupId][id] = enrichTask(task, me);
      } else {
        if (!tasks[me.uid]) tasks[me.uid] = {};
        tasks[me.uid][id] = enrichTask(task, me);
      }
    });
    Store.setState({ tasks: { ...tasks }, subscriptions });
    handleAsyncUsersBatch(Store, state, data);
  };
  await subscribeMyTasks(uid || me.uid, updateStore);
};
