export const getInitialState = () => ({
  subscriptions: {},
  authUser: null,
  me: {
    isAuthenticated: null,
    isAdmin: null,
  },
  activeMeetingId: null,
  activeAgendaId: {},
  selectedAgendaId: {},
  agendas: {},
  meetings: {},
  schedules: {},
  scheduledMeetings: {},
  users: {},
  participants: {},
  minutes: {},
  notes: {},
  responses: {},
  templates: {},
  accounts: {},
  features: {},
  groups: {},
  groupHandles: {},
  notifications: {},
  comments: {},
  tasks: {},
  categories: {},
  projects: {},
  tags: {},
  timers: {},
  events: {},
  signedUris: {},
});

export const initialState = getInitialState();
