import React, { useState } from 'react';
import styled from 'styled-components';
import { Confirm as SuiConfirm } from 'semantic-ui-react';
import { COLORS } from '~/styles/Consts';
import Button from '../Button';
import Icon from '../Icon';
import Popup from '../Popup';

const Wrapper = styled.div`
    padding: 1.5rem;
`;

const ConfirmStyled = styled(({ confirmButtonColor, ...rest }) => <SuiConfirm {...rest} />)`
    button.ui.primary {
        background-color: ${({ confirmButtonColor }) => confirmButtonColor} !important;
    }
`;

const Confirm = ({
  element: Element,
  elementProps,
  cancelButton,
  confirmButton,
  confirmButtonColor,
  content,
  header,
  popup,
  icon,
  size,
  cb,
  cancelCb,
  isOpen,
}) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const handleInvokeConfirm = () => {
    setShowConfirmDialog(true);
  };

  const handleConfirm = () => {
    if (cb) cb(true);
    setShowConfirmDialog(false);
  };

  const handleCancel = () => {
    if (cancelCb) cancelCb(false);
    setShowConfirmDialog(false);
  };

  const renderContent = () => (
    <Wrapper>
      {(icon && content) && <Icon name={icon} />}
      {content}
    </Wrapper>
  );

  const renderTrigger = () => <Element {...elementProps} onClick={handleInvokeConfirm} />;

  const renderPopup = () => (
    <Popup
      trigger={<div>{renderTrigger()}</div>}
      content={popup}
      on="hover"
      hideOnScroll
      size="small"
      inverted
    />
  );

  return (
    <>
      {popup ? renderPopup() : renderTrigger()}
      <ConfirmStyled
        header={header}
        content={renderContent()}
        open={showConfirmDialog || isOpen}
        cancelButton={cancelButton}
        confirmButton={confirmButton}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        size={size}
        confirmButtonColor={confirmButtonColor}
      />
    </>
  );
};

Confirm.defaultProps = {
  element: Button,
  size: 'mini',
  cancelButton: 'Cancel',
  confirmButton: 'OK',
  icon: 'warning sign',
  confirmButtonColor: COLORS.error,
  isOpen: false,
};

export default Confirm;
