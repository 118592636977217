import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import ReactPlayer from 'react-player/lazy';
import Common from 'Common';
import {
  calcHumanFileSize, getHumanTime, calcDuration, forEach,
} from 'Utils';
import { EVENT_TYPES } from 'Utils/consts';
import { COLORS } from '~/styles/Consts';
import PdfViewer from './PdfViewer';

const { Button, Image } = Common;

const PLAY = 'PLAY';
const PAUSE = 'PAUSE';
const MUTE = 'MUTE';
const UNMUTE = 'UNMUTE';

const IFrame = styled.iframe`
    width: 100%;
    height: 76vh;
    border: none;
`;

// const ExternalLink = styled.a`
//     color: ${COLORS.primary};
//     :hover {
//         color: ${COLORS.highlight};
//     }
// `;

const AttachmentViewer = ({
  attachment, events, isAdmin, onMediaAction,
}) => {
  const [attachmentMuted, setAttachmentMuted] = useState(true);
  const [attachmentPlaying, setAttachmentPlaying] = useState(true);

  const attachmentRef = useRef(null);
  const attachmentIdForAllParticipantsRef = useRef(null);
  const isAttachmentInvokedFromEventRef = useRef(false);
  const attachmentStateRef = useRef(null);

  const handleAvMediaAction = (action) => {
    switch (action) {
      case PLAY:
        setAttachmentPlaying(true);
        // previewRef.current.play();
        break;
      case PAUSE:
        setAttachmentPlaying(false);
        // previewRef.current.pause();
        break;
      case MUTE:
        setAttachmentMuted(true);
        // previewRef.current.muted = true;
        break;
      case UNMUTE:
        setAttachmentMuted(false);
        // previewRef.current.muted = false;
        break;
      default:
        break;
    }
  };

  const handleControlAvMedia = (action = null) => {
    let type = EVENT_TYPES.CLOSE_ATTACHMENT;
    switch (action) {
      case EVENT_TYPES.PLAY_AV_MEDIA:
        attachmentIdForAllParticipantsRef.current = attachment?.id;
        type = EVENT_TYPES.PLAY_AV_MEDIA;
        break;
      case EVENT_TYPES.PAUSE_AV_MEDIA:
        attachmentIdForAllParticipantsRef.current = attachment?.id;
        type = EVENT_TYPES.PAUSE_AV_MEDIA;
        break;
      default:
        attachmentIdForAllParticipantsRef.current = null;
        break;
    }
    const event = {
      attachmentId: attachment?.id,
      state: {
        currentTime: attachmentRef?.current?.currentTime || 0,
      },
      type,
    };
    if (onMediaAction) onMediaAction(event);
  };

  useEffect(() => {
    if (!attachment && attachmentIdForAllParticipantsRef.current) {
      // TODO dispatch close event
    } else if (attachment) {
      if (attachmentRef.current) {
        const { currentTime, lastStartTime, action } = attachmentStateRef.current?.state || {};
        let ts = currentTime || 0;
        const now = moment().format('X');
        const d = calcDuration({
          startDateTime: lastStartTime,
          startFormat: 'X',
          endDateTime: now,
          endFormat: 'X',
        });
        ts += d * 0.001;
        attachmentRef.current.currentTime = ts;
        switch (action) {
          case EVENT_TYPES.PLAY_AV_MEDIA:
            handleAvMediaAction(PLAY);
            setTimeout(() => {
              // handleAvMediaActionDb(UNMUTE);
            }, 5000);
            break;
          case EVENT_TYPES.PAUSE_AV_MEDIA:
            handleAvMediaAction(PAUSE);
            break;
          default:
            break;
        }
      }
    }
  }, [attachment, events]);

  useEffect(() => {
    // TODO Monitor events to control AV media for others
    let activeAttachmentId = null;
    let aLastStartTime = null;
    let aState = null;
    forEach(events, (aEvent, aId) => {
      const { active, state } = aEvent;
      if (active) {
        activeAttachmentId = aId;
        aLastStartTime = state?.lastStartTime;
        aState = state;
      }
    });
    if (activeAttachmentId) {
      isAttachmentInvokedFromEventRef.current = true;
      attachmentStateRef.current = {
        state: aState, lastStartTime: aLastStartTime,
      };
    //   handlePreview(activeAttachmentId);
    } else if (!activeAttachmentId && attachment) {
    //   handlePreview();
    }
  },
  [events]);

  const getDocType = (type) => {
    let contentType = 'image';
    switch (true) {
      case `${type}`.includes('image'): {
        contentType = 'image';
        break;
      }
      case `${type}`.includes('link'): {
        contentType = 'link';
        break;
      }
      case `${type}`.includes('pdf'): {
        contentType = 'pdf';
        break;
      }
      case `${type}`.includes('audio'): {
        contentType = 'audio';
        break;
      }
      case `${type}`.includes('video'): {
        contentType = 'video';
        break;
      }
      default:
        break;
    }
    return contentType;
  };

  const renderContent = () => {
    if (!attachment) return null;
    if (!attachment.url) return 'Loading …';
    const contentType = getDocType(attachment.type);
    if (contentType === 'audio') {
      return 'Unsupported file type (audio, coming soon)';
    }
    if (contentType === 'video') {
      return (
        <>
          <ReactPlayer
            ref={attachmentRef}
            url={attachment.url}
            width="800px"
            controls={isAdmin || true}
            playing={attachmentPlaying}
            muted={attachmentMuted}
          >
          </ReactPlayer>
          {isAdmin && false && (
            <>
              <Button onClick={() => handleControlAvMedia(EVENT_TYPES.PLAY_AV_MEDIA)}>Start for all participants</Button>
              <Button onClick={() => handleControlAvMedia(EVENT_TYPES.PAUSE_AV_MEDIA)}>Stop for all participants</Button>
            </>
          )}
          <Button onClick={() => handleAvMediaAction(attachmentMuted ? UNMUTE : MUTE)}>{attachmentMuted ? 'Unmute' : 'Mute'}</Button>
        </>
      );
    }
    if (contentType === 'image') {
      return <Image src={attachment.url} alt={attachment.name} centered />;
    }
    if (contentType === 'link') {
      return <IFrame src={attachment.url} name={attachment.id}></IFrame>;
    }
    if (contentType === 'pdf') {
      return (
        <PdfViewer id={attachment.id} src={attachment.url} />
      );
    }
  };

  return renderContent();
};

AttachmentViewer.defaultProps = {
  isAdmin: false,
};

export default AttachmentViewer;
