import _get from 'lodash/get';
import _sum from 'lodash/sum';
import _reject from 'lodash/reject';
import _toNumber from 'lodash/toNumber';
import moment from 'moment-timezone';
import {
  ISO_8601_ms, sort, momentizeTime, calcDuration, forEach,
} from 'Utils';
import {
  USER_ROLES,
  EVENT_TYPES,
  MEETING_TYPE,
  AGENDA_TYPE,
  AGENDA_STATUSES,
} from 'Utils/Consts';
import { timer } from '../firebase';

const {
  subscribeTimerStatus,
  unsubscribeTimerStatus,
  setTimerStatus,
  initTimer,
} = timer;

const {
  START_TIMER,
  STOP_TIMER,
  SET_TIMER,
  ADJUST_TIMER,
  OPEN_ATTACHMENT,
  CLOSE_ATTACHMENT,
  PLAY_AV_MEDIA,
  PAUSE_AV_MEDIA,
} = EVENT_TYPES;

const {
  OWNER, VIEWER, DELEGATE, MEMBER,
} = USER_ROLES;

export const projectTimerEvents = (t) => {
  if (!t) return;
  let elapsed = 0;
  let active = false;
  let lastStartTime = null;
  const tEvents = _get(t, 'events', {});
  const events = [];
  forEach(tEvents, (e, k) => {
    const event = { ...e, key: k };
    if (!e.timestamp) {
      // TODO Ignoring events without timestamp for now
      // events.push(event);
    } else {
      event.timestamp = momentizeTime(e.timestamp);
      events.push(event);
    }
  });
  const eventsSorted = sort(events, ['key']);
  // Removes consecutive duplicate start/stop events
  const eventsCompacted = _reject(eventsSorted, (e, idx) => {
    if (e.type === START_TIMER || e.type === STOP_TIMER) {
      return idx > 0 && eventsSorted[idx - 1].type === e.type;
    }
  });
  const elapsedUnits = [];
  eventsCompacted.forEach((event) => {
    const { timestamp: ts, type } = event;
    const timestamp = moment(ts).toISOString();
    switch (type) {
      case START_TIMER: {
        lastStartTime = timestamp;
        active = true;
        break;
      }
      case STOP_TIMER: {
        const d = calcDuration({
          startDateTime: lastStartTime || timestamp,
          startFormat: moment.ISO_8601,
          endDateTime: timestamp,
          endFormat: moment.ISO_8601,
        });
        elapsedUnits.push(d);
        active = false;
        break;
      }
      case SET_TIMER: {
        // TODO
        break;
      }
      case ADJUST_TIMER: {
        // TODO +/- value
        break;
      }
      default: {
        break;
      }
    }
  });

  let eventStateActive = false;
  let eventStateLastStartTime = null;
  let eState = null;

  let eventState = null;
  eventsSorted.forEach((event) => {
    const {
      timestamp: ts, type, attachmentId, state,
    } = event;
    const timestamp = moment(ts).toISOString();
    switch (type) {
      case OPEN_ATTACHMENT: {
        if (!eventState) eventState = {};
        eventState[attachmentId] = {};
        eventStateLastStartTime = timestamp;
        eventStateActive = true;
        eState = state;
        break;
      }
      case CLOSE_ATTACHMENT: {
        if (!eventState) eventState = {};
        eventState[attachmentId] = {};
        eventStateActive = false;
        eState = state;
        break;
      }
      case PLAY_AV_MEDIA: {
        if (!eventState) eventState = {};
        eventState[attachmentId] = {};
        eventStateLastStartTime = timestamp;
        eventStateActive = true;
        eState = state;
        break;
      }
      case PAUSE_AV_MEDIA: {
        if (!eventState) eventState = {};
        eventState[attachmentId] = {};
        eventStateActive = true;
        eState = state;
        break;
      }
      default:
        break;
    }

    if (eventState && eventState[attachmentId]) {
      eventState[attachmentId].active = eventStateActive;
      eventState[attachmentId].state = {
        ...eState, action: type, lastStartTime: moment(eventStateLastStartTime).format('X'),
      };
    }
  });

  elapsed = _sum(elapsedUnits);
  t.active = active;
  t.elapsed = elapsed;
  t.lastStartTime = moment(lastStartTime).format('X');
  t.events = tEvents;
  return { timerState: t, eventState };
};

export const handleSubscribeTimer = async (Store, state, id, type, cb) => {
  const updateTimer = (data, key) => {
    const {
      timers,
      events,
      agendas,
      activeMeetingId,
      activeAgendaId,
    } = Store.getState();
    if (!timers[id]) {
      timers[id] = {
        active: false,
        elapsed: 0,
        events: {},
      };
    }
    if (key) {
      timers[id].events[key] = data;
    } else {
      timers[id].events = { ...data };
    }
    const { timerState, eventState } = projectTimerEvents(timers[id]);
    if (type === AGENDA_TYPE && timerState.active) {
      activeAgendaId[activeMeetingId] = id;
      const activeTimers = [];
      forEach(agendas[activeMeetingId], (_, k) => {
        const t = timers[k];
        if (k !== id && k !== activeMeetingId && t.active) {
          activeTimers.push(k);
        }
      });
      forEach(activeTimers, (timerId) => handleAddTimerEvent(Store, state, timerId, AGENDA_TYPE, {
        type: STOP_TIMER,
      }),);
      // TODO safely _get .active
      if (!timers[activeMeetingId].active) {
        handleAddTimerEvent(Store, state, activeMeetingId, MEETING_TYPE, {
          type: START_TIMER,
        });
      }
    }
    if (cb) cb({ res: timerState });
    Store.setState({
      timers: { ...timers, [id]: timerState },
      events: { ...events, [id]: eventState },
      activeAgendaId,
    });
  };
  if (!id) {
    console.error('Unable to subscribe to timer:', { id, type });
  }
  subscribeTimerStatus(id, type, updateTimer);
};

export const handleUnsubscribeTimer = (Store, state, id, type) => {
  const { timers } = Store.getState();
  delete timers[id];
  // Stop interval
  unsubscribeTimerStatus(id, type);
  return { timers };
};

export const handleAddTimerEvent = (Store, state, id, type, event) => {
  const {
    me: { uid: userId },
    timers,
  } = Store.getState();
  if (!id) {
    console.error('Unable to add timer event:', { id, type, event });
  }
  // const activeTimers = {};
  // forEach(timers, (t, tId) => {
  //   if ((t.active && tId !== id)) activeTimers[tId] = { ...t, active: false };
  // });
  // timer[id].active = event.type === START_TIMER;
  // Store.setState({ ...timers, ...activeTimers, [id]: timer[id] });
  setTimerStatus(id, type, { ...event, userId });
};
