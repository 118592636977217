export const NAME_SPACE = '1ac4ce79-98ab-4aad-bea2-fb4d9eb2d0b0';

export const FEATURE_TOGGLES = {};

export const USER_ROLES = {
  OWNER: 'OWNER',
  VIEWER: 'VIEWER',
  DELEGATE: 'DELEGATE',
  MEMBER: 'MEMBER',
};

export const USER_ROLES_LABELS = {
  OWNER: 'Owner',
  VIEWER: 'Viewer',
  DELEGATE: 'Admin Delegate',
  MEMBER: 'Member',
};

export const EVENT_TYPES = {
  START_TIMER: 'START_TIMER',
  STOP_TIMER: 'STOP_TIMER',
  SET_STATUS: 'SET_STATUS',
  ADJUST_TIMER: 'ADJUST_TIMER',
  OPEN_ATTACHMENT: 'OPEN_ATTACHMENT',
  CLOSE_ATTACHMENT: 'CLOSE_ATTACHMENT',
  PLAY_AV_MEDIA: 'PLAY_AV_MEDIA',
  PAUSE_AV_MEDIA: 'PAUSE_AV_MEDIA',
};

export const AGENDA_STATUSES = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  COMPLETED: 'COMPLETED',
  TABLED: 'TABLED',
};

export const MEETING_STATUSES = {
  PENDING: 'PENDING',
  COMMENCED: 'COMMENCED',
  RECESS: 'RECESS',
  ADJOURNED: 'ADJOURNED',
  CANCELLED: 'CANCELLED',
};

export const MINUTES_STATUSES = {
  PENDING: 'PENDING',
  REVIEWED: 'REVIEWED',
};

export const AGENDA_STATUS_LABELS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  COMPLETED: 'Completed',
  TABLED: 'Tabled',
};

export const MEETING_STATUS_LABELS = {
  PENDING: 'Pending',
  COMMENCED: 'Commenced',
  RECESS: 'Recess',
  ADJOURNED: 'Adjourned',
  CANCELLED: 'Cancelled',
};

export const MINUTES_STATUS_LABELS = {
  PENDING: 'Pending',
  REVIEWED: 'Reviewed',
};

export const MEETING_TYPE = 'meetings';
export const AGENDA_TYPE = 'agendas';

export const VISIBILITY_ENUMS = {
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE',
};

export const JOB_TYPES = {
  MEETING_INVITE: 'MEETING_INVITE',
  GROUP_INVITE: 'GROUP_INVITE',
  ACCOUNT_UPSERT: 'ACCOUNT_UPSERT',
  BULK_PROVISION_USERS: 'BULK_PROVISION_USERS',
};

export const JOB_ACTIONS = {
  ADD_SUBSCRIPTION: 'ADD_SUBSCRIPTION',
  CANCEL_SUBSCRIPTION: 'CANCEL_SUBSCRIPTION',
  UPDATE_SUBSCRIPTION: 'UPDATE_SUBSCRIPTION',
};

export const QUEUE_STATUSES = {
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
};

export const TASK_STATUSES = {
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  ON_HOLD: 'ON_HOLD',
  BLOCKED: 'BLOCKED',
  CANCELLED: 'CANCELLED',
};

export const TASK_STATUS_LABELS = {
  NOT_STARTED: 'Not Started',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
  ON_HOLD: 'On Hold',
  BLOCKED: 'Help Needed',
  CANCELLED: 'Cancelled',
};

export const TASK_PRIORITIES = {
  P1: 1,
  P2: 2,
  P3: 3,
  P4: 4,
  P5: 5,
  P6: 6,
};

export const TASK_PRIORITY_LABELS = {
  P1: '1-None',
  P2: '2-Low',
  P3: '3-Medium',
  P4: '4-High',
  P5: '5-Urgent',
  P6: '6-Critical',
};

export const GROUP_STATUSES = {
  ACTIVE: 'ACTIVE',
};

export const PRESENCE_STATUSES = {
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
  AWAY: 'AWAY',
  IDLE: 'IDLE',
};

export const RSVP_STATUSES = {
  CONFIRMED: 'CONFIRMED',
  DECLINED: 'DECLINED',
};

export const RSVP_STATUS_LABELS = {
  CONFIRMED: 'Confirmed',
  DECLINED: 'Declined',
};

export const OPERATORS = {
  eq: 'eq',
  neq: 'neq',
  gt: 'gt',
  gte: 'gte',
  lt: 'lt',
  lte: 'lte',
  ex: 'ex',
  nex: 'nex',
  isValid: 'isValid',
  isMatch: 'isMatch',
};

export const CONDITIONAL_LOGIC = {
  all: 'all',
  any: 'any',
};
