import styled from 'styled-components';
import { Button as SuiButton } from 'semantic-ui-react';
import { styleConstructor } from '~/styles/Utils';

const Button = styled(SuiButton)`
  ${({ styling }) => styleConstructor(styling)}
`;

Button.Content = SuiButton.Content;
Button.Group = SuiButton.Group;
Button.Or = SuiButton.Or;

export default Button;
