import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'unistore/react';
import styled from 'styled-components';
import _get from 'lodash/get';
import _debounce from 'lodash/debounce';
import _isEqual from 'lodash/isEqual';
import Common from 'Common';
import Actions from '~/state/Actions';

const {
  TextArea, Icon, Confirm,
} = Common;

const Label = styled.label`
  font-weight: 900;
  display: flex;
  margin-bottom: 2px;
  justify-content: space-between;
  align-items: center;
`;

// TODO Refactor Minutes, Notes to use shared component
const Minutes = (props) => {
  const {
    store, debounce, agendaId, meetingId, onSetMinutes,
  } = props;
  const {
    minutes, users, participants, me: { uid },
  } = store;

  const [value, setValue] = useState({});
  const [prevValue, setPrevValue] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isInit, setIsInit] = useState(false);
  // const [isLocked, setIsLocked] = useState({});

  const getLockFieldStatus = () => {
    const lockField = _get(minutes, `${meetingId}.lockField.${agendaId}`, null);
    return lockField ? lockField !== uid : false;
  };

  const handleInProgress = (bool) => {
    setIsSubmitting(bool);
  };

  const onInProgress = useCallback(handleInProgress, []);

  const handleFieldTouch = (bool) => {
    setIsDirty(bool);
  };

  const onFieldTouch = useCallback(handleFieldTouch, []);

  const handleSave = async () => {
    if (!isSubmitting && !_isEqual(value, prevValue)) {
      onInProgress(true);
      if (onSetMinutes) {
        await onSetMinutes(meetingId, agendaId, value[agendaId]);
      }
      setPrevValue(value);
      onInProgress(false);
      onFieldTouch(false);
    }
  };

  const autoSaveChanges = _debounce(handleSave, debounce);

  const handleSetMinutes = async (e) => {
    const minutesVal = _get(e, 'target.value', '');
    setValue({ ...value, [agendaId]: minutesVal });
  };

  const handleLock = () => {
    onSetMinutes(meetingId, 'lockField', { [agendaId]: uid });
  };

  const handleUnlock = () => {
    onSetMinutes(meetingId, 'lockField', { [agendaId]: null });
  };

  useEffect(() => {
    const n = _get(minutes, meetingId, {});

    const isLocked = getLockFieldStatus();
    if (isLocked) {
      setPrevValue(n);
      setValue(n);
      return;
    }

    // Init minutes state
    const shouldUpdate = !isInit && !_isEqual(n, value);
    // Using this condition so >1 admin will be in-sync on subscription
    // const shouldUpdate = !_isEqual(n, value);
    if (shouldUpdate) {
      setIsInit(true);
      setPrevValue(n);
      setValue(n);
    }
  }, [minutes[meetingId]]);

  useEffect(() => {
    if (!_isEqual(value, prevValue)) {
      onInProgress(false);
      onFieldTouch(true);
      autoSaveChanges();
    }
  }, [value]);

  if (!agendaId || !meetingId) return null;

  const getSavedState = () => {
    if (isSubmitting) return 'sync';
    if (isDirty) return 'write';
    return 'check';
  };

  const renderIcon = () => (
    <Icon
      name={getSavedState()}
      loading={isSubmitting}
      size="small"
      className="right.aligned"
    />
  );

  const renderLabel = () => (
    <>
      <span key="minutes-label">Minutes</span>
      {renderIcon()}
    </>
  );

  const renderLockUser = () => {
    const lockField = _get(minutes, `${meetingId}.lockField.${agendaId}`, null);
    if (!lockField) return null;
    const userName = _get(users, lockField)
      ? _get(users, `${lockField}.fullName`, null)
      : _get(participants, `${meetingId}.${lockField}.fullName`, null);
    const message = getLockFieldStatus()
      ? `This field is locked for this agenda item because ${userName || 'another user'} is typing here.`
      : 'This field is locked for other users while your cursor is inside. To release it, click outside of the text box.';
    return <p>{message}</p>;
  };

  const renderForceUnlock = () => {
    const lockField = _get(minutes, `${meetingId}.lockField.${agendaId}`, null);
    if (!lockField) return null;
    if (getLockFieldStatus()) {
      return (
        <Confirm
          elementProps={{
            icon: 'warning',
            content: 'Force unlock',
            size: 'mini',
            basic: true,
          }}
          icon={null}
          header="Force unlock field"
          content="This could cause synchronization issues if multiple users type in the field at once. If the user has disconnected, this option will allow you to take over."
          confirmButton="Force unlock"
          cb={() => handleUnlock()}
        />
      );
    }
  };

  return (
    <React.Fragment key={`${agendaId}-minutes`}>
      <Label htmlFor={`minutes-${agendaId}`}>{renderLabel()}</Label>
      <TextArea
        disabled={getLockFieldStatus()}
        id={`minutes-${agendaId}`}
        name="minutes"
        minRows={3}
        onChange={handleSetMinutes}
        value={value[agendaId]}
        onFocus={handleLock}
        onBlur={handleUnlock}
      />
      {renderLockUser()}
      {renderForceUnlock()}
    </React.Fragment>
  );
};

Minutes.defaultProps = {
  debounce: 1500,
};

export default connect((store) => ({ store }), Actions)(Minutes);
