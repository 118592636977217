import React from 'react';
import ProgressLine from './Line';
import ProgressCircle from './Circle';

const Progress = ({ type, ...rest }) => {
  if (type === 'circle') {
    return <ProgressCircle {...rest} />;
  }
  return <ProgressLine {...rest} />;
};

Progress.buildStyles = ProgressCircle.buildStyles;

Progress.defaultProps = {
  type: 'line',
};

export default Progress;
