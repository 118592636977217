import React, { useState } from 'react';
// import styled from 'styled-components';
import { Input as SuiInput } from 'semantic-ui-react';
// import TimeInputPolyfill from 'react-time-input-polyfill';
import Icon from '../Icon';

// const TimeInput = styled(TimeInputPolyfill)`
//     font-family: 'Roboto', sans-serif;
//     display: inline-flex;
//     flex: 1 0 auto;
//     padding: 9.5px 14px;
//     border: 1px solid rgba(34,36,38,.15);
//     line-height: 1.2;
//     width: 100%;
//     outline: 0;
//     &:focus {
//         border-color: #85b7d9;
//         color: rgba(0,0,0,.8);
//         box-shadow: none;
//     }
// `;

const Input = (props) => {
  const { type, input } = props;
  const t = type || (input && input.type) || 'text';
  const [showPassword, setShowPassword] = useState(false);

  const handleToggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const renderPasswordIcon = () => (
    <Icon name={showPassword ? 'eye' : 'eye slash'} />
  );

  if (t === 'password') {
    return (
      <SuiInput
        {...props}
        input={{ ...input, type: showPassword ? 'text' : 'password' }}
        type={showPassword ? 'text' : 'password'}
        label={{
          basic: true, icon: renderPasswordIcon(), onClick: handleToggleShowPassword,
        }}
        labelPosition="right"
      />
    );
  }
  if (t !== 'time') return <SuiInput {...props} />;
  return <SuiInput {...props} type="text" input={{ ...input, type: 'text' }} />;
  // Polyfill time input — because Safari
  // return (
  //   <TimeInput {...props} />
  // );
};

export default Input;
