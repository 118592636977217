import React from 'react';
import styled from 'styled-components';
import { Icon as SUIIcon } from 'semantic-ui-react';

const IconStyled = styled(SUIIcon)`
    ${({ c }) => c ? `color: ${c} !important;` : ''}
`;

const Icon = ({ color, ...props }) => <IconStyled {...props} c={color} />;

Icon.Group = SUIIcon.Group;

export default Icon;
