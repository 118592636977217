import { functions } from './firebase';

export const callFunction = async (params, func) => {
  const fn = functions.httpsCallable(func);
  const res = await fn(params);
  return res;
};

export const getSignedUploadUri = (params) => callFunction(params, 'getSignedUploadUri').then((res) => res.data);

export const getSignedDownloadUri = (params) => callFunction(params, 'getSignedDownloadUri').then((res) => res.data);

export const getVideoServerAndJwt = (params) => callFunction(params, 'getVideoServerAndJwt').then((res) => res.data);

export const getAccountFeatures = (params) => callFunction(params, 'getAccountFeatures').then((res) => res.data);

export const getPendingInvites = (params) => callFunction(params, 'getPendingInvites').then((res) => res.data);

export const createProvisionedUsers = (params) => callFunction(params, 'createProvisionedUsers').then((res) => res.data);

export const getRsvpMeetings = (params) => callFunction(params, 'getRsvpMeetings').then((res) => res.data);

export const getRsvpResponses = (params) => callFunction(params, 'getRsvpResponses').then((res) => res.data);

export const getStripePublicKey = (params) => callFunction(params, 'getStripePublicKey').then((res) => res.data);

export const upsertTask = (params) => callFunction(params, 'upsertTask').then((res) => res.data);

export const upsertUser = (params) => callFunction(params, 'upsertUser').then((res) => res.data);

export const getScheduledMeeting = (params) => callFunction(params, 'getScheduledMeeting').then((res) => res.data);

export const getIsValidInstanceForRecurringRule = (params) => callFunction(params, 'getIsValidInstanceForRecurringRule').then((res) => res.data);

export const getScheduleInstancesForDateTimeRange = (params) => callFunction(params, 'getScheduleInstancesForDateTimeRange').then((res) => res.data);

export const logReporter = async (params) => {
  const logRes = await callFunction(JSON.stringify(params), 'logReporter').then((res) => res.data);
  console.log(logRes);
  return logRes;
};
