import styledNormalize from 'styled-normalize';
import { createGlobalStyle } from 'styled-components';
import { COLORS } from './Consts';

export default createGlobalStyle`
  ${styledNormalize}

  * {
    font-family: 'Roboto', sans-serif;
    box-sizing: border-box;
  }

  button, input, select {
    font-family: 'Roboto', sans-serif !important;
  }

  html {
    height: -webkit-fill-available;
  }

  body {
    height: 100%;
    min-height: -webkit-fill-available;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    padding: 0;
    margin: 0;
    position: fixed;
    background-color: ${COLORS.lightGray2} !important;
  }

  .primary {
    background-color: ${COLORS.primary} !important;
  }

  // @media (prefers-color-scheme: dark) {
  //   body {
  //     background-color: ${COLORS.background} !important;
  //     color: ${COLORS.white} !important;
  //   }
  // }
`;
