import React from 'react';
import styled from 'styled-components';
import { COLORS } from '~/styles/Consts';

const Wrapper = styled.div`
  padding: 1rem;
  background-color: ${COLORS.brand};
  display: flex;
  justify-content: space-between;
  height: 4.5rem;
`;

const Header = ({ logo, navigation }) => (
  <Wrapper>
    {logo}
    {navigation}
  </Wrapper>
);

export default Header;
