import React from 'react';
import styled from 'styled-components';
import { useNavigation } from 'react-navi';
import Components from 'Components';
import Common from 'Common';
import useViewport from 'Hooks/useViewport';
import { EXTERNAL_FRAME_MOBILE } from '~/routes';

const { MobileNavigationSidebar } = Components;
const { Error } = Common;

const BaseContainerSansHeaderAndFooter = styled.div`
  display: grid;
  height: 100vh;
  overflow: hidden;
  padding: 0;
  margin: 0;
  grid-gap: 0;
`;

const BaseContainer = styled.div`
  display: grid;
  height: 100vh;
  overflow: hidden;
  padding: 0;
  margin: 0;

  grid-gap: 0;
  justify-items: stretch;
  align-items: stretch;
  grid: 'header' 4.5rem
        'main' auto
         /* 'footer' 2.5rem */
       / 1fr;

  header { grid-area: header; }
  main { grid-area: main; }
  footer { grid-area: footer; }
`;

const HeaderContainer = styled.header`
  height: 4.5rem;
  padding: 0;
  margin: 0;
`;

const ContentContainer = styled.main`
  /* height: calc(100vh - 2.5rem - 4.5rem); */
  /* height: calc(100vh - 4.5rem); */
  overflow: hidden;
  padding: 0;
  margin: 0;
`;

const FooterContainer = styled.footer`
  display: none;
`;

// TODO Temp
const MobileNotice = styled.div`
    ${() => [
    'position: absolute',
    'display: block',
    'z-index: 9999',
    'width: 100%',
    'min-height: 10vh',
    'bottom: 8rem',
    'left: 0',
    /* 'background-color: red',
    'color: white',
    'opacity: 0.8', */
  ].join('; ')}
`;

const shouldHideHeaderAndFooter = (url) => {
  const omitList = [EXTERNAL_FRAME_MOBILE];
  return omitList.some((r) => r === url);
};

const BaseLayout = ({
  header, footer, children, isAuthenticated,
}) => {
  const { isMobile } = useViewport();
  const navigation = useNavigation();

  const hideHeaderAndFooter = shouldHideHeaderAndFooter(navigation?.lastRoute?.url?.pathname);

  const renderMobileNotice = () => {
    if (isAuthenticated && isMobile) {
      return (
        <MobileNotice>
          <Error icon="mobile alternate" header="Mobile design is underway" size="large" type="warning">We've just launched Ensembl for desktop, and we're now urgently working to make the site mobile-friendly. Until then, please use a computer or regular size tablet. Thank you!</Error>
        </MobileNotice>
      );
    }
    return null;
  };

  const renderMainContent = () => (
    <>
      {renderMobileNotice()}
      <ContentContainer>
        {children}
      </ContentContainer>
    </>
  );

  if (hideHeaderAndFooter) {
    return (
      <BaseContainerSansHeaderAndFooter>
        {renderMainContent()}
      </BaseContainerSansHeaderAndFooter>
    );
  }

  return (
    <BaseContainer>
      {<HeaderContainer>{header}</HeaderContainer>}
      {isMobile ? (
        <MobileNavigationSidebar>
          {renderMainContent()}
        </MobileNavigationSidebar>
      ) : renderMainContent()}
      {<FooterContainer>{footer}</FooterContainer>}
    </BaseContainer>
  );
};

export default BaseLayout;
