import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import { arrayMove } from 'react-sortable-hoc';
import SortableList from './SortableList';

const SortableElements = ({
  onUpdateSortCb,
  debounceDelay,
  items,
  sortKey,
  sortProperty,
  useDragHandle,
  renderItem,
  ...rest
}) => {
  const [sortedItems, setSortedItems] = useState([]);

  useEffect(() => {
    let hasSortChanged = false;
    if (items.length !== sortedItems.length) {
      hasSortChanged = true;
    } else {
      hasSortChanged = items.every((item, idx) => item.id === sortedItems[idx].id);
    }
    if (hasSortChanged) setSortedItems(items);
  }, [items]);

  const onSortFn = debounce(onUpdateSortCb, debounceDelay);
  const onSort = (prevItems, nextItems) => {
    const updateItems = [];
    nextItems.forEach((n, i) => {
      if ((!n[sortProperty] || n[sortProperty] !== 0) && n[sortKey] !== items[i][sortKey]) {
        updateItems.push({ [sortKey]: n[sortKey], [sortProperty]: i });
      }
    });
    if (onUpdateSortCb) onSortFn(updateItems);
  };

  const handleSortItems = ({ oldIndex, newIndex }) => {
    const prevItems = [...sortedItems];
    const nextItems = arrayMove(sortedItems, oldIndex, newIndex);
    setSortedItems(nextItems);
    onSort(prevItems, nextItems);
  };

  return (
    <SortableList
      items={sortedItems}
      renderItem={renderItem}
      onSort={handleSortItems}
      useDragHandle={useDragHandle}
      {...rest}
    />
  );
};

SortableElements.defaultProps = {
  items: [],
  sortKey: 'id',
  sortProperty: 'sortOrder',
  useDragHandle: true,
  debounceDelay: 2000,
  onUpdateSortCb: (items) => console.log('SortableElements default handler:', items),
};

export default SortableElements;
