import React from 'react';
import { sortableContainer } from 'react-sortable-hoc';
import SortableItem from './SortableItem';

const SortableList = ({
  items, onSort, useDragHandle, ...rest
}) => {
  const SortableContainer = sortableContainer(({ children }) => <div>{children}</div>);

  return (
    <SortableContainer onSortEnd={onSort} useDragHandle={useDragHandle}>
      {items.map((item, idx) => (
        <SortableItem
          key={`item-${item.id}`}
          index={idx}
          value={item}
          {...rest}
        />
      ))}

    </SortableContainer>
  );
};

export default SortableList;
