import { lazy } from 'react';
import { forEach } from 'Utils';

// TODO Dupe CONSTS in routes/index
export const REDIRECTS = {
  meeting: '/meeting',
  task: '/task',
};
export const WILD = '*';
export const BASE = '/';
export const ADMIN = '/admin';
export const EXTERNAL_FRAME = '/resource';
export const EXTERNAL_FRAME_MOBILE = '/resource-mobile';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const PASSWORD_FORGET = '/reset-password';
export const DASHBOARD = '/dashboard';
export const PROFILE_ID = '/profile/:userId'; // TODO
export const PROFILE = '/profile';
export const ACCOUNT = '/account/:accountId';
export const PLANS = '/plans';
export const CHECKOUT = '/plans/:planId/checkout';
export const SCHEDULE = '/recurring/:scheduleKey';
export const MEETING = '/m/:meetingId';
export const MEETING_PRESENT = '/m/:meetingId/present';
export const MEETING_MINUTES = '/m/:meetingId/minutes';
export const MEETING_REVIEW = '/m/:meetingId/review';
export const MEETING_NOTES = '/m/:meetingId/notes';
export const ORG_PAGE = '/page/:orgHandle';
export const GROUP_PAGE = '/g/:groupHandle';
export const GROUP_BY_ID = '/group/:groupId';
export const TASK = '/t/:taskNumber';
export const TASK_BY_ID = '/task/:taskId';

export const constructRoute = (route, params) => {
  let loc = route;
  forEach(params, (val, key) => {
    loc = loc.replace(`:${key}`, val);
  });
  return loc;
};

// Master list for Router and Main Nav menu
const NotFound = lazy(() => import('~/containers/_NotFound'));
const Admin = lazy(() => import('~/containers/Admin'));
const SignIn = lazy(() => import('~/containers/SignIn'));
const SignUp = lazy(() => import('~/containers/SignUp'));
const PasswordForgot = lazy(() => import('~/containers/PasswordForgot'));
const OrgPage = lazy(() => import('~/containers/OrgPage'));
const Profile = lazy(() => import('~/containers/Profile'));
const Dashboard = lazy(() => import('~/containers/Dashboard'));
const Meeting = lazy(() => import('~/containers/Meeting'));

export const Pages = [
  {
    Component: NotFound,
    isDefault: true,
  },
  {
    Component: Admin,
    route: ADMIN,
    withAuth: true,
    redirect: SIGN_IN,
  },
  {
    Component: SignIn,
    route: SIGN_IN,
    withAuth: false,
    redirect: DASHBOARD,
    label: 'Sign In',
  },
  {
    Component: SignUp,
    route: SIGN_UP,
    withAuth: false,
    redirect: DASHBOARD,
    label: 'Sign Up',
  },
  {
    Component: PasswordForgot,
    route: PASSWORD_FORGET,
    withAuth: false,
    redirect: PROFILE,
    label: 'Forgot Password',
  },
  {
    Component: OrgPage,
    route: ORG_PAGE,
    withAuth: null,
    redirect: null,
  },
  {
    Component: Profile,
    route: PROFILE,
    withAuth: true,
    redirect: SIGN_IN,
    label: 'My Profile',
  },
  {
    Component: Dashboard,
    route: DASHBOARD,
    withAuth: true,
    redirect: SIGN_IN,
    label: 'Dashboard',
  },
  {
    Component: Meeting,
    route: MEETING,
    withAuth: true,
    redirect: SIGN_IN,
  },
  {
    Component: null,
    route: REDIRECTS.meeting,
    withAuth: true,
    redirect: DASHBOARD,
  },
];
