import React from 'react';
import { connect } from 'unistore/react';
import styled from 'styled-components';
import _debounce from 'lodash/debounce';
import Common from 'Common';
import { ENV } from 'Utils';
import { logReporter } from '~/firebase/functions';

const {
  Error, Debug, Button, Icon,
} = Common;

const Wrapper = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 80%;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  align-self: auto;
  align-items: center;
  vertical-align: middle;
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false, error: null, report: null,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    console.error(error);
    const { store } = this.props;
    const { APP_NAME, DOMAIN } = ENV;
    const {
      languages, language, vendor, userAgent, product, platform, appVersion, appName, appCodeName,
    } = window.navigator;
    const { message, stack } = error || {};
    const { subscriptions, ...s } = store;
    const report = {
      store: s,
      info,
      error: { message, stack },
      env: { appName: APP_NAME, domain: DOMAIN },
      browser: {
        languages, language, vendor, userAgent, product, platform, appVersion, appName, appCodeName,
      },
    };
    this.setState({ report });
    const isLocal = `${APP_NAME}`.includes('LOCAL');
    if (!isLocal) {
      this.handleLogReport(report);
    }
  }

  async handleLogReport(report) {
    await logReporter(report);
  }

  render() {
    const { children } = this.props;
    const { hasError, error, report } = this.state;
    const isDev = !`${ENV.FIREBASE_CONFIG}`.includes('prod');

    if (hasError) {
      // You can render any custom fallback UI
      return (
        <Container>
          <Wrapper>
            <Error header="Something went wrong">
              <p>We've logged the error and will be investigating to fix asap.</p>
              <Button
                primary
                onClick={() => window.location.reload(false)}
              >
                <Icon name="redo" />
                Reload App
              </Button>
              {isDev && (
                <>
                  <h3>{error.message}</h3>
                  <pre>{error.stack}</pre>
                </>
              )}
            </Error>
            {isDev && <Debug {...report} />}
          </Wrapper>
        </Container>
      );
    }
    return children;
  }
}

export default connect((store) => ({ store }))(ErrorBoundary);
