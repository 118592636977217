const req = require.context('.', true, /\.js$/);

const components = {};

req.keys().forEach((key) => {
  const componentName = key.replace(/^.+\/([^/]+)\/index\.js/, '$1');
  if (key !== './index.js' && !key.includes('./common')) { // Exclude self and common
    components[componentName] = req(key).default;
  }
});

export default components;
