import { VISIBILITY_ENUMS } from 'Utils/Consts';
import { firestore } from './firebase';

// const {
//   OWNER, VIEWER, DELEGATE, MEMBER,
// } = USER_ROLES;

// const roles = [OWNER, VIEWER, DELEGATE, MEMBER];

const ProjectsCollection = firestore.collection('projects');

export const getProjectsByAccountId = async (accountId) => ProjectsCollection
  .where('visibility', '==', VISIBILITY_ENUMS.PUBLIC)
  .where('accountId', '==', accountId)
  .get()
  .then((querySnapshot) => {
    const data = [];
    if (querySnapshot.empty) return data;
    querySnapshot.forEach((doc) => {
      data.push({ id: doc.id, ...doc.data() });
    });
    return data;
  }).catch((err) => {
    console.error('Error getting projects by accountId', { err, accountId });
  });
