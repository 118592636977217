import _get from 'lodash/get';
import _merge from 'lodash/merge';
import _isEqual from 'lodash/isEqual';
import {
  getIsAdmin, processFormData, sort, makeArray, findTemplate, forEach,
} from 'Utils';
import {
  EVENT_TYPES, AGENDA_TYPE, AGENDA_STATUSES, MEETING_STATUSES,
} from 'Utils/Consts';
import {
  getSignedDownloadUri, getAccountFeatures, getRsvpMeetings, getScheduledMeeting,
} from '~/firebase/functions';
import { meeting, fsTimestamp, timer } from '../firebase';
import { subscribeMySchedules, subscribeGroupSchedules } from '../firebase/schedule';
import { handleSubscribeTimer, handleAddTimerEvent } from './timer';
import { handleAsyncUsersBatch } from './user';

const { setTimerStatus } = timer;

const {
  getMyMeetings,
  getGroupMeetings,
  subscribeMeeting,
  subscribeMeetingAgendas,
  upsertMeeting,
  upsertAgenda,
  batchUpdateAgenda,
  subscribeMyMeetings,
  subscribeGroupMeetings,
} = meeting;

const enrichMeeting = (m, me, g = {}) => {
  const isAdmin = getIsAdmin(m, me) || getIsAdmin(g, me);
  m.isAdmin = isAdmin;
  return m;
};

const enrichAgenda = enrichMeeting;

// TODO Refactor / move helper func elsewhere
const cleanStateDataForDb = (obj) => {
  const omitKeys = ['isAdmin', 'updatedAt', 'createdAt', 'createdBy'];
  const o = { ...obj };
  omitKeys.forEach((k) => {
    if (o[k] || o[k] === false || o[k] === 0) delete o[k];
  });
  forEach(o, (_, p) => {
    if (o[p] === undefined) delete o[p];
  });
  return o;
};

export const handleSetActiveMeeting = async (state, id) => ({
  activeMeetingId: id,
});

export const handleGetMeetingByScheduleKey = async (Store, state, scheduleKey) => {
  const { scheduledMeetings, meetings, me } = state;
  let sMeetingId = null;
  forEach(meetings, ({ scheduleKey: sKey }, mId) => {
    if (sKey === scheduleKey) sMeetingId = mId;
  });
  if (sMeetingId) { // Meeting already in cache
    return { scheduledMeetings: { ...scheduledMeetings, [scheduleKey]: sMeetingId } };
  }

  const sMeeting = await getScheduledMeeting({ scheduleKey });
  if (sMeeting) {
    const { id, ...m } = sMeeting;
    const em = enrichMeeting(m, me);
    const savedScheduledMeetings = {
      scheduledMeetings: { ...scheduledMeetings, [scheduleKey]: id },
      meetings: { ...meetings, [id]: em },
    };
    return savedScheduledMeetings;
  }
};

export const handleGetGroupMeetings = async (state, groupId, params, cb) => {
  const {
    me, meetings, groups, features, scheduledMeetings,
  } = state;
  const { uid: userId } = me;
  const accountIds = [];
  await getGroupMeetings(groupId, userId, params)
    .then((res) => forEach(res, (m) => {
      const g = groups[groupId] || {};
      const em = enrichMeeting(m, me, g);
      if (m.scheduleKey && !scheduledMeetings[m.scheduleKey]) {
        scheduledMeetings[m.scheduleKey] = m.id;
      }
      meetings[m.id] = em;
      if (
        m.accountId
        && !features[m.accountId]
        && accountIds.indexOf(m.accountId) < 0
      ) {
        accountIds.push(m.accountId);
      }
    }));
  await Promise.all(accountIds.map(async (accountId) => {
    features[accountId] = await getAccountFeatures({ accountId });
    return Promise.resolve();
  }));
  if (cb) cb({ res: meetings });
  return { meetings: { ...meetings }, features: { ...features }, scheduledMeetings: { ...scheduledMeetings } };
};

export const handleGetMyMeetings = async (state, params, cb) => {
  const {
    me, meetings, groups, features,
  } = state;
  const { uid: userId } = me;
  const accountIds = [];
  await getMyMeetings(userId, params)
    .then((res) => forEach(res, (m) => {
      const { groupId } = m;
      const g = groups[groupId] || {};
      const em = enrichMeeting(m, me, g);
      meetings[m.id] = em;
      if (
        m.accountId
        && !features[m.accountId]
        && accountIds.indexOf(m.accountId) < 0
      ) {
        accountIds.push(m.accountId);
      }
    }));
  await Promise.all(accountIds.map(async (accountId) => {
    features[accountId] = await getAccountFeatures({ accountId });
    return Promise.resolve();
  }));
  if (cb) cb({ res: meetings });
  return { meetings: { ...meetings, features: { ...features } } };
};

export const handleSubscribeMyMeetings = async (Store, state, uid, cb) => {
  const { me } = state;
  const userId = uid || me.uid;
  const updateStore = async ({ data, unsubscribe }) => {
    const {
      meetings, groups, subscriptions, features, scheduledMeetings,
    } = Store.getState();
    if (cb) cb({ res: data });
    const accountIds = [];
    const newMeetings = { ...meetings };
    if (unsubscribe) subscriptions[`${userId}-meetings`] = { unsubscribe };
    forEach(data, (mData) => {
      // if (mData.isDeleted) {
      //   delete meetings[mData.id];
      //   return;
      // };
      const { groupId, scheduleKey } = mData;
      const g = groups[groupId] || {};
      const m = enrichMeeting(mData, me, g);
      newMeetings[mData.id] = m;
      if (scheduleKey && !scheduledMeetings[scheduleKey]) {
        scheduledMeetings[scheduleKey] = mData.id;
      }
      if (
        m.accountId
        && !features[m.accountId]
        && accountIds.indexOf(m.accountId) < 0
      ) {
        accountIds.push(m.accountId);
      }
    });
    Store.setState({ meetings: { ...newMeetings }, scheduledMeetings, subscriptions });
    if (accountIds.length > 0) {
      await Promise.all(accountIds.map(async (accountId) => {
        features[accountId] = await getAccountFeatures({ accountId });
        return Promise.resolve();
      }));
      const { features: latestFeatures } = Store.getState();
      Store.setState({ features: { ...latestFeatures, ...features } });
    }
    handleAsyncUsersBatch(Store, state, data);
  };
  subscribeMyMeetings(userId, updateStore);
  const rsvpMeetings = await getRsvpMeetings();
  if (rsvpMeetings?.length) updateStore({ data: rsvpMeetings });
};

export const handleSubscribeGroupMeetings = async (Store, state, groupId, cb) => {
  const { me } = state;
  const userId = me.uid;
  const updateStore = async ({ data, unsubscribe }) => {
    const {
      meetings, groups, features, scheduledMeetings, subscriptions,
    } = Store.getState();
    if (cb) cb({ res: data });
    const accountIds = [];
    subscriptions[`${groupId}-meetings`] = { unsubscribe };
    let shouldSave = false;
    const newMeetings = { ...meetings };
    forEach(data, (mData) => {
      const g = groups[groupId] || {};
      const m = enrichMeeting(mData, me, g);
      if (!newMeetings[mData.id] || !_isEqual(newMeetings[mData.id], m)) {
        newMeetings[mData.id] = m;
        shouldSave = true;
      }
      if (m.scheduleKey && !scheduledMeetings[m.scheduleKey]) {
        scheduledMeetings[m.scheduleKey] = mData.id;
        shouldSave = true;
      }
      if (
        m.accountId
        && !features[m.accountId]
        && accountIds.indexOf(m.accountId) < 0
      ) {
        accountIds.push(m.accountId);
      }
    });
    if (shouldSave) Store.setState({ meetings: newMeetings, scheduledMeetings });
    Store.setState({ subscriptions });
    if (accountIds.length > 0) {
      await Promise.all(accountIds.map(async (accountId) => {
        features[accountId] = await getAccountFeatures({ accountId });
        return Promise.resolve();
      }));
      const { features: latestFeatures } = Store.getState();
      Store.setState({ features: { ...latestFeatures, ...features } });
    }
    handleAsyncUsersBatch(Store, state, data);
  };
  // TODO Optimize to avoid multiple subs on my meetings
  await subscribeGroupMeetings(groupId, userId, updateStore);
};

export const handleSubscribeMeeting = async (Store, state, id, cb) => {
  const { me, groups } = state;
  const updateStore = async ({ data, unsubscribe }) => {
    const {
      meetings, features, subscriptions, scheduledMeetings,
    } = Store.getState();
    const { groupId, accountId } = data;
    const g = groups[groupId] || {};
    const m = enrichMeeting(data, me, g);
    if (cb) cb({ res: m });
    meetings[id] = m;
    subscriptions[id] = { unsubscribe };
    if (m.scheduleKey && !scheduledMeetings[m.scheduleKey]) {
      scheduledMeetings[m.scheduleKey] = id;
    }
    Store.setState({ meetings: { ...meetings }, subscriptions, scheduledMeetings });
    if (accountId && !features[accountId]) {
      features[accountId] = await getAccountFeatures({ accountId });
      Store.setState({ features: { ...features } });
    }
    handleAsyncUsersBatch(Store, state, [data]);
  };
  const subscribeAgendaTimers = ({ res: agendaItems }) => {
    forEach(agendaItems, ({ id: agendaId }) => {
      handleSubscribeTimer(Store, state, agendaId, 'agenda');
    });
  };
  await Promise.all([
    subscribeMeeting(id, updateStore),
    handleSubscribeAgenda(Store, state, id, subscribeAgendaTimers),
  ]);
};

export const handleSubscribeAgenda = async (Store, state, id, cb) => {
  const { me, activeAgendaId } = state;
  const updateStore = async ({ data, unsubscribe }) => {
    const attachmentQueue = [];
    const a = {};

    const queueForSignedLinks = (attachments, agendaId) => {
      forEach(attachments, (val, attachmentId) => {
        if (!val) return;
        const { location, url, isDeleted } = val;
        if (isDeleted || !location) return;
        // TODO Temp preserve backward-compatibility for files in Google Storage
        if (`${url}`.includes('https://firebasestorage.googleapis.com')) return;
        const filePathArr = location.split('/');
        const fileName = filePathArr.pop();
        attachmentQueue.push({
          id: attachmentId, filePath: filePathArr.join('/'), fileName, agendaId,
        });
      });
    };

    forEach(data, (agenda, agendaId) => {
      if (agenda.isDeleted) {
        delete a[agendaId];
        return;
      }
      a[agendaId] = enrichAgenda(agenda, me);
      if (a[agendaId].attachments) queueForSignedLinks(a[agendaId].attachments, agendaId);
      if (a[agendaId].status === AGENDA_STATUSES.ACTIVE) {
        // Set active agenda item
        activeAgendaId[id] = agendaId;
      } else if (activeAgendaId[id] === agendaId) {
        // Clear previously active agenda item
        activeAgendaId[id] = null;
      }
    });

    const { agendas, subscriptions } = Store.getState();
    const agendasClone = { ...agendas };
    agendasClone[id] = a;
    subscriptions[`${id}-agendas`] = { unsubscribe };
    Store.setState({ agendas: agendasClone, subscriptions, activeAgendaId });

    if (attachmentQueue.length > 0) {
      const uris = await Promise.all(attachmentQueue.map(async ({ id: attachmentId, agendaId, ...params }) => {
        const { signedUri } = await getSignedDownloadUri(params);
        return { attachmentId, signedUri };
      }));
      const { signedUris } = Store.getState();
      uris.forEach(({ attachmentId, signedUri }) => {
        if (signedUri) signedUris[attachmentId] = signedUri;
      });
      Store.setState({ signedUris: { ...signedUris } });
    }
    if (cb) cb({ res: a });
  };

  await subscribeMeetingAgendas(id, updateStore);
  return Promise.resolve();
};

export const handleUnsubscribe = async (Store, state, id) => {
  const subscriptionIds = [id, `${id}-agendas`, `${id}-tasks`];
  const { subscriptions } = Store.getState();
  forEach(subscriptionIds, (subId) => {
    if (subscriptions[subId]) {
      const { unsubscribe } = subscriptions[subId];
      if (unsubscribe) unsubscribe();
      delete subscriptions[subId];
    }
  });
  return { subscriptions };
};

export const handleSetActiveAgendaId = (state, id, meetingId) => {
  const { activeAgendaId } = state;
  if (id && meetingId) {
    activeAgendaId[meetingId] = id;
  } else if (activeAgendaId[meetingId]) {
    delete activeAgendaId[meetingId];
  }
  return { activeAgendaId };
};

export const handleSetSelectedAgendaId = (state, id, meetingId) => {
  const { selectedAgendaId } = state;
  if (id && meetingId) {
    selectedAgendaId[meetingId] = id;
  } else if (selectedAgendaId[meetingId]) {
    delete selectedAgendaId[meetingId];
  }
  return { selectedAgendaId };
};

export const handleUpsertMeeting = async (
  Store,
  state,
  meetingData,
  meetingId,
  cb,
) => {
  const {
    me, meetings, groups, agendas, accounts, scheduledMeetings,
  } = state;
  const { uid: userId } = me;
  const updateStore = async ({ data }) => {
    const cachedMeeting = meetings[meetingId] || {};
    const { groupId } = data;
    const g = groups[groupId] || {};
    const m = enrichMeeting(_merge(cachedMeeting, data), me, g);
    if (m.scheduleKey && !scheduledMeetings[m.scheduleKey]) {
      scheduledMeetings[m.scheduleKey] = m.id;
    }
    if (cb) cb({ res: m });
    const { id } = data;
    meetings[id] = { ...m };
    Store.setState({ meetings: { ...meetings }, scheduledMeetings });

    if (!meetingId && meetingData.templateId) {
      const { templates } = state;
      const selectedTemplate = findTemplate(templates, meetingData.templateId);
      if (selectedTemplate && selectedTemplate.agendaItems) {
        const { agendaItems } = selectedTemplate;
        const agendaQueue = [];
        forEach(agendaItems, (agendaItem) => {
          agendaQueue.push({
            ...agendaItem,
            meetingId: id,
            accountId: meetingData.accountId,
            templateId: meetingData.templateId,
            groupId: meetingData.groupId,
            users: meetingData.users,
            visibility: meetingData.visibility,
            status: AGENDA_STATUSES.INACTIVE,
          });
        });
        await Promise.all(agendaQueue.map((agendaItem) => handleUpsertAgenda(Store, state, agendaItem)));
      }
    }
  };
  // TODO add state.prevData param to processFormData()
  const pMeeting = processFormData(meetingData,
    userId, fsTimestamp, !!meetingId);

  const groupId = pMeeting.groupId || pMeeting.groupId === '' ? pMeeting.groupId : _get(meetings, `${meetingId}.groupId`, '');
  let accountId = pMeeting.accountId || pMeeting.accountId === '' ? pMeeting.accountId : _get(groups, `${groupId}.accountId`, _get(meetings, `${meetingId}.accountId`, ''));

  // For ad hoc meetings, do final check to see if user has account
  if (!meetingId && !accountId) {
    // TODO Temp hack — For now we assume only 1 active account per user
    forEach(accounts, (a, aId) => {
      if (a.isActive) accountId = aId;
    });
  }

  pMeeting.accountId = accountId;
  pMeeting.groupId = groupId;

  const q = [() => upsertMeeting(meetingId ? cleanStateDataForDb(pMeeting) : pMeeting, meetingId, updateStore)];

  if (pMeeting.status === MEETING_STATUSES.COMMENCED) {
    // Start first agenda item
    const inactiveAgendaIds = [];
    const inactiveAgendaSortOrders = [];
    forEach(agendas[meetingId], (a, aId) => {
      if (a.status === AGENDA_STATUSES.INACTIVE) {
        inactiveAgendaIds.push(aId);
        inactiveAgendaSortOrders.push(a.sortOrder);
      }
    });
    if (inactiveAgendaIds.length > 0) {
      const lowestSortOrderIdx = inactiveAgendaSortOrders.indexOf(Math.min(...inactiveAgendaSortOrders));
      const agendaId = inactiveAgendaIds[lowestSortOrderIdx];
      if (agendaId) {
        q.push(() => upsertAgenda({ status: AGENDA_STATUSES.ACTIVE }, agendaId));
        const event = { type: EVENT_TYPES.START_TIMER };
        q.push(() => setTimerStatus(agendaId, AGENDA_TYPE, { ...event, userId }));
      }
    }
  } else if (
    pMeeting.status
    && pMeeting.status !== MEETING_STATUSES.COMMENCED
    && pMeeting.status !== MEETING_STATUSES.ADJOURNED
  ) {
    // Stop active agenda item
    const activeAgendaIds = [];
    forEach(agendas[meetingId], (a, aId) => {
      if (a.status === AGENDA_STATUSES.ACTIVE) {
        activeAgendaIds.push(aId);
      }
    });
    if (activeAgendaIds.length > 0) {
      q.push(() => Promise.all(activeAgendaIds.map((agendaId) => upsertAgenda({ status: AGENDA_STATUSES.INACTIVE }, agendaId))));
      const event = { type: EVENT_TYPES.STOP_TIMER };
      q.push(() => Promise.all(activeAgendaIds.map((agendaId) => setTimerStatus(agendaId, AGENDA_TYPE, { ...event, userId }))));
    }
  } else if (pMeeting.status && pMeeting.status === MEETING_STATUSES.ADJOURNED) {
    // Stop active agenda item
    const activeAgendaIds = [];
    forEach(agendas[meetingId], (a, aId) => {
      if (a.status === AGENDA_STATUSES.ACTIVE) {
        activeAgendaIds.push(aId);
      }
    });
    if (activeAgendaIds.length > 0) {
      q.push(() => Promise.all(activeAgendaIds.map((agendaId) => upsertAgenda({ status: AGENDA_STATUSES.COMPLETED }, agendaId))));
      const event = { type: EVENT_TYPES.STOP_TIMER };
      q.push(() => Promise.all(activeAgendaIds.map((agendaId) => setTimerStatus(agendaId, AGENDA_TYPE, { ...event, userId }))));
    }
  }

  await Promise.all(q.map((fn) => fn()));
};

export const handleUpsertAgenda = async (
  Store,
  state,
  agendaData,
  agendaId,
  cb,
) => {
  const { meetingId } = agendaData;
  if (!meetingId) {
    console.error(`Missing meetingId in agenda data ${agendaId || ''}: `, agendaData);
    return;
  }
  const { me, selectedAgendaId } = state;
  const { uid: userId } = me;
  const { agendas, meetings, groups } = Store.getState();
  if (!agendas[meetingId]) agendas[meetingId] = {};
  const updateStore = ({ data }) => {
    const { id } = data;
    const cachedAgenda = agendas[meetingId][agendaId || id];
    const a = enrichAgenda(_merge(cachedAgenda, data), me);
    if (cb) cb({ res: a });
    agendas[meetingId][agendaId || id] = { ...a };
    Store.setState({ agendas });
  };
  // Control timers based on status changes
  const handleTimerAction = async (id, makeActive = false) => {
    const { START_TIMER, STOP_TIMER } = EVENT_TYPES;
    const event = {
      type: makeActive ? START_TIMER : STOP_TIMER,
    };
    await handleAddTimerEvent(Store, state, id, AGENDA_TYPE, event);
  };

  const sortedAgenda = sort(makeArray(agendas[meetingId] || {}), ['sortOrder']).filter(({ isDeleted }) => !isDeleted);

  // TODO add state.prevData param to processFormData()
  const pAgenda = processFormData(
    agendaData,
    userId,
    fsTimestamp,
    !!agendas[meetingId][agendaId],
  );

  const groupId = _get(meetings, `${meetingId}.groupId`, '');
  const accountId = _get(meetings, `${meetingId}.accountId`, '') || _get(groups, `${groupId}.accountId`, '');
  pAgenda.accountId = accountId;
  pAgenda.groupId = groupId;

  if (!pAgenda.sortOrder || pAgenda.sortOrder !== 0) {
    if (pAgenda.sortOrder >= 0) {
      // No-op
    } else {
      const cachedAgenda = agendas[meetingId][agendaId] || {};
      const agendaCount = sortedAgenda.length;
      pAgenda.sortOrder = cachedAgenda.sortOrder >= 0 ? cachedAgenda.sortOrder : agendaCount;
    }
  }

  const timerItems = {};
  const updateItems = {};
  let shouldBatch = false;

  if (pAgenda.status) {
    const {
      COMPLETED, ACTIVE, INACTIVE, TABLED,
    } = AGENDA_STATUSES;

    updateItems[agendaId] = cleanStateDataForDb(
      _merge(agendas[meetingId][agendaId], agendaData),
    );

    switch (pAgenda.status) {
      case ACTIVE: {
        timerItems[agendaId] = { active: true };
        forEach(agendas[meetingId], (a, id) => {
          if (a.status === ACTIVE) {
            if (id !== agendaId) {
              shouldBatch = true;
              timerItems[id] = { active: false };
              updateItems[id] = cleanStateDataForDb({
                ...a,
                status: COMPLETED,
              });
            } else if (agendaId) {
              updateItems[agendaId] = cleanStateDataForDb(
                _merge(a, agendaData),
              );
            }
          }
        });
        break;
      }
      case TABLED:
        // Fall-thru
      case COMPLETED: {
        let hasFoundNextInactiveItem = false;
        timerItems[agendaId] = { active: false };
        forEach(agendas[meetingId], (a, id) => {
          if (!hasFoundNextInactiveItem && a.status === INACTIVE && a.sortOrder === pAgenda.sortOrder + 1) {
            hasFoundNextInactiveItem = true;
            if (id !== agendaId) {
              shouldBatch = true;
              timerItems[id] = { active: true };
              updateItems[id] = cleanStateDataForDb({
                ...a,
                status: ACTIVE,
              });
              Store.setState({ selectedAgendaId: { ...selectedAgendaId, [meetingId]: id } });
            } else if (agendaId) {
              updateItems[agendaId] = cleanStateDataForDb(
                _merge(a, agendaData),
              );
            }
          }
        });
        break;
      }
      default:
        if (agendaId) timerItems[agendaId] = { active: false };
        break;
    }
  }

  if (shouldBatch) {
    await Promise.all([
      batchUpdateAgenda(makeArray(updateItems)),
      makeArray(timerItems).map((t) => handleTimerAction(t.id, t.active)),
    ]);
  } else {
    await Promise.all([
      upsertAgenda(agendaId ? cleanStateDataForDb(pAgenda) : pAgenda, agendaId, updateStore),
      makeArray(timerItems).map((t) => handleTimerAction(t.id, t.active)),
    ]);
  }
};

export const handleSortAgenda = async (
  Store,
  state,
  agendaItems,
  meetingId,
  cb,
) => {
  if (!agendaItems || agendaItems.length === 0) return Promise.resolve();

  const { me } = state;
  const { uid: userId } = me;
  const { agendas } = Store.getState();
  const updateStore = ({ data }) => {
    forEach(data, ({ id, ...rest }) => {
      agendas[meetingId][id] = enrichAgenda(
        {
          ...agendas[meetingId][id],
          ...rest,
        },
        me,
      );
    });
    if (cb) cb({ res: agendas });
    Store.setState({ agendas });
  };
  const aItems = agendaItems.filter(({ isDeleted }) => !isDeleted).map(({ id, ...rest }) => {
    const a = { ...agendas[meetingId][id], ...rest };
    return cleanStateDataForDb(processFormData(a, userId, fsTimestamp, true));
  });
  await batchUpdateAgenda(aItems);
  updateStore({ data: aItems });
};

// schedules: {},
// scheduledMeetings: {},
