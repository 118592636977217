import React, {
  useEffect, useState, useMemo, useRef,
} from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';
import Common from 'Common';
import { getLocalDateTime, constructCalendarFeedURI, forEach } from 'Utils';
import { MEETING_STATUSES, MEETING_STATUS_LABELS } from 'Utils/Consts';
import { COLORS } from '~/styles/Consts';

const {
  Calendar, Icon, Button, Heading, Grid, Popup, Label,
} = Common;

const Event = styled(Label)`
  width: 100% !important;
`;

const PendingEvent = styled(Event)`
  background-color: ${COLORS.primary} !important;
  color: ${COLORS.white} !important;
`;

// const EventTitle = styled.span`
//   font-weight: 600;
//   font-size: 0.8rem;
//   margin: 0;
//   padding: 0;
// `;

// const EventTime = styled.span`
//   font-size: 0.66rem;
//   margin: 0;
//   padding: 0;
// `;

const Wrapper = styled.div`
  ${({ loading }) => loading ? 'opacity: 0.5;' : ''}
  margin-top: 8rem;
  /* padding: 1rem; */
  /* background-color: ${COLORS.primaryLight}; */
  /* TODO calc: window - footer - header */
  /* height: calc(100vh - 2.5rem - 4.5rem); */
  .rbc-event {
    /* background-color: ${COLORS.primary}; */
    background-color: unset;
  }
  .rbc-today {
    /* background-color: ${COLORS.lightGray2} !important; */
  }
`;

const CalendarLink = styled.a`
  color: ${COLORS.primary};
  :hover {
    color: ${COLORS.active};
  }
`;

const CalendarLinkWrapper = styled.div`
  margin-bottom: 1rem;
  text-align: right;
`;

const Toolbar = styled.div`
  padding: 0;
  margin: 1rem 1.5rem 2rem;
`;

const GridStyled = styled(Grid)`
  padding: 0 !important;
`;

const ColumnStyled = styled(Grid.Column)`
  padding: 0 !important;
`;

// TODO Component navigation feels clunky; wrapping experiment didn't reliable navigate via UE (it's not consistent with cb handlers either, but updating eg month but not calendar is a no-go)
const CustomToolbar = ({
  date: propDate, view: propView, onNavigate, onView, onSelectMonth,
}) => {
  const [dateDisplay, setDateDisplay] = useState(moment(propDate).format());
  const [view, setView] = useState('month');
  const init = useRef(false);

  const handleGoToBack = () => {
    // setDate((moment(date).add(-1, 'month').format()));
    propDate.setMonth(propDate.getMonth() - 1);
    if (init.current) setDateDisplay(moment(dateDisplay).add({ month: -1 }).format());
    onNavigate('prev', propDate);
  };

  const handleGoToNext = () => {
    // setDate(moment(date).add(1, 'month').format());
    propDate.setMonth(propDate.getMonth() + 1);
    if (init.current) setDateDisplay(moment(dateDisplay).add({ month: 1 }).format());
    onNavigate('next', propDate);
  };

  const handleGoToCurrent = () => {
    // setDate((moment().format()));
    const now = new Date();
    propDate.setMonth(now.getMonth());
    propDate.setYear(now.getFullYear());
    if (init.current) setDateDisplay(moment(now).format());
    onNavigate('current', propDate);
  };

  useEffect(() => {
    if (onSelectMonth) onSelectMonth(dateDisplay);
  }, [dateDisplay]);

  const handleSelectMonth = () => {
    if (view !== 'month') setView('month');
  };

  // const handleSelectWeek = () => {
  // setView('week');
  // };

  // const handleSelectDay = () => {
  // setView('day');
  // };

  useEffect(() => {
    if (view !== propView) onView(view);
  }, [view]);

  // Hack to make nav feel a bit snappier by init these cbs
  useEffect(() => {
    if (init.current) return;
    init.current = true;
    handleGoToBack();
    handleGoToNext();
    handleGoToCurrent();
  }, []);

  const renderDate = () => <Heading as="h3" textAlign="left">{moment(dateDisplay).format('MMMM Y')}</Heading>;

  const renderButton = ({
    icon,
    popup,
    onClick,
    color,
    text,
    ...rest
  }) => (
    <Button key={text || popup} onClick={onClick} color={color} {...rest}>
      <Popup
        trigger={(
          <span>
            {icon && <Icon name={icon} />}
            {text}
          </span>
        )}
        content={popup}
        position="top center"
        on="hover"
        hideOnScroll
        size="small"
        inverted
      />
    </Button>
  );

  const renderNavigateDate = () => {
    const isTodayActive = moment(dateDisplay).format('Y-MMM') === moment().format('Y-MMM');
    const buttons = [
      {
        active: false,
        icon: 'chevron left',
        popup: `Go to ${moment(dateDisplay).add(-1, 'month').format('MMM Y')}`,
        onClick: handleGoToBack,
      },
      {
        active: isTodayActive,
        popup: isTodayActive ? `Viewing ${moment().format('MMM Y')}` : `Go to ${moment().format('MMM Y')}`,
        text: 'Today',
        onClick: handleGoToCurrent,
      },
      {
        active: false,
        icon: 'chevron right',
        popup: `Go to ${moment(dateDisplay).add(1, 'month').format('MMM Y')}`,
        onClick: handleGoToNext,
      },
    ];
    return (
      <Button.Group size="mini">
        {buttons.map(renderButton)}
      </Button.Group>
    );
  };

  const renderSelectView = () => {
    const buttons = [
      {
        active: view === 'month',
        popup: 'Month view',
        text: 'Month',
        onClick: handleSelectMonth,
      },
      // {
      //   active: view === 'week',
      //   popup: 'Week view',
      //   text: 'Week',
      //   onClick: handleSelectWeek,
      // },
      // {
      //   active: view === 'day',
      //   popup: 'Day view',
      //   text: 'Day',
      //   onClick: handleSelectDay,
      // },
    ];
    return (
      <Button.Group size="tiny" compact basic>
        {buttons.map(renderButton)}
      </Button.Group>
    );
  };

  return (
    <Toolbar>
      <GridStyled padded={false} verticalAlign="bottom">
        <ColumnStyled width={4} textAlign="left">
          {renderDate()}
        </ColumnStyled>
        <ColumnStyled width={8} textAlign="center">
          {renderNavigateDate()}
        </ColumnStyled>
        <ColumnStyled width={4} textAlign="right">
          {renderSelectView()}
        </ColumnStyled>
      </GridStyled>
    </Toolbar>
  );
};

// =======================================================================================

const MeetingCalendar = ({
  meetings,
  me,
  isAdmin,
  customToolbar,
  showCalendarFeedLink,
  onGoToMeeting,
  onGoToScheduledMeeting,
  onCalendarClick,
  onSelectMonth,
  selectedMonth,
  loading,
}) => {
  const [meetingEvents, setMeetingEvents] = useState([]);

  const handleTransformMeetingsToEvents = () => {
    const events = [];
    if (!meetings) return;
    forEach(meetings, (m) => {
      if (!m || m.isDeleted) return;
      const {
        id, title, startDateTime, endDateTime, timeZone, isScheduled = false,
      } = m;
      const {
        startDateTime: calendarStart,
        endDateTime: calendarEnd,
        timeRange,
        zone,
      } = getLocalDateTime(startDateTime, endDateTime, timeZone);
      events.push({
        calendarStart: calendarStart.toDate(),
        calendarEnd: calendarEnd.toDate(),
        id,
        title,
        timeRange,
        zone,
        isScheduled,
      });
    });
    setMeetingEvents(events);
  };

  useEffect(() => {
    handleTransformMeetingsToEvents();
  }, [meetings, selectedMonth]);

  const toolbar = useMemo(() => (p) => customToolbar({ ...p, onSelectMonth, selectedMonth }), []);
  // const toolbar = (p) => customToolbar({ ...p, onSelectMonth });

  const renderMeetingEvent = (m) => {
    const {
      status, description, location, isScheduled, scheduleKey,
    } = meetings[m.id] || {};
    if (!status) return null;
    const isRecurring = isScheduled || !!scheduleKey;
    let c = null;
    let El = Event;
    switch (status) {
      case MEETING_STATUSES.PENDING:
        El = PendingEvent;
        break;
      case MEETING_STATUSES.COMMENCED:
        c = 'teal';
        break;
      case MEETING_STATUSES.RECESS:
        c = 'orange';
        break;
      case MEETING_STATUSES.ADJOURNED:
        c = 'blue';
        break;
      case MEETING_STATUSES.CANCELLED:
        c = 'red';
        break;
      default:
        break;
    }
    return (
      <Popup
        trigger={(
          <div>
            <El color={c} size="tiny">{m.title}</El>
          </div>
        )}
        content={(
          <div>
            <div>
              <Icon.Group>
                <Icon name="clock outline" color={isScheduled ? 'grey' : undefined} />
                {isRecurring && <Icon name="redo" color={isScheduled ? 'grey' : undefined} corner />}
              </Icon.Group>
              {isRecurring && ' '}
              <b>
                {m.timeRange}
                {' '}
                {m.zone}
              </b>
            </div>
            {!!location && (
              <div>
                <Icon name="point" />
                {location}
              </div>
            )}
            <div>{MEETING_STATUS_LABELS[status]}</div>
            {!!description && <p>{description}</p>}
          </div>
        )}
        position="left center"
        on="hover"
        hideOnScroll
        size="small"
      />
    );
  };

  // const renderWeekEvent = (m) => {
  //   console.log(m);
  //   return <div>{m.title}</div>;
  // };

  const renderMeetings = () => (
    <Calendar
      events={meetingEvents}
      startAccessor="calendarStart"
      endAccessor="calendarEnd"
      onSelectEvent={({ id, isScheduled }) => isScheduled ? onGoToScheduledMeeting(id) : onGoToMeeting(id)}
      onSelectSlot={isAdmin
        ? ({ start: date }) => onCalendarClick(date)
        : null}
      selectable
      popup
      defaultView="month"
      views={{
        month: true,
        week: true,
        work_week: false,
        day: true,
      }}
      titleAccessor={renderMeetingEvent}
      components={{
        toolbar,
      }}
      style={{ height: '45vh' }}
    />
  );

  const renderCalendarLink = () => (
    <CalendarLinkWrapper>
      <CalendarLink href={constructCalendarFeedURI(me)}>
        <Icon name="external alternate" size="small" />
        Link feed to my calendar
      </CalendarLink>
    </CalendarLinkWrapper>
  );

  return (
    <Wrapper loading={loading}>
      {showCalendarFeedLink && renderCalendarLink()}
      {renderMeetings()}
    </Wrapper>
  );
};

MeetingCalendar.defaultProps = {
  customToolbar: CustomToolbar,
};

export default MeetingCalendar;
