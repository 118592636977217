import { USER_ROLES, QUEUE_STATUSES } from 'Utils/Consts';
import { getTimestamp } from 'Utils';
import { job, fsTimestamp } from '../firebase';

const {
  createJob,
} = job;

export const handleCreateJob = async (state, job) => {
  if (!job) {
    console.error('handleCreateJob() missing job:', job);
    return;
  }
  const { me } = state;
  await createJob({
    ...job,
    users: { [me.uid]: { roles: [USER_ROLES.OWNER] } },
    status: QUEUE_STATUSES.PENDING,
    createdAt: getTimestamp(fsTimestamp),
  });
};
