import React from 'react';
import styled from 'styled-components';
import { momentizeTime } from 'Utils';

const DateTimeStyled = styled.time`

`;

const DateTime = ({
  dt,
  dateTime,
  format,
  prefix,
  suffix,
  calendarFormat,
}) => {
  const showHumanTime = format === 'human';
  const momentDateTime = momentizeTime(dt || dateTime);
  const isoDateTime = momentDateTime.format();
  const formattedDateTime = showHumanTime ? momentDateTime.calendar(null, calendarFormat) : momentDateTime.format(format);
  return (
    <DateTimeStyled datetime={isoDateTime}>
      {prefix}
      {formattedDateTime}
      {suffix}
    </DateTimeStyled>
  );
};

DateTime.defaultProps = {
  format: 'human',
  calendarFormat: null,
  prefix: '',
  suffix: '',
};

export default DateTime;
