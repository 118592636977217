import React, {
  useState, useMemo, useEffect, useRef,
} from 'react';
import styled from 'styled-components';
import { connect } from 'unistore/react';
import * as Yup from 'yup';
import moment from 'moment-timezone';
import * as chrono from 'chrono-node';
import _padStart from 'lodash/padStart';
import _isFinite from 'lodash/isFinite';
import { RRule } from 'rrule';
import Common from 'Common';
import {
  parseTime, getLocalTime, USER_TZ, findTemplate, forEach, getScheduleKey, getRecurrenceRule,
} from 'Utils';
import { MEETING_STATUSES, MEETING_STATUS_LABELS, VISIBILITY_ENUMS } from 'Utils/Consts';
import { getIsValidInstanceForRecurringRule } from '~/firebase/functions';
import RecurringForm from '../RecurringForm';

const {
  Form, Modal, Heading, Grid, CalendarInput, Checkbox, Button, Icon,
} = Common;

const { Calendar, withRange } = CalendarInput;
const CalendarWithRange = withRange(Calendar);

const getTimeString = (h, m) => `${_padStart(h, 2, '0')}:${_padStart(m, 2, '0')}`;

const parseRawTime = (value) => {
  if (value.match(/[a|p]$/i)) value = `${value}m`.toLowerCase();
  let pt = chrono.parse(value);
  let t = pt.length ? pt[0] : null;
  if (!t && _isFinite(Number(value)) && `${value}`.length === 4) {
    const valAttempt = `${`${value}`.slice(0, 2)}:${`${value}`.slice(2)}`;
    pt = chrono.parse(valAttempt);
    t = pt.length ? pt[0] : null;
  }
  if (!t) return null;
  const h = t?.start?.get('hour');
  const m = t?.start?.get('minute');
  if (!_isFinite(h) || !_isFinite(m)) return null;
  return getTimeString(h, m);
};

const parseDate = (dt) => ({
  year: dt.getFullYear(),
  month: dt.getMonth(),
  date: dt.getDate(),
});

const MeetingSchema = Yup.object().shape({
  title: Yup.string().required('Please enter a meeting title'),
  description: Yup.string(),
  location: Yup.string(),
  externalLink: Yup.string().url(),
  templateId: Yup.string(),
  groupId: Yup.string(),
  startTime: Yup.string()
    .test('time check', 'Please enter a valid start time',
      (value) => {
        if (!value) return false;
        const time = parseRawTime(value);
        if (!time) return false;
        const isValid = moment(time, 'HH:mm').isValid();
        return isValid;
      }),
  endTime: Yup.string()
    .test('time check', 'Please enter a valid end time',
      (value) => {
        if (!value) return false;
        const time = parseRawTime(value);
        if (!time) return false;
        const isValid = moment(time, 'HH:mm').isValid();
        return isValid;
      }),
  duration: Yup.number().required('Please add duration'),
  timeZone: Yup.mixed()
    .oneOf(moment.tz.names())
    .required('Please select a valid time zone'),
  status: Yup.string(),
  visibility: Yup.string(),
});

const MeetingPayloadSchema = Yup.object().shape({
  title: Yup.string().required('Please enter a meeting title'),
  description: Yup.string(),
  location: Yup.string(),
  externalLink: Yup.string().url(),
  templateId: Yup.string(),
  groupId: Yup.string(),
  startDateTime: Yup.date().required('Enter valid start time'),
  endDateTime: Yup.date().required('Enter valid end time'),
  duration: Yup.number().required('Please add duration'),
  timeZone: Yup.mixed()
    .oneOf(moment.tz.names())
    .required('Please select a valid time zone'),
  status: Yup.string(),
  visibility: Yup.string(),
  scheduleId: Yup.string(),
  scheduleKey: Yup.string(),
});

const INITIAL_STATE = {
  title: '',
  description: '',
  startDate: moment().toDate(),
  endDate: moment().toDate(),
  startTime: '',
  endTime: '',
  location: '',
  externalLink: '',
  timeZone: USER_TZ,
  isPublic: true,
  hasRsvp: false,
  status: MEETING_STATUSES.PENDING,
  templateId: '',
  groupId: '',
  isVideoEnabled: true,
};

const RecurringWrapper = styled.div`
  margin-top: 1rem;
`;

const defaultOptions = [{ value: '', text: '-None-' }];

const getTemplateOptions = (templates, uid) => {
  const options = [...defaultOptions];
  forEach(templates, (groupVal, groupKey) => {
    // if (groupKey === uid)
    // TODO group list options by 'my' and $group
    forEach(groupVal, (val, key) => {
      let agendaCount = 0;
      forEach(val.agendaItems, () => {
        agendaCount += 1;
      });
      options.push({
        value: key,
        text: `${val.title} (${agendaCount} agenda ${agendaCount === 1 ? 'item' : 'items'})`,
      });
    });
  });
  return options;
};

const getGroupOptions = (groups) => {
  const options = [...defaultOptions];
  forEach(groups, (val, key) => {
    if (val.isAdmin) {
      options.push({
        value: key,
        text: val.title,
      });
    }
  });
  return options;
};

const getStatusOptions = () => {
  const options = [];
  forEach(MEETING_STATUSES, (val, key) => {
    options.push({ value: val, text: MEETING_STATUS_LABELS[key] });
  });
  return options;
};

const getTimeZoneList = (date = moment()) => moment.tz.names().map((z) => ({
  text: `${z.replace('/', ' / ').replace('_', ' ')} (${moment
    .tz(date, z)
    .format('Z z')})`,
  value: z,
}));

const MeetingForm = ({
  onUpsertMeeting,
  onUpsertSchedule,
  onToggleMeetingFormModal,
  showModal,
  meetingData,
  templatesData,
  groupsData,
  groupId,
  uid,
  selectedDate,
  features,
  accountId: aId,
  store,
}) => {
  const { schedules, groups, accounts } = store;
  const isEditMode = !!meetingData;
  const [accountId, setAccountId] = useState(isEditMode ? meetingData.accountId : aId);
  const [selectedGroupId, setSelectedGroupId] = useState(isEditMode ? meetingData.groupId : groupId);
  const [groupOptions, setGroupOptions] = useState(groupsData);
  const templateOptions = getTemplateOptions(templatesData, uid);
  const selectedTemplateRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [invalidInstance, setInvalidInstance] = useState(null);

  const initMultiDayMode = useMemo(() => isEditMode
    ? (
      moment(meetingData.startDateTime).format('Y-MM-DD')
      !== moment(meetingData.endDateTime).format('Y-MM-DD')
    )
    : false,
  [meetingData]);
  const [multiDayMode, setMuliDayMode] = useState(initMultiDayMode);
  const [startDate, setStartDate] = useState(moment(
    selectedDate || (isEditMode ? meetingData.startDateTime : undefined),
  ).toDate());
  const [endDate, setEndDate] = useState(moment(
    selectedDate || (isEditMode ? meetingData.endDateTime : undefined),
  ).toDate());

  const [showRecurringForm, setShowRecurringForm] = useState(false);
  const [recurringText, setRecurringText] = useState(null);
  const recurringStateRef = useRef(null);

  const handleSetRRule = ({ options, text, rrule }) => {
    recurringStateRef.current = { options, text, rrule };
    setRecurringText(text);
  };

  useEffect(() => {
    if (!isEditMode) return;
    if (meetingData?.scheduleId && schedules[meetingData.scheduleId]) {
      const { recurrence } = schedules[meetingData.scheduleId];
      const options = { ...recurrence };
      ['dtstart', 'until'].forEach((field) => {
        if (options[field]) {
          options[field] = field === 'dtstart' ? null : moment(options[field]).toDate();
        }
      });
      const r = new RRule(options);
      const text = r.toText();
      const rrule = r.toString();
      handleSetRRule({ options: r.options, text, rrule });
    }
  }, [meetingData, schedules, schedules[meetingData?.scheduleId]]);

  const { accountId: myAccountId } = useMemo(() => {
    let activeAccount = null;
    forEach(accounts, (val, key) => {
      if (val.isActive) {
        activeAccount = key;
      }
    });
    if (!accountId && activeAccount) setAccountId(activeAccount);
    return {
      accountId: activeAccount,
      accountHandle: activeAccount ? accounts[activeAccount].handle : null,
    };
  }, [accounts]);

  useEffect(() => {
    setGroupOptions(getGroupOptions(groups));
    if (groupId && groups[groupId] && (groups[groupId].accountId !== accountId)) {
      setAccountId(groups[groupId].accountId);
    }
  }, [groups]);

  const handleGroupSelection = (gId) => {
    setSelectedGroupId(gId);
    if (gId) {
      if (groups[gId] && groups[gId].accountId !== accountId) {
        setAccountId(groups[gId].accountId);
      }
    } else {
      setAccountId(aId || myAccountId || '');
    }
  };

  const handleToggleRecurringForm = () => setShowRecurringForm(!showRecurringForm);

  const handleToggleMultiDayMode = () => setMuliDayMode(!multiDayMode);

  const handleToggleModal = () => onToggleMeetingFormModal();

  const handleSubmitMeeting = async (vals) => {
    if (invalidInstance) setInvalidInstance(null);
    setLoading(true);
    const {
      templateId = '',
      groupId: gId = '',
      title = '',
      description = '',
      location = '',
      timeZone = '',
      startTime = '',
      endTime = '',
      isPublic = '',
      status = '',
      externalLink = '',
      isVideoEnabled = null,
      hasRsvp = null,
    } = vals;
    if (!startTime || !endTime) {
      setLoading(false);
      return;
    }
    const startDateTime = moment
      .tz({ ...parseDate(startDate), ...parseTime(parseRawTime(startTime)) }, timeZone);
    const endDateTime = moment
      .tz({ ...parseDate(endDate), ...parseTime(parseRawTime(endTime)) }, timeZone);
    const meetingVals = {
      templateId: isEditMode ? meetingData.templateId : templateId || '',
      duration: moment
        .duration(endDateTime.diff(startDateTime))
        .asMilliseconds(),
      status: isEditMode ? status : MEETING_STATUSES.PENDING,
      visibility: isPublic ? VISIBILITY_ENUMS.PUBLIC : VISIBILITY_ENUMS.PRIVATE,
      startDateTime: startDateTime.toISOString(),
      endDateTime: endDateTime.toISOString(),
      isDeleted: isEditMode ? meetingData?.isDeleted : false,
      accountId: '',
      groupId: '',
      title,
      description,
      location,
      timeZone,
      externalLink,
      isVideoEnabled,
      hasRsvp,
    };
    if (selectedGroupId || gId || groupId) meetingVals.groupId = selectedGroupId || gId || groupId;
    meetingVals.accountId = isEditMode ? meetingData?.accountId : (accountId || aId || '');
    const isValid = await MeetingPayloadSchema.isValid(meetingVals);
    let scheduleId = meetingData?.scheduleId || '';
    let scheduleKey = meetingData?.scheduleKey || '';
    if (isValid && recurringStateRef.current?.options) {
      // const sId = scheduleId || docId();
      const { options } = recurringStateRef.current;
      options.dtstart = meetingVals.startDateTime;
      options.tzid = meetingVals.timeZone;
      options.byhour = null;
      options.byminute = null;
      options.bysecond = null;
      const rule = getRecurrenceRule(options, meetingVals);
      const scheduleData = {
        recurrence: options,
        rrule: rule.toString(),
        text: rule.toText(),
        meeting: meetingVals,
        accountId: meetingVals.accountId,
        groupId: meetingVals.groupId,
        visibility: meetingVals.visibility,
        query: {
          startTime: meetingVals.startDateTime.split('T')[1], // Z time to query for reminders
          endTime: meetingVals.endDateTime.split('T')[1],
          until: options.until,
          // days: [], // TODO optimize search fields to minimize schedules calls
        },
      };
      const isValidInstance = await getIsValidInstanceForRecurringRule({
        instance: meetingVals.startDateTime,
        scheduleData: {
          ...scheduleData,
          recurrence: { ...options, until: moment(meetingVals.startDateTime).add({ day: 1 }).toISOString() },
          meeting: { ...meetingVals, startDateTime: moment(meetingVals.startDateTime).add({ year: -1 }).toISOString() },
        },
      });
      if (!isValidInstance) {
        setInvalidInstance(`${moment(meetingVals.startDateTime).format('LLLL')} is not a valid date for the recurring rule set in this series. Please adjust the date or the recurring rule.`);
        setLoading(false);
        return;
      }
      if (!isEditMode) {
        const scheduleRes = await new Promise((resolve) => {
          onUpsertSchedule(scheduleData, null, resolve);
        });
        const { res: { id: sId } } = scheduleRes;
        scheduleId = sId;
        scheduleKey = getScheduleKey(meetingVals.startDateTime, scheduleId);
      }
    }
    meetingVals.scheduleId = scheduleId;
    meetingVals.scheduleKey = scheduleKey;
    if (isValid) {
      if (isEditMode) {
        meetingVals.updateLog = meetingData.updateLog;
      }
      const meetingId = isEditMode ? meetingData.id : undefined;
      if (isEditMode) {
        await onUpsertMeeting(meetingVals, meetingId);
      } else {
        await onUpsertMeeting(meetingVals, meetingId);
      }
      setLoading(false);
      handleToggleModal();
    } else {
      setLoading(false);
    }
  };

  const handleCalendarSelect = (date) => {
    if (multiDayMode) {
      const { start, end } = date;
      setStartDate(start);
      setEndDate(end);
    } else {
      const shouldUnselect = moment(date).format()
      === moment(startDate).format();
      const newDate = shouldUnselect ? false : date;
      setStartDate(newDate);
      setEndDate(newDate);
    }
  };

  const handleSelectTemplate = (templateId) => {
    selectedTemplateRef.current = findTemplate(templatesData, templateId);
    if (selectedTemplateRef.current) {
      const { meeting } = selectedTemplateRef.current;
      forEach(meeting, (_, property) => {
        switch (property) {
          case 'templateId':
            break;
          case 'startDateTime':
            window.setFormValue('startTime', getLocalTime(meeting[property], meeting.timeZone).format('LT'));
            break;
          case 'endDateTime':
            window.setFormValue('endTime', getLocalTime(meeting[property], meeting.timeZone).format('LT'));
            break;
          case 'visibility':
            window.setFormValue('isPublic', meeting[property] === VISIBILITY_ENUMS.PUBLIC);
            break;
          default:
            window.setFormValue(property, meeting[property]);
            break;
        }
      });
      if (meeting.groupId !== groupId) handleGroupSelection(meeting.groupId);
    }
    window.setFormValue('templateId', templateId || '');
  };

  const meetingFields = useMemo(() => {
    let mFields = [
      {
        id: 'title',
        label: 'Title',
        initialValue: INITIAL_STATE.title,
        autoFocus: !isEditMode,
      },
      {
        id: 'description',
        label: 'Description',
        initialValue: INITIAL_STATE.description,
      },
      {
        id: 'location',
        label: 'Location',
        initialValue: INITIAL_STATE.location,
      },
      {
        id: 'externalLink',
        label: 'Video Link (eg Zoom or Skype)',
        initialValue: INITIAL_STATE.externalLink,
      },
      {
        id: 'startTime',
        label: 'Start Time',
        type: 'time',
        initialValue: INITIAL_STATE.startTime,
      },
      {
        id: 'endTime',
        label: 'End Time',
        type: 'time',
        initialValue: INITIAL_STATE.endTime,
      },
      {
        id: 'timeZone',
        label: 'Time Zone',
        type: 'dropdown',
        initialValue: INITIAL_STATE.timeZone,
        controlProps: {
          placeholder: 'Select meeting time zone',
          fluid: true,
          search: true,
          selection: true,
          options: getTimeZoneList(),
        },
      },
      {
        id: 'isPublic',
        label: 'Shareable Link (Anyone with a link can view meeting if enabled)',
        type: 'checkbox',
        initialValue: INITIAL_STATE.isPublic,
      },
      {
        id: 'hasRsvp',
        label: 'Request RSVP from invitees and participants',
        type: 'checkbox',
        initialValue: INITIAL_STATE.hasRsvp,
      },
    ];

    if (features.hasVideo) {
      mFields[3] = {
        id: 'isVideoEnabled',
        label: 'Integrated Video Conferencing + Screensharing',
        type: 'checkbox',
        initialValue: INITIAL_STATE.isVideoEnabled,
      };
      // TODO Splice max participants selector here
    }

    if (groupOptions.length > defaultOptions.length) {
      mFields.unshift({
        id: 'groupId',
        label: 'Group',
        type: 'dropdown',
        initialValue: INITIAL_STATE.groupId || groupId,
        controlProps: {
          value: selectedGroupId,
          onChange: (e, { value }) => handleGroupSelection(value),
          placeholder: 'Select group',
          fluid: true,
          selection: true,
          clearable: true,
          options: groupOptions,
        },
      },);
    }

    if (!isEditMode && templateOptions.length > defaultOptions.length) {
      mFields.unshift({
        id: 'templateId',
        label: 'Template',
        type: 'dropdown',
        initialValue: INITIAL_STATE.templateId,
        controlProps: {
          placeholder: 'Select template',
          fluid: true,
          selection: true,
          clearable: true,
          options: templateOptions,
          onChange: (_, { value }) => handleSelectTemplate(value),
        },
      },);
    }

    if (isEditMode) {
      mFields.push({
        id: 'status',
        label: 'Status',
        type: 'dropdown',
        initialValue: INITIAL_STATE.status,
        controlProps: {
          placeholder: 'Meeting status',
          fluid: true,
          search: true,
          selection: true,
          options: getStatusOptions(),
        },
      });
      const { timeZone, startDateTime, endDateTime } = meetingData;
      const fields = mFields.map((field) => {
        const { id } = field;
        let fieldVal = meetingData[id];
        if (id === 'startTime') {
          fieldVal = getLocalTime(startDateTime, timeZone).format('LT');
        }
        if (id === 'endTime') {
          fieldVal = getLocalTime(endDateTime, timeZone).format('LT');
        }
        if (id === 'isVideoEnabled') {
          fieldVal = !!meetingData.isVideoEnabled;
        }
        if (id === 'isPublic') {
          fieldVal = meetingData.visibility === 'PUBLIC';
        }
        // TODO Refactor to account for falsey vals
        if (fieldVal || id === 'isVideoEnabled' || id === 'isPublic') {
          return {
            ...field,
            initialValue: fieldVal,
          };
        }
        return field;
      });
      mFields = fields;
    }
    return mFields;
  }, [templateOptions, features]);

  const renderColumns = ({ left, right }) => (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>{left}</Grid.Column>
        <Grid.Column width={8}>{right}</Grid.Column>
      </Grid.Row>
    </Grid>
  );

  const renderRecurringModal = () => {
    const recurrence = recurringStateRef.current?.options ?? meetingData?.recurrence ?? null;
    return (
      <Modal
        open={showRecurringForm}
        // onClose={handleToggleModal}
        size="tiny"
      >
        <Heading
          icon="redo"
          content="Recurring Schedule"
        />
        <Modal.Content>
          <RecurringForm
            onSetRRule={handleSetRRule}
            recurrence={recurrence}
            timeZone={null}
            startDateTIme={null}
            onClose={handleToggleRecurringForm}
          />
        </Modal.Content>
      </Modal>
    );
  };

  const renderRecurring = () => {
    if (!features.hasSchedules) return null;
    return (
      <RecurringWrapper>
        {!!invalidInstance && <Form.Error>{invalidInstance}</Form.Error>}
        <Checkbox
          disabled={isEditMode && !!meetingData?.scheduleKey}
          checked={!!recurringText || !!meetingData?.scheduleKey}
          onChange={() => {
            if (!recurringText) handleToggleRecurringForm();
            if (recurringText) {
              handleSetRRule({ options: null, text: null, rrule: null });
              if (invalidInstance) setInvalidInstance(null);
            }
          }}
          label={(
            <Form.Label>
              Recurring Meeting
            </Form.Label>
          )}
          toggle
        />
        {!!recurringText && (
          <RecurringWrapper>
            <Icon name="redo" />
            <Button
              as={isEditMode ? 'span' : 'a'}
              onClick={isEditMode ? undefined : handleToggleRecurringForm}
              styling={isEditMode ? undefined : { cursor: 'pointer' }}
            >
              <b>
                {!isEditMode && <Icon name="pencil" />}
                {' '}
                {recurringText}
              </b>
            </Button>
          </RecurringWrapper>
        )}
        {renderRecurringModal()}
      </RecurringWrapper>
    );
  };

  const renderCalendar = () => (
    <>
      <CalendarInput
        Component={multiDayMode ? CalendarWithRange : undefined}
        width="100%"
        height={240}
        selected={multiDayMode ? {
          start: startDate,
          end: endDate,
        } : startDate}
        locale={{
          headerFormat: 'MMM Do',
        }}
        onSelect={handleCalendarSelect}
      />
      <br />
      <Checkbox
        defaultChecked={multiDayMode}
        onChange={handleToggleMultiDayMode}
        label={(
          <Form.Label>
            Multi-Day Mode
          </Form.Label>
        )}
        toggle
      />
      {renderRecurring()}
    </>
  );

  const renderForm = () => (
    <Form
      onSubmit={handleSubmitMeeting}
      validationSchema={MeetingSchema}
      fields={meetingFields}
      buttons={[
        {
          text: isEditMode ? 'Save Meeting' : 'Create Meeting',
          params: {
            type: 'submit',
            primary: true,
            loading,
          },
        },
        {
          text: 'Cancel',
          params: {
            basic: true,
            onClick: handleToggleModal,
            disabled: loading,
          },
        },
      ]}
    />
  );

  return (
    <Modal
      open={showModal}
      // onClose={handleToggleModal}
      size="large"
      // dimmer="blurring"
    >
      <Heading
        icon="calendar alternate outline"
        content={isEditMode ? 'Save Meeting' : 'Create Meeting'}
      />
      <Modal.Content>
        {renderColumns({
          left: renderCalendar(),
          right: renderForm(),
        })}
      </Modal.Content>
    </Modal>
  );
};

export default connect((store) => ({ store }))(MeetingForm);
