import styled from 'styled-components';
import InfiniteCalendar, { Calendar, withRange } from 'react-infinite-calendar';
import { COLORS } from '~/styles/Consts';
import 'react-infinite-calendar/styles.css';

const CalendarInput = styled(InfiniteCalendar)`
    &[style] {
        font-family: 'Roboto' !important;
        .Cal__Header__root, .Cal__Day__selection {
            background-color: ${COLORS.darkGray} !important;
        }
        .Cal__Day__selection {
            border-color: ${COLORS.darkGray} !important;
        }
        .Cal__Weekdays__root {
            background-color: ${COLORS.active} !important;
        }
        .Cal__Day__today {
            color: ${COLORS.highlight} !important;
        }
        .Cal__Header__range, .Cal__Day__range {
            color: ${COLORS.primary} !important;
        }
    }
`;

CalendarInput.Calendar = Calendar;
CalendarInput.withRange = withRange;

export default CalendarInput;
