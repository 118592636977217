import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'unistore/react';
import styled from 'styled-components';
import _get from 'lodash/get';
import moment from 'moment-timezone';
import Common from 'Common';
import {
  ISO_8601_ms,
  calcDuration,
  calcMsToHumanTime,
  getTimerContext,
} from 'Utils';
import { EVENT_TYPES, MEETING_STATUSES } from 'Utils/Consts';
import { TIMER_COLORS } from '~/styles/Consts';
// import useInterval from '~/hooks/useInterval';
import Actions from '~/state/Actions';

const { Progress, Icon } = Common;

const Wrapper = styled.div`
  display: flex;
  margin-top: 0;
  margin-bottom: 0;
  .ui.progress:last-child {
    margin-bottom: 0 !important;
  }
`;

const ProgressStyled = styled(Progress)`
  flex: auto;
  margin-top: 0 !important;
  .label {
    font-weight: 400 !important;
  }
  ${'' /* @media (prefers-color-scheme: dark) {
    .label {
      color: #fff !important;
    }
  } */}
`; // TODO Adjust color on disabled dark scheme — use setting

const TimerController = styled(Icon)`
  font-size: 2.2rem !important;
  padding: 0 2rem;
  cursor: pointer;
`;

const getElapsedTime = (entity) => {
  if (!entity) return 0;
  const {
    isBefore,
    isAfter,
    isBetween,
    msFromStart,
    msSinceEnd,
  } = getTimerContext(entity);
  const isAdjourned = entity.status === MEETING_STATUSES.ADJOURNED;
  if (isBetween) {
    return msFromStart;
  }
  if (isAfter && !isAdjourned) {
    return calcDuration(entity) + msSinceEnd;
  }
  if (isBefore) {
    return 0;
  }
  if (isAfter) {
    return calcDuration(entity);
  }
  return 0;
};

const getIsActive = (entity) => {
  const { isBefore, isAfter, isBetween } = getTimerContext(entity);
  const isAdjourned = entity.status === MEETING_STATUSES.ADJOURNED;
  const isCancelled = entity.status === MEETING_STATUSES.CANCELLED;
  if (isBefore) return false;
  if (isBetween) return true;
  if (isAfter) return !isAdjourned || !isCancelled || false;
};

const Timer = (props) => {
  const {
    id,
    meetingId,
    size,
    type,
    store,
    updateFreq,
    manageSubscription,
    hideControls,
    hideMessage,
    showCurrentTime,
    altMode,
    autoMode,
    messagePosition,
    transformMessageCb,
    renderMessage,
    onSubscribeMeetingTimer,
    onUnsubscribeMeetingTimer,
    onSubscribeAgendaTimer,
    onUnsubscribeAgendaTimer,
    onAddMeetingTimerEvent,
    onAddAgendaTimerEvent,
  } = props;

  const { timers, meetings, agendas } = store;
  const isMeeting = type === 'meeting';
  const isAgenda = type === 'agenda';
  const entity = (isMeeting && meetings[id]) || (isAgenda && agendas[meetingId][id]);
  if (!entity) return null;
  const timer = timers[id];
  const isAdjourned = entity.status === MEETING_STATUSES.ADJOURNED;
  const isActive = autoMode
    ? getIsActive(entity)
    : _get(timer, 'active', false);
  const lastStartTime = _get(timer, 'lastStartTime', null);
  const initElapsed = autoMode
    ? getElapsedTime(entity)
    : _get(timer, 'elapsed', 0);
  const initDuration = isMeeting
    ? calcDuration(entity)
    : _get(entity, 'budgetedDuration', 0);
  const { isAdmin } = entity;

  const [elapsed, setElapsed] = useState(initElapsed);
  const [duration, setDuration] = useState(initDuration);
  const [message, setMessage] = useState(null);
  const [active, setActive] = useState(isActive);
  const [currentTime, setCurrentTime] = useState(moment().format('LT'));

  const timerIdRef = useRef(null);
  const timerElapsedRef = useRef(0);
  const autoStartTimerIdRef = useRef(null);

  const handleSetMessage = () => {
    if (!entity) return null;
    if (transformMessageCb) {
      const msg = transformMessageCb({ elapsed, duration, active });
      if (!hideMessage) setMessage(msg);
      return;
    }
    if (hideMessage) return null;
    const { startDateTime, endDateTime } = entity;
    const meetingTime = isMeeting && false // TODO
      ? ` (${moment(startDateTime).format('HH:mm')} – ${moment(
        endDateTime,
      ).format('HH:mm')})`
      : '';
    const meetingDuration = calcMsToHumanTime(duration);
    const m = duration >= elapsed
      ? `Time allotted: ${meetingDuration}${meetingTime} / ${calcMsToHumanTime(
        duration - elapsed,
      )} remaining`
      : `Time allotted: ${meetingDuration}${meetingTime} / ${calcMsToHumanTime(
        elapsed - duration,
      )} over`;
    setMessage(m);
  };

  useEffect(() => {
    if (!showCurrentTime) return;
    const setCurTime = () => {
      const time = moment().format('LT');
      if (currentTime !== time) {
        setCurrentTime(time);
      }
    };
    const t = setInterval(setCurTime, 1000);
    return () => {
      clearInterval(t);
    };
  }, []);

  const handleTimerAction = () => {
    const { START_TIMER, STOP_TIMER } = EVENT_TYPES;
    const event = {
      type: active ? STOP_TIMER : START_TIMER,
    };
    if (isMeeting) {
      onAddMeetingTimerEvent(id, event);
    } else if (isAgenda) {
      onAddAgendaTimerEvent(id, event);
    }
  };

  const handleClearTimer = () => {
    if (timerIdRef.current) {
      clearInterval(timerIdRef.current);
      timerIdRef.current = null;
    }
  };

  const handleTimer = () => {
    let { elapsed: calcElapsed } = timer || {};
    if (autoMode) {
      calcElapsed = getElapsedTime(entity);
    }
    const now = moment();
    const msSince = active && !autoMode
      ? calcDuration({
        startDateTime: lastStartTime || now,
        endDateTime: now,
      })
      : 0;
    timerElapsedRef.current = calcElapsed ? calcElapsed + msSince : msSince;
    if (timerElapsedRef.current > 0) {
      setElapsed(timerElapsedRef.current);
    }
  };

  const startTimer = () => {
    if (!timerIdRef.cirrent) {
      timerIdRef.current = setInterval(handleTimer, updateFreq);
    }
  };

  useEffect(() => {
    handleSetMessage();
  }, [elapsed, duration]);

  useEffect(() => {
    if (entity && timer) {
      const d = isMeeting ? calcDuration(entity) : entity.budgetedDuration;
      if (d !== duration) setDuration(d);
      handleTimer();
    }
  }, [id, entity, timer]);

  useEffect(() => {
    if (active) {
      startTimer();
    } else {
      handleClearTimer();
    }
  }, [id, active]);

  useEffect(() => {
    if (manageSubscription) {
      if (isMeeting) {
        onSubscribeMeetingTimer(id);
      } else if (isAgenda) {
        onSubscribeAgendaTimer(id);
      }
    }
    return () => {
      handleClearTimer();
      if (manageSubscription) {
        if (isMeeting) {
          onUnsubscribeMeetingTimer(id);
        } else if (isAgenda) {
          onUnsubscribeAgendaTimer(id);
        }
      }
    };
  }, [id]);

  useEffect(() => {
    if (autoMode) {
      const isActiveNow = getIsActive(entity);
      if (isActiveNow !== active) {
        setActive(isActiveNow);
      }
    }
  }, [entity]);

  useEffect(() => {
    if (!entity || !autoMode) return;
    const context = getTimerContext(entity);
    if (context.isAfter && isAdjourned) {
      if (active) setActive(false);
    } else if (context.isBetween || (context.isAfter && !isAdjourned)) {
      if (!active) setActive(true);
    } else if (context.isBefore) {
      if (active) setActive(false);
      autoStartTimerIdRef.current = setTimeout(
        () => setActive(true),
        context.msUntilStart,
      );
    }
    return () => {
      if (autoStartTimerIdRef.current) {
        clearTimeout(autoStartTimerIdRef.current);
      }
    };
  }, [entity]);

  const renderMsg = () => {
    const m = showCurrentTime ? `${message} (${currentTime})` : message;
    return renderMessage ? renderMessage(m) : m;
  };

  const renderProgressBar = () => {
    const percent = (elapsed / duration) * 100;
    let color = TIMER_COLORS.ample;
    switch (true) {
      case percent > 100:
        color = TIMER_COLORS.exceeded;
        break;
      case percent >= 98:
        color = TIMER_COLORS.fleeting;
        break;
      case percent >= 90:
        color = TIMER_COLORS.headsup2;
        break;
      case percent >= 80:
        color = TIMER_COLORS.headsup;
        break;
      default:
        break;
    }
    return (
      <>
        {messagePosition === 'above' && renderMsg()}
        <ProgressStyled
          active={active}
          disabled={!active}
          value={elapsed}
          total={duration}
          color={color}
          indicating={false}
          size={size}
        >
          {messagePosition === 'below' && renderMsg()}
        </ProgressStyled>
      </>
    );
  };

  const renderControls = () => {
    if (hideControls || !isAdmin || autoMode) return null;
    return (
      <TimerController
        onClick={handleTimerAction}
        name={active ? 'pause circle outline' : 'play circle outline'}
        size="large"
      />
    );
  };

  if (!timer) return null;

  return altMode ? (
    renderProgressBar()
  ) : (
    <Wrapper>
      {renderProgressBar()}
      {renderControls()}
    </Wrapper>
  );
};

Timer.defaultProps = {
  updateFreq: 1000,
  size: 'tiny',
  manageSubscription: true,
  hideControls: false,
  hideMessage: false,
  altMode: false,
  autoMode: false,
  messagePosition: 'below',
  showCurrentTime: false,
};

export default connect((store) => ({ store }), Actions)(Timer);
