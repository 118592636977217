import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import Common from 'Common';
import { GROUP_STATUSES, VISIBILITY_ENUMS } from 'Utils/Consts';

const {
  Form, Modal, Heading, Icon,
} = Common;

const HandlePreview = styled.span`
  font-weight: 300;
  font-size: 0.8rem;
`;

const GroupSchema = Yup.object().shape({
  title: Yup.string().required('Please enter a meeting title'),
  description: Yup.string(),
  handle: Yup.string().required('Please enter group handle'),
  url: Yup.string().url(),
  logoUri: Yup.string().url(),
  accountId: Yup.string().required('Missing accountId'),
  status: Yup.string(),
  visibility: Yup.string(),
});

const INITIAL_STATE = {
  title: '',
  description: '',
  handle: '',
  url: '',
  logoUri: '',
  isPublic: true,
};

const getFormattedHandle = (val) => val ? `${val}`.replace(/[\W_]+/g, '-').toLowerCase() : '';

const GroupForm = ({
  onUpsertGroup,
  onToggleGroupFormModal,
  showModal,
  groupData,
  accountId,
  accountHandle,
}) => {
  const isEditMode = !!groupData;

  const [gHandle, ah] = isEditMode ? `${groupData.handle}`.split('@') : '';
  const aHandle = isEditMode ? ah : accountHandle;

  const [loading, setLoading] = useState(false);
  const [handlePlaceholder, setHandlePlaceholder] = useState('');
  const [handlePreview, setHandlePreview] = useState(isEditMode ? gHandle : '');

  const handleToggleModal = () => onToggleGroupFormModal();

  const handleSubmitGroup = async (vals) => {
    setLoading(true);
    const {
      title,
      handle,
      description = '',
      url = '',
      logoUri = '',
      isPublic,
    } = vals;
    const groupVals = {
      status: GROUP_STATUSES.ACTIVE,
      visibility: isPublic ? VISIBILITY_ENUMS.PUBLIC : VISIBILITY_ENUMS.PRIVATE,
      handle: handle ? `${handle}@${aHandle}` : null,
      accountId,
      title,
      description,
      url,
      logoUri,
    };
    const isValid = await GroupSchema.isValid(groupVals);
    if (isValid) {
      if (isEditMode) {
        groupVals.updateLog = groupData.updateLog;
        if (groupData.accountId) {
          groupVals.accountId = groupData.accountId;
        }
      }
      const groupId = isEditMode ? groupData.id : undefined;
      if (isEditMode) {
        await onUpsertGroup(groupVals, groupId);
      } else {
        await onUpsertGroup(groupVals, groupId);
      }
      setLoading(false);
      handleToggleModal();
    } else {
      console.error('GroupForm vals invalid', groupVals);
      setLoading(false);
    }
  };

  const onTitleChange = (value) => {
    const formattedHandle = getFormattedHandle(value);
    setHandlePlaceholder(formattedHandle);
    window.setFormValue('handle', formattedHandle);
    setHandlePreview(formattedHandle);
    return value;
  };

  const handleTransformHandle = (value) => {
    const formattedHandle = getFormattedHandle(value);
    setHandlePreview(formattedHandle);
    return formattedHandle;
  };

  const renderHandleLabel = () => (
    <label htmlFor="handle">
      Handle
      {' '}
      {/* <Popup
        size="small"
        trigger={(
        )}
      >
        {`Your group's unique URL will be: /g/${handlePreview || 'handle'}@${accountHandle}`}
      </Popup> */}
      <span>
        <Icon
          name="info circle"
          color="blue"
          size="small"
        />
      </span>
      <HandlePreview>
        {`Your group's unique URL will be: /g/${handlePreview || 'handle'}@${aHandle}`}
      </HandlePreview>
    </label>
  );

  const groupFields = useMemo(() => {
    let gFields = [
      {
        id: 'title',
        label: 'Title',
        initialValue: INITIAL_STATE.title,
        parse: onTitleChange,
        autoFocus: !isEditMode,
      },
      {
        id: 'description',
        label: 'Description',
        initialValue: INITIAL_STATE.description,
      },
      {
        id: 'handle',
        label: renderHandleLabel(),
        initialValue: INITIAL_STATE.handle,
        placeholder: handlePlaceholder,
        parse: handleTransformHandle,
      },
      {
        id: 'url',
        label: 'External URL',
        initialValue: INITIAL_STATE.url,
      },
      {
        id: 'isPublic',
        label: 'Shareable Link (Anyone with a link can view group page if enabled)',
        type: 'checkbox',
        initialValue: INITIAL_STATE.isPublic,
      },
    ];

    if (isEditMode) {
      const fields = gFields.map((field) => {
        const { id } = field;
        let fieldVal = groupData[id];
        if (id === 'handle') {
          const [h] = `${groupData.handle}`.split('@');
          fieldVal = h || '';
        }
        if (id === 'isPublic') {
          fieldVal = groupData.visibility === 'PUBLIC';
        }
        if (fieldVal || id === 'isPublic') {
          return {
            ...field,
            initialValue: fieldVal,
          };
        }
        return field;
      });
      gFields = fields;
    }
    return gFields;
  }, [groupData, handlePreview]);

  const renderForm = () => (
    <Form
      onSubmit={handleSubmitGroup}
      validationSchema={GroupSchema}
      fields={groupFields}
      buttons={[
        {
          text: isEditMode ? 'Save Group' : 'Create Group',
          params: {
            type: 'submit',
            primary: true,
            loading,
          },
        },
        {
          text: 'Cancel',
          params: {
            basic: true,
            onClick: handleToggleModal,
            disabled: loading,
          },
        },
      ]}
    />
  );

  return (
    <Modal
      open={showModal}
      // onClose={handleToggleModal}
      size="small"
      // dimmer="blurring"
    >
      <Heading
        icon="group"
        content={isEditMode ? 'Save Group' : 'Create Group'}
      />
      <Modal.Content>
        {renderForm()}
      </Modal.Content>
    </Modal>
  );
};

export default GroupForm;
