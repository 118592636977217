import { firestore } from './firebase';

const MinutesCollection = firestore.collection('minutes');

export const upsertMinutes = async (minutes, minutesId, cb, merge = true) => {
  const minutesRef = minutesId
    ? MinutesCollection.doc(minutesId)
    : MinutesCollection.doc();
  return minutesRef
    .set(minutes, {
      merge,
    })
    .then(() => {
      const data = { ...minutes, id: minutesId || minutesRef.id };
      if (cb) cb({ data });
      return { data };
    })
    .catch((err) => console.error(err));
};

export const getMinutesById = async (meetingId) => MinutesCollection.doc(meetingId).get()
  .then((doc) => {
    if (doc.exists) {
      const data = doc.data();
      return data;
    }
  });

export const subscribeMinutes = (id, cb) => {
  if (!id) return;
  const MAX_ATTEMPTS = 30;
  let attempts = 0;
  const onSubscribe = () => {
    attempts += 1;
    if (attempts > MAX_ATTEMPTS) {
      console.error(`Unable to subscribe to minutes: ${id}`);
      return;
    }
    const docRef = MinutesCollection.doc(id);
    const unsubscribe = docRef.onSnapshot(
      (doc) => {
        const data = { ...doc.data(), id: doc.id };
        if (cb) cb({ data, unsubscribe });
        return { data, unsubscribe };
      },
      (err) => {
        console.error('Error getting minutes by id', err);
        setTimeout(() => {
          onSubscribe();
        }, 200);
      },
    );
  };
  return onSubscribe();
};
