import React from 'react';
import styled from 'styled-components';
import Common from 'Common';
import { ENV } from 'Utils';
import { COLORS } from '~/styles/Consts';

const {
  Heading, Icon, Helmet, Spinner,
} = Common;

const HeadingStyled = styled(Heading)`
  margin: 0 !important;
  margin-bottom: 1rem !important;
  padding: 1rem !important;
  background-color: ${COLORS.darkGray} !important;
  color: ${COLORS.white} !important;
  min-height: 4rem !important;
`;

const DefaultContainer = styled.div`
`;

const DefaultWrapper = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  overflow-y: auto;
  max-height: calc(100vh - 4.5rem);
`;

const IconStyled = styled(Icon)`
  margin-right: 1rem !important;
`;

const PageContainer = ({
  children, pageTitle, icon, loading, loadingMessage, container, wrapper, footer, header,
}) => {
  const renderHeader = () => (
    <HeadingStyled as="h2">
      {icon && <IconStyled name={icon} />}
      {pageTitle}
    </HeadingStyled>
  );

  const renderFooter = () => null;

  const renderTitle = () => (
    <Helmet>
      <title>{`${pageTitle} | ${ENV.APP_NAME}`}</title>
    </Helmet>
  );

  const Element = container || DefaultContainer;
  const Wrapper = wrapper || DefaultWrapper;
  const Header = header || renderHeader;
  const Footer = footer || renderFooter;

  const renderElement = () => {
    if (loading) {
      return (
        <Spinner message={loadingMessage} />
      );
    }
    return <Element>{children}</Element>;
  };

  return (
    <>
      {pageTitle && renderTitle()}
      <Wrapper>
        {(pageTitle || header) && <Header />}
        {renderElement()}
        <Footer />
      </Wrapper>
    </>
  );
};

export default PageContainer;
