import { css } from 'styled-components';
import _kebabCase from 'lodash/kebabcase';
import { forEach } from 'Utils';
// https://github.com/styled-components/styled-components/blob/master/docs/tips-and-tricks.md
export function getMediaQuery(size) {
  return (...args) => css`
    @media (max-width: ${size}px) {
      ${css(...args)}
    }
  `;
}

export const breakpoints = {
  s: 460,
  m: 768,
  l: 1024,
  xl: 1224,
  xxl: 1824,
};

export const media = {
  s: getMediaQuery(breakpoints.s),
  m: getMediaQuery(breakpoints.m),
  l: getMediaQuery(breakpoints.l),
  xl: getMediaQuery(breakpoints.xl),
  xxl: getMediaQuery(breakpoints.xxl),
};

export const styleConstructor = (styles) => {
  if (!styles || typeof styles !== 'object') return '';
  let styleStr = '';
  forEach(styles, (style, key) => {
    if (style !== undefined || style !== null) {
      styleStr = (`${styleStr}\n${_kebabCase(key)}: ${style} !important;`);
    }
  });
  return styleStr;
};

export default {
  getMediaQuery,
  breakpoints,
  media,
};
