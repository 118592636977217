import * as Sentry from '@sentry/react';
import createStore from 'unistore';
import devtools from 'unistore/devtools';
import { initialState } from './initialState';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const Store = process.env.NODE_ENV === 'production'
  ? createStore(initialState, sentryReduxEnhancer)
  : devtools(createStore(initialState, sentryReduxEnhancer));

export default Store;
