import React from 'react';
import { sortableElement, sortableHandle } from 'react-sortable-hoc';

const SortableItem = sortableElement(({ value, renderItem, renderDragHandle }) => {
  const DragHandle = sortableHandle(() => renderDragHandle
    ? renderDragHandle()
    : <span>::</span>);

  return renderItem(value, <DragHandle />);
});

export default SortableItem;
