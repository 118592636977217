import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import styled from 'styled-components';
import Jitsi from 'react-jitsi';
import Common from 'Common';
import { ENV } from 'Utils';
import { COLORS } from '~/styles/Consts';
import { getVideoServerAndJwt, logReporter } from '~/firebase/functions';

const {
  Spinner, Popup, Icon, Error, Debug,
} = Common;

const REMOTE_DISPLAY = 'Participant';

const LoadingVideo = styled.div`
  display: flex;
  flex: 1 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: ${({ videoHeight }) => videoHeight};
  max-height: 100%;
  color: ${COLORS.white};
`;

const ModalControl = styled.div`
  margin: 6px 1rem;
  text-align: right;
`;

const VideoWrapper = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    height: ${({ videoHeight }) => videoHeight};;
    min-height: ${({ videoHeight }) => videoHeight};;
    background-color: ${COLORS.darkGray};
    ${({ isModal }) => {
    if (isModal) {
      return [
        'position: absolute',
        'display: block',
        'z-index: 9999',
        'width: 100%',
        'height: 100vh',
        'top: 0',
        'left: 0',
      ].join('; ');
    }
  }}
`;

const Video = ({
  roomName, roomType, displayName, videoHeight, hideExpandVideo, renderMeetingHeader, onPauseMainTimer,
}) => {
  const [showInModal, setShowInModal] = useState(false);
  const [serverParams, setServerParams] = useState(null);
  const [error, setError] = useState(false);
  const [apiIsLoaded, setApiIsLoaded] = useState(false);
  const [logs, setLogs] = useState(null);

  const apiRef = useRef();
  const { current: api } = apiRef;

  const getServerParamsAttemptsRef = useRef(0);
  const hasLoggedReportRef = useRef(false);
  const capturedLogRef = useRef(null);
  const capturedErrRef = useRef(null);

  const { current: jitsiState } = useRef({
    showFilm: true,
    showTile: false,
  });

  const loadJitsiScript = () => {
    let resolveLoadJitsiScriptPromise = null;
    const loadJitsiScriptPromise = new Promise((resolve) => {
      resolveLoadJitsiScriptPromise = resolve;
    });
    const script = document.createElement('script');
    script.src = `https://${serverParams.server}/external_api.js`;
    script.async = true;
    script.onload = resolveLoadJitsiScriptPromise;
    document.body.appendChild(script);
    return loadJitsiScriptPromise;
  };

  const initialiseJitsi = async () => {
    if (!window.JitsiMeetExternalAPI) {
      await loadJitsiScript();
    }
  };

  const handleLogReport = (err) => {
    if (hasLoggedReportRef.current || serverParams) return;
    hasLoggedReportRef.current = true;
    const { APP_NAME, DOMAIN } = ENV;
    const attempts = getServerParamsAttemptsRef.current;
    const info = { roomName, roomType, displayName };
    const {
      languages, language, vendor, userAgent, product, platform, appVersion, appName, appCodeName,
    } = window.navigator;
    const report = {
      store: null,
      info,
      error: {
        message: `Video did not start after ${attempts}`,
        logs: { log: capturedLogRef.current, error: capturedErrRef.current },
        responseErr: err,
      },
      env: { appName: APP_NAME, domain: DOMAIN },
      browser: {
        languages, language, vendor, userAgent, product, platform, appVersion, appName, appCodeName,
      },
    };
    logReporter(report);
    hasLoggedReportRef.current = true;
  };

  // TODO Move to utils
  const timeoutFn = (ms, promise) => new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      reject(new Error('getVideoServerAndJwt() timeout'));
    }, ms);
    promise
      .then((value) => {
        clearTimeout(timer);
        resolve(value);
      })
      .catch((reason) => {
        clearTimeout(timer);
        reject(reason);
      });
  });

  const getServerParams = async (err) => {
    if (serverParams) return;
    getServerParamsAttemptsRef.current += 1;
    if (getServerParamsAttemptsRef.current === 4) {
      handleLogReport(err);
      setError(true);
      return;
    }
    if (roomName && !serverParams) {
      await timeoutFn(4000, getVideoServerAndJwt({ [roomType === 'group' ? 'groupId' : 'meetingId']: roomName }))
        .then((res) => {
          if (res && res.jwt && res.room && res.server) {
            if (!serverParams) setServerParams(res);
          } else {
            setError(true);
            console.error(res);
            if (!serverParams && getServerParamsAttemptsRef.current <= 3) {
              getServerParams(err);
            }
          }
        })
        .catch((timeoutErr) => {
          console.error(timeoutErr);
          if (!serverParams && getServerParamsAttemptsRef.current <= 3) {
            getServerParams();
          }
        });
    }
  };

  useEffect(() => {
    if (serverParams && serverParams.server) {
      initialiseJitsi();
    }
  }, [serverParams]);

  useEffect(() => {
    if (!capturedLogRef.current) capturedLogRef.current = console.log.bind(console);
    if (!capturedErrRef.current) capturedErrRef.current = console.error.bind(console);
    if (!serverParams) getServerParams();
  }, [roomName]);

  const handleToggleShowInModal = useCallback((showInModalBool = false) => {
    setShowInModal(showInModalBool);
    onPauseMainTimer(showInModalBool);
  }, []);

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      handleToggleShowInModal(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => document.removeEventListener('keydown', escFunction, false);
  }, [roomName]);

  const handleOnApiLoad = (jitsiApi) => {
    apiRef.current = jitsiApi;
    setApiIsLoaded(true);
  };

  useEffect(() => {
    if (!api) return;

    api.addListener('screenSharingStatusChanged', (listener) => {
      // TODO Make UI indicate screen sharing
    });

    api.addListener('filmstripDisplayChanged', (listener) => {
      jitsiState.showFilm = listener.visible;
    });

    api.addListener('log', (listener) => {
      setLogs([...logs, listener]);
    });

    api.addListener('contentSharingParticipantsChanged', (listener) => {
      if (jitsiState.showFilm && listener?.data?.length > 0) {
        api.executeCommand('toggleFilmStrip');
        jitsiState.showFilm = false;
        // TODO Add icon to participant list
      }
      if (!jitsiState.showFilm && listener?.data?.length === 0) {
        api.executeCommand('toggleFilmStrip');
        jitsiState.showFilm = true;
      }
    });

    // chatUpdated
    // participantJoined
    // participantLeft
    // api.executeCommand('toggleTileView');

    return () => {
      api.removeListener('screenSharingStatusChanged');
      api.removeListener('filmstripDisplayChanged');
      api.removeListener('contentSharingParticipantsChanged');
    };
  }, [apiIsLoaded]);

  const renderSpinner = () => (
    <LoadingVideo videoHeight={videoHeight}>
      <Spinner
        message={(
          <>
            <br />
            Loading video …
          </>
        )}
      />
      {logs && <Debug {...logs} />}
    </LoadingVideo>
  );

  const renderError = () => <Error header="Error loading video">:-(</Error>;

  const renderVideo = () => {
    const interfaceConfig = {
      APP_NAME: ENV.APP_NAME,
      DEFAULT_REMOTE_DISPLAY_NAME: REMOTE_DISPLAY,
      DEFAULT_WELCOME_PAGE_LOGO_URL: `https://${ENV.DOMAIN}`,
      JITSI_WATERMARK_LINK: `https://${ENV.DOMAIN}`,
      HIDE_INVITE_MORE_HEADER: true,
      SHOW_BRAND_WATERMARK: false,
      TOOLBAR_BUTTONS: serverParams ? serverParams.toolbar : [],
      DISPLAY_WELCOME_FOOTER: false,
      DISPLAY_WELCOME_PAGE_ADDITIONAL_CARD: false,
      DISPLAY_WELCOME_PAGE_CONTENT: false,
      DISPLAY_WELCOME_PAGE_TOOLBAR_ADDITIONAL_CONTENT: false,
      // INITIAL_TOOLBAR_TIMEOUT: 3000,
      TOOLBAR_ALWAYS_VISIBLE: true,
      // TOOLBAR_TIMEOUT: 3000,
      VERTICAL_FILMSTRIP: false && !showInModal,
      DISABLE_PRESENCE_STATUS: false,
      GENERATE_ROOMNAMES_ON_WELCOME_PAGE: false,
      TILE_VIEW_MAX_COLUMNS: 3,
      DISABLE_RINGING: true,
      FILM_STRIP_MAX_HEIGHT: 120,
      // filmStripOnly: true,
      SHOW_POWERED_BY: false,
      DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
      SHOW_JITSI_WATERMARK: false,
      VIDEO_LAYOUT_FIT: 'both',
    };

    const config = {
    // iAmRecorder: !showInModal,
    // startAudioOnly: true,
      startAudioMuted: 2,
      startWithVideoMuted: true,
      subject: ' ',
      fileRecordingsEnabled: true,
    };

    const containerStyle = {
      width: '100%',
      height: videoHeight,
      minHeight: videoHeight,
      backgroundColor: COLORS.darkGray,
    };

    const frameStyle = {
      //   display: 'none',
      width: showInModal ? '100%' : '100%',
      height: showInModal ? '92vh' : videoHeight,
      backgroundColor: COLORS.darkGray,
      // '-ms-zoom': '0.85',
      // '-moz-transform': 'scale(0.85)',
      // '-moz-transform-origin': '0 0',
      // '-o-transform': 'scale(0.85)',
      // '-o-transform-origin': '0 0',
      // '-webkit-transform': 'scale(0.85)',
      // '-webkit-transform-origin': '0 0',
    };

    return (
      <VideoWrapper isModal={showInModal} videoHeight={videoHeight}>
        {showInModal && (
          renderMeetingHeader(showInModal, handleToggleShowInModal)
        )}
        <Jitsi
          onAPILoad={handleOnApiLoad}
          jwt={serverParams.jwt}
          roomName={serverParams.room}
          displayName={displayName}
          domain={serverParams.server}
          loadingComponent={renderSpinner}
          containerStyle={containerStyle}
          frameStyle={frameStyle}
          config={config}
          interfaceConfig={interfaceConfig}
          noSSL={serverParams.server.includes('localhost')}
        />
      </VideoWrapper>
    );
  };

  const handleTest = () => {
    if (!api) {
      return;
    }
    // api.executeCommand('startRecording', {
    //   mode: 'file',
    // });
    api.getAvailableDevices().then((devices) => {
      console.log(devices);
    });
    api.getCurrentDevices().then((devices) => {
      console.log(devices);
    });
    const usersP = api.getParticipantsInfo();
    console.log(usersP);
    const numberOfParticipants = api.getNumberOfParticipants();
    console.log(numberOfParticipants);
    api.isAudioMuted().then((muted) => {
      console.log(muted, 'MUTE');
    });
    api.isVideoMuted().then((muted) => {
      console.log(muted, 'VIDEO');
    });
    // api.executeCommand('setTileView',
    //   true);
    // Init toggle state; use to enable/disable on events
    api.executeCommand('toggleFilmStrip');
    // api.executeCommand('hangup');
  };

  // const renderTest = () => <button onClick={handleTest}>TEST</button>;

  if (error) return renderError();
  if (!serverParams) return renderSpinner();

  return (
    <>
      {!hideExpandVideo && (
        <Popup
          key="renderVideoModal"
          position="bottom right"
          on="hover"
          size="small"
          header="Expand video"
          content="Video chat will overlay screen. Useful to follow screen shares."
          trigger={(
            <ModalControl onClick={() => handleToggleShowInModal(!showInModal)}>
              <Icon name={showInModal ? 'compress' : 'expand arrows alternate'} />
              Expand Video
            </ModalControl>
          )}
          hideOnScroll
        />
      )}
      {renderVideo()}
      {/* {renderTest()} */}
    </>
  );
};

Video.defaultProps = {
  videoHeight: '60vh',
  hideExpandVideo: false,
  roomType: 'meeting',
};

export default Video;
