import { USER_ROLES, VISIBILITY_ENUMS } from 'Utils/Consts';
import { firestore } from './firebase';

// const {
//   OWNER, VIEWER, DELEGATE, MEMBER,
// } = USER_ROLES;

// const roles = [OWNER, VIEWER, DELEGATE, MEMBER];

const CommentsCollection = firestore.collection('comments');

export const upsertComment = async (comment, commentId, cb) => {
  const commentRef = commentId
    ? CommentsCollection.doc(commentId)
    : CommentsCollection.doc();
  return commentRef
    .set(comment, {
      merge: true,
    })
    .then(() => {
      const data = { ...comment, id: commentId || commentRef.id };
      if (cb) cb({ data });
      return { data };
    })
    .catch((err) => console.error(err));
};

export const getCommentsByTopic = async (topic) => CommentsCollection.where('topic', '==', topic)
  .then((querySnapshot) => {
    if (querySnapshot.empty) return null;
    let data = null;
    querySnapshot.forEach((doc) => {
      data = { id: doc.id, ...doc.data() };
    });
    return data;
  });

export const subscribeCommentsByTopic = (topic, cb) => {
  if (!topic) return;
  const commentsRef = CommentsCollection.where('topic', '==', topic);
  return new Promise((resolve, reject) => {
    const unsubscribe = commentsRef.onSnapshot(
      (docs) => {
        const data = {};
        docs.forEach((doc) => {
          data[doc.id] = { ...doc.data(), id: doc.id };
        });
        if (cb) cb({ data, unsubscribe });
        return resolve({ data, unsubscribe });
      },
      (err) => {
        console.error('Error subscribing comments', err);
        reject();
      },
    );
  });
};
