import moment from 'moment';
import { USER_ROLES } from 'Utils/Consts';
import { forEach, sort } from 'Utils';

import { firestore } from './firebase';

const {
  OWNER, VIEWER, DELEGATE, MEMBER,
} = USER_ROLES;

const roles = [OWNER, VIEWER, DELEGATE, MEMBER];

const MeetingsCollection = firestore.collection('meetings');
const AgendasCollection = firestore.collection('agendas');

const getDateTimeStr = (
  offset,
  isStart = true,
  snapUnit = 'month',
) => {
  const dt = isStart
    ? moment().startOf(snapUnit)
    : moment().endOf(snapUnit);
  return dt.add(offset).toISOString();
};

export const getGroupMeetings = (groupId, uid, params) => {
  // TODO params for filters (eg date range)
  if (!uid) {
    console.error('uid missing');
    return;
  }

  const getMeetings = async () => MeetingsCollection
    // .where(`users.${uid}.roles`, 'not-in', roles) // Exclude meetings already in getMyMeetings query
    .where('groupId', '==', groupId)
    .get()
    .then((querySnapshot) => {
      const meetings = [];
      querySnapshot.forEach((doc) => {
        meetings.push({ ...doc.data(), id: doc.id });
      });
      return meetings;
    })
    .catch((err) => console.error('Error getting group meetings: ', err));

  return Promise.all([getMeetings()]).then((meetings) => {
    const groupMeetings = [];
    forEach(meetings, (set) => {
      forEach(set, (m) => {
        groupMeetings.push(m);
      });
    });
    const sortedMeetings = sort(groupMeetings, ['startDateTime']);
    return sortedMeetings;
  });
};

export const getMyMeetings = (uid, params) => {
  // TODO params for filters (eg date range)
  if (!uid) {
    console.error('uid missing');
    return;
  }
  // const getMeetingsForRole = async (role) =>
  //   MeetingsCollection.where(`roles.${uid}`, '==', role)
  //     .get()
  //     .then((querySnapshot) => {
  //       const meetings = [];
  //       querySnapshot.forEach((doc) => {
  //         meetings.push({ ...doc.data(), id: doc.id });
  //       });
  //       return meetings;
  //     })
  //     .catch((err) => console.error('Error getting my meetings: ', err));

  const getMeetings = async () => MeetingsCollection.where(`users.${uid}.roles`, 'array-contains-any', roles)
    .get()
    .then((querySnapshot) => {
      const meetings = [];
      querySnapshot.forEach((doc) => {
        meetings.push({ ...doc.data(), id: doc.id });
      });
      return meetings;
    })
    .catch((err) => console.error('Error getting my meetings: ', err));

  return Promise.all([getMeetings()]).then((meetings) => {
    const myMeetings = [];
    forEach(meetings, (set) => {
      forEach(set, (m) => {
        myMeetings.push(m);
      });
    });
    const sortedMeetings = sort(myMeetings, ['startDateTime']);
    return sortedMeetings;
  });
};

export const subscribeMeeting = (id, cb) => {
  if (!id) return;
  const MAX_ATTEMPTS = 30;
  let attempts = 0;
  const onSubscribe = () => {
    attempts += 1;
    if (attempts > MAX_ATTEMPTS) {
      console.error(`Unable to subscribe to meeting: ${id}`);
      return;
    }
    const docRef = MeetingsCollection.doc(id);
    const unsubscribe = docRef.onSnapshot(
      (doc) => {
        const data = { ...doc.data(), id: doc.id };
        if (cb) cb({ data, unsubscribe });
        return { data, unsubscribe };
      },
      (err) => {
        console.error('Error getting meeting by id', err);
        setTimeout(() => {
          onSubscribe();
        }, 200);
      },
    );
  };
  return onSubscribe();
};

export const subscribeMeetingAgendas = (meetingId, cb) => {
  if (!meetingId) return;
  const agendasRef = AgendasCollection.where('meetingId', '==', meetingId); // .where('isDeleted', '!=', true)
  return new Promise((resolve, reject) => {
    const unsubscribe = agendasRef.onSnapshot(
      (docs) => {
        const data = {};
        docs.forEach((doc) => {
          data[doc.id] = { ...doc.data(), id: doc.id };
        });
        if (cb) cb({ data, unsubscribe });
        return resolve({ data, unsubscribe });
      },
      (err) => {
        console.log('Error getting agenda by meetingId', err);
        reject();
      },
    );
  });
};

export const upsertMeeting = async (meeting, meetingId, cb) => {
  const meetingRef = meetingId
    ? MeetingsCollection.doc(meetingId)
    : MeetingsCollection.doc();
  return meetingRef
    .set(meeting, {
      merge: true,
    })
    .then(() => {
      const data = { ...meeting, id: meetingId || meetingRef.id };
      if (cb) cb({ data });
      return { data };
    })
    .catch((err) => console.error(err));
};

export const upsertAgenda = async (agenda, agendaId, cb) => {
  const agendaRef = agendaId
    ? AgendasCollection.doc(agendaId)
    : AgendasCollection.doc();
  return agendaRef
    .set(agenda, {
      merge: true,
    })
    .then(() => {
      const data = { ...agenda, id: agendaId || agendaRef.id };
      if (cb) cb({ data });
      return { data };
    })
    .catch((err) => console.error('Unable to upsert agenda:', err, agenda, agendaId));
};

export const batchUpdateAgenda = async (agendaItems) => {
  const batch = firestore.batch();
  forEach(agendaItems, (a) => {
    const { id, ...rest } = a;
    const ref = AgendasCollection.doc(id);
    batch.update(ref, rest);
  });
  return batch.commit().catch((err) => console.error(err));
};

export const subscribeMyMeetings = (uid, cb) => {
  if (!uid) return;
  // const offset = { month: -1 };
  // const start = getDateTimeStr(offset);
  // const end = getDateTimeStr({}, false);

  const meetingsRef = MeetingsCollection
    // .where('startDateTime', '>=', start)
    // .where('startDateTime', '<=', end)
    // .where('groupId', '==', '') // TODO This won't work if a user is on a meeting but not in a group
    .where(`users.${uid}.roles`, 'array-contains-any', roles); // .where('isDeleted', '!=', true)
  return new Promise((resolve, reject) => {
    const unsubscribe = meetingsRef.onSnapshot(
      (docs) => {
        const data = {};
        docs.forEach((doc) => {
          data[doc.id] = { ...doc.data(), id: doc.id };
        });
        if (cb) cb({ data, unsubscribe });
        return resolve({ data, unsubscribe });
      },
      (err) => {
        console.error('Error subscribing my meetings', err);
        reject();
      },
    );
  });
};

export const subscribeGroupMeetings = (groupId, uid, cb) => {
  if (!groupId || !uid) return;
  // const offset = { month: -1 };
  // const start = getDateTimeStr(offset);
  // const end = getDateTimeStr({}, false);
  // console.log({ start, end });

  const meetingsRef = MeetingsCollection
    // .where('startDateTime', '>=', start)
    // .where('startDateTime', '<=', end)
    .where('groupId', '==', groupId); // .where('isDeleted', '!=', true)
  return new Promise((resolve, reject) => {
    const unsubscribe = meetingsRef.onSnapshot(
      (docs) => {
        const data = {};
        docs.forEach((doc) => {
          data[doc.id] = { ...doc.data(), id: doc.id };
        });
        if (cb) cb({ data, unsubscribe });
        return resolve({ data, unsubscribe });
      },
      (err) => {
        console.error('Error subscribing group meetings', err);
        reject();
      },
    );
  });
};
