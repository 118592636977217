import React from 'react';
import styled from 'styled-components';
import { Label as SuiLabel } from 'semantic-ui-react';
import { styleConstructor } from '~/styles/Utils';

// const COLOR_ENUMS = ['red', 'orange', 'yellow', 'olive', 'green', 'teal', 'blue', 'violet', 'purple', 'pink', 'brown', 'grey', 'black'];

const Label = styled(({ styling, ...rest }) => <SuiLabel {...rest} />)`
    ${({ styling }) => styleConstructor(styling)}
`;

Label.Detail = SuiLabel.Detail;
Label.Group = SuiLabel.Group;

export default Label;
