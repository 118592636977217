import React, { Suspense, useEffect, useMemo } from 'react';
import * as Sentry from '@sentry/react';
import { connect } from 'unistore/react';
import _debounce from 'lodash/debounce';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import { Router, View } from 'react-navi';
import Common from 'Common';
import Components from 'Components';
import { ENV, forEach } from 'Utils';
import BaseLayout from '~/templates/BaseLayout';
import Header from '~/templates/Header';
import Footer from '~/templates/Footer';
import { ViewportProvider } from './hooks/useViewport';
import { GlobalContextProvider } from './hooks/useGlobalContext';
import ErrorBoundary from './ErrorBoundary';
import { routes } from './routes';
import { auth } from './firebase';

import Actions from '~/state/Actions';
// import { media } from '~/styles/Utils';

import 'semantic-ui-css/semantic.min.css';

const { Loader, Helmet } = Common;
const { Navigation, Logo } = Components;

// const AppWrapper = styled.div`
//   position: relative;
//   font-weight: 900;
//   ${media.m`
//     background: red;
//   `}
//
//   ${media.l`
//     background: blue;
//   `}
// `;

// TODO Mobile style

const App = (props) => {
  const {
    store,
    onSignUp,
    onSignIn,
    onSignOut,
    onPasswordForgot,
    onGetMe,
    setAuthUser,
    onSetActiveMeeting,
    onGetMyMeetings,
    onSubscribeMeeting,
    onUnsubscribeMeeting,
    onGetNotes,
    onGetMinutes,
    onUpsertMeeting,
    onUpsertUser,
    onSubscribeAccounts,
    onSubscribeGroups,
  } = props;
  const { me, accounts } = store;
  const { isAuthenticated } = me;

  const handleGetMe = _debounce(onGetMe, 200, { leading: true, trailing: true });

  useEffect(() => {
    auth.onAuthStateChanged((authUser) => {
      const uid = _get(authUser, 'uid', null);
      setAuthUser(authUser);
      if (uid) handleGetMe(uid);
    });
  }, [isAuthenticated]);

  useEffect(() => {
    const hasMe = !!_get(me, 'emailAddress');
    if (hasMe) {
      const uid = _get(me, 'uid');
      onSubscribeGroups(uid);
      onSubscribeAccounts(uid);
    }
  }, [me]);

  const actions = useMemo(
    () => isAuthenticated
      ? {
        onSignOut,
        onGetMyMeetings,
        onSetActiveMeeting,
        onSubscribeMeeting,
        onUnsubscribeMeeting,
        onGetNotes,
        onGetMinutes,
        onUpsertMeeting,
        onUpsertUser,
      }
      : {
        onSignIn,
        onSignUp,
        onPasswordForgot,
      },
    [isAuthenticated],
  );

  const { accountId, account } = useMemo(() => {
    let activeAccount = null;
    forEach(accounts, (val, key) => {
      if (val.isActive) {
        activeAccount = key;
      }
    });
    return {
      accountId: activeAccount,
      account: activeAccount ? accounts[activeAccount] : null,
    };
  }, [accounts]);

  const renderFallback = () => <Loader />;

  const renderHeaderLogo = () => <Logo />;

  const renderNavigation = () => (
    <Navigation
      actions={actions}
      isAuthenticated={isAuthenticated}
      me={me}
      account={account}
      accountId={accountId}
    />
  );

  const navigation = useMemo(renderNavigation, [isAuthenticated, me, accountId]);

  if (_isEqual(store, {})) return null;

  return (
    <ErrorBoundary>
      <Router
        routes={routes}
        context={{
          isAuthenticated,
          actions,
        }}
      >
        <ViewportProvider>
          <GlobalContextProvider>
            <Helmet>
              <title>{ENV.APP_NAME}</title>
            </Helmet>
            <BaseLayout
              header={<Header logo={renderHeaderLogo()} navigation={navigation} />}
              footer={<Footer />}
              isAuthenticated={isAuthenticated}
            >
              <ErrorBoundary>
                <Suspense fallback={renderFallback()}>
                  <View />
                </Suspense>
              </ErrorBoundary>
            </BaseLayout>
          </GlobalContextProvider>
        </ViewportProvider>
      </Router>
    </ErrorBoundary>
  );
};

// export default Sentry.withProfiler(connect((store) => ({ store }), Actions)(App));
export default (connect((store) => ({ store }), Actions)(App));
