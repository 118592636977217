import React from 'react';
import _get from 'lodash/get';
import queryString from 'query-string';
import { View } from 'react-navi';
import {
  compose, lazy, map, mount, redirect, route, withView,
} from 'navi';
import { forEach } from 'Utils';
import RouteWrapper from './RouteWrapper';

// Temp link
const BETA_SIGNUP = '/plans/beta-21a-m/checkout';

// TODO Dupe CONSTS in utiles/routes
export const WILD = '*';
export const BASE = '/';
export const ADMIN = '/admin';
export const EXTERNAL_FRAME = '/resource';
export const EXTERNAL_FRAME_MOBILE = '/resource-mobile';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const PASSWORD_FORGET = '/reset-password';
export const DASHBOARD = '/dashboard';
export const PROFILE_ID = '/profile/:userId'; // TODO
export const PROFILE = '/profile';
export const ACCOUNT = '/account/:accountId';
export const PLANS = '/plans';
export const CHECKOUT = '/plans/:planId/checkout';
export const SCHEDULE = '/recurring/:scheduleKey';
export const MEETING = '/m/:meetingId';
export const MEETING_PRESENT = '/m/:meetingId/present';
export const MEETING_MINUTES = '/m/:meetingId/minutes';
export const MEETING_REVIEW = '/m/:meetingId/review';
export const MEETING_NOTES = '/m/:meetingId/notes';
export const ORG_PAGE = '/page/:orgHandle';
export const GROUP_PAGE = '/g/:groupHandle';
export const GROUP_BY_ID = '/group/:groupId';
export const TASK = '/t/:taskNumber';
export const TASK_BY_ID = '/task/:taskId';

export const REDIRECTS = {
  meeting: '/meeting',
  task: '/task',
  beta: '/join-beta',
};

const MARKETING_SITE_URL = 'https://ensemblapp.com/';

const Pages = [
  {
    component: null,
    route: WILD,
    withAuth: true,
    redirect: SIGN_IN,
  },
  {
    component: null,
    route: BASE,
    withAuth: true,
    redirect: DASHBOARD,
  },
  {
    component: lazy(() => import('./iframe')),
    route: EXTERNAL_FRAME,
    withAuth: true,
    redirect: SIGN_IN,
  },
  {
    component: lazy(() => import('./iframe')),
    route: EXTERNAL_FRAME_MOBILE,
    withAuth: false,
  },
  {
    component: lazy(() => import('./admin')),
    route: ADMIN,
    withAuth: true,
    redirect: SIGN_IN,
  },
  {
    component: lazy(() => import('./signin')),
    route: SIGN_IN,
    withAuth: false,
    redirect: DASHBOARD,
  },
  {
    component: lazy(() => import('./signup')),
    route: SIGN_UP,
    withAuth: false,
    redirect: DASHBOARD,
  },
  {
    component: lazy(() => import('./forgotPassword')),
    route: PASSWORD_FORGET,
    withAuth: false,
    redirect: PROFILE,
  },
  {
    component: lazy(() => import('./orgPage')),
    route: ORG_PAGE,
    withAuth: true,
    redirect: SIGN_IN,
  },
  {
    component: lazy(() => import('./group')),
    route: GROUP_PAGE,
    withAuth: true,
    redirect: SIGN_IN,
  },
  {
    component: lazy(() => import('./group')),
    route: GROUP_BY_ID,
    withAuth: true,
    redirect: SIGN_IN,
  },
  {
    component: lazy(() => import('./profile')),
    route: PROFILE,
    withAuth: true,
    redirect: SIGN_IN,
  },
  {
    component: lazy(() => import('./dashboard')),
    route: DASHBOARD,
    withAuth: true,
    redirect: SIGN_IN,
  },
  {
    component: lazy(() => import('./meeting')),
    route: MEETING,
    withAuth: true,
    redirect: SIGN_IN,
  },
  {
    component: lazy(() => import('./minutes')),
    route: MEETING_MINUTES,
    withAuth: true,
    redirect: SIGN_IN,
  },
  {
    component: lazy(() => import('./minutesReview')),
    route: MEETING_REVIEW,
    withAuth: true,
    redirect: SIGN_IN,
  },
  {
    component: lazy(() => import('./notes')),
    route: MEETING_NOTES,
    withAuth: true,
    redirect: SIGN_IN,
  },
  {
    component: lazy(() => import('./meeting')),
    route: MEETING_PRESENT,
    withAuth: true,
    redirect: SIGN_IN,
  },
  {
    component: lazy(() => import('./task')),
    route: TASK_BY_ID,
    withAuth: true,
    redirect: SIGN_IN,
  },
  {
    component: lazy(() => import('./account')),
    route: ACCOUNT,
    withAuth: true,
    redirect: SIGN_IN,
  },
  {
    component: lazy(() => import('./plans')),
    route: PLANS,
    withAuth: true,
    redirect: SIGN_IN,
  },
  {
    component: lazy(() => import('./checkout')),
    route: CHECKOUT,
    withAuth: true,
    redirect: SIGN_UP,
  },
  {
    component: lazy(() => import('./schedule')),
    route: SCHEDULE,
    withAuth: true,
    redirect: SIGN_UP,
  },
  {
    component: null,
    route: REDIRECTS.meeting,
    withAuth: true,
    redirect: DASHBOARD,
  },
  {
    component: null,
    route: REDIRECTS.task,
    withAuth: true,
    redirect: DASHBOARD,
  },
  {
    component: null,
    route: REDIRECTS.beta,
    withAuth: true,
    redirect: BETA_SIGNUP,
  },
];

// export const getQueryParams = (str) => queryString.parse(str);

export const constructQueryString = (queryParams = {}, options = {}) => {
  const qs = queryString.stringify(queryParams, { arrayFormat: 'comma', ...options });
  return qs ? `?${qs}` : '';
};

export const constructLocationQuery = (routeTo, query) => `${routeTo}${constructQueryString(query)}`;

export const constructRouteWithParams = (routeTemplate, vals) => {
  let string = `${routeTemplate}`;
  forEach(vals, (val, key) => {
    string = string.replace(`:${key}`, val);
  });
  return string;
};

export const constructRoute = ({
  route: r, loc, pathParams, queries,
}) => {
  let href = r;
  if (loc) {
    href = constructLocationQuery(r, loc);
  } else if (pathParams) {
    href = constructRouteWithParams(r, pathParams);
  }
  if (queries) {
    const qs = constructQueryString(queries);
    href = `${href}${qs}`;
  }
  return href;
};

const constructPageRoutes = () => {
  const pageRoutes = {};
  Pages.forEach(({
    component, route: page, withAuth, redirect: altRoute,
  }) => {
    pageRoutes[page] = map(async (req, ctx) => {
      const { isAuthenticated } = ctx;
      if (isAuthenticated === null) {
        return route({ view: <></> });
      }
      if (!isAuthenticated && (page === BASE)) {
        return window.location.replace(MARKETING_SITE_URL);
      }
      if (!component) {
        return page === WILD
          ? redirect(altRoute, { exact: false })
          : redirect(altRoute);
      }
      if (withAuth === isAuthenticated || withAuth === null) {
        return component;
      }
      if (withAuth !== isAuthenticated && altRoute) {
        const to = constructLocationQuery(
          altRoute,
          { ...req.query, loc: constructRouteWithParams(page, req.params) },
        );
        const loc = _get(req, 'query.loc', to);
        return redirect(isAuthenticated ? loc || altRoute : loc);
      }
    });
  });
  return pageRoutes;
};

const pages = constructPageRoutes();

export const routes = compose(
  withView(() => (
    <RouteWrapper>
      <View />
    </RouteWrapper>
  )),
  mount(pages),
);
