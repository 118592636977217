import React, { useMemo } from 'react';
import styled from 'styled-components';
import Common from 'Common';
import { makeArray, sort } from 'Utils';
import { COLORS } from '~/styles/Consts';
import { media } from '~/styles/Utils';

const {
  Heading, Button, Icon, Divider,
} = Common;

const Wrapper = styled.div`
  margin-left: 1rem;
`;

const GroupListStyled = styled.div`
  padding-right: 1rem;
  max-height: 20vh;
  overflow-y: auto;
  ${media.s`
    max-height: unset;
    overflow-y: unset;
  `}
`;

const Group = styled.div`
  cursor: pointer;
  border-radius: 6px;
  padding: 1rem;
  background-color: ${COLORS.primaryLight};
  color: ${COLORS.primary};
  margin-bottom: 1rem;
  border: 2px solid ${COLORS.highlight};
  /* border-left: 9px solid ${COLORS.highlight}; */
  :hover {
    border: 2px solid ${COLORS.primary};
    /* border-left: 9px solid ${COLORS.primary}; */
  }
`;

const GroupTitle = styled.div`
  font-size: 1rem;
  font-weight: 600;
`;

const GroupDescription = styled.div`
  font-size: 0.85rem;
`;

const GroupList = ({
  groups, hasGroups, hasGroupsForProvisionedUsers, onGoToGroup, onCreateGroup,
}) => {
  const groupsArr = useMemo(() => makeArray(groups, (data) => sort(data, ['title', 'handle'])), [groups]);
  const renderGroupItem = (group) => {
    const {
      id, title, description, handle,
    } = group;
    return (
      <Group key={id} onClick={() => onGoToGroup(id)}>
        <GroupDescription>
          {`/g/${handle}`}
        </GroupDescription>
        <GroupTitle>
          {title}
        </GroupTitle>
        <GroupDescription>
          {description}
        </GroupDescription>
      </Group>
    );
  };

  const renderGroupListStyled = () => (
    <GroupListStyled>
      {groupsArr.map(renderGroupItem)}
    </GroupListStyled>
  );

  const renderCreateGroup = () => (
    <Button
      onClick={() => onCreateGroup()}
      fluid
    >
      <Icon name="group" />
      Create Group
    </Button>
  );

  const shouldRenderGroups = groupsArr.length > 0 || hasGroups || hasGroupsForProvisionedUsers; // User is in group, or has groups feature toggle

  return shouldRenderGroups ? (
    <Wrapper>
      <Heading as="h3">Groups</Heading>
      {(hasGroups || hasGroupsForProvisionedUsers) && (
        <>
          {renderCreateGroup()}
          <Divider />
        </>
      )}
      {renderGroupListStyled()}
    </Wrapper>
  ) : null;
};

export default GroupList;
