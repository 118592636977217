import React from 'react';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment-timezone';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

const Calendar = ({ events, ...rest }) => (
  <BigCalendar
    localizer={localizer}
    events={events}
    startAccessor="calendarStart"
    endAccessor="calendarEnd"
    style={{ height: '50vh' }}
    {...rest}
  />
);

export default Calendar;
