import React, { useState } from 'react';
import * as Yup from 'yup';
import Common from 'Common';
import { getTimestamp, storageId } from 'Utils';
// TODO
import { fsTimestamp } from '~/firebase/firebase';

const {
  Form, Modal, Heading,
} = Common;

const LinkSchema = Yup.object().shape({
  url: Yup.string()
    .url('Please enter a valid URL')
    .required('Please enter a valid URL'),
  name: Yup.string().required('Please enter a link name / description'),
});

const INITIAL_STATE = {
  name: '',
  url: '',
};

const LinkForm = ({
  onUpsertAgenda,
  onUpsertTask,
  onToggleLinkFormModal,
  showModal,
  linkData,
  meetingId,
  agendaId,
  taskId,
  inModal,
  userId,
}) => {
  const isEditMode = !!linkData;

  const [loading, setLoading] = useState(false);

  const handleToggleModal = () => onToggleLinkFormModal();

  const handleSubmitLink = async (vals) => {
    setLoading(true);
    const { name, url } = vals;
    const linkVals = {
      name,
      url,
      uploadedAt: getTimestamp(fsTimestamp),
      type: 'link',
      userId,
    };
    const isValid = await LinkSchema.isValid(linkVals);
    if (isValid) {
      const linkId = storageId(encodeURIComponent(name));
      if (onUpsertAgenda && agendaId) {
        await onUpsertAgenda(
          {
            attachments: {
              [linkId]: { ...linkVals, uploadedAt: getTimestamp(fsTimestamp) },
            },
            meetingId,
          },
          agendaId,
        );
      } else if (onUpsertTask && taskId) {
        await onUpsertTask(
          {
            attachments: {
              [linkId]: { ...linkVals, uploadedAt: getTimestamp(fsTimestamp) },
            },
          },
          taskId,
        );
      }
      setLoading(false);
      handleToggleModal();
    } else setLoading(false);
  };

  const fields = [
    {
      id: 'name',
      label: 'Name',
      initialValue: INITIAL_STATE.name,
    },
    {
      id: 'url',
      label: 'Link',
      initialValue: INITIAL_STATE.url,
    },
  ];

  const renderForm = () => (
    <Form
      onSubmit={handleSubmitLink}
      validationSchema={LinkSchema}
      fields={fields}
      buttons={[
        {
          text: 'Add Link',
          params: {
            type: 'submit',
            primary: true,
            loading,
          },
        },
      ]}
    />
  );

  return inModal ? (
    <Modal
      open={showModal}
      onClose={handleToggleModal}
      size="tiny"
      // dimmer="blurring"
    >
      <Heading
        icon="linkify"
        content={isEditMode ? 'Save Link' : 'Add Link'}
      />
      <Modal.Content>
        {renderForm()}
      </Modal.Content>
    </Modal>
  ) : renderForm();
};

LinkForm.defaultProps = {
  inModal: true,
};

export default LinkForm;
