import React from 'react';
import styled from 'styled-components';
import Common from 'Common';
import { COLORS } from '~/styles/Consts';

const { Icon } = Common;

const EmptyStateWrapper = styled.div`
  display: flex;
  flex: 1 auto;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem;
  margin: 1rem 1rem;
  opacity: 1;
  color: ${COLORS.primaryGray};
  outline: 1px ${COLORS.white} solid;
`;

const IconStyled = styled(Icon)`
  display: block !important;
  margin-bottom: 4px !important;
`;

const EmptyState = ({ icon, message }) => (
  <EmptyStateWrapper>
    {icon && <IconStyled name={icon} size="large" />}
    {message}
  </EmptyStateWrapper>
);

export default EmptyState;
