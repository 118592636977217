import React, {
  useCallback, useState, useRef, createRef,
} from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
// import { useSpring, animated } from 'react-spring';
import Common from 'Common';
import { storageId } from 'Utils';
import { COLORS } from '~/styles/Consts';
// TODO Replace Firebase Storage S3-compatible service (eg DigitalOcean)
// import { uploadFile } from '~/firebase/storage';
import { getSignedUploadUri } from '~/firebase/functions';

const { Icon, Message, Label } = Common;

const DropArea = styled.div``;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  padding: 2rem;
  background-color: ${COLORS.white};
  ${({ o }) => (o ? `outline: 2px ${COLORS.primary} dotted;` : '')}
`;

const IconStyled = styled(Icon)`
  display: block !important;
  margin-bottom: 0.75rem !important;
`;

const FauxLink = styled.b`
  color: ${COLORS.primary};
  cursor: pointer;
`;

// TODO
const BetaNotice = styled(Label)`
  text-transform: uppercase !important;
`;

const DropZone = ({
  userId,
  uploadPath,
  onDropCb,
  onUploadFile,
  message,
  uploadProgress,
  acceptFileTypes,
  minSize,
  maxSize,
  entityId,
  features,
  uploadHandler,
  maxFiles,
}) => {
  const dropzoneRef = createRef();
  const [progress, setProgress] = useState({});
  const [rejectedFiles, setRejectedFiles] = useState({});
  const errorRef = useRef(null);

  const getFileExt = (fileType) => {
    const [, ext] = fileType.match(/([^\/]*)\/*$/);
    return ext;
  };

  const getFileName = (fileName) => {
    const n = `${fileName}`.split('.');
    n.pop();
    return n.join('-');
  };

  const handleUpgradeNotice = () => {
    // TODO Upgrade notice
  };

  const handleUploadFile = async (file, eId) => {
    setProgress({ ...progress, [eId]: true });
    const {
      name, type, size, lastModified,
    } = file;
    const id = storageId(getFileName(encodeURIComponent(name)));
    const ext = getFileExt(type);
    const path = uploadPath;
    let signedUri = null;
    if (!uploadHandler) {
      signedUri = await getSignedUploadUri({
        fileName: `${id}.${ext}`,
        filePath: path,
      }).then(({ signedUri: uri }) => uri);
      if (!signedUri) {
        console.error('Unable to get signed URI to upload file', file);
        return;
      }
    }

    const uploadFile = async (data) => {
      if (uploadHandler) {
        await uploadHandler({
          fileName: `${id}.${ext}`,
          filePath: path,
          data,
          id,
          ext,
          name,
          size,
          lastModified,
          type,
        });
      } else {
        await fetch(signedUri, {
          method: 'PUT',
          body: data,
          contentDisposition: 'inline',
        }).catch((err) => {
          errorRef.current = true;
          setProgress({ ...progress, [eId]: false });
          console.error('Error uploading file: ', {
            err,
            fileName: `${id}.${ext}`,
            filePath: path,
            id,
            size,
            signedUri,
          });
        });
      }
      if (onUploadFile && !errorRef.current) {
        await onUploadFile({
          id,
          // url: signedUri,
          userId,
          name,
          type,
          size,
          lastModified,
          location: `${path}/${id}.${ext}`,
        }, eId);
      }
      setProgress({ ...progress, [eId]: false });
    };

    const reader = new FileReader();
    reader.onabort = () => console.warn('file reading was aborted');
    reader.onerror = () => console.error('file reading has failed');
    reader.onload = () => {
      const data = reader.result;
      uploadFile(data);
      // uploadFile({
      //   onComplete: onUploadFile,
      //   id: storageId(getFileName(encodeURIComponent(file.name))),
      //   ext: getFileExt(file.type),
      //   path: uploadPath,
      //   file,
      //   data,
      //   userId,
      // });
    };
    reader.readAsArrayBuffer(file);
  };

  const handleFileDrop = useCallback((files, eId) => {
    if (onDropCb) onDropCb(files, eId);
    files.forEach((file) => handleUploadFile(file, eId));
  }, []);

  const handleRejectedFiles = useCallback((files) => {
    setRejectedFiles({ ...rejectedFiles, [entityId]: files });
  }, [entityId]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
  } = useDropzone({
    ref: dropzoneRef,
    onDropRejected: handleRejectedFiles,
    onDrop: features && features.hasStorage
      ? (files) => handleFileDrop(files, entityId)
      : (files) => handleFileDrop(files, entityId),
    // : handleUpgradeNotice,
    accept: acceptFileTypes,
    minSize,
    maxSize,
    maxFiles,
  });

  const renderUploadProgress = () => {
    if (!uploadProgress) return null;
    return uploadProgress;
  };

  const renderIcon = () => (
    <Icon.Group size="large">
      <IconStyled name="cloud upload" size="huge" color={COLORS.primary} />
      {progress[entityId] && <IconStyled corner loading name="sync alternate" color={COLORS.primary} />}
    </Icon.Group>
  );

  const renderActiveZone = () => (
    <>
      {renderIcon()}
      <p>
        Drop
        {' '}
        {maxFiles === 1 ? 'file' : 'files'}
        {' '}
        to upload …
      </p>
      {message || null}
    </>
  );

  const renderInactiveZone = () => (
    <>
      {renderIcon()}
      <p>
        Drag-and-drop
        {' '}
        {maxFiles === 1 ? 'file' : 'files'}
        {' '}
        here to upload, or
        {' '}
        <FauxLink>click here</FauxLink>
        .
      </p>
      {message || null}
    </>
  );

  const renderFeedback = () => {
    if (!rejectedFiles[entityId] || (Array.isArray(rejectedFiles[entityId]) && rejectedFiles[entityId].length === 0)) return null;
    return (
      <Message>
        <Message.Header>
          <Icon name="exclamation triangle" />
          Error uploading files:
        </Message.Header>
        {rejectedFiles[entityId].map(({ errors, file }) => (
          <React.Fragment key={file.name}>
            <p>
              <b>{`${file.name}:`}</b>
            </p>
            <div>
              {errors.map(({ message: m }) => (
                <p key={m}>{`• ${m}`}</p>
              ))}
            </div>
          </React.Fragment>
        ))}
      </Message>
    );
  };

  return (
    <DropArea {...getRootProps()}>
      <input {...getInputProps()} />
      <Wrapper o={isDragActive}>
        {isDragActive ? renderActiveZone() : renderInactiveZone()}
        {renderFeedback()}
        {renderUploadProgress()}
        {(features && !features.hasStorage) && (
          <BetaNotice color="orange" pointing="below" ribbon attached="top">
            Enjoy integrated file storage during pre-launch trial
          </BetaNotice>
        )}
      </Wrapper>
    </DropArea>
  );
};

DropZone.defaultProps = {
  acceptFileTypes: ['image/png', 'image/gif', 'image/jpeg', 'application/pdf', 'video/mp4', 'video/m4v'],
  minSize: 1, // 1B
  maxSize: 52428800, // 50MB
  // maxSize: 10485760, // 10MB
};

export default DropZone;
