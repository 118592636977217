import { firestore } from './firebase';

const ResponsesCollection = firestore.collection('responses');

export const upsertResponse = async (responseFragment, responseId, merge = true) => responseId
  ? ResponsesCollection.doc(responseId).set(responseFragment, { merge })
  : ResponsesCollection.doc().set(responseFragment);

export const subscribeResponsesById = (id, cb) => {
  if (!id) return;
  const MAX_ATTEMPTS = 30;
  let attempts = 0;
  const onSubscribe = () => {
    attempts += 1;
    if (attempts > MAX_ATTEMPTS) {
      console.error(`Unable to subscribe to response: ${id}`);
      return;
    }
    const docRef = ResponsesCollection.doc(id);
    const unsubscribe = docRef.onSnapshot(
      (doc) => {
        const data = { ...doc.data(), id: doc.id };
        if (cb) cb({ data, unsubscribe });
        return { data, unsubscribe };
      },
      (err) => {
        console.error('Error getting response by id', err);
        setTimeout(() => {
          onSubscribe();
        }, 200);
      },
    );
  };
  return onSubscribe();
};

export const subscribeResponsesByEntityId = (entityId, cb) => {
  if (!entityId) return;
  const responsesRef = ResponsesCollection.where('entityId', '==', entityId);
  return new Promise((resolve, reject) => {
    const unsubscribe = responsesRef.onSnapshot(
      (docs) => {
        const data = {};
        docs.forEach((doc) => {
          data[doc.id] = { ...doc.data(), id: doc.id };
        });
        if (cb) cb({ data, unsubscribe });
        return resolve({ data, unsubscribe });
      },
      (err) => {
        console.log('Error getting responses by entityId', err);
        reject();
      },
    );
  });
};
