import React from 'react';
import styled from 'styled-components';
import { RotateSpinner } from 'react-spinners-kit';
import { COLORS } from '~/styles/Consts';

const SpinWrapper = styled.div`
  display: flex;
  flex: 1 auto;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Spinner = ({ message, ...props }) => (
  <SpinWrapper>
    <RotateSpinner {...props} />
    {message}
  </SpinWrapper>
);

Spinner.defaultProps = {
  size: 30,
  color: COLORS.primary,
};

export default Spinner;
