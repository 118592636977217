import _isEqual from 'lodash/isEqual';
import {
  uuid, getUserFullName, makeArray, forEach,
} from 'Utils';
import { USER_ROLES } from 'Utils/Consts';
import { user as fbUser } from '../firebase';
import { subscribeProvisionedUsers } from '../firebase/user';
import { getAccountFeatures } from '../firebase/functions';

const { getUserById, upsertUser } = fbUser;

export const handleGetMe = async (Store, state, uid) => {
  const { me: prevMe } = Store.getState();
  const isAuthenticated = !!uid;
  if (uid) {
    const user = await getUserById(uid);
    const me = {
      ...prevMe,
      ...user,
      isAuthenticated,
      fullName: getUserFullName(user),
    };
    const {
      avatarUri,
      lastName,
      firstName,
      fullName,
      createdBy,
      createdAt,
      visibility,
    } = me;
    const publicMe = {
      id: uid,
      avatarUri,
      lastName,
      firstName,
      fullName,
      createdBy,
      createdAt,
      visibility,
    };
    const { users, features } = Store.getState();
    let accountFeatures = null;
    if (me.accountId && !features[me.accountId]) {
      accountFeatures = await getAccountFeatures({ accountId: me.accountId });
    }
    return accountFeatures
      ? { me, users: { ...users, [uid]: publicMe }, features: { ...features, [me.accountId]: accountFeatures } }
      : { me, users: { ...users, [uid]: publicMe } };
  }
  // Not signediniti
  const me = { isAuthenticated };
  return { me };
};

const isUserFetchingNow = {};
const checkUserFetching = (id) => {
  if (!isUserFetchingNow[id]) {
    isUserFetchingNow[id] = true;
    return false;
  }
  return true;
};

export const handleGetUserById = async (Store, state, id, cb, preferCache = false) => {
  const { me } = state;
  if (!id || me?.uid === id) {
    if (!id) console.error('Missing id in handleGetUserById()');
    return state.users;
  }
  if (me?.uid !== id) {
    const { users: userCache } = Store.getState();
    let user = preferCache ? userCache[id] : null;
    if (!user) {
      const isFetching = checkUserFetching(id);
      if (!isFetching) {
        user = await getUserById(id, true);
        delete isUserFetchingNow[id];
      }
    }
    if (!user) return state.users;
    const u = {
      [id]: {
        ...user,
        id,
        fullName: getUserFullName(user),
      },
    };
    if (cb) cb(u);
    const { users } = Store.getState();
    return { users: { ...users, ...u } };
  }
};

// TODO Make generic or wrapper func
export const handleUpsertUser = async (state, user, cb, merge, isSignUp) => {
  if (!user.uid) {
    console.error('Missing uid in handleUpsertUser(): ', user);
    return;
  }
  if (!user.accountId) user.accountId = '';
  if (!user.icalKey) user.icalKey = uuid(user.uid);
  if (!user.users) user.users = { [user.uid]: { roles: [USER_ROLES.OWNER] } };
  // TODO Helper func to cleanup db objects vs local state
  if (user.isAuthenticated !== undefined) delete user.isAuthenticated;
  if (user.fullName !== undefined) delete user.fullName;
  await upsertUser(user, merge, isSignUp);
  if (cb) cb({ res: user });
  return ({ me: user });
};

export const handleSubscribeProvisionedUsers = async (Store, state, accountId, cb) => {
  const updateStore = ({ data, unsubscribe }) => {
    const {
      users, subscriptions,
    } = Store.getState();
    if (cb) cb({ res: data });
    subscriptions[`${accountId}-users`] = { unsubscribe };
    forEach(data, ({ id, ...user }) => {
      users[id] = { ...user, fullName: getUserFullName(user) };
    });
    Store.setState({
      users: { ...users },
      subscriptions,
    });
  };
  await subscribeProvisionedUsers(accountId, updateStore);
};

const handleAsyncUsersFetch = async (Store, state, entity) => {
  const { users: usersState } = Store.getState();
  const { users, createdBy, updateLog } = entity;
  const userIds = [];
  if (createdBy) userIds.push(createdBy);
  forEach(users, (_, userId) => {
    if (userId && !userIds.includes(userId) && !usersState[userId]) userIds.push(userId);
  });
  forEach(updateLog, ({ userId }) => {
    if (userId && !userIds.includes(userId) && !usersState[userId]) userIds.push(userId);
  });
  if (userIds.length === 0) return;
  let newUsers = {};
  const addUser = (res) => {
    newUsers = { ...newUsers, ...res };
  };
  await Promise.all(userIds.map((userId) => handleGetUserById(Store, state, userId, addUser, true)));
  const { users: currentUserState } = Store.getState();
  let shouldUpdateState = false;
  forEach(newUsers, (u, uid) => {
    if (!shouldUpdateState) {
      if (!_isEqual(u, currentUserState[uid])) {
        shouldUpdateState = true;
      }
    }
  });
  if (shouldUpdateState) Store.setState({ users: { ...currentUserState, ...newUsers } });
  return Promise.resolve();
};

export const handleAsyncUsersBatch = async (Store, state, batchEntities) => {
  const runSerial = (batch) => {
    let result = Promise.resolve();
    batch.forEach((b) => {
      result = result.then(() => b());
    });
    return result;
  };
  const bEntities = Array.isArray(batchEntities) ? batchEntities : makeArray(batchEntities);
  if (bEntities.length === 0) return;
  const q = bEntities.map((entity) => () => handleAsyncUsersFetch(Store, state, entity));
  await runSerial(q);
};
