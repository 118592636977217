import React, { useState } from 'react';
import * as Yup from 'yup';
import Common from 'Common';
import { getTimestamp, forEach } from 'Utils';
import { fsTimestamp } from '~/firebase';

const {
  Form, Modal, Heading,
} = Common;

const RsvpSchema = Yup.object().shape({
  rsvpStatus: Yup.string().required('Attendance status is required'),
});

const RsvpForm = ({
  onUpsertResponse,
  onToggleRsvpFormModal,
  showModal,
  responseData,
  me,
  meetingId,
  inModal,
}) => {
  const isEditMode = !!(responseData?.responses && responseData?.responses[me.uid]);
  const [loading, setLoading] = useState(false);

  const handleToggleModal = () => onToggleRsvpFormModal();

  const handleSubmitRsvp = async (vals) => {
    setLoading(true);
    const { rsvpStatus, ...rest } = vals;
    const rsvpVals = { rsvpStatus };
    const isValid = await RsvpSchema.isValid(rsvpVals);
    if (isValid) {
      forEach(rest, (v, k) => {
        rsvpVals[k] = v || null;
      });
      if (responseData?.responses && !responseData?.responses[me.uid]?.createdAt) {
        rsvpVals.createdAt = getTimestamp(fsTimestamp);
      }
      rsvpVals.updatedAt = getTimestamp(fsTimestamp);
      const rsvpData = { responses: { [me.uid]: rsvpVals } };
      await onUpsertResponse(rsvpData, meetingId);
      setLoading(false);
      handleToggleModal();
    } else {
      setLoading(false);
    }
  };

  const rsvpFields = responseData?.fields.map(({ options, placeholder, ...f }) => {
    f.controlProps = { options, placeholder };
    if (isEditMode && f.id !== 'createdAt' && f.id !== 'updatedAt') {
      f.initialValue = responseData.responses[me.uid][f.id] || '';
    }
    return f;
  }) || [];

  const renderForm = () => {
    const buttons = [
      {
        text: isEditMode ? `Update ${responseData.title}` : responseData.title,
        params: {
          type: 'submit',
          primary: true,
          loading,
          disabled: loading,
        },
      },
    ];

    if (isEditMode) {
      buttons.push({
        text: 'Cancel',
        params: {
          basic: true,
          onClick: handleToggleModal,
          disabled: loading,
        },
      });
    }

    return (
      <Form
        onSubmit={handleSubmitRsvp}
        validationSchema={RsvpSchema}
        fields={rsvpFields}
        buttons={buttons}
      />
    );
  };

  if (!responseData) return null;

  return inModal ? (
    <Modal
      open={showModal}
      size="tiny"
    //   dimmer="blurring"
    >
      <Heading
        icon="calendar check outline"
        content={isEditMode ? `Update ${responseData.title}` : responseData.title}
      />
      <Modal.Content>
        {!!responseData.description && (
          <p>
            {responseData.description}
          </p>
        )}
        {renderForm()}
      </Modal.Content>
    </Modal>
  ) : renderForm();
};

RsvpForm.defaultProps = {
  inModal: true,
};

export default RsvpForm;
