import React, { useState, useMemo } from 'react';
import * as Yup from 'yup';
import _isFinite from 'lodash/isFinite';
import Common from 'Common';
import { calcMinToMs, calcMsToMin, forEach } from 'Utils';
import { AGENDA_STATUSES, AGENDA_STATUS_LABELS } from 'Utils/Consts';
import { COLORS } from '~/styles/Consts';

const {
  Form, Modal, Heading, Confirm,
} = Common;

const AgendaSchema = Yup.object().shape({
  title: Yup.string().required('Please enter an agenda title'),
  description: Yup.string(),
  budgetedDuration: Yup.string()
    .test('duration check', 'Please add a duration',
      (value) => {
        const valNum = Number(value);
        if (!valNum) return false;
        if (valNum < 0) return false;
        return _isFinite(valNum);
      }),
  status: Yup.string(),
  visibility: Yup.string(),
});

const INITIAL_STATE = {
  title: '',
  description: '',
  budgetedDuration: '',
  status: AGENDA_STATUSES.INACTIVE,
};

const getStatusOptions = () => {
  const options = [];
  forEach(AGENDA_STATUSES, (val, key) => {
    options.push({ value: val, text: AGENDA_STATUS_LABELS[key] });
  });
  return options;
};

const AgendaForm = ({
  onUpsertAgenda,
  onDeleteAgendaItem,
  onToggleAgendaFormModal,
  showModal,
  agendaData,
  meetingId,
  meetingData,
  inModal,
}) => {
  const isEditMode = !!agendaData;

  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const handleToggleModal = () => onToggleAgendaFormModal();

  const handleSubmitAgenda = async (vals) => {
    setLoading(true);
    const {
      title,
      description,
      budgetedDuration,
      status,
    } = vals;
    const agendaVals = {
      budgetedDuration: budgetedDuration ? calcMinToMs(budgetedDuration) : null,
      status: isEditMode ? status : AGENDA_STATUSES.INACTIVE,
      visibility: meetingData.visibility,
      users: meetingData.users,
      title,
      description,
      meetingId,
    };
    const isValid = await AgendaSchema.isValid(agendaVals);
    if (isValid) {
      if (isEditMode) {
        // TODO Update log?
      }
      const agendaId = isEditMode ? agendaData.id : undefined;
      await onUpsertAgenda(agendaVals, agendaId);
      handleToggleModal();
    } else {
      setLoading(false);
    }
  };

  const handleDeleteAgendaItem = async (agendaId) => {
    setLoadingDelete(true);
    await onDeleteAgendaItem(agendaId);
    handleToggleModal();
  };

  const agendaFields = useMemo(() => {
    let aFields = [
      {
        id: 'title',
        label: 'Title',
        initialValue: INITIAL_STATE.title,
        disabled: loading || loadingDelete,
        autoFocus: !isEditMode,
      },
      {
        id: 'description',
        label: 'Description',
        initialValue: INITIAL_STATE.description,
        disabled: loading || loadingDelete,
      },
      {
        id: 'budgetedDuration',
        label: 'Time Allotted (minutes)',
        initialValue: INITIAL_STATE.budgetedDuration,
        disabled: loading || loadingDelete,
        type: 'number',
      },
    ];

    if (isEditMode) {
      aFields.push({
        id: 'status',
        label: 'Status',
        type: 'dropdown',
        initialValue: INITIAL_STATE.status,
        disabled: loading || loadingDelete,
        controlProps: {
          placeholder: 'Agenda item status',
          fluid: true,
          search: true,
          selection: true,
          options: getStatusOptions(),
        },
      });
      const fields = aFields.map((field) => {
        const { id } = field;
        const fieldVal = id === 'budgetedDuration'
          ? calcMsToMin(agendaData[id])
          : agendaData[id];
        if (fieldVal) {
          return {
            ...field,
            initialValue: fieldVal,
          };
        }
        return field;
      });
      aFields = fields;
    }

    return aFields;
  }, [agendaData]);

  const renderDeleteAgendaAction = () => {
    if (!isEditMode) return null;
    const { id, title } = agendaData;
    return (
      <Confirm
        key={`${id}-delete-btn`}
        elementProps={{
          type: 'button',
          icon: 'trash alternate',
          content: 'Delete',
          loading: loadingDelete,
          styling: {
            backgroundColor: COLORS.error, color: COLORS.white, float: 'right',
          },
        }}
        header={`Delete '${title}'`}
        content={`Are you sure you want to delete '${title}'?`}
        confirmButton="Delete"
        cb={() => handleDeleteAgendaItem(id)}
      />
    );
  };

  const deleteAgendaAction = useMemo(() => renderDeleteAgendaAction(), []);

  const renderForm = () => {
    const buttons = [
      {
        text: isEditMode ? 'Save Agenda Item' : 'Create Agenda Item',
        params: {
          type: 'submit',
          primary: true,
          loading,
          disabled: loading || loadingDelete,
        },
      },
      {
        text: 'Cancel',
        params: {
          basic: true,
          onClick: handleToggleModal,
          disabled: loading || loadingDelete,
        },
      },
    ];
    if (isEditMode) {
      buttons.push({
        component: deleteAgendaAction,
      });
    }

    return (
      <Form
        onSubmit={handleSubmitAgenda}
        validationSchema={AgendaSchema}
        fields={agendaFields}
        buttons={buttons}
      />
    );
  };

  return inModal ? (
    <Modal
      open={showModal}
      onClose={handleToggleModal}
      size="tiny"
      // dimmer="blurring"
    >
      <Heading
        icon="plus square outline"
        content={isEditMode ? 'Save Agenda Item' : 'Create Agenda Item'}
      />
      <Modal.Content>
        {renderForm()}
      </Modal.Content>
    </Modal>
  ) : renderForm();
};

AgendaForm.defaultProps = {
  inModal: true,
};

export default AgendaForm;
