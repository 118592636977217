// import { USER_ROLES } from 'Utils/Consts';
import { firebase } from './firebase';

// const fsTimestamp = firebase.firestore.Timestamp;

const db = firebase.database();

// TODO Refacto dupe const
const TIMER_COLLECTIONS = {
  meetings: 'meetings',
  meeting: 'meetings',
  agendas: 'agendas',
  agenda: 'agendas',
};

export const subscribeTimerStatus = (id, type, cb) => {
  // Init timer is not immediately consistent; adding retry logic to account for async job
  const MAX_ATTEMPTS = 30;
  let attempts = 0;
  const onSubscribe = () => {
    attempts += 1;
    if (attempts > MAX_ATTEMPTS) {
      console.error(`Unable to subscribe to timer: ${type} / ${id}`);
      return;
    }
    const events = db.ref(`${TIMER_COLLECTIONS[type]}/${id}/events`);
    if (!events) {
      setTimeout(() => {
        onSubscribe();
      }, 200);
      return;
    }
    events.once('value').then((snapshot) => {
      const data = snapshot.val() || {};
      if (cb) cb(data);
      events.on('child_added', (newEvent) => {
        const { key } = newEvent;
        if (data[key]) return;
        const event = newEvent.val();
        if (cb) cb(event, key);
      });
    }).catch(() => {
      setTimeout(() => {
        onSubscribe();
      }, 200);
    });
  };
  onSubscribe();
};

export const unsubscribeTimerStatus = (id, type) => {
  const status = db.ref(`${TIMER_COLLECTIONS[type]}/${id}`);
  if (!status) return;
  status.off();
};

export const setTimerStatus = (id, type, event) => {
  if (!id) {
    console.error('No timer id provided', { id, event });
    return;
  }
  return db.ref(`${TIMER_COLLECTIONS[type]}/${id}/events`).push({
    ...event,
    timestamp: firebase.database.ServerValue.TIMESTAMP,
  });
};

// var commentsRef = firebase.database().ref('post-comments/' + postId);
// commentsRef.on('child_added', function(data) {
//   addCommentElement(postElement, data.key, data.val().text, data.val().author);
// });
//
// commentsRef.on('child_changed', function(data) {
//   setCommentValues(postElement, data.key, data.val().text, data.val().author);
// });
