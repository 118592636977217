import React from 'react';
import styled from 'styled-components';
import { Modal as SuiModal } from 'semantic-ui-react';
import { styleConstructor } from '~/styles/Utils';

const Modal = styled(({ styling, ...rest }) => <SuiModal {...rest} />)`
    ${({ styling }) => styleConstructor(styling)}
`;

Modal.Header = SuiModal.Header;
Modal.Content = SuiModal.Content;
Modal.Actions = SuiModal.Actions;
Modal.Descriptions = SuiModal.Descriptions;
Modal.Dimmer = SuiModal.Dimmer;

export default Modal;
