import React, { useState } from 'react';
import { connect } from 'unistore/react';
import Common from 'Common';
import { getTimestamp } from 'Utils';
import Actions from '~/state/Actions';
import DropZone from '../DropZone';
import Attachments from '../Attachments';
import LinkForm from '../LinkForm';
import { fsTimestamp } from '~/firebase/firebase';

const { Icon, Button } = Common;

// TODO Refactor to be more generic (kludging task attachment support, but should be generic)
const Resources = ({
  onUpsertAgenda,
  onAddAgendaTimerEvent,
  onUpsertTask,
  onGetSignedUri,
  taskId,
  meetingId,
  agendaId,
  userId,
  isAdmin,
  attachments,
  title,
  features,
  accountId,
  store: { events, signedUris },
}) => {
  const [showAddLinkModal, setAddLinkModal] = useState(false);
  // TODO If groupId, store under group, else store under uid
  const storagePath = taskId ? `${accountId || userId}/task/${taskId}` : `${accountId || userId}/m/${meetingId}`;

  const handleToggleAddLinkModal = (linkData = null) => {
    setAddLinkModal(!showAddLinkModal);
  };

  const handleFileUpload = async ({ id: fileId, ...fileInfo }, id) => {
    if (onUpsertAgenda && agendaId) {
      onUpsertAgenda(
        {
          attachments: {
            [fileId]: { ...fileInfo, uploadedAt: getTimestamp(fsTimestamp) },
          },
          meetingId,
        },
        id,
      );
    } else if (onUpsertTask && taskId) {
      onUpsertTask(
        {
          attachments: {
            [fileId]: { ...fileInfo, uploadedAt: getTimestamp(fsTimestamp) },
          },
        },
        id,
      );
    }
  };

  const handleDeleteAttachment = async (attachmentId) => {
    if (onUpsertAgenda && agendaId) {
      onUpsertAgenda(
        {
          attachments: {
            [attachmentId]: {
              isDeleted: true,
            },
          },
          meetingId,
        },
        agendaId,
      );
    } else if (onUpsertTask && taskId) {
      onUpsertTask(
        {
          attachments: {
            [attachmentId]: {
              isDeleted: true,
            },
          },
        },
        taskId,
      );
    }
  };

  const renderAddLinkForm = () => (
    <LinkForm
      onUpsertAgenda={onUpsertAgenda}
      onUpsertTask={onUpsertTask}
      onToggleLinkFormModal={(val) => handleToggleAddLinkModal(val)}
      showModal={showAddLinkModal}
      // linkData={}
      meetingId={meetingId}
      agendaId={agendaId}
      taskId={taskId}
      userId={userId}
    />
  );

  const renderAddLinkButton = () => (
    <>
      <Button
        onClick={() => handleToggleAddLinkModal()}
        size="mini"
        primary
      >
        <Icon name="linkify" />
        Add Link
      </Button>
      {renderAddLinkForm()}
    </>
  );
  const renderAttachments = () => (
    <Attachments
      agendaId={agendaId}
      taskId={taskId}
      attachments={attachments}
      isAdmin={isAdmin}
      onDeleteAttachment={handleDeleteAttachment}
      onAddAgendaEvent={onAddAgendaTimerEvent}
      agendaEvents={events[agendaId]}
      signedUris={signedUris}
      onGetSignedUri={onGetSignedUri}
    />
  );

  const renderMessage = () => (
    <div>
      {'Attach documents for '}
      <b>{title}</b>
    </div>
  );

  const renderDropZone = () => (
    <DropZone
      onUploadFile={handleFileUpload}
      uploadPath={storagePath}
      userId={userId}
      entityId={agendaId}
      message={renderMessage()}
      features={features}
    />
  );

  return !isAdmin ? renderAttachments()
    : (
      <>
        {renderDropZone()}
        {renderAddLinkButton()}
        {renderAttachments()}
      </>
    );
};

export default connect((store) => ({ store }), Actions)(Resources);
