import { USER_ROLES } from 'Utils/Consts';
import { firestore } from './firebase';

const {
  OWNER, VIEWER, DELEGATE, MEMBER,
} = USER_ROLES;

const roles = [OWNER, VIEWER, DELEGATE, MEMBER];

const SchedulesCollection = firestore.collection('schedules');

export const upsertSchedule = async (schedule, scheduleId, cb) => {
  const scheduleRef = scheduleId
    ? SchedulesCollection.doc(scheduleId)
    : SchedulesCollection.doc();
  return scheduleRef
    .set(schedule, {
      merge: true,
    })
    .then(() => {
      const data = { ...schedule, id: scheduleId || scheduleRef.id };
      if (cb) cb({ data });
      return { data };
    })
    .catch((err) => console.error(err));
};

export const getScheduleById = async (scheduleId) => SchedulesCollection.doc(scheduleId).get()
  .then((doc) => {
    if (doc.exists) {
      const data = doc.data();
      return data;
    }
  });

export const subscribeMySchedules = (uid, cb) => {
  if (!uid) return;
  const schedulesRef = SchedulesCollection.where(`users.${uid}.roles`, 'array-contains-any', roles);
  return new Promise((resolve, reject) => {
    const unsubscribe = schedulesRef.onSnapshot(
      (docs) => {
        const data = {};
        docs.forEach((doc) => {
          data[doc.id] = { ...doc.data(), id: doc.id };
        });
        if (cb) cb({ data, unsubscribe });
        return resolve({ data, unsubscribe });
      },
      (err) => {
        console.error('Error subscribing my schedules', err);
        reject();
      },
    );
  });
};

export const subscribeGroupSchedules = (groupId, cb) => {
  if (!groupId) return;
  const schedulesRef = SchedulesCollection.where('groupId', '==', groupId);
  return new Promise((resolve, reject) => {
    const unsubscribe = schedulesRef.onSnapshot(
      (docs) => {
        const data = {};
        docs.forEach((doc) => {
          data[doc.id] = { ...doc.data(), id: doc.id };
        });
        if (cb) cb({ data, unsubscribe });
        return resolve({ data, unsubscribe });
      },
      (err) => {
        console.error('Error subscribing group schedules', err);
        reject();
      },
    );
  });
};
