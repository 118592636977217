import React from 'react';
import styled from 'styled-components';
import { Progress as ProgressLine } from 'semantic-ui-react';

const ProgressStyled = styled(ProgressLine)`
    &&.ui.progress {
        background-color: rgba(125, 125, 125, .5) !important;

    }
    &&.ui.progress .bar {
        border-radius: 0 !important;
        ${({ c }) => c ? `background-color: ${c} !important;` : ''}
    }
`;

const Progress = ({ color, ...props }) => <ProgressStyled {...props} c={color} />;

export default Progress;
