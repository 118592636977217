import { getIsAdmin, forEach } from 'Utils';
import { getProjectsByAccountId } from '../firebase/project';

const enrichProject = (p, me) => {
  const isAdmin = getIsAdmin(p, me);
  p.isAdmin = isAdmin;
  return p;
};

export const handleGetProjectsByAccountId = async (Store, state, accountId) => {
  const { projects, me } = state;
  const data = await getProjectsByAccountId(accountId);
  if (data.length === 0) return;
  let shouldUpdateState = false;
  const savedProjects = { ...projects };
  forEach(data, ({ id, ...project }) => {
    if (!savedProjects[accountId]) {
      savedProjects[accountId] = {};
      shouldUpdateState = true;
    }
    if (!savedProjects[accountId][id]) shouldUpdateState = true;
    const p = enrichProject(project, me);
    savedProjects[accountId][id] = p;
  });
  if (shouldUpdateState) Store.setState({ projects: { ...savedProjects } });
};
