import { getTimestamp } from 'Utils';
import { fsTimestamp, firestore } from './firebase';
import { upsertUser as upsertUserApi } from './functions';

const UsersCollection = firestore.collection('users');
const UsersPublicCollection = firestore.collection('usersPublic');

export const getUserById = async (id, isPublic = false) => {
  if (!id) return null;
  const Collection = isPublic ? UsersPublicCollection : UsersCollection;
  const user = await Collection.doc(id).get().then(async (doc) => {
    if (doc.exists) {
      const data = doc.data();
      return data;
    }
    // 404: doc.data() will be undefined in this case
    return null;
  });
  return user;
};

export const upsertUser = async (user, merge = true, isSignUp = false) => {
  const updateUser = {
    ...user,
    updatedAt: getTimestamp(fsTimestamp),
  };
  if (isSignUp) {
    updateUser.createdBy = user.uid;
    updateUser.createdAt = getTimestamp(fsTimestamp);
  }
  const payload = isSignUp
    ? { docId: user.uid, user: updateUser }
    : { id: user.uid, user: updateUser, merge };
  return upsertUserApi(payload);
  // await UsersCollection.doc(user.uid).set(updateUser, { merge })
  //   .catch((err) => console.error(err));
  // return Promise.resolve();
};

export const subscribeProvisionedUsers = async (accountId, cb) => {
  if (!accountId) return;
  const usersRef = UsersCollection.where('accountId', '==', accountId);
  return new Promise((resolve, reject) => {
    const unsubscribe = usersRef.onSnapshot(
      (docs) => {
        const data = {};
        docs.forEach((doc) => {
          data[doc.id] = { ...doc.data(), id: doc.id };
        });
        if (cb) cb({ data, unsubscribe });
        return resolve({ data, unsubscribe });
      },
      (err) => {
        console.error('Error subscribing provisioned users', err);
        reject();
      },
    );
  });
};
