import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import firebaseConfig from './keys.json';

const isDev = process.env.NODE_ENV !== 'production'; // TODO
const isLocal = process.env.BASE_API_URI.includes('localhost');
const config = firebaseConfig[process.env.FIREBASE_CONFIG];

const ssl = false;
const localDomain = 'localhost';
const emulatorDomain = 'localhost';

if (isLocal) {
  // config.databaseURL = config.databaseURL.replace('localhost', process.env.NETWORK_IP);
  config.databaseURL = config.databaseURL.replace('localhost', localDomain);
  if (ssl) {
    config.databaseURL = config.databaseURL.replace('http:', 'https:');
    config.databaseURL = config.databaseURL.replace(':5001:', ':45001');
  }
}

if (!config) {
  console.error('Unable to determine Firebase config:', {
    env: process.env,
    isDev,
    config,
    firebaseConfig,
    fbEnv: process.env.FIREBASE_CONFIG,
    nodeEnv: process.env.NODE_ENV,
  });
}

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const auth = firebase.auth();
const storage = firebase.storage();
const db = firebase.database();
const firestore = firebase.firestore();
const functions = firebase.functions();
if (isDev) {
  // auth.useEmulator('http://localhost:9099/');
  functions.useEmulator(emulatorDomain, 5001);
  db.useEmulator(emulatorDomain, 9000);
  storage.useEmulator(emulatorDomain, 9199);
  if (ssl) {
    firestore.settings({ host: `${localDomain}:49080`, ssl });
    // db.settings({ host: `${localDomain}:9000`, ssl });
  } else {
    firestore.useEmulator(emulatorDomain, 9080);
  }
}
const fsTimestamp = firebase.firestore.Timestamp;

export {
  auth, db, firestore, functions, firebase, fsTimestamp, storage,
};
