import {
  getIsAdmin, processFormData, forEach,
} from 'Utils';
import { fsTimestamp } from '../firebase';
import {
  upsertComment,
  subscribeCommentsByTopic,
} from '../firebase/comment';
import { handleAsyncUsersBatch } from './user';

const enrichComment = (c, me) => {
  const isAdmin = getIsAdmin(c, me);
  c.isAdmin = isAdmin;
  return c;
};

export const handleUpsertComment = async (
  Store,
  state,
  commentData,
  commentId,
  cb,
) => {
  const {
    me, comments,
  } = state;
  const { uid: userId } = me;
  const updateStore = async ({ data }) => {
    const c = enrichComment(data, me);
    if (cb) cb({ res: c });
    const { id, topic } = data;
    if (!comments[topic]) comments[topic] = {};
    comments[topic][id] = { ...c };
    Store.setState({ comments: { ...comments } });
  };
  const pComment = processFormData(commentData, userId, fsTimestamp, !!commentId);
  await upsertComment(pComment, commentId, updateStore);
};

export const handleSubscribeCommentsByTopic = async (Store, state, topic, cb) => {
  const { me } = state;
  const updateStore = async ({ data, unsubscribe }) => {
    const {
      comments, subscriptions,
    } = Store.getState();
    if (cb) cb({ res: data });
    subscriptions[`${topic}-comments`] = { unsubscribe };
    forEach(data, ({ id, ...comment }) => {
      const c = enrichComment(comment, me);
      if (!comments[topic]) comments[topic] = {};
      comments[topic][id] = c;
    });
    Store.setState({
      comments: { ...comments },
      subscriptions,
    });
    await handleAsyncUsersBatch(Store, state, data);
  };
  await subscribeCommentsByTopic(topic, updateStore);
};
