import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import styled from 'styled-components';
import { connect } from 'unistore/react';
import moment from 'moment-timezone';
import _sum from 'lodash/sum';
import _findIndex from 'lodash/findIndex';
import Common from 'Common';
import {
  calcMsToHumanTime,
  calcMsToMin,
  calcDuration,
  makeArray,
  sort,
  getTimerContext,
  momentizeTime,
  forEach,
} from 'Utils';
import { EVENT_TYPES, MEETING_STATUSES } from 'Utils/Consts';
import useInterval from '~/hooks/useInterval';
import { TIMER_COLORS, COLORS } from '~/styles/Consts';

const {
  Grid, Progress, Label, Heading, Popup,
} = Common;
const { buildStyles } = Progress;

const ProgressStyled = styled(Progress)`
  width: 3rem !important;
`;

const TimerWrapper = styled.div`
  padding: 6px;
`;

const OverUnderTimeLabel = styled(Heading)`
  margin-top: 2px !important;
  text-align: right;
  ${({ c }) => (c ? `color: ${c} !important;` : '')}
`;

const Message = styled.div`
  margin-top: 0.5rem;
  text-align: right;
`;

const TimerColumn = styled(Grid.Column)`
  padding-left: 0 !important;
  padding-right: 0 !important;
`;

const INITIAL_STATE = {
  meetingVariance: null,
  agendaVariance: null,
  active: false,
  currentElapsed: 0,
  currentBudgeted: 0,
  lastStartTime: null,
};

const Tempo = ({
  id: meetingId, store, updateFreq, autoMode, hideBookingVariance, reverseColor, displayAbsolute,
}) => {
  const {
    agendas, meetings, activeAgendaId, timers,
  } = store;
  const meeting = meetings[meetingId];
  const [elapsed, setElapsed] = useState(0);
  const [variance, setVariance] = useState(INITIAL_STATE);
  const {
    active,
    agendaVariance, // tracks agenda timers
    meetingVariance, // diff agenda budgets vs meeting budget
    lastStartTime, // of all timers
    cumulativeElapsed,
    cumulativeBudgeted,
    currentElapsed, // cumulative to current active agenda
    currentBudgeted, // "
    meetingElapsed, // overall meeting elapsed
    meetingElapsedVariance, // ± early | late start
  } = variance;

  const meetingDuration = calcDuration(meeting);
  const { isBefore, msFromStart } = getTimerContext(meeting);

  const hasAllAgendaTimers = useMemo(() => {
    let hasAllAgendaTimersBool = true;
    forEach(agendas[meetingId], (_, id) => {
      if (!timers[id]) hasAllAgendaTimersBool = false;
    });
    return hasAllAgendaTimersBool;
  }, [agendas[meetingId], timers]);

  const meetingAgendas = useMemo(
    () => sort(makeArray(agendas[meetingId]).filter(({ isDeleted }) => !isDeleted), ['sortOrder']),
    [agendas[meetingId]],
  );

  // console.table({
  //   active,
  //   lastStartTime,
  //   elapsed: calcMsToMin(elapsed),
  //   agendaVariance: calcMsToMin(agendaVariance),
  //   meetingVariance: calcMsToMin(meetingVariance),
  //   cumulativeElapsed: calcMsToMin(cumulativeElapsed),
  //   cumulativeBudgeted: calcMsToMin(cumulativeBudgeted),
  //   currentElapsed: calcMsToMin(currentElapsed),
  //   currentBudgeted: calcMsToMin(currentBudgeted),
  //   meetingElapsed: calcMsToMin(meetingElapsed),
  //   meetingElapsedVariance: calcMsToMin(meetingElapsedVariance),
  //   meetingDuration: calcMsToMin(meetingDuration),
  // });

  const timerElapsedRef = useRef(0);

  const handleTimer = () => {
    const now = moment();
    const activeAgenda = activeAgendaId[meetingId];
    const hasActiveTimer = !!timers[activeAgenda];
    const msSince = calcDuration({
      startDateTime:
        activeAgenda && hasActiveTimer
          ? timers[activeAgenda].lastStartTime
          : lastStartTime || now,
      endDateTime: now,
    });
    if (timerElapsedRef.current === msSince) return;
    timerElapsedRef.current = msSince;
    setElapsed(timerElapsedRef.current);
  };

  useInterval(() => {
    handleTimer();
  }, updateFreq);

  const handleSetVariance = useCallback((val) => {
    setVariance(val);
  }, []);

  useEffect(() => {
    if (!meeting || meetingAgendas.length === 0) return;

    const calcTimerVariance = () => {
      const meetingElapsedVal = msFromStart;
      let meetingElapsedVarianceVal = 0;

      let currentAgendaIdx = _findIndex(meetingAgendas, {
        id: activeAgendaId[meetingId],
      });
      if (currentAgendaIdx < 0) currentAgendaIdx = 0;

      const cumulativeElapsedUnits = []; // up to active agenda item
      const cumulativeBudgetedUnits = []; // up to active agenda item
      const totalElapsedUnits = []; // all agenda items
      const totalBudgetedUnits = []; // all agenda items
      let currentElapsedVal = 0;
      let currentBudgetedVal = 0;
      let hasActiveTimer = false;
      let lastStartTimeOfActiveTimer = null;

      if (autoMode && meeting) {
        // Determine if meeting is / will be commenced late
        let st = null; // first startTime event
        let et = null; // last endTime event
        forEach(meetingAgendas, ({ id }) => {
          if (timers[id]) {
            forEach(timers[id].events, ({ type, timestamp }) => {
              if (type === EVENT_TYPES.START_TIMER) {
                const momentSt = momentizeTime(timestamp);
                if (st && moment(momentSt).isBefore(st)) {
                  st = momentSt;
                } else if (!st) st = momentSt;
              }
              if (type === EVENT_TYPES.STOP_TIMER) {
                const momentEt = momentizeTime(timestamp);
                if (et && moment(momentEt).isAfter(et)) {
                  et = momentEt;
                } else if (!et) et = momentEt;
              }
            });
          }
        });
        // Calc early | late start
        if (st && moment(meeting.startDateTime).isBefore(st)) {
          meetingElapsedVarianceVal = calcDuration({
            startDateTime: meeting.startDateTime,
            endDateTime: st,
          });
        } else if (st && moment(st).isBefore(meeting.startDateTime)) {
          meetingElapsedVarianceVal = calcDuration({
            startDateTime: meeting.startDateTime,
            endDateTime: st,
          });
        } else {
          meetingElapsedVarianceVal = calcDuration({
            startDateTime: meeting.startDateTime,
            endDateTime: moment(),
          });
        }

        const isMeetingActive = !isBefore && meeting.status !== MEETING_STATUSES.ADJOURNED;
        if (isMeetingActive) {
          hasActiveTimer = true;
          if (!lastStartTimeOfActiveTimer) {
            lastStartTimeOfActiveTimer = st
              ? momentizeTime(st).format()
              : moment(meeting.startDateTime).format();
          }
        }
      }

      forEach(meetingAgendas, ({ id, budgetedDuration }, idx) => {
        if (!timers[id]) return;
        const { elapsed: e, active: a } = timers[id];
        totalElapsedUnits.push(e);
        totalBudgetedUnits.push(budgetedDuration);
        if (idx < currentAgendaIdx) {
          cumulativeElapsedUnits.push(e);
          cumulativeBudgetedUnits.push(budgetedDuration);
        }
        if (id === activeAgendaId[meetingId]) {
          currentElapsedVal = e;
          currentBudgetedVal = budgetedDuration;
          lastStartTimeOfActiveTimer = timers[id].lastStartTime;
        }
        if (a) {
          hasActiveTimer = true;
        }
      });

      const cumulativeElapsedVal = _sum(cumulativeElapsedUnits);
      const cumulativeBudgetedVal = _sum(cumulativeBudgetedUnits);
      // const totalElapsed = _sum(totalElapsedUnits);
      const totalBudgeted = _sum(totalBudgetedUnits);
      const mVariance = meetingDuration - totalBudgeted;
      const aVariance = cumulativeElapsed - cumulativeBudgeted;

      const varianceVals = {
        cumulativeElapsed: cumulativeElapsedVal,
        cumulativeBudgeted: cumulativeBudgetedVal,
        currentElapsed: currentElapsedVal,
        currentBudgeted: currentBudgetedVal,
        agendaVariance: aVariance,
        meetingVariance: mVariance,
        active: hasActiveTimer,
        lastStartTime: lastStartTimeOfActiveTimer,
        meetingElapsed: meetingElapsedVal,
        meetingElapsedVariance: meetingElapsedVarianceVal,
      };
      return varianceVals;
    };
    handleSetVariance(calcTimerVariance());
  }, [timers, meeting, agendas[meetingId], activeAgendaId[meetingId]]);

  if (!agendaVariance && agendaVariance !== 0) return null;
  if (!hasAllAgendaTimers) return null;

  const calcVariance = () => {
    if (!active) return agendaVariance;
    // const meetingAdjustment = meetingDuration - meetingVariance;
    // const elapsedCombined = currentElapsed + cumulativeElapsed;
    // const budgetedCombined = cumulativeBudgeted + currentBudgeted;
    let v = agendaVariance;

    if (
      activeAgendaId[meetingId]
      && currentBudgeted < elapsed + currentElapsed
    ) {
      v += elapsed + currentElapsed - currentBudgeted;
    }
    // Cacl variance from scheduled meeting start
    if (activeAgendaId[meetingId]) {
      v += meetingElapsedVariance;
    }
    // TODO Edge case if !activeAgenda timer: use last endTime as bound in calc?
    return v;
  };

  const elapsedVariance = calcVariance();
  const elapsedVarianceMins = calcMsToMin(elapsedVariance);

  // Adjusts variance to val / 60 mins
  const calcPercent = () => {
    if (elapsedVarianceMins > 1) {
      return (elapsedVarianceMins / 60) * 100;
    }
    if (elapsedVarianceMins < 1) {
      return (Math.abs(elapsedVarianceMins) / 60) * 100;
    }
    return 0;
  };

  const getColor = () => {
    let color = TIMER_COLORS.ample;
    switch (true) {
      case elapsedVarianceMins >= 59:
        color = TIMER_COLORS.exceededCircle;
        break;
      case elapsedVarianceMins > 1:
        color = TIMER_COLORS.fleeting;
        break;
      case elapsedVarianceMins <= 59:
        color = TIMER_COLORS.ample;
        break;
      case elapsedVarianceMins < 1:
        color = TIMER_COLORS.ample;
        break;
      default:
        break;
    }
    return color;
  };

  const percent = calcPercent();
  const color = getColor();

  const renderTimer = () => (
    <TimerWrapper>
      <ProgressStyled
        type="circle"
        strokeWidth={12}
        value={percent}
        counterClockwise={elapsedVarianceMins < 0}
        styles={buildStyles({
          rotation: 0,
          strokeLinecap: 'round',
          pathTransitionDuration: 0.5,
          trailColor: reverseColor ? COLORS.white : COLORS.darkGray,
          pathColor: color,
        })}
        initialAnimation
      />
    </TimerWrapper>
  );

  const varianceLabel = calcMsToHumanTime(Math.abs(elapsedVariance));
  let message = 'Meeting on-pace';
  let label = '';

  const getLabels = () => {
    const isMeetingOverbudget = meetingVariance !== 0 && calcMsToMin(meetingVariance) < 1;
    const isMeetingUnderbudget = meetingVariance !== 0 && calcMsToMin(meetingVariance) > 1;
    const isAgendaProgressOff = Math.abs(elapsedVarianceMins) > 1;
    if (isAgendaProgressOff) {
      message = `Meeting pacing ${varianceLabel} ${
        elapsedVarianceMins < 0 ? 'ahead' : 'behind'
      }`;
    }
    if (isMeetingOverbudget) {
      label = `Agenda overbooked ${Math.abs(
        calcMsToMin(meetingVariance),
      )} mins`;
    }
    if (isMeetingUnderbudget) {
      label = `Agenda underbooked ${calcMsToMin(meetingVariance)} mins`;
    }
    return label;
  };
  getLabels();

  const renderLabel = () => !!label && (
    <Message>
      <Label
        basic
        content={label}
        icon="warning sign"
        color="yellow"
        size="small"
        horizontal
      />
    </Message>
  );

  const renderMessage = () => message;

  const renderOverUnderTimeLabel = () => Math.round(elapsedVarianceMins) ? (
    <OverUnderTimeLabel c={color}>
      {displayAbsolute
        ? Math.abs(Math.round(elapsedVarianceMins))
        : `${elapsedVarianceMins > 0 ? '+' : ''}${Math.round(
          elapsedVarianceMins,
        )}`}
    </OverUnderTimeLabel>
  ) : null;

  const renderTimerColumns = () => (
    <Grid columns={2}>
      <TimerColumn width={12} verticalAlign="middle" textAlign="right">
        {renderOverUnderTimeLabel()}
      </TimerColumn>
      <TimerColumn width={4} verticalAlign="middle">
        <Popup
          trigger={renderTimer()}
          content={renderMessage()}
          on="hover"
          hideOnScroll
          basic
          size="small"
        />
        {}
      </TimerColumn>
    </Grid>
  );

  return (
    <>
      {renderTimerColumns()}
      {!hideBookingVariance && renderLabel()}
    </>
  );
};

Tempo.defaultProps = {
  autoMode: false,
  updateFreq: 500,
  hideBookingVariance: false,
  reverseColor: false,
  displayAbsolute: true,
};

export default connect((store) => ({ store }))(Tempo);
