import { Header } from 'semantic-ui-react';
import styled from 'styled-components';
import { styleConstructor } from '~/styles/Utils';

const Heading = styled(Header)`
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 900;
    ${({ styling }) => styleConstructor(styling)}
`;

export default Heading;
