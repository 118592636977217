import React, { useState } from 'react';
import * as Yup from 'yup';
import Common from 'Common';
import { sort } from 'Utils';

const {
  Form, Modal, Heading,
} = Common;

const UserSchema = Yup.object().shape({
  firstName: Yup.string().required('Please enter a first name'),
  lastName: Yup.string().required('Please enter a last name'),
  emailAddress: Yup.string()
    .required('Please enter a valid email address')
    .email('Please enter a valid email address'),
  avatarUri: Yup.string(),
  employeeId: Yup.string(),
  phoneNumber: Yup.string(),
  department: Yup.string(),
  title: Yup.string(),
});

const INITIAL_STATE = {
  firstName: '',
  lastName: '',
  emailAddress: '',
  avatarUri: '',
  employeeId: '',
  phoneNumber: '',
  department: '',
  title: '',
};

const getDepartmentOptions = (departments) => sort(departments.map((val) => ({ value: val, text: val })), ['text']);

const ProvisionedUserForm = ({
  onCreateProvisionedUser,
  onUpdateProvisionedUser,
  onToggleProvisionedUserFormModal,
  showModal,
  userData,
  accountId,
  departments,
  inModal,
}) => {
  const isEditMode = !!userData;

  const [loading, setLoading] = useState(false);
  const [deptOptions, setDeptOptions] = useState(departments);

  const handleSetDeptOptions = (val) => {
    if (deptOptions.indexOf(val) === -1) {
      setDeptOptions([...deptOptions, val]);
    }
  };

  const handleToggleModal = () => onToggleProvisionedUserFormModal();

  const handleSubmitAgenda = async (vals) => {
    setLoading(true);
    const {
      firstName,
      lastName,
      emailAddress,
      avatarUri = '',
      employeeId = '',
      phoneNumber = '',
      department = '',
      title = '',
    } = vals;
    const userVals = {
      firstName,
      lastName,
      emailAddress,
      avatarUri,
      employeeId,
      phoneNumber,
      department,
      title,
    };
    const isValid = await UserSchema.isValid(userVals);
    if (isValid) {
      if (isEditMode) {
        // const { id: userId } = userData;
        // await onUpdateProvisionedUser(userVals, userId);
        // TODO
        console.warn('User edit not implemented', userData);
      } else {
        await onCreateProvisionedUser(userVals, accountId);
      }
      setLoading(false);
      handleToggleModal();
    }
  };

  let userFields = [
    {
      id: 'firstName',
      label: 'First Name',
      initialValue: INITIAL_STATE.firstName,
      disabled: loading,
    },
    {
      id: 'lastName',
      label: 'Last Name',
      initialValue: INITIAL_STATE.lastName,
      disabled: loading,
    },
    {
      id: 'emailAddress',
      label: 'Email (this is the user\'s sign-in ID)',
      type: 'email',
      initialValue: INITIAL_STATE.emailAddress,
      disabled: loading,
    },
    {
      id: 'department',
      label: 'Department',
      type: 'dropdown',
      initialValue: INITIAL_STATE.department,
      disabled: loading,
      controlProps: {
        placeholder: 'Enter or select department',
        fluid: true,
        search: true,
        selection: true,
        allowAdditions: true,
        clearable: true,
        options: getDepartmentOptions(deptOptions),
        onAddItem: (e, { value, ...x }) => handleSetDeptOptions(value, x),
      },
    },
    {
      id: 'title',
      label: 'Title',
      initialValue: INITIAL_STATE.title,
      disabled: loading,
    },
    {
      id: 'phoneNumber',
      label: 'Phone Number',
      initialValue: INITIAL_STATE.phoneNumber,
      disabled: loading,
    },
    {
      id: 'employeeId',
      label: 'Employee ID',
      initialValue: INITIAL_STATE.employeeId,
      disabled: loading,
    },
  ];

  if (isEditMode) {
    const fields = userFields.map((field) => {
      const { id } = field;
      const fieldVal = userData[id];
      if (fieldVal) {
        return {
          ...field,
          initialValue: fieldVal,
        };
      }
      return field;
    });
    userFields = fields;
  }

  const renderForm = () => {
    const buttons = [
      {
        text: isEditMode ? 'Save User' : 'Create User',
        params: {
          type: 'submit',
          primary: true,
          loading,
          disabled: loading,
        },
      },
      {
        text: 'Cancel',
        params: {
          basic: true,
          onClick: handleToggleModal,
          disabled: loading,
        },
      },
    ];

    return (
      <Form
        onSubmit={handleSubmitAgenda}
        validationSchema={UserSchema}
        fields={userFields}
        buttons={buttons}
      />
    );
  };

  return inModal ? (
    <Modal
      open={showModal}
      size="tiny"
      // dimmer="blurring"
    >
      <Heading
        icon="user plus"
        content={isEditMode ? 'Save User' : 'Create User'}
      />
      <Modal.Content>
        {renderForm()}
      </Modal.Content>
    </Modal>
  ) : renderForm();
};

ProvisionedUserForm.defaultProps = {
  inModal: true,
  departments: [],
};

export default ProvisionedUserForm;
