import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { forEach } from 'Utils';
import Common from 'Common';

const {
  Form, Modal, Heading,
} = Common;

const TemplateSchema = Yup.object().shape({
  title: Yup.string().required('Please enter a template title'),
  description: Yup.string(),
});

const INITIAL_STATE = {
  title: '',
  description: '',
};

const TemplateForm = ({
  onUpsertTemplate,
  onToggleTemplateFormModal,
  showModal,
  templateData,
  meetingId,
  meetingData,
  agendaData,
  inModal,
}) => {
  const isEditMode = !!templateData;

  const [agendaCount, setAgendaCount] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleToggleModal = () => onToggleTemplateFormModal();

  const handleSubmitSaveTemplate = async (vals) => {
    setLoading(true);
    const {
      title,
      description,
    } = vals;

    const {
      templateId: mTemplateId,
      createdAt,
      createdBy,
      updatedAt,
      updateLog,
      status,
      id,
      isAdmin,
      ...meetingVals
    } = meetingData;

    const templateVals = {
      title,
      description,
      meetingId,
      visibility: meetingData.visibility,
      users: meetingData.users, // TODO
      groupId: meetingData.groupId, // TODO
      meeting: meetingVals,
      agendaItems: {},
    };

    forEach(agendaData, (agenda, agendaId) => {
      const {
        sortOrder, title: aTitle, description: aDescription, budgetedDuration,
      } = agenda;
      templateVals.agendaItems[agendaId] = {
        title: aTitle,
        description: aDescription,
        budgetedDuration,
        sortOrder,
      };
    });

    const isValid = await TemplateSchema.isValid(templateVals);
    if (isValid) {
      if (isEditMode) {
        // TODO Update log?
      }
      const templateId = isEditMode ? templateData.id : undefined;
      await onUpsertTemplate(templateVals, templateId);
      setLoading(false);
      handleToggleModal();
    }
  };

  useEffect(() => {
    let count = 0;
    forEach(agendaData, () => {
      count += 1;
    });
    if (count !== agendaCount) setAgendaCount(count);
  }, [agendaData]);

  let templateFields = [
    {
      id: 'title',
      label: 'Title',
      initialValue: INITIAL_STATE.title,
    },
    {
      id: 'description',
      label: 'Description',
      initialValue: INITIAL_STATE.description,
    },
  ];

  if (isEditMode) {
    const fields = templateFields.map((field) => {
      const { id } = field;
      const fieldVal = templateData[id];
      if (fieldVal) {
        return {
          ...field,
          initialValue: fieldVal,
        };
      }
      return field;
    });
    templateFields = fields;
  }

  const renderForm = () => (
    <Form
      onSubmit={handleSubmitSaveTemplate}
      validationSchema={TemplateSchema}
      fields={templateFields}
      buttons={[
        {
          text: isEditMode ? 'Save Template' : 'Create Template',
          params: {
            type: 'submit',
            primary: true,
            loading,
          },
        },
        {
          text: 'Cancel',
          params: {
            basic: true,
            onClick: handleToggleModal,
            disabled: loading,
          },
        },
      ]}
    />
  );

  return inModal ? (
    <Modal
      open={showModal}
      onClose={handleToggleModal}
      size="small"
      // dimmer="blurring"
    >
      <Heading
        icon="plus square outline"
        content={isEditMode ? 'Save Template' : 'Create Template'}
      />
      <Modal.Content>
        {renderForm()}
      </Modal.Content>
    </Modal>
  ) : renderForm();
};

TemplateForm.defaultProps = {
  inModal: true,
};

export default TemplateForm;
