import React from 'react';

const formatNumber = (amount, currencyCode, locale, decimalPrecision) => new Intl.NumberFormat(locale, {
  style: 'currency',
  currency: currencyCode,
  maximumSignificantDigits: decimalPrecision === 0 ? Math.trunc(Math.abs(amount)).toFixed().length : undefined,
}).format(amount);

const getLocale = () => window.navigator.userLanguage || window.navigator.language;

const Currency = ({
  as, amount, currencyCode, locale, decimalPrecision,
}) => {
  if (!amount) console.warn('Missing amount in Currency');
  const Element = as || null;
  const formattedNumber = formatNumber(amount * 0.01, currencyCode, locale, decimalPrecision);
  return Element ? <Element>{formattedNumber}</Element> : <span>{formattedNumber}</span>;
};

Currency.defaultProps = {
  currencyCode: 'USD',
  locale: getLocale(),
  decimalPrecision: 2,
};

export default Currency;
