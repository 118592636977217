import styled from 'styled-components';
import { Checkbox as SuiCheckbox } from 'semantic-ui-react';
import { COLORS } from '~/styles/Consts';

const Checkbox = styled(SuiCheckbox)`
    &&.ui.checked.toggle.checkbox {
        label::before {
            background-color: ${COLORS.active} !important;
        }
    }
`;

export default Checkbox;
