import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import _groupBy from 'lodash/groupBy';
import Common from 'Common';
import {
  sort, forEach, makeArray, getLocalDateTime, USER_TZ,
} from 'Utils';
import { TASK_STATUSES, TASK_STATUS_LABELS } from 'Utils/Consts';
import useViewport from 'Hooks/useViewport';
import Tasks from '../Tasks';
import TaskForm from '../TaskForm';
import ElementWrapper from '../ElementWrapper';

const {
  NOT_STARTED,
  IN_PROGRESS,
  COMPLETED,
  ON_HOLD,
  BLOCKED,
  CANCELLED,
} = TASK_STATUSES;

const statuses = [
  IN_PROGRESS,
  BLOCKED,
  NOT_STARTED,
  COMPLETED,
  ON_HOLD,
  CANCELLED,
];

const {
  Heading, Tab, Label, Accordion, Icon, Divider, Button,
} = Common;

const Wrapper = styled.div`
  margin-left: 0;
  width: 100%;
`;

const MeetingColumn = styled.a`
  cursor: pointer;
`;

const MeetingColumnDate = styled.span`
  font-weight: 300;
`;

const TabPane = styled(Tab.Pane)`
`;

const TaskRowWrapper = styled.div`
  max-height: 11rem !important;
  overflow-y: auto !important;
`;

const TabStyled = styled(Tab)`
  margin: 1.5rem 1rem !important;
  .column {
    margin: 0 !important;
    padding: 0 !important;
  }
`;

const LabelStyled = styled(Label)`
  float: right !important;
  margin-top: -4px !important;
`;

const TaskList = ({
  tasks,
  meetings,
  groups,
  onUpsertTask,
  onGoToMeeting,
  showAssignees,
  showTicketNumber,
  showPriority,
  isGroupContext,
  groupId: gId,
  accountId,
  isAdmin,
  renderFilterControls,
}) => {
  const [sortedTasks, setSortedTasks] = useState(null);
  const [activePane, setActivePane] = useState(0);
  const [selectedPane, setSelectedPane] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const { isMobile } = useViewport();

  const compareTask = (obj1, obj2) => {
    if (obj2.dueDate && obj1.dueDate > obj2.dueDate) { return 2; }
    if (obj2.dueDate && obj2.dueDate > obj1.dueDate) { return 1; }
    if (obj1.createdAt?.seconds > obj2.createdAt?.seconds) { return -2; }
    if (obj2.createdAt?.seconds > obj1.createdAt?.seconds) { return -1; }
    return 0;
  };

  useEffect(() => {
    let hasTasks = false;
    const flatTasks = {};
    forEach(tasks, (_, mId) => {
      forEach(tasks[mId], (task, taskId) => {
        flatTasks[taskId] = task;
        if (!hasTasks) hasTasks = true;
      });
    });
    const groupedTasks = _groupBy(makeArray(flatTasks), 'status');
    forEach(groupedTasks, (taskArr, status) => {
      groupedTasks[status] = taskArr.sort(compareTask)
        .map((t) => ({ ...t, status }));
    });
    let paneIdx = null;
    statuses.forEach((s, idx) => {
      if (!groupedTasks[s]) groupedTasks[s] = [];
      const count = groupedTasks[s].length;
      if (paneIdx === null && count) {
        paneIdx = idx;
      }
    });
    setSortedTasks(groupedTasks);
    const sPane = (paneIdx || 0);
    if (hasTasks && selectedPane === null) {
      setActivePane(sPane);
      setSelectedPane(sPane);
    }
  }, [tasks]);

  const handleEditTask = (task = null, onToggle) => {
    setSelectedTask(task);
    if (onToggle) onToggle();
  };

  const handleUpsertTask = async (taskVals, taskId) => {
    await onUpsertTask(taskVals, taskId);
    handleEditTask();
  };

  const handleSelectTab = (e, { activeIndex }) => {
    setActivePane(activeIndex);
    setSelectedPane(activeIndex);
  };

  // TODO Dupe in Tasks
  const getStatusColor = (status) => {
    let color = null;
    switch (status) {
      case TASK_STATUSES.NOT_STARTED:
        break;
      case TASK_STATUSES.IN_PROGRESS:
        color = 'purple';
        break;
      case TASK_STATUSES.COMPLETED:
        color = 'teal';
        break;
      case TASK_STATUSES.ON_HOLD:
        color = 'brown';
        break;
      case TASK_STATUSES.BLOCKED:
        color = 'red';
        break;
      case TASK_STATUSES.CANCELLED:
        color = 'black';
        break;
      default:
        break;
    }
    return color;
  };

  const renderMeetingInfo = (task) => {
    const { meetingId, agendaId } = task;
    if (!meetings[meetingId]) return null;
    let meetingName = null;
    let groupName = null;
    const m = meetings[meetingId];
    if (m) {
      const { groupId, title } = m;
      meetingName = title;
      if (groupId) {
        if (groups[groupId]) {
          groupName = groups[groupId].title;
        }
      }
    }
    let display = meetingName;
    if (groupName && !isGroupContext) display = `${display} (${groupName})`;
    const meetingDate = getLocalDateTime(m.startDateTime, m.endDateTime, m.timeZone);
    const { year, month, day } = meetingDate;
    const dateTime = `${year} ${month} ${day}`;
    return display ? (
      <>
        <MeetingColumn onClick={() => onGoToMeeting(meetingId, agendaId)}>
          {display}
        </MeetingColumn>
        <MeetingColumnDate>
          {` ${dateTime}`}
        </MeetingColumnDate>
      </>
    ) : null;
  };

  const renderTasks = (status) => (
    <>
      <Label attached="top" size="tiny" color={getStatusColor(status)}>{TASK_STATUS_LABELS[status]}</Label>
      <TaskRowWrapper>
        <Tasks
          key={`tasks-${status}`}
          tasks={sortedTasks[status]}
          canCreate={false}
          onUpsertTask={onUpsertTask}
          showStatus={false}
          showDetails={false}
          showAssignees={showAssignees}
          showTicketNumber={showTicketNumber}
          showPriority={showPriority}
          customColumn={{ header: 'Meeting', render: renderMeetingInfo }}
          isAdmin
        />
      </TaskRowWrapper>
    </>
  );

  const renderTabs = () => {
    const panes = statuses.map((s) => ({
      menuItem: {
        key: s,
        content: (
          <>
            {TASK_STATUS_LABELS[s]}
            {!!sortedTasks[s].length && (
              <Label color={getStatusColor(s)}>
                {sortedTasks[s].length}
              </Label>
            )}
          </>
        ),
      },
      render: () => <TabPane>{renderTasks(s)}</TabPane>,
    }));
    return (
      <TabStyled
        renderActiveOnly
        panes={panes}
        menu={{
          vertical: true,
          fluid: true,
          size: 'mini',
          tabular: 'right',
        }}
        // menuPosition="left"
        activeIndex={activePane}
        onTabChange={handleSelectTab}
      />
    );
  };

  const renderCreateTask = () => (
    <ElementWrapper
      displayIn={ElementWrapper.MODAL}
      size="small"
      minWidth="42rem"
      triggerParams={{
        content: 'Create Task',
        icon: 'paperclip',
        primary: true,
      }}
      heading={(
        <Heading
          icon="paperclip"
          content="Create Task"
        />
      )}
      onRenderChildren={({ onToggle }) => (
        <TaskForm
          onUpsertTask={async (taskVals) => {
            await onUpsertTask(taskVals);
            onToggle();
          }}
          groupId={gId}
          accountId={accountId}
          timeZone={USER_TZ}
        />
      )}
      onRenderTriggerElement={({ onToggle }) => (
        <Button onClick={onToggle}>
          <Icon name="paperclip" />
          Create Task
        </Button>
      )}
    />
  );

  const renderTaskList = (t, idx, isLast) => (
    <Accordion.Content key={t.id} active={activePane === idx}>
      <p>
        <b>{`${t.title}: `}</b>
        {renderMeetingInfo(t)}
      </p>
      {!isLast && <Divider />}
    </Accordion.Content>
  );

  const renderMobileTasks = () => statuses.map((s, idx) => (
    <Accordion key={s} styled>
      <Accordion.Title
        active={activePane === idx}
        index={idx}
        onClick={() => handleSelectTab(null, { activeIndex: idx })}
      >
        <Icon name={activePane === idx ? 'angle down' : 'angle right'} />
        {TASK_STATUS_LABELS[s]}
        {' '}
        <LabelStyled color={getStatusColor(s)}>
          {sortedTasks[s].length}
        </LabelStyled>
      </Accordion.Title>
      {!!sortedTasks[s].length && (
        <>
          {sortedTasks[s].map((t, i) => renderTaskList(t, idx, i === sortedTasks[s].length - 1))}
        </>
      )}
    </Accordion>
  ));

  return (
    <Wrapper>
      <Heading as="h3">Tasks</Heading>
      {isAdmin && renderCreateTask()}
      {renderFilterControls && renderFilterControls()}
      {sortedTasks && (isMobile ? renderMobileTasks() : renderTabs())}
    </Wrapper>
  );
};

TaskList.defaultProps = {
  showTicketNumber: false,
  showAssignees: false,
  isGroupContext: false,
  showPriority: false,
};

export default TaskList;
