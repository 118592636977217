import React from 'react';
import styled from 'styled-components';
import { Link as NaviLink } from 'react-navi';
import { constructRoute } from 'Routes';
import { COLORS } from '~/styles/Consts';
import { styleConstructor } from '~/styles/Utils';

const LinkStyled = styled(NaviLink)`
    color: ${COLORS.primary};
    &:hover {
        color: ${COLORS.highlight};
    }
    ${({ styling }) => styleConstructor(styling)}
`;

const Link = ({
  route, loc, pathParams, queries, text, children, styling,
}) => {
  const href = constructRoute({
    route, loc, pathParams, queries,
  });
  return (
    <LinkStyled href={href} styling={styling}>
      {children || text}
    </LinkStyled>
  );
};

export default Link;
