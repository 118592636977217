import React from 'react';
import styled from 'styled-components';
import { COLORS } from '~/styles/Consts';

const Copyright = styled.div`
  font-size: 0.75rem;
  text-align: center;
  padding: 0.5rem;
  background-color: ${COLORS.black};
  color: ${COLORS.lightGray2};
  height: 2.5rem;
`;

const Footer = () => {
  const renderCopyright = () => {
    const year = new Date().getFullYear();
    const copyrightNotice = `Copyright © ${year} Ensembl. All rights reserved`;
    return (
      <Copyright>
        {copyrightNotice}
      </Copyright>
    );
  };
  return renderCopyright();
};

export default Footer;
