import _get from 'lodash/get';
import { forEach } from 'Utils';
import {
  upsertTemplate,
  getTemplateById,
  subscribeGroupTemplates,
  subscribeMyTemplates,
} from '../firebase/template';

export const handleUpsertTemplate = async (
  Store,
  state,
  templateData,
  templateId,
  cb
) => {
  const {
    templates,
    meetings,
    me: { uid: userId },
  } = state;
  const { createdBy, meetingId } = templateData;
  const templateFragment = createdBy ? templateData : {
    ...templateData,
    createdBy: userId,
  };

  const accountId = _get(meetings, `${meetingId}.accountId`, '');
  const groupId = _get(meetings, `${meetingId}.groupId`, '');
  templateFragment.agendaId = accountId;
  templateFragment.groupId = groupId;

  await upsertTemplate(templateId, templateFragment, cb);
  // const savedTemplate = {
  //   templates: {
  //     ...templates,
  //   },
  // };
  return templates;
};

export const handleGetTemplateById = async (Store, state, templateId) => {
  const { templates } = state;
  const template = await getTemplateById(templateId);
  const savedTemplates = {
    templates: {
      ...templates,
      [templateId]: {
        ...templates[templateId], [templateId]: template,
      },
    },
  };
  return savedTemplates;
};

export const handleSubscribeTemplates = async (Store, state, groupId, cb) => {
  //   const { me } = state;
  const updateStore = ({ data, unsubscribe }) => {
    // TODO enrich with isAdmin etc
    if (cb) cb({ res: data });
    const { templates, subscriptions } = Store.getState();
    subscriptions[`${groupId}-templates`] = { unsubscribe };
    if (!templates[groupId]) templates[groupId] = {};
    forEach(data, ({ id, ...task }) => {
      templates[groupId][id] = task;
    });
    Store.setState({ templates: { ...templates }, subscriptions });
  };
  await subscribeGroupTemplates(groupId, updateStore);
};

export const handleSubscribeMyTemplates = async (Store, state, uid, cb) => {
  const { me } = state;
  const updateStore = ({ data, unsubscribe }) => {
    // TODO enrich with isAdmin etc
    if (cb) cb({ res: data });
    const { templates, subscriptions } = Store.getState();
    subscriptions[`${me.uid}-templates`] = { unsubscribe };
    if (!templates[me.uid]) templates[me.uid] = {};
    forEach(data, ({ id, ...template }) => {
      templates[me.uid][id] = template;
    });
    Store.setState({ templates: { ...templates }, subscriptions });
  };
  await subscribeMyTemplates(me.uid, updateStore);
};
