import { getIsAdmin, forEach } from 'Utils';
import {
  upsertResponse,
  subscribeResponsesById,
  subscribeResponsesByEntityId,
} from '../firebase/response';
import { getRsvpResponses } from '../firebase/functions';
// import { handleAsyncUsersBatch, handleAsyncUsersFetch } from './user';

const enrichResponse = (g, me) => {
  const isAdmin = getIsAdmin(g, me);
  g.isAdmin = isAdmin;
  return g;
};

export const handleGetRsvpResponses = async (Store, state, meetingId, cb) => {
  const updateStore = async ({ data }) => {
    const { responses } = Store.getState();
    if (cb) cb({ res: data });
    let uResponses = responses;
    uResponses = { ...uResponses, [meetingId]: data };
    Store.setState({ responses: uResponses });
  };
  const rsvpResponses = await getRsvpResponses({ id: meetingId });
  if (rsvpResponses) updateStore({ data: rsvpResponses });
};

export const handleUpsertResponse = async (
  Store,
  state,
  responseData,
  responseId,
  // cb,
) => {
  // const {
  //   me, responses,
  // } = state;
  // const updateStore = async ({ data }) => {
  //   const r = enrichResponse(data, me);
  //   if (cb) cb({ res: r });
  //   Store.setState({ responses: { ...responses, [data.id]: r } });
  // };
  const pResponse = responseData;
  await upsertResponse(pResponse, responseId);
  const rsvps = await handleGetRsvpResponses(Store, state, responseId);
  // handleAsyncUsersFetch(Store, state, rsvps);
};

export const handleSubscribeResponses = async (Store, state, responseId, entityId, cb) => {
  const { me } = state;
  const userId = me.uid;
  const updateStore = async ({ data, unsubscribe }) => {
    const { responses, subscriptions } = Store.getState();
    if (cb) cb({ res: data });
    subscriptions[`${userId}-groups`] = { unsubscribe };
    let uResponses = responses;
    if (Array.isArray(data)) {
      forEach(data, ({ id, ...response }) => {
        const r = enrichResponse(response, me);
        uResponses = { ...uResponses, [id]: r };
      });
    } else {
      const { id, ...r } = data;
      uResponses[id] = r;
    }
    Store.setState({ responses: uResponses });
    // handleAsyncUsersBatch(Store, state, uResponses);
  };
  if (responseId) subscribeResponsesById(responseId, updateStore);
  else if (entityId) subscribeResponsesByEntityId(entityId, updateStore);
  if (!responseId && !entityId) {
    console.error('Missing params in handleSubscribeResponses()', { responseId, entityId });
  }
};
