import { firestore } from './firebase';

// NotesSubCollection
const UsersCollection = firestore.collection('users');

export const upsertNotes = async (userId, meetingId, noteFragment, merge = true) => UsersCollection.doc(userId).collection('notes').doc(meetingId).set(noteFragment, { merge });

export const getNotesById = async (userId, meetingId) => UsersCollection.doc(userId).collection('notes').doc(meetingId).get()
  .then((doc) => {
    if (doc.exists) {
      const data = doc.data();
      return data;
    }
  });
