import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigation } from 'react-navi';
import Common from 'Common';
import { getHumanTime } from 'Utils';
import { TASK_STATUSES, TASK_STATUS_LABELS } from 'Utils/Consts';
import { TASK_BY_ID, constructRoute } from 'Utils/routes';
import { COLORS } from '~/styles/Consts';
import EmptyState from '../EmptyState';
import ElementWrapper from '../ElementWrapper';
import TaskForm from '../TaskForm';

const {
  Heading, Table, Icon, Label, DateTime,
} = Common;

const DATE_FORMAT = 'MMM D, Y';

const TaskTitle = styled.div`
    cursor: pointer;
    display: inline-block;
    margin-right: 0.5rem;
    :hover {
      color: ${COLORS.highlight};
    }
`;

const Tasks = ({
  onUpsertTask,
  groupId,
  meetingId,
  agendaId,
  accountId,
  tasks,
  isAdmin,
  timeZone,
  canCreate,
  showStatus,
  showAssignees,
  showDetails,
  customColumn,
  showTicketNumber,
  showPriority,
}) => {
  const [selectedTask, setSelectedTask] = useState(null);
  const navigation = useNavigation();
  const { navigate } = navigation;

  const handleEditTask = (task = null, onToggle) => {
    setSelectedTask(task);
    if (onToggle) onToggle();
  };

  const handleUpsertTask = async (taskVals, taskId) => {
    await onUpsertTask(taskVals, taskId);
    handleEditTask();
  };

  const handleNavigateToTask = (taskId) => {
    const r = constructRoute(TASK_BY_ID, { taskId });
    navigate(r);
  };

  // red orange yellow olive green teal blue violet purple pink brown grey black
  // TODO Dupe in MyTasks
  const getStatusColor = (status) => {
    let color = null;
    switch (status) {
      case TASK_STATUSES.NOT_STARTED:
        break;
      case TASK_STATUSES.IN_PROGRESS:
        color = 'purple';
        break;
      case TASK_STATUSES.COMPLETED:
        color = 'teal';
        break;
      case TASK_STATUSES.ON_HOLD:
        color = 'brown';
        break;
      case TASK_STATUSES.BLOCKED:
        color = 'red';
        break;
      case TASK_STATUSES.CANCELLED:
        color = 'black';
        break;
      default:
        break;
    }
    return color;
  };

  const renderCreateTask = (triggerElement) => {
    if (!isAdmin) return null;
    return (
      <ElementWrapper
        displayIn={ElementWrapper.MODAL}
        size="small"
        minWidth="42rem"
        triggerParams={{
          content: 'Create Task',
          icon: 'paperclip',
          primary: true,
        }}
        heading={(
          <Heading
            icon="paperclip"
            content={selectedTask ? 'Edit Task' : 'Create Task'}
          />
        )}
        onRenderChildren={({ onToggle }) => (
          <TaskForm
            onUpsertTask={async (taskVals, taskId) => {
              await handleUpsertTask(taskVals, taskId);
              handleEditTask(null, onToggle);
            }}
            groupId={selectedTask ? selectedTask.groupId : groupId}
            meetingId={selectedTask ? selectedTask.meetingId : meetingId}
            agendaId={selectedTask ? selectedTask.agendaId : agendaId}
            accountId={selectedTask ? selectedTask.accountId : accountId}
            taskId={selectedTask ? selectedTask.id : null}
            taskData={selectedTask}
            timeZone={selectedTask ? selectedTask.timeZone : timeZone}
          />
        )}
        onRenderTriggerElement={triggerElement}
        onClose={() => handleEditTask(null)}
      />
    );
  };

  const renderTask = (task) => {
    const {
      id, title, ticketNumber, details, assignees, dueDate, priority, status, timeZone: tz,
    } = task;
    return (
      <Table.Row key={id}>
        <Table.Cell>
          {isAdmin ? renderCreateTask(({ onToggle }) => (
            <>
              <TaskTitle onClick={() => handleEditTask(task, onToggle)}>
                <Heading as="h5">
                  <Icon name="pencil" size="small" />
                  {` ${title} `}
                </Heading>
              </TaskTitle>
              <TaskTitle onClick={() => handleNavigateToTask(id)}>
                <Icon name="arrow right" size="small" />
              </TaskTitle>
            </>
          )) : (
            <Heading as="h5">
              {title}
            </Heading>
          )}
        </Table.Cell>
        {showTicketNumber && (
          <Table.Cell>
            {ticketNumber && (
              <TaskTitle onClick={() => handleNavigateToTask(id)}>
                {ticketNumber.split('@')[0]}
              </TaskTitle>
            )}
          </Table.Cell>
        )}
        {showPriority && (
          <Table.Cell>
            {priority}
          </Table.Cell>
        )}
        {showDetails && <Table.Cell>{details}</Table.Cell>}
        <Table.Cell>
          {dueDate ? (
            <DateTime
              dt={dueDate}
              calendarFormat={{
                sameDay: '[Today]',
                nextDay: '[Tomorrow]',
                nextWeek: 'ddd MMM D',
                lastDay: '[Yesterday]',
                lastWeek: '[Last] ddd MMM D',
                sameElse: DATE_FORMAT,
              }}
            />
          ) : null}
        </Table.Cell>

        {showAssignees && (
          <Table.Cell>
            {assignees?.map((a) => a.label).join(', ')}
          </Table.Cell>
        )}
        {showStatus && (
          <Table.Cell>
            <Label size="small" color={getStatusColor(status)}>{TASK_STATUS_LABELS[status]}</Label>
          </Table.Cell>
        )}
        {customColumn && customColumn.render && <Table.HeaderCell>{customColumn.render(task)}</Table.HeaderCell>}
      </Table.Row>
    );
  };

  const renderTasks = () => (
    tasks.length ? (
      <Table basic="very" celled collapsing compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Task</Table.HeaderCell>
            {showTicketNumber && <Table.HeaderCell>Num</Table.HeaderCell>}
            {showPriority && <Table.HeaderCell>Priority</Table.HeaderCell>}
            {showDetails && <Table.HeaderCell>Details</Table.HeaderCell>}
            <Table.HeaderCell>Due Date</Table.HeaderCell>
            {showAssignees && <Table.HeaderCell>Assignees</Table.HeaderCell>}
            {showStatus && <Table.HeaderCell>Status</Table.HeaderCell>}
            {customColumn && customColumn.header && <Table.HeaderCell>{customColumn.header}</Table.HeaderCell>}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {tasks.map(renderTask)}
        </Table.Body>
      </Table>
    ) : null
  );

  const renderEmptyState = () => !tasks.length
    ? <EmptyState icon="paperclip" message="No tasks here" />
    : null;

  return (
    <>
      {canCreate && renderCreateTask()}
      {renderTasks()}
      {renderEmptyState()}
    </>
  );
};

Tasks.defaultProps = {
  canCreate: true,
  showStatus: true,
  showAssignees: true,
  showDetails: true,
  showTicketNumber: false,
  showPriority: false,
};

export default Tasks;
