import React from 'react';
import { Image as SuiImage } from 'semantic-ui-react';

const Image = (props) => <SuiImage {...props} />;

Image.defaultProps = {
  loading: 'lazy',
};

Image.Group = SuiImage.Group;

export default Image;
