import React, {
  useState, useMemo, useRef, useEffect,
} from 'react';
import { connect } from 'unistore/react';
import styled from 'styled-components';
import _isEqual from 'lodash/isEqual';
import * as Yup from 'yup';
import moment from 'moment-timezone';
import Common from 'Common';
import {
  getTimestamp, getUserFullName, sort, momentizeTime, forEach,
} from 'Utils';
import {
  TASK_STATUSES, TASK_STATUS_LABELS, TASK_PRIORITIES, TASK_PRIORITY_LABELS, VISIBILITY_ENUMS, USER_ROLES,
} from 'Utils/Consts';
import { getFeatureToggleBools } from 'Utils/featureToggles';
// TODO
import { fsTimestamp } from '~/firebase/firebase';
import Actions from '~/state/Actions';

const {
  Form, CalendarInput, Grid, DateTime, Icon, Avatar,
} = Common;

const { OWNER, DELEGATE } = USER_ROLES;

const MetaInfo = styled.div`
  margin-top: 1rem;
  /* display: flex; */
  /* justify-content: space-between; */
  font-size: 0.8rem;
`;

const TaskFormSchema = Yup.object().shape({
  title: Yup.string().required('Please enter a task name'),
  status: Yup.string().required(),
  details: Yup.string(),
  assignees: Yup.array(),
  dueDate: Yup.string(),
  timeZone: Yup.mixed()
    .oneOf(moment.tz.names())
    .required('Please select a valid time zone'),
  visibility: Yup.string(),
  projectId: Yup.string(),
  priority: Yup.number().required(),
});

const TaskFormSchemaEdit = Yup.object().shape({
  title: Yup.string().required('Please enter a task name'),
  status: Yup.string().required(),
  details: Yup.string(),
  assignees: Yup.string(),
  dueDate: Yup.string(),
  timeZone: Yup.mixed()
    .oneOf(moment.tz.names())
    .required('Please select a valid time zone'),
  visibility: Yup.string(),
  projectId: Yup.string(),
  priority: Yup.number().required(),
});

const TaskSchema = Yup.object().shape({
  title: Yup.string().required('Please enter a task name'),
  status: Yup.string().required(),
  details: Yup.string(),
  assignees: Yup.array()
    .of(Yup.object().shape({
      userId: Yup.string(),
      emailAddress: Yup.string().email(),
      label: Yup.string(),
    })),
  dueDate: Yup.string(),
  timeZone: Yup.mixed()
    .oneOf(moment.tz.names())
    .required('Please select a valid time zone'),
  visibility: Yup.string(),
  projectId: Yup.string(),
  priority: Yup.number().required(),
});

const EmailValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Please enter a valid email address')
    .email('Please enter a valid email address'),
});

const INITIAL_STATE = {
  title: '',
  details: '',
  assignees: [],
  status: VISIBILITY_ENUMS.PUBLIC,
  priority: TASK_PRIORITIES.P3,
  groupId: '',
  projectId: '',
};

const defaultOptions = [{ value: '', text: '-None-' }];

const getGroupOptions = (groups) => {
  const options = [...defaultOptions];
  forEach(groups, (val, key) => {
    if (val.isAdmin) {
      options.push({
        value: key,
        text: val.title,
      });
    }
  });
  return options;
};

const getProjectOptions = (projects) => {
  const options = [...defaultOptions];
  forEach(projects, (val, key) => {
    options.push({
      value: key,
      text: val.title,
    });
  });
  return options;
};

const getStatusOptions = () => {
  const options = [];
  forEach(TASK_STATUSES, (val, key) => {
    options.push({ key: val, value: val, text: TASK_STATUS_LABELS[key] });
  });
  return options;
};

const getPriorityOptions = () => {
  const options = [];
  forEach(TASK_PRIORITIES, (val, key) => {
    options.push({ key: val, value: val, text: TASK_PRIORITY_LABELS[key] });
  });
  return options;
};

const TaskForm = ({
  accountId: aId = '',
  onUpsertTask,
  taskData,
  groupId,
  meetingId,
  agendaId,
  taskId,
  timeZone,
  store,
  onGetProjectsByAccountId,
}) => {
  const {
    meetings, participants, groups, users: usersPublic, me, accounts, projects,
  } = store;
  const isEditMode = !!taskData;
  const [groupOptions, setGroupOptions] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [features, setFeatures] = useState({});
  const [accountId, setAccountId] = useState(isEditMode ? taskData.accountId : aId);
  const [selectedGroupId, setSelectedGroupId] = useState(isEditMode ? taskData.groupId : groupId);
  const [selectedProjectId, setSelectedProjectId] = useState(isEditMode ? taskData.projectId : INITIAL_STATE.projectId);

  const { hasProjects } = features;

  const { accountId: myAccountId } = useMemo(() => {
    let activeAccount = null;
    forEach(accounts, (val, key) => {
      if (val.isActive) {
        activeAccount = key;
      }
    });
    if (!accountId && activeAccount) setAccountId(activeAccount);
    return {
      accountId: activeAccount,
      accountHandle: activeAccount ? accounts[activeAccount].handle : null,
    };
  }, [accounts]);

  useEffect(() => {
    setGroupOptions(getGroupOptions(groups));
    if (groupId && groups[groupId] && (groups[groupId].accountId !== accountId)) {
      setAccountId(groups[groupId].accountId);
    }
  }, [groups]);

  useEffect(() => {
    if (!hasProjects) return;
    if (accountId) {
      if (projects[accountId]) setProjectOptions(getProjectOptions(projects[accountId]));
    } else {
      setProjectOptions(getProjectOptions({}));
    }
  }, [projects, hasProjects, accountId]);

  useEffect(() => {
    if (!hasProjects) return;
    let initVal = taskData?.projectId ?? INITIAL_STATE.projectId;
    if (!isEditMode && projectOptions.length === 2) initVal = projectOptions[1].value;
    if (selectedProjectId !== initVal) setSelectedProjectId(initVal);
  }, [hasProjects, projectOptions, taskData]);

  useEffect(() => {
    if (!hasProjects) return;
    if (accountId && !projects[accountId]) {
      onGetProjectsByAccountId(accountId);
    }
  }, [projects, hasProjects, accountId]);

  // TODO Convert to hook?
  useEffect(() => {
    const f = getFeatureToggleBools(
      store.features[accountId]
    );
    setFeatures(f);
  }, [store.features, myAccountId, taskData, projects, accountId]);

  const { current: optionsMap } = useRef({});

  const generateAssigneeOptions = (initOptions = []) => {
    let meOption = null;
    const options = initOptions;
    if (me.fullName && !optionsMap[me.uid]) {
      meOption = { key: me.uid, value: me.uid, text: me.fullName };
      optionsMap[me.uid] = { value: me.fullName, isUser: true };
    }
    if (groups[groupId]) {
      forEach(groups[groupId].users, (_, userId) => {
        if (!optionsMap[userId] && usersPublic[userId]) {
          options.push({ key: userId, value: userId, text: usersPublic[userId].fullName });
          optionsMap[userId] = { value: usersPublic[userId].fullName, isUser: true };
        }
      });
    }
    if (meetings[meetingId]) {
      forEach(meetings[meetingId].users, (_, userId) => {
        if (!optionsMap[userId] && usersPublic[userId]) {
          options.push({ key: userId, value: userId, text: usersPublic[userId].fullName });
          optionsMap[userId] = { value: usersPublic[userId].fullName, isUser: true };
        }
      });
    }
    if (accountId || me?.accountId || myAccountId) {
      forEach(usersPublic, (_, userId) => {
        if (!optionsMap[userId] && usersPublic[userId]?.accountId === accountId) {
          options.push({ key: userId, value: userId, text: usersPublic[userId].fullName });
          optionsMap[userId] = { value: usersPublic[userId].fullName, isUser: true };
        }
      });
    }
    if (myAccountId) {
      forEach(accounts[myAccountId].users, (_, userId) => {
        if (!optionsMap[userId] && usersPublic[userId]) {
          options.push({ key: userId, value: userId, text: usersPublic[userId].fullName });
          optionsMap[userId] = { value: usersPublic[userId].fullName, isUser: true };
        }
      });
    }
    if (participants[meetingId]) {
      forEach(participants[meetingId], (_, userId) => {
        const p = participants[meetingId][userId];
        const fullName = getUserFullName(p);
        if (!optionsMap[userId]) options.push({ key: userId, value: userId, text: fullName });
        optionsMap[userId] = { value: fullName, isUser: true };
      });
    }
    if (isEditMode && taskData.assignees.length) {
      taskData.assignees.forEach(({ userId, emailAddress, label }) => {
        if (userId && !optionsMap[userId]) {
          options.push({ key: userId, value: userId, text: label });
          optionsMap[userId] = { value: label, isUser: true };
        } else if (emailAddress && !optionsMap[emailAddress]) {
          options.push({ key: emailAddress, value: emailAddress, text: emailAddress });
          optionsMap[emailAddress] = { value: emailAddress, isEmail: true };
        } else if (!userId && !emailAddress && !optionsMap[label] && label) {
          options.push({ key: label, value: label, text: label });
          optionsMap[label] = { value: label };
        }
      });
    }
    const sortedOptions = sort(options, [(o) => o.text.toLowerCase()]);
    // Add self to top
    if (meOption) sortedOptions.unshift(meOption);
    return sortedOptions;
  };

  const initAssigneeOptions = useMemo(generateAssigneeOptions,
    [taskData, me, usersPublic, groups, meetings, participants, accounts]);

  // TODO Memo / Cache
  const selectedDateInit = isEditMode && taskData.dueDate ? moment(
    taskData.dueDate
  ).tz(timeZone).toDate() : false;

  const [assigneeOptions, setAssigneeOptions] = useState(initAssigneeOptions);
  const [selectedDate, setSelectedDate] = useState(selectedDateInit);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // optionsMap = {};
    const aOptions = generateAssigneeOptions([...assigneeOptions]);
    const shouldUpdateState = !_isEqual(aOptions, assigneeOptions);
    if (shouldUpdateState) setAssigneeOptions(aOptions);
  }, [taskData, me, usersPublic, groups, meetings, participants, accounts]);

  const handleGroupSelection = (gId) => {
    setSelectedGroupId(gId);
    if (hasProjects && (!gId || (groups[gId] && groups[gId].accountId !== accountId))) {
      window.setFormValue('projectId', '');
    }
    if (gId) {
      if (groups[gId] && groups[gId].accountId !== accountId) {
        setAccountId(groups[gId].accountId);
      }
    } else {
      setAccountId(aId || myAccountId || '');
    }
  };

  const handleProjectSelection = (pId) => {
    setSelectedProjectId(pId);
  };

  const handleAssigneeOptions = (value) => {
    let option = null;
    if (!optionsMap[value]) {
      option = { key: value, value, text: value };
      optionsMap[value] = { value };
    }
    if (option) setAssigneeOptions([...assigneeOptions, option]);
  };

  const handleCalendarSelect = (date) => {
    const shouldUnselect = moment(date).tz(timeZone).format()
      === moment(selectedDate).tz(timeZone).format();
    const newDate = shouldUnselect ? false : moment(date).tz(timeZone).toDate();
    setSelectedDate(newDate);
  };

  const handleSubmitTask = async (vals) => {
    setLoading(true);
    const {
      title,
      details = '',
      assignees = '',
      status,
      priority,
      projectId = '',
      groupId: gId,
    } = vals;
    const users = isEditMode ? {} : { [me.uid]: { roles: [OWNER] } };
    if (isEditMode) {
      forEach(taskData.users, (val, key) => {
        if (val.roles.includes(OWNER)) users[key] = { roles: [OWNER] };
        else users[key] = { roles: [] };
      });
    }
    const processAssignee = (a) => {
      let val = { userId: '', emailAddress: '', label: '' };
      const isEmail = EmailValidationSchema.isValidSync({ email: a });
      if (optionsMap[a]) {
        if (optionsMap[a].isUser) {
          val = { userId: a, emailAddress: '', label: optionsMap[a].value };
          // if (users[a] && !users[a].roles.includes(DELEGATE)) {
          //   users[a].roles.push(DELEGATE);
          // } else {
          //   users[a] = { roles: [DELEGATE] };
          // }
        } else if (optionsMap[a].isEmail) {
          val = { userId: '', emailAddress: a, label: optionsMap[a].value };
        } else {
          val = { userId: '', emailAddress: isEmail ? a : '', label: a };
        }
      } else val = { userId: '', emailAddress: '', label: a || '' };
      return val;
    };
    const processedAssignees = isEditMode ? [processAssignee(assignees)] : assignees.map(processAssignee);
    const taskVals = {
      title,
      details,
      timeZone,
      users,
      groupId: '',
      priority: priority ? Number(priority) : TASK_PRIORITIES.P3,
      agendaId: agendaId || '',
      projectId: selectedProjectId || projectId || '',
      assignees: processedAssignees,
      dueDate: selectedDate ? selectedDate.toISOString() : '',
      status: status || TASK_STATUSES.NOT_STARTED,
      updatedAt: getTimestamp(fsTimestamp),
    };
    if (!isEditMode) taskVals.createdAt = getTimestamp(fsTimestamp);
    if (selectedGroupId || gId || groupId) taskVals.groupId = selectedGroupId || gId || groupId;
    if (meetingId) taskVals.meetingId = meetingId;
    taskVals.accountId = isEditMode ? taskData?.accountId : (accountId || aId || '');
    const isValid = await TaskSchema.isValid(taskVals);
    if (isValid) {
      if (isEditMode) {
        await onUpsertTask(taskVals, taskId);
      } else if (processedAssignees.length > 0) {
        await Promise.all(processedAssignees.map((a) => {
          const t = { ...taskVals, assignees: [a] };
          return onUpsertTask(t, null);
        }));
      } else {
        await onUpsertTask(taskVals, null);
      }
      setLoading(false);
    } else setLoading(false);
  };

  const getAssigneeVal = () => {
    if (isEditMode && taskData.assignees.length) {
      const vals = taskData.assignees.map((a) => {
        if (a.userId) return a.userId;
        if (a.emailAddress) return a.emailAddress;
        return a.label;
      });
      return vals[0];
    }
    return '';
  };

  const fields = useMemo(() => {
    const tFields = [
      {
        id: 'title',
        label: 'Task',
        initialValue: isEditMode ? taskData.title : INITIAL_STATE.title,
        autoFocus: !isEditMode,
      },
      {
        id: 'assignees',
        label: 'Assignee (user, email, or custom text)',
        type: 'dropdown',
        initialValue: isEditMode ? getAssigneeVal() : INITIAL_STATE.assignees,
        controlProps: {
          placeholder: 'Assignee',
          fluid: true,
          search: true,
          selection: true,
          allowAdditions: true,
          clearable: true,
          multiple: !isEditMode,
          additionPosition: 'top',
          options: assigneeOptions,
          // TODO
          onAddItem: (e, { value, ...x }) => handleAssigneeOptions(value, x),
          // onChange: (e, { value, ...x }) => handleAssigneeCount(value, x),
        },
      },
      {
        id: 'taskCount',
        component: () => !isEditMode ? <p>If multiple assignees are added, a task will be created for each</p> : null,
      },
    ];
    if (hasProjects) {
      let initVal = taskData?.projectId ?? INITIAL_STATE.projectId;
      if (!isEditMode && projectOptions.length === 2) initVal = projectOptions[1].value;
      if (isEditMode && taskData.projectId && projects[accountId] && projects[accountId][taskData.projectId]) {
        tFields.unshift({
          id: 'projectId',
          label: 'Project',
          component: () => (
            <div>
              <Icon name="folder" />
              {' '}
              {projects[accountId][taskData.projectId].title}
            </div>
          ),
        });
      } else if (!isEditMode) {
        tFields.unshift({
          id: 'projectId',
          label: 'Project',
          type: 'dropdown',
          initialValue: initVal,
          controlProps: {
            value: selectedProjectId,
            onChange: (e, { value }) => handleProjectSelection(value),
            loading: !projects[accountId],
            placeholder: 'Select project',
            fluid: true,
            search: true,
            selection: true,
            options: projectOptions,
          },
        });
      } else if (isEditMode && !taskData.projectId) {
        tFields.unshift({
          id: 'projectId',
          label: 'Project',
          type: 'dropdown',
          initialValue: initVal,
          controlProps: {
            value: selectedProjectId,
            onChange: (e, { value }) => handleProjectSelection(value),
            loading: !projects[accountId],
            placeholder: 'Select project',
            fluid: true,
            search: true,
            selection: true,
            options: projectOptions,
          },
        });
      }
    }

    [{
      id: 'priority',
      label: 'Priority',
      type: 'dropdown',
      initialValue: isEditMode ? taskData.priority : INITIAL_STATE.priority,
      controlProps: {
        placeholder: 'Task priority',
        fluid: true,
        search: true,
        selection: true,
        options: getPriorityOptions(),
      },
    },
    {
      id: 'details',
      label: 'Details',
      type: 'textarea',
      initialValue: isEditMode ? taskData.details : INITIAL_STATE.details,
    }].forEach((f) => tFields.push(f));

    if (isEditMode) {
      tFields.push({
        id: 'status',
        label: 'Status',
        type: 'dropdown',
        initialValue: isEditMode ? taskData.status : INITIAL_STATE.status,
        controlProps: {
          placeholder: 'Task status',
          fluid: true,
          search: true,
          selection: true,
          options: getStatusOptions(),
        },
      });
    }

    if (!isEditMode && !meetingId && !groupId && groupOptions.length > defaultOptions.length) {
      tFields.unshift({
        id: 'groupId',
        label: 'Group',
        type: 'dropdown',
        initialValue: INITIAL_STATE.groupId || groupId,
        controlProps: {
          value: selectedGroupId,
          onChange: (e, { value }) => handleGroupSelection(value),
          placeholder: 'Select group',
          fluid: true,
          selection: true,
          clearable: true,
          options: groupOptions,
        },
      });
    } else if (!meetingId && groupId) {
      tFields.unshift({
        id: 'groupId',
        label: 'Group',
        component: () => (
          <div>
            <Icon name="group" />
            {' '}
            {groups[groupId].title}
          </div>
        ),
      });
    }
    return tFields;
  }, [
    taskData,
    meetingId,
    groupId,
    groupOptions,
    defaultOptions,
    projectOptions,
    hasProjects,
    projects,
    accountId,
    selectedGroupId,
    selectedProjectId,
  ]);

  const renderColumns = ({ left, right }) => (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>{left}</Grid.Column>
        <Grid.Column width={8}>{right}</Grid.Column>
      </Grid.Row>
    </Grid>
  );

  const renderCalendar = () => (
    <>
      <Form.Label>Due Date</Form.Label>
      <CalendarInput
        width="100%"
        height={240}
        selected={selectedDate}
        locale={{
          headerFormat: 'MMM Do',
        }}
        onSelect={handleCalendarSelect}
        autoFocus={false}
      />
    </>
  );

  const renderForm = () => (
    <Form
      onSubmit={handleSubmitTask}
      validationSchema={isEditMode ? TaskFormSchemaEdit : TaskFormSchema}
      fields={fields}
      buttons={[
        {
          text: isEditMode ? 'Save Task' : 'Add Task',
          params: {
            icon: 'paperclip',
            type: 'submit',
            primary: true,
            loading,
          },
        },
      ]}
    />
  );

  const renderMain = () => renderColumns({
    left: renderCalendar(),
    right: renderForm(),
  });

  const renderMetaInfo = () => {
    if (!isEditMode) return null;
    const {
      updateLog, updatedAt, createdAt, createdBy,
    } = taskData;
    const { avatarUri, fullName } = usersPublic[createdBy] || {};
    let lastestUpdate = null;
    let updatedBy = null;
    forEach(updateLog, ({ timestamp, userId }) => {
      const mt = momentizeTime(timestamp).toISOString();
      if (!lastestUpdate || lastestUpdate < mt) {
        lastestUpdate = mt;
        updatedBy = userId;
      }
    });
    return (
      <MetaInfo>
        <div>
          Created
          {' '}
          <DateTime dt={createdAt} />
          {' '}
          by
          {' '}
          {fullName}
        </div>
        {!!updatedBy && !!usersPublic[updatedBy] && (
          <div>
            Last update
            {' '}
            <DateTime dt={updatedAt} />
            {' '}
            by
            {' '}
            {usersPublic[updatedBy].fullName}
          </div>
        )}
      </MetaInfo>
    );
    // return (
    //   <MetaInfo>
    //     <div>
    //       <Form.Label>Created By</Form.Label>
    //       <Avatar src={avatarUri} name={fullName} size={22} showName />
    //     </div>
    //     <div>
    //       <Form.Label>Created At</Form.Label>
    //       <DateTime dt={createdAt} />
    //     </div>
    //     {!!(updatedBy && usersPublic[updatedBy]) && (
    //       <div>
    //         <Form.Label>Last Updated By</Form.Label>
    //         <Avatar src={usersPublic[updatedBy].avatarUri} name={usersPublic[updatedBy].fullName} size={22} showName />
    //       </div>
    //     )}
    //     <div>
    //       <Form.Label>Last Updated At</Form.Label>
    //       <DateTime dt={updatedAt} />
    //     </div>
    //   </MetaInfo>
    // );
  };

  return (
    <>
      {renderMain()}
      {isEditMode && renderMetaInfo()}
    </>
  );
};

export default connect((store) => ({ store }), Actions)(TaskForm);
