import { forEach } from './index';

export const FEATURE_TOGGLES = {
  'meetings.video.embed': 'meetings.video.embed',
  'groups.video.embed': 'groups.video.embed',
  'groups.all': 'groups.all',
  'storage.all': 'storage.all',
  'users.provisioned.all': 'users.provisioned.all',
  'users.provisioned.groups.all': 'users.provisioned.groups.all',
  'account.branding': 'account.branding',
  'notes.all': 'notes.all',
  'tasks.all': 'tasks.all',
  'tasks.comments': 'tasks.comments',
  'tasks.attachments': 'tasks.attachments',
  'projects.all': 'projects.all',
  'categories.all': 'categories.all',
  'tags.all': 'tags.all',
  'schedules.meetings': 'schedules.meetings',
};

// TODO Move to hook
export const getFeatureToggles = (accounts) => {
  let accountId = null;
  forEach(accounts, (_, aId) => {
    accountId = aId;
  });
  if (!accountId) return [];
  return accounts[accountId].features;
};

// TODO Move to hook
export const hasFeatureToggle = (features = [], toggle = '', isExact = false) => features.some((f) => {
  if (isExact) return f === toggle;
  return f.includes(toggle);
});

export const getFeatureToggleBools = (features) => {
  let toggles = [];
  if (
    features
    && features.toggles
    && features.toggles.length > 0
  ) {
    toggles = features.toggles;
  }
  const bools = {
    hasGroups: hasFeatureToggle(toggles, FEATURE_TOGGLES['groups.all']),
    hasGroupsForProvisionedUsers: hasFeatureToggle(toggles, FEATURE_TOGGLES['users.provisioned.groups.all']),
    hasGroupVideo: hasFeatureToggle(toggles, FEATURE_TOGGLES['groups.video.embed']),
    hasVideo: true || hasFeatureToggle(toggles, FEATURE_TOGGLES['meetings.video.embed']), // TODO implement quota limit
    hasStorage: hasFeatureToggle(toggles, FEATURE_TOGGLES['storage.all']),
    hasProvisionedUsers: hasFeatureToggle(toggles, FEATURE_TOGGLES['users.provisioned.all']),
    hasBranding: hasFeatureToggle(toggles, FEATURE_TOGGLES['account.branding']),
    hasNotes: true || hasFeatureToggle(toggles, FEATURE_TOGGLES['notes.all']),
    hasTasks: true || hasFeatureToggle(toggles, FEATURE_TOGGLES['tasks.all']),
    hasTaskComments: hasFeatureToggle(toggles, FEATURE_TOGGLES['tasks.comments']),
    hasTaskAttachments: hasFeatureToggle(toggles, FEATURE_TOGGLES['tasks.attachments']),
    hasProjects: hasFeatureToggle(toggles, FEATURE_TOGGLES['projects.all']),
    hasCatgories: hasFeatureToggle(toggles, FEATURE_TOGGLES['categories.all']),
    hasTags: hasFeatureToggle(toggles, FEATURE_TOGGLES['tags.all']),
    hasSchedules: hasFeatureToggle(toggles, FEATURE_TOGGLES['schedules.meetings']),
    isAdmin: !!features && !!features.isAdmin,
  };
  return bools;
};
