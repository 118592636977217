import { randomBytes, createHash } from 'crypto';
import { createCipheriv, createDecipheriv } from 'browserify-aes';
// import axios from 'axios';
import _get from 'lodash/get';
import _orderBy from 'lodash/orderBy';
import _forEach from 'lodash/forEach';
import _isEqual from 'lodash/isEqual';
import _toNumber from 'lodash/toNumber';
import _isFinite from 'lodash/isFinite';
import _round from 'lodash/round';
import _includes from 'lodash/includes';
import { v1 as uuidv1, v4 as uuidv4, v5 as uuidv5 } from 'uuid';
import moment from 'moment-timezone';
import { DateTime } from 'luxon';
import { RRule } from 'rrule';
import queryString from 'query-string';
import {
  USER_ROLES, NAME_SPACE, OPERATORS, CONDITIONAL_LOGIC,
} from 'Utils/Consts';
import meLabelMap from '~/assets/me-label-map.json';

// Default AES key (not a security issue since we are fine with clients extracting data with this key)
const imagine = 'Imagine_Imaginetheresnoheaven_Itseasyifyoutry_Nohellbelowus_Aboveus_onlysky_Imagineallthepeople_Livinfortoday_Imaginetheresnocountries_Itisnthardtodo_Nothingtokillordiefor_Andnoreligion_too_Imagineallthepeople_Livinlifeinpeace_YoumaysayImadreamer_ButImnottheonlyone_Ihopesomedayyoulljoinus_Andtheworldwillbeasone_Imaginenopossessions_Iwonderifyoucan_Noneedforgreedorhunger_Abrotherhoodofman_Imagineallthepeople_Sharingalltheworld_YoumaysayImadreamer_ButImnottheonlyone_Ihopesomedayyoulljoinus_Andtheworldwillliveasone';

const {
  OWNER, VIEWER, DELEGATE, MEMBER,
} = USER_ROLES;
const adminRoles = [OWNER, DELEGATE];

export const forEach = (obj, cb) => _forEach(obj, cb);

const getOS = () => {
  const { userAgent } = window.navigator;
  const { platform } = window.navigator;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  let os = null;
  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'macOS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }
  return os;
};

const userOS = getOS();
const isAppleOS = userOS === 'macOS' || userOS === 'iOS';

export const ENV = {
  APP_NAME: process.env.APP_NAME,
  DOMAIN: process.env.DOMAIN,
  BASE_API_URI: process.env.BASE_API_URI,
  WEBCAL_BASE_API_URI: isAppleOS ? process.env.BASE_API_URI.replace(
    /^(https?|http):\/\//,
    'webcal://',
  ) : process.env.BASE_API_URI,
  FIREBASE_CONFIG: process.env.FIREBASE_CONFIG,
};

export const USER_TZ = moment.tz.guess();
// export const USER_TZ = 'Pacific/Honolulu';
// export const USER_TZ = 'Asia/Kolkata';

moment.tz.setDefault(USER_TZ);

export const ISO_8601_ms = 'Y-MM-DDTHH:mm:ss.SSSZ';

export const randomNumber = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
export const uuid = (str) => uuidv5(`${str}@ensembl.app`, NAME_SPACE);
export const sort = (data, sortFields, sortBy) => _orderBy(data, sortFields, sortBy);
export const eventId = () => uuidv1().replace(/-/gi, '');
export const storageId = (name) => {
  const id = uuidv4().replace(/-/gi, '');
  if (!name) return id;
  const n = name.replace(/[^a-z0-9-]/gi, '');
  return n ? `${id}-${n}` : id;
};
// Firestore's function that generates random GUIDs
export const docId = () => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let autoId = '';
  while (autoId.length < 20) {
    const bytes = randomBytes(40);
    bytes.forEach((b) => {
      const maxValue = 62 * 4 - 1;
      if (autoId.length < 20 && b <= maxValue) {
        autoId += chars.charAt(b % 62);
      }
    });
  }
  return autoId;
};

export const encrypt = (text, algorithm, key, ivLength, providedIv) => {
  const iv = providedIv ?? randomBytes(ivLength);
  const cipher = createCipheriv(algorithm, Buffer.from(key, 'hex'), iv);
  let encrypted = cipher.update(text);
  encrypted = Buffer.concat([encrypted, cipher.final()]);
  return `${encrypted.toString('hex')}:${iv.toString('hex')}`;
};

export const decrypt = (text, algorithm, key) => {
  const textParts = text.split(':');
  const iv = Buffer.from(textParts.pop(), 'hex');
  const encryptedText = Buffer.from(textParts.join(':'), 'hex');
  const decipher = createDecipheriv(algorithm, Buffer.from(key, 'hex'), iv);
  let decrypted = decipher.update(encryptedText);
  decrypted = Buffer.concat([decrypted, decipher.final()]);
  return decrypted.toString();
};

export const getIvFromString = (str, ivLength) => {
  const buf = new Buffer.alloc(ivLength);
  buf.write(createHash('sha256').update(str).digest('base64'));
  return buf;
};

export const getKeyFromSecret = (secret) => createHash('sha256').update(secret).digest('hex');
const imagineKey = getKeyFromSecret(imagine);

export const getScheduleKey = (instanceDateTime, scheduleId, type = 'meeting') => encrypt(`${instanceDateTime}_${type}_${scheduleId}`, 'aes-256-ctr', imagineKey, 16, getIvFromString(`${instanceDateTime}_${scheduleId}`, 16));

export const getInstanceParams = (scheduleKey) => decrypt(scheduleKey, 'aes-256-ctr', imagineKey);

export const getIsAdmin = (obj, user) => {
  const uid = user.uid || user.id;
  let isAdmin = false;
  if (uid) {
    if (!obj.users || !obj.users[uid]) return isAdmin;
    const { roles } = obj.users[uid];
    forEach(roles, (role) => {
      if (!isAdmin) isAdmin = _includes(adminRoles, role);
    });
  }
  if (!uid) {
    console.warn('Missing uid in getIsAdmin');
  }
  return isAdmin;
};

export const getMeLabel = () => {
  let lang = window.navigator.userLanguage || window.navigator.language;
  if (lang.includes('-')) {
    const [l] = lang.split('-');
    lang = l;
  }
  const [m] = meLabelMap.filter(({ symbol }) => symbol.includes(lang));
  if (!m) return 'me';
  return m.text;
};

export const makeArray = (obj, cb) => {
  const arr = [];
  forEach(obj, (val, key) => arr.push({ id: key, ...val }));
  return cb ? cb(arr) : arr;
};

export const makeObject = (arr, key = 'id') => {
  const obj = {};
  forEach(arr, (item) => (item[key] = item));
  return obj;
};

export const evaluateCondition = (condition, entity, prevEntity) => {
  const { operator } = condition;
  let { property, value } = condition;
  const isPrev = property.includes('$prev.');
  const obj = isPrev ? prevEntity : entity;
  if (isPrev) property = property.replace('$prev.', '');
  if (`${value}`.includes('$prev.')) {
    value = _get(prevEntity, value.replace('$prev.', ''), null);
  }
  if (`${value}`.includes('$entity.')) {
    value = _get(entity, value.replace('$entity.', ''), null);
  }
  let c = false;
  switch (operator) {
    case OPERATORS.eq:
      c = _isEqual(_get(obj, property, null), value);
      break;
    case OPERATORS.neq:
      c = !_isEqual(_get(obj, property, null), value);
      break;
    case OPERATORS.gt:
      c = _get(obj, property, null) > value;
      break;
    case OPERATORS.gte:
      c = _get(obj, property, null) >= value;
      break;
    case OPERATORS.lt:
      c = _get(obj, property, null) < value;
      break;
    case OPERATORS.lte:
      c = _get(obj, property, null) <= value;
      break;
    case OPERATORS.ex:
      c = _get(obj, property, undefined) !== undefined;
      break;
    case OPERATORS.nex:
      c = _get(obj, property, undefined) === undefined;
      break;
    // case Operator.isValid:
      // c = _get(obj, property, null);
      // TODO
      // break;
    // case Operator.isMatch:
      // c = _get(obj, property, null);
      // TODO
      // break;
    default:
      console.warn('Invalid condition operator in evaluateCondition: ', { condition, obj });
      break;
  }
  return c;
};

export const partition = (data, pConditions) => {
  const asArray = Array.isArray(data);
  const pData = {
    _counts: {},
  };
  forEach(data, (item, itemId) => {
    pConditions.some((pc) => {
      let foundMatch = false;
      forEach(pc, ({ conditions, conditionalLogic }, key) => {
        if (!pData[key]) pData[key] = asArray ? [] : {};
        const isMatch = conditionalLogic === CONDITIONAL_LOGIC.any
          ? conditions.some((c) => evaluateCondition(c, item),)
          : conditions.every((c) => evaluateCondition(c, item),);
        if (isMatch) {
          foundMatch = true;
          pData._counts[key] = pData._counts[key] ? pData._counts[key] + 1 : 1;
          if (asArray) pData[key].push(item);
          else pData[key][itemId] = item;
        }
      });
      return foundMatch;
    });
  });
  return pData;
};

// Date / Time
export const getTimestamp = (
  fsTimestamp,
  forceMoment = false,
  format,
  ts = moment().utc(),
) => {
  if (forceMoment) return ts.format(format);
  const nowToDate = ts.toDate();
  return fsTimestamp.fromDate(nowToDate);
};

export const getTimerContext = (
  { startDateTime, endDateTime },
  time = moment(),
) => {
  const isBefore = moment(time).isBefore(startDateTime);
  const isAfter = moment(time).isAfter(endDateTime);
  const isBetween = !isBefore && !isAfter;
  let msUntilStart = null;
  let msFromStart = null;
  let msSinceEnd = null;
  if (isBefore) {
    msUntilStart = calcDuration({
      startDateTime: time,
      endDateTime: startDateTime,
    });
  }
  if (isBetween) {
    msFromStart = calcDuration({ startDateTime, endDateTime: time });
  }
  if (isAfter) {
    msFromStart = calcDuration({ startDateTime, endDateTime: time });
    msSinceEnd = calcDuration({
      startDateTime: endDateTime,
      endDateTime: time,
    });
  }
  // console.table({
  //   msUntilStart,
  //   msFromStart,
  //   msSinceEnd,
  //   isBefore,
  //   isBetween,
  //   isAfter,
  // });
  return {
    msUntilStart,
    msFromStart,
    msSinceEnd,
    isBefore,
    isBetween,
    isAfter,
  };
};

export const formatUnixTime = (time, format) => {
  if (!time) {
    console.error('Invalid time', time, format);
    return;
  }
  const ts = _get(time, 'seconds', null)
    ? `${_get(time, 'seconds', 0)}.${_get(time, 'nanoseconds', 0)}`
    : time;
  return moment.unix(ts).format(format);
};

export const momentizeTime = (time, format) => {
  const isMomentValid = moment(time, format).isValid();
  if (isMomentValid) {
    return moment(time, format);
  }
  const x = formatUnixTime(time, format);
  return moment(x, format);
};

export const calcDuration = (obj, isMoment = false) => {
  const {
    startDateTime, endDateTime, startFormat, endFormat,
  } = obj;
  if (!obj || !(startDateTime && endDateTime)) {
    console.error('Invalid start/end time to calc duration', obj);
    return 0;
  }

  const x = isMoment
    ? startDateTime
    : momentizeTime(startDateTime, startFormat);
  const y = isMoment ? endDateTime : momentizeTime(endDateTime, endFormat);
  const duration = moment.duration(y.diff(x)).asMilliseconds();
  if (duration < 0) {
    // console.log('Negative duration', duration, obj);
  }
  return duration;
};

export const calcHumanPreciseDuration = (obj) => {
  const { startDateTime, endDateTime } = obj;
  if (!obj || !(startDateTime && endDateTime)) {
    console.error('Invalid start/end time to calc duration', obj);
    return 0;
  }
  const x = momentizeTime(startDateTime);
  const y = momentizeTime(endDateTime);
  const res = moment.preciseDiff(x, y);
  return res;
};

export const calcMinToMs = (int) => {
  const val = _toNumber(int);
  if (val === 0) return 0;
  if (!int || !_isFinite(val)) {
    console.error(
      `Invalid time to calc duration; provide int in minutes: ${int}`,
    );
    return 0;
  }
  const ms = moment.duration(val, 'minutes').asMilliseconds();
  return ms;
};

export const calcMsToMin = (ms) => {
  const val = _toNumber(ms);
  if (val === 0) return 0;
  if (!ms || !_isFinite(val)) {
    console.error(`Invalid time to calc duration; provide int in ms: ${ms}`);
    return 0;
  }
  const min = moment.duration(val, 'milliseconds').asMinutes();
  return min;
};

// Provide HH:mm and duration in ms to calc endTime
export const calcTimeFromNow = (obj) => {
  if (!obj) {
    console.error('Invalid parameters: ', obj);
    return null;
  }
  const { startDateTime, durationFrom } = obj;
  const x = momentizeTime(startDateTime);
  const endTime = x.add(durationFrom, 'milliseconds').format('HH:mm');
  return endTime;
};

export const calcMsToHumanTime = (int) => {
  const val = _toNumber(int);
  if (val === 0) return 0;
  if (!_isFinite(val)) {
    console.error(`Invalid time to calc duration; provide int in ms: ${int}`);
    return 0;
  }
  const ms = moment.duration(val).asMinutes();
  const roundMs = _round(ms);
  const isApprox = ms !== roundMs;
  // return moment.duration(val).humanize().replace('an ', '1 ').replace('a ', '1 ');
  return isApprox
    ? `about ${roundMs} ${roundMs === 1 ? 'minute' : 'minutes'}`
    : `${roundMs} ${roundMs === 1 ? 'minute' : 'minutes'}`;
};

// Source: https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string/10420404
export const calcHumanFileSize = (bytes, si = false, dp = 1) => {
  if (!bytes || bytes === 0) return '';
  const thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`;
  }
  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;
  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh
    && u < units.length - 1
  );
  return `${bytes.toFixed(dp)} ${units[u]}`;
};

// URL / Routes
export const constructQueryString = (str) => {
  const queries = {};
  const qs = str.substring(1).split('&');
  qs.forEach((q) => {
    const [k, v] = q.split('=');
    queries[k] = encodeURIComponent(v);
  });
  return queries;
};

export const constructCalendarFeedURI = (user) => {
  const { uid, icalKey } = user;
  if (!uid || !icalKey) return;
  const url = `${ENV.WEBCAL_BASE_API_URI}/calendars/${uid}?key=${icalKey}`;
  return url;
};

export const parseQueryString = (str) => queryString.parse(str);

export const getRouteActions = (pageProps) => {
  const actions = _get(pageProps, 'route.data.ctx.actions', null);
  if (!actions) console.error('Cannot get route actions', { pageProps });
  return actions;
};

export const getCtxProp = (pageProps, propertyName, defaultVal = null) => {
  let val = _get(pageProps, `route.data.ctx.${propertyName}`, null);
  if (!val) val = _get(pageProps, `route.data.${propertyName}`, defaultVal);
  if (!val) {
    console.error('Cannot get route property', { pageProps, propertyName });
  }
  return val;
};

const logEvent = (type, userId, timestamp) => ({
  type,
  userId,
  timestamp,
});

export const parseTime = (time) => {
  const [hour = 0, minute = 0] = `${time}`.split(':');
  return {
    hour,
    minute,
    seconds: 0,
    milliseconds: 0,
  };
};

export const getLocalTime = (dateTime, tZ, timeOnly = false, format = 'LT') => {
  if (!dateTime) return '';
  const timeZone = USER_TZ; // Using local machine tz
  const localTime = moment(dateTime).tz(timeZone);
  // return timeOnly ? localTime.format().substring(11, 16) : localTime;
  return timeOnly ? localTime.format(format) : localTime;
};

export const getHumanTime = (dateTime, format, tZ) => {
  const timeZone = USER_TZ; // Using local machine tz
  if (!dateTime) {
    console.warn('Invalid dateTime in getHumanTime()', { dateTime, format });
    return '';
  }
  const dt = momentizeTime(dateTime);
  return timeZone
    ? moment(dt).tz(timeZone).format(format)
    : moment(dt).format(format);
};

export const getLocalDateTime = (from, to, tZ) => {
  const timeZone = USER_TZ; // Using local machine tz
  const startDateTime = getLocalTime(from, timeZone);
  const endDateTime = getLocalTime(to, timeZone);
  const startTime = getLocalTime(from, timeZone, true);
  const endTime = getLocalTime(to, timeZone, true);
  const timeRange = `${startTime}–${endTime}`;
  const zone = moment(startDateTime).tz(timeZone).zoneAbbr();
  const month = moment(startDateTime).tz(timeZone).format('MMM');
  const day = moment(startDateTime).tz(timeZone).format('DD');
  const year = moment(startDateTime).tz(timeZone).format('Y');
  const weekDay = moment(startDateTime).tz(timeZone).format('ddd');
  return {
    startDateTime,
    endDateTime,
    startTime,
    endTime,
    timeRange,
    zone,
    month,
    day,
    year,
    weekDay,
  };
};

export const getUserFullName = (user) => {
  if (user.fullName) return user.fullName;
  if (user.firstName && user.lastName) {
    return `${user.firstName || ''} ${user.lastName || ''}`.trim();
  }
  if (user.firstName) return user.firstName;
  if (user.lastName) return user.lastName;
  // console.warn('Cannot make user full name:', user);
  return '';
};

export const findTemplate = (templates, templateId) => {
  let template = null;
  forEach(templates, (groupVal) => {
    forEach(groupVal, (val, key) => {
      if (key === templateId) template = val;
    });
  });
  return template;
};

export const processFormData = (data, uid, fsTimestamp, updateOnly = false) => {
  const now = fsTimestamp.now();
  const omitProperties = ['isAdmin, id'];
  omitProperties.forEach((property) => {
    if (data[property]) delete data[property];
  });
  if (!data.createdAt && !updateOnly) {
    data.createdAt = now;
  }
  if (!data.createdBy && !updateOnly) {
    data.createdBy = uid;
  }
  if (data.isDeleted !== false && !updateOnly) {
    data.isDeleted = false;
  }
  if (!data.updateLog && !updateOnly) {
    data.updateLog = {
      [eventId()]: logEvent('CREATE', uid, now),
    };
  } else {
    if (!data.updateLog) data.updateLog = {};
    const eId = eventId();
    data.updateLog[eId] = logEvent('UPDATE', uid, now);
    // data.updateLog[eventId].diff =
  }
  data.updatedAt = now;
  if (!data.users && !updateOnly) {
    data.users = {};
  }
  if (!!data.users && !data.users[uid] && !updateOnly) {
    data.users[uid] = { roles: [OWNER] };
  }
  return data;
};

// ===

const setPartsToUTCDate = (d) => new Date(
  Date.UTC(
    d.getFullYear(),
    d.getMonth(),
    d.getDate(),
    d.getHours(),
    d.getMinutes(),
    d.getSeconds()
  )
);

const setUTCPartsToDate = (d) => new Date(
  d.getUTCFullYear(),
  d.getUTCMonth(),
  d.getUTCDate(),
  d.getUTCHours(),
  d.getUTCMinutes(),
  d.getUTCSeconds()
);

export const getRecurrenceRule = (recurrence, meeting) => {
  const options = { ...recurrence };
  const { startDateTime, timeZone } = meeting;

  const st = moment(startDateTime);
  const additionalOptionsParams = {
    dtstart: setPartsToUTCDate(st.toDate()),
    tzid: timeZone,
    byhour: null,
    byminute: null,
    bysecond: null,
  };

  ['dtstart', 'until'].forEach((field) => {
    if (options[field]) {
      options[field] = setPartsToUTCDate(moment(options[field]).toDate());
    }
  });
  const rule = new RRule({ ...options, ...additionalOptionsParams });
  return rule;
};

export const getInstancesForRange = (
  instance,
  rule,
  timeZone,
  ruleStartDateTime,
  offset = 1,
  offsetUnit = 'week',
  boundUnit = 'month',
  mode = 'BETWEEN',
) => {
  const makeJsUtcDate = (d) => {
    const dta = moment(d).toArray();
    const dt = new Date(Date.UTC(...dta));
    return dt;
  };
  const from = moment(instance).startOf(boundUnit).add({ [offsetUnit]: -(offset) });
  const to = moment(instance).endOf(boundUnit).add({ [offsetUnit]: offset });

  const instances = mode === 'ALL'
    ? rule.all() // Make sure rule has a count ot this can be huge (used for initial validation since Rrule insanely doesn't have inclusive dtstart)
    : rule.between(makeJsUtcDate(from), makeJsUtcDate(to));

  // const stOffset = moment(ruleStartDateTime).tz(timeZone).utcOffset();
  const getAdjustedDt = (dt) => {
    const dateTimeInstance = setUTCPartsToDate(dt);
    console.log(moment(dt).format());
    return dateTimeInstance.toISOString();
  };

  const dtInstances = instances.map(getAdjustedDt);
  console.log('INSTANCES', instance, dtInstances);
  return dtInstances;
};

export const getIsValidInstance = (instance, scheduleData) => {
  const { recurrence, meeting } = scheduleData;
  const initialRule = getRecurrenceRule({ ...recurrence, count: 1 }, meeting);
  const rule = getRecurrenceRule(recurrence, meeting);
  const initialInstance = getInstancesForRange(
    instance,
    initialRule,
    meeting.timeZone,
    meeting.startDateTime,
    1,
    'minutes',
    'day',
    'ALL' // Rule limits the count; Limitation of Rrule dtstart not inclusive
  );
  const instances = getInstancesForRange(
    instance,
    rule,
    meeting.timeZone,
    meeting.startDateTime,
    1,
    'minutes',
    'day',
  );
  const isValid = [...initialInstance, ...instances].some((i) => i === instance);
  return isValid;
};

// =====

export const getUrlDomain = (url) => {
  let result;
  let match;
  if (
    (match = url.match(
      /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im,
    ))
  ) {
    result = match[1];
    if ((match = result.match(/^[^\.]+\.(.+\..+)$/))) {
      result = match[1];
    }
  }
  return result;
};

// TODO This may be deprecated in favor of as="a" href={signedUris[id] || url} download={attachment.name} on element
// export const downloadBlob = ({ blob, name }) => {
//   const url = URL.createObjectURL(blob);
//   console.log({ blob });
//   const a = document.createElement('a');
//   a.href = url;
//   a.download = name || 'download';
//   const clickHandler = () => {
//     setTimeout(() => {
//       URL.revokeObjectURL(url);
//       a.removeEventListener('click', clickHandler);
//     }, 150);
//   };
//   a.addEventListener('click', clickHandler, false);
//   a.click();
//   return a;
// };

// // TODO CORS IS FUBAR
// export const downloadFile = async ({ url, name, type }) => {
//   // console.log(url);
//   const options = {
//     method: 'GET',
//     headers: {
//       // Origin: 'ensembl',
//       // 'Access-Control-Allow-Origin': '*',
//       // 'Access-Control-Allow-Headers': '*',
//       // 'Access-Control-Expose-Headers': '*',
//       Accept: type,
//       'Content-Type': `${type};charset=UTF-8`,
//     },
//     responseType: 'blob',
//     // mode: 'no-cors',
//     // crossdomain: true,
//   };
//   // const x = await axios.options(url);
//   // console.log({ x });
//   // await fetch(url, { method: 'options' });
//   fetch(url, options)
//     .then((response) => {
//       // console.log(response);
//       if (response.ok) {
//         return response.blob();
//       }
//       return null;
//     })
//     .then((blob) => {
//       // console.log(blob);
//       if (blob) downloadBlob({ blob, name });
//     }).catch((err) => console.error(err));
// };
