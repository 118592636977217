import { upsertNotes, getNotesById } from '../firebase/note';

export const handleSetNote = async (Store, state, meetingId, agendaId, note) => {
  const { notes, me: { uid: userId } } = state;
  const noteFragment = { [agendaId]: note };
  await upsertNotes(userId, meetingId, noteFragment);
  const savedNotes = { notes: { ...notes, [meetingId]: { ...notes[meetingId], [agendaId]: note } } };
  return savedNotes;
};

export const handleGetNotes = async (Store, state, meetingId) => {
  const { notes, me: { uid: userId } } = state;
  const meetingNotes = await getNotesById(userId, meetingId);
  const savedNotes = { notes: { ...notes, [meetingId]: meetingNotes } };
  return savedNotes;
};
