import React, { useEffect, useState } from 'react';
import { connect } from 'unistore/react';
import styled from 'styled-components';
import Common from 'Common';
import { makeArray, forEach } from 'Utils';
import { VISIBILITY_ENUMS } from 'Utils/Consts';
import { COLORS } from '~/styles/Consts';
import { styleConstructor } from '~/styles/Utils';
import Actions from '~/state/Actions';

const {
  Comment, Button, Avatar, TextArea, DateTime, Icon, Heading,
} = Common;

const CommentContent = styled(({ styling, ...rest }) => <Comment.Content {...rest} />)`
    margin-left: 2.5rem !important;
    ${({ styling }) => styleConstructor(styling)}
`;

const CommentReply = styled(Comment)`
  padding-left: 0.5rem !important;
  border-left: 3px solid ${COLORS.lightGray2} !important;
`;

const TextAreaWrapper = styled.div`
    max-width: 20rem;
    margin-bottom: 0.5rem;
`;

const Value = styled(({ styling, ...rest }) => <span {...rest} />)`
  white-space: pre-wrap;
  ${({ styling }) => styleConstructor(styling)}
`;

const Comments = ({
  topic,
  visibility,
  highlightCommentId,
  store,
  onUpsertComment,
  onSubscribeCommentsByTopic,
  onUnsubscribeComments,
  showHeader,
  manageSubscription,
}) => {
  const { comments, users, me } = store;
  const [processedComments, setProcessedComments] = useState([]);
  const [replyForm, setReplyForm] = useState(null);
  const [replyContent, setReplyContent] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleReplyContentOnChange = (val) => {
    setReplyContent(val);
  };

  const handleUpsertComment = async (commentId = null) => {
    if (!replyContent) return;
    setLoading(true);
    const comment = {
      topic,
      parentId: replyForm || '',
      isLocked: false,
      isFlagged: false,
      isDeleted: false,
      content: replyContent,
      attachments: {},
      mentions: {},
      reactions: {},
      visibility,
    };
    setReplyContent(null);
    await onUpsertComment(comment, commentId);
    if (replyForm) setReplyForm(null);
    setLoading(false);
  };

  useEffect(() => {
    if (!comments[topic]) return;
    const commentParents = {};
    const commentChildrenIds = {};
    forEach(comments[topic], (comment, cId) => {
      const { parentId } = comment;
      if (parentId) {
        if (!commentChildrenIds[parentId]) {
          commentChildrenIds[parentId] = [];
        }
        commentChildrenIds[parentId].push(cId);
      } else {
        commentParents[cId] = comment;
      }
    });
    forEach(commentChildrenIds, (cIds, parentId) => {
      if (!commentParents[parentId]) return; // Orphan child comment
      commentParents[parentId].children = cIds.map((cId) => ({ ...comments[topic][cId], id: cId }));
    });
    const commentsArr = makeArray(commentParents);

    setProcessedComments(commentsArr);
  }, [comments]);

  useEffect(() => {
    if (!manageSubscription) return;
    onSubscribeCommentsByTopic(topic);
    return () => {
      if (!manageSubscription) return;
      onUnsubscribeComments(topic);
    };
  }, [manageSubscription]);

  const renderAddCommentField = (parentId) => {
    const El = parentId ? CommentReply : Comment;
    return (
      <El>
        <Comment.Avatar
          as={Avatar}
          src={me?.avatarUri}
          name={me?.fullName}
          styling={{ width: '2.5rem', height: '2.5rem' }}
        />
        <Comment.Content>
          <TextAreaWrapper>
            <TextArea
              onChange={({ target: { value } }) => handleReplyContentOnChange(value, parentId)}
              placeholder={parentId ? 'Add a reply …' : 'Add a comment …'}
              value={replyContent || ''}
              disabled={loading}
            />
          </TextAreaWrapper>
          <Button
            onClick={() => handleUpsertComment()}
            loading={loading}
            primary
          >
            <Icon name="chat" />
            {parentId ? 'Reply' : 'Comment'}
          </Button>
        </Comment.Content>
      </El>
    );
  };

  const renderComment = (comment, isThread = false) => {
    const {
      id, createdBy, updatedAt, parentId, content, children = [],
    } = comment;
    const user = users[createdBy];
    const El = isThread === true ? CommentReply : Comment;
    return (
      <El key={id}>
        <Comment.Avatar
          as={Avatar}
          src={user?.avatarUri}
          name={user?.fullName || 'Unknown User'}
          styling={{ width: '2rem', height: '2rem' }}
        />
        <CommentContent>
          <Comment.Author as="span">{user?.fullName || 'Unknown User'}</Comment.Author>
          <Comment.Metadata>
            <DateTime dt={updatedAt} />
          </Comment.Metadata>
          <Comment.Text>
            <Value
              styling={id === highlightCommentId
                ? {
                  backgroundColor: COLORS.yellow, padding: '0.25rem', marginTop: '0.5rem', display: 'block',
                }
                : null}
            >
              {content}
            </Value>
          </Comment.Text>
          {!parentId && (
            <Comment.Actions>
              {/* <a onClick={() => console.log('')}>
                <Icon name="thumbs up" size="small" />
                Like
              </a> */}
              <a onClick={() => setReplyForm(replyForm === id ? null : id)}>
                <Icon name="talk" size="small" />
                {replyForm === id ? 'Cancel reply' : 'Reply'}
              </a>
              {replyForm === id && renderAddCommentField(id)}
            </Comment.Actions>
          )}
          {children.map((c) => renderComment(c, true))}
        </CommentContent>
      </El>
    );
  };

  return (
    <>
      {showHeader && <Heading as="h3">Comments</Heading>}
      <Comment.Group threaded>
        {processedComments.map(renderComment)}
        {!replyForm && renderAddCommentField()}
      </Comment.Group>
    </>
  );
};

Comments.defaultProps = {
  manageSubscription: true,
  showHeader: true,
  visibility: VISIBILITY_ENUMS.PRIVATE,
};

export default connect((store) => ({ store }), Actions)(Comments);
