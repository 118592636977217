import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';
import RRRuleGenerator from 'react-rrule-generator';
import { RRule, rrulestr } from 'rrule';
import Common from 'Common';

import 'react-rrule-generator/build/styles.css';
import bootstrap from '!!raw-loader!./bootstrap.min.css.txt';

const { Button, Radio } = Common;

const ButtonWrapper = styled.div`
  margin-top: 2rem;
`;

const RRRuleGeneratorWrapper = styled.div`
    ${bootstrap}
    font-family: 'Roboto', sans-serif;
    padding: 1rem;
    background-color: #fff;
`;

const ON_THE = 'on the';
const ON = 'on';
const NEVER = 'Never';
const ON_DATE = 'On date';
const AFTER = 'After';
const DAILY = 'Daily';
const WEEKLY = 'Weekly';
const MONTHLY = 'Monthly';
const YEARLY = 'Yearly';

const RecurringForm = ({
  recurrence, onSetRRule, onClose, showButtons, showForm,
}) => {
  const [mode, setMode] = useState(ON_THE);
  const [freq, setFreq] = useState(RRule.WEEKLY);
  const [onTheRule, setOnTheRule] = useState((new RRule({ freq }).toString()));
  const [onRule, setOnRule] = useState((new RRule({ freq }).toString()));

  const prevModeRef = useRef(mode);

  const prevRecurrence = useRef(recurrence);
  useEffect(() => {
    if (recurrence) {
      const r = new RRule(recurrence);
      const rruleStr = r.toString();
      if (mode === ON) {
        if (rruleStr !== onRule) setOnRule(rruleStr);
      } else if (mode === ON_THE) {
        if (rruleStr !== onTheRule) setOnTheRule(rruleStr);
      }
    }
  }, [recurrence]);

  const handleRRuleChange = (rruleStr) => {
    const { options: o } = rrulestr(rruleStr);
    let r = new RRule(o);
    if (mode !== prevModeRef.current) {
      prevModeRef.current = mode;
      const mOptions = {};
      if (mode === ON) {
        mOptions.bymonthday = null;
      }
      if (mode === ON_THE) {
        mOptions.interval = null;
      }
      r = new RRule({ freq: o.freq, ...mOptions });
    }
    const options = { ...r.options };
    r = new RRule({ ...options, dtstart: null });
    const text = r.toText();
    const rrule = r.toString();

    ['dtstart', 'until'].forEach((field) => {
      if (options[field]) {
        options[field] = field === 'dtstart' ? null : moment(options[field]).toISOString();
      }
    });

    if (options.freq !== freq) setFreq(options.freq);

    if (mode === ON) setOnRule(rrule);
    if (mode === ON_THE) setOnTheRule(rrule);

    if (onSetRRule) onSetRRule({ options, text, rrule });
  };

  const handleCancel = () => {
    if (prevRecurrence.current) {
      const prevRrule = new RRule(prevRecurrence.current).toString();
      handleRRuleChange(prevRrule);
    }
    if (onClose) onClose();
  };

  const handleSave = () => {
    handleRRuleChange(mode === ON ? onRule : onTheRule);
    if (onClose) onClose();
  };

  const handleToggleMode = () => {
    const m = mode === ON ? ON_THE : ON;
    setMode(m);
    handleRRuleChange(m === ON ? onRule : onTheRule);
  };

  const renderForm = () => mode === ON ? (
    <RRRuleGenerator
      key={`${mode}-${freq}`}
      onChange={handleRRuleChange}
      value={onRule}
      config={{
        repeat: [DAILY, WEEKLY, MONTHLY, YEARLY],
        end: [NEVER, ON_DATE, AFTER],
        weekStartsOnSunday: false,
        hideError: true,
        hideEnd: false,
      }}
    />
  ) : (
    <RRRuleGenerator
      key={`${mode}-${freq}`}
      onChange={handleRRuleChange}
      value={onTheRule}
      config={{
        repeat: [DAILY, WEEKLY, MONTHLY, YEARLY],
        end: [NEVER, ON_DATE, AFTER],
        weekStartsOnSunday: false,
        hideError: true,
        hideEnd: false,
      }}
    />
  );

  const renderModeToggles = () => {
    if (freq === RRule.MONTHLY || freq === RRule.YEARLY) {
      return (
        <div>
          <Radio
            label="Each"
            name="mode"
            value={ON}
            checked={mode === ON}
            onChange={handleToggleMode}
          />
          <Radio
            label="On the …"
            name="mode"
            value={ON_THE}
            checked={mode === ON_THE}
            onChange={handleToggleMode}
          />
        </div>
      );
    }
    return null;
  };

  const renderButtons = () => (
    <ButtonWrapper>
      <Button
        onClick={handleSave}
        type="submit"
        primary
      >
        Save Recurring Schedule
      </Button>
      <Button onClick={handleCancel} basic>Cancel</Button>
    </ButtonWrapper>
  );

  return showForm ? (
    <RRRuleGeneratorWrapper>
      {/* {renderModeToggles()} */}
      {renderForm()}
      {showButtons && renderButtons()}
    </RRRuleGeneratorWrapper>
  )
    : <RRRuleGeneratorWrapper />;
};

RecurringForm.defaultProps = {
  showButtons: true,
  timeZone: null,
  showForm: true,
};

export default RecurringForm;
