import _merge from 'lodash/merge';
import { getIsAdmin, processFormData, forEach } from 'Utils';
import { fsTimestamp } from '../firebase';
import { upsertMinutes, getMinutesById, subscribeMinutes } from '../firebase/minutes';

const enrichMinutes = (m, me, g = {}) => {
  const isAdmin = getIsAdmin(m, me) || getIsAdmin(g, me);
  m.isAdmin = isAdmin;
  return m;
};

// TODO Refactor / move helper func elsewhere
const cleanStateDataForDb = (obj) => {
  const omitKeys = ['isAdmin', 'updatedAt', 'createdAt', 'createdBy'];
  const o = { ...obj };
  omitKeys.forEach((k) => {
    if (o[k] || o[k] === false || o[k] === 0) delete o[k];
  });
  forEach(o, (_, p) => {
    if (o[p] === undefined) delete o[p];
  });
  return o;
};

export const handleSetMinutes = async (
  Store,
  state,
  meetingId,
  agendaId,
  minutesVal,
) => {
  const {
    minutes,
  } = state;
  const minutesFragment = {
    [agendaId]: minutesVal,
  };
  await upsertMinutes(minutesFragment, meetingId);
  const savedMinutes = {
    minutes: {
      ...minutes,
      [meetingId]: { ...minutes[meetingId], [agendaId]: minutesVal },
    },
  };
  return savedMinutes;
};

export const handleGetMinutes = async (Store, state, meetingId) => {
  const { minutes, me } = state;
  const meetingMinutes = await getMinutesById(meetingId);
  if (!meetingMinutes) return;
  const savedMinutes = { minutes: { ...minutes, [meetingId]: enrichMinutes(meetingMinutes, me) } };
  return savedMinutes;
};

export const handleUpsertMinutes = async (
  Store,
  state,
  minutesData,
  minutesId,
  cb,
) => {
  const { me } = state;
  const { uid: userId } = me;
  const updateStore = ({ data }) => {
    const { minutes } = Store.getState();
    const cachedMinutes = minutes[minutesId] || {};
    const m = enrichMinutes(_merge(cachedMinutes, data), me);
    if (cb) cb({ res: m });
    const { id } = data;
    minutes[id] = m;
    Store.setState({ minutes: { ...minutes } });
  };
  // TODO add state.prevData param to processFormData()
  const pMinutes = cleanStateDataForDb(processFormData(minutesData, userId, fsTimestamp, !!minutesId));
  await upsertMinutes(pMinutes, minutesId, updateStore);
};

export const handleSubscribeMinutes = async (Store, state, id, cb) => {
  const { me, groups } = state;
  const updateStore = async ({ data, unsubscribe }) => {
    const { meetings, minutes, subscriptions } = Store.getState();
    const { groupId } = meetings[id];
    const g = groups[groupId] || {};
    const m = enrichMinutes(data, me, g);
    minutes[id] = m;
    if (cb) cb({ res: m });
    minutes[id] = m;
    subscriptions[`${id}-minutes`] = { unsubscribe };
    Store.setState({ minutes: { ...minutes }, subscriptions });
  };
  await subscribeMinutes(id, updateStore);
};
