import React, { useState } from 'react';
import styled from 'styled-components';
import { Document, Page, pdfjs } from 'react-pdf';
import Common from 'Common';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

const { Button, Grid, Input } = Common;

const Wrapper = styled.div`
  margin: auto;
  text-align: center;
`;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewer = ({ startPage, src, id }) => {
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(startPage);

  const handleDocumentLoadSuccess = ({ numPages }) => {
    setPageCount(numPages);
  };

  const handlePageNavigation = (pageNum) => {
    if (pageNum === 0 || pageNum > pageCount) return;
    setPage(pageNum);
  };

  const renderControls = () => pageCount > 1 ? (
    <Grid columns={3} container>
      <Grid.Column verticalAlign="middle">
        <Button.Group size="tiny">
          <Button onClick={() => handlePageNavigation(page - 1)} icon="arrow circle left"></Button>
          <Button onClick={() => handlePageNavigation(page + 1)} icon="arrow circle right"></Button>
        </Button.Group>
      </Grid.Column>
      <Grid.Column verticalAlign="middle">
        <Input
          type="number"
          name="page"
          value={page}
          onChange={(_, { value }) => handlePageNavigation(Number(value))}
        />
      </Grid.Column>
      <Grid.Column verticalAlign="middle">
        {`Page ${page} of ${pageCount}`}
      </Grid.Column>
    </Grid>
  ) : null;

  return (
    <Wrapper>
      {renderControls()}
      <Document id={id} file={src} onLoadSuccess={handleDocumentLoadSuccess}>
        <Page key={`${id}-${page}`} pageNumber={page} />
      </Document>
    </Wrapper>
  );
};

PdfViewer.defaultProps = {
  startPage: 1,
};

export default PdfViewer;
