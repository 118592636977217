import React from 'react';
import styled from 'styled-components';
import logo from '~/assets/ensembl-logo-h-r.svg';
import tagline from '~/assets/ensembl-tagline-r.svg';

const LogoImg = styled.img`
width: 8.6rem;
display: inline-block;
`;

const Tagline = styled.img`
width: 6rem;
display: inline-block;
`;

const LogoWrapper = styled.div`
display: flex;
padding: 0;
margin: 0;
`;

const Logo = () => (
  <LogoWrapper>
    <LogoImg src={logo} alt="Ensembl logo" />
    <Tagline src={tagline} alt="Get it together." />
  </LogoWrapper>
);

export default Logo;
