import React, { useMemo, useEffect } from 'react';
import styled from 'styled-components';
import Common from 'Common';
import { useNavigation } from 'react-navi';
import { getUserFullName } from 'Utils';
import { COLORS } from '~/styles/Consts';
import {
  BASE, SIGN_IN, PROFILE, DASHBOARD, ACCOUNT, SIGN_UP, FORGOT_PASSWORD, constructRoute,
} from '~/routes';
import useViewport from '~/hooks/useViewport';
import useGlobalContext from '~/hooks/useGlobalContext';

const {
  Menu, Icon, Avatar, Dropdown, Label,
} = Common;

const Wrapper = styled.nav`
  display: inline-block;
`;

const MenuStyled = styled(Menu)`
  height: 100% !important;
  background-color: unset !important;
  border: unset !important;
  box-shadow: unset !important;
  min-height: unset !important;
`;

const ItemStyled = styled(Menu.Item)`
  color: #fff !important;
  border: unset !important;
  &:before {
    background-color: unset !important;
  }
`;

const DropdownStyled = styled(Dropdown)`
  color: #fff !important;
  &&.item:before {
    width: 0 !important;
    height: 0 !important;
  };
`;

const AvatarStyled = styled(Avatar)`
  margin-right: 0.5rem;
  background-color: #fff !important;
  color: ${COLORS.primary} !important;
  /* opacity: 0.7; */
`;

const Navigation = ({
  actions, isAuthenticated, me, account, accountId,
}) => {
  const [{ showMobileSidebar }, setGlobalState] = useGlobalContext();
  const { isMobile } = useViewport();
  const navigation = useNavigation();
  const { navigate } = navigation;

  const handleToggleShowSidebar = () => {
    setGlobalState({ showMobileSidebar: !showMobileSidebar });
  };

  const fullName = useMemo(() => getUserFullName(me), [me]);

  const mobileItems = [
    {
      content: null,
      onClick: handleToggleShowSidebar,
      auth: null,
      icon: showMobileSidebar ? 'close' : 'sidebar',
    },
  ];

  const menuItems = useMemo(() => {
    const m = isMobile ? [
      {
        content: 'Dashboard',
        link: DASHBOARD,
        auth: true,
        icon: 'calendar alternate',
      },
      {
        content: fullName || 'My Profile',
        link: PROFILE,
        auth: true,
        icon: 'user',
        avatar: me.avatarUri,
      },
      {
        content: 'Sign-in',
        link: SIGN_IN,
        auth: false,
        icon: 'sign in alternate',
      },
      {
        content: 'Sign-up',
        link: SIGN_UP,
        auth: false,
        icon: 'user plus',
        isMobileOnly: true,
      },
      {
        content: 'Forgot Password?',
        link: FORGOT_PASSWORD,
        auth: false,
        icon: 'paper plane',
        isMobileOnly: true,
      },
      {
        content: 'Sign-out',
        onClick: actions.onSignOut,
        auth: true,
        icon: 'sign out alternate',
      },
    ] : [
      {
        content: 'Dashboard',
        link: DASHBOARD,
        auth: true,
        icon: 'calendar alternate',
      },
      {
        content: fullName || 'My Profile',
        link: PROFILE,
        auth: true,
        icon: 'user',
        avatar: me.avatarUri,
        badgeProps: { empty: true, styling: { background: 'orange' } },
        showBadge: me.emailAddress && !me.avatarUri,
        dropdown: [
          {
            content: 'Profile',
            link: PROFILE,
            auth: true,
            icon: 'user',
            badgeProps: { empty: true, styling: { background: 'orange', marginLeft: '0.5rem' } },
            showBadge: me.emailAddress && !me.avatarUri,
          },
          {
            content: 'Sign-out',
            onClick: actions.onSignOut,
            auth: true,
            icon: 'sign out alternate',
          },
        ],
      },
      {
        content: 'Sign-in',
        link: SIGN_IN,
        auth: false,
        icon: 'sign in alternate',
      },
    ];
    if (!isMobile && accountId) {
      m[1].dropdown.splice(1, 0, {
        content: account?.organization?.name,
        link: constructRoute({ route: ACCOUNT, pathParams: { accountId } }),
        auth: true,
        icon: 'building',
      });
    }
    return m;
  }, [isAuthenticated, me, isMobile, account, accountId]);

  useEffect(() => {
    if (isMobile) {
      setGlobalState({ sidebarMenuItems: menuItems });
    }
    return () => {
      setGlobalState({ sidebarMenuItems: null });
    };
  }, [isMobile, isAuthenticated, me]);

  const renderBadge = (badgeProps) => <Label size="mini" floating={false} circular {...badgeProps} />;

  const renderDropdownItem = (item) => {
    const {
      auth, content, icon, avatar, link, showBadge, badgeProps, onClick, isMobileOnly,
    } = item;
    if ((auth !== isAuthenticated && auth !== null) || isMobileOnly) {
      return null;
    }
    const itemParams = {
      key: item.content,
      content: (
        <>
          {!!avatar && <AvatarStyled src={avatar} />}
          {(!avatar && !!icon) && <Icon name={icon} />}
          {content}
          {showBadge && renderBadge(badgeProps)}
        </>
      ),
      onClick: link ? () => navigate(link) : (onClick || null),
    };
    return <Dropdown.Item {...itemParams} />;
  };

  const renderDropdownMenu = (dropdownItem) => {
    const { dropdown, key, content } = dropdownItem;

    return (
      <DropdownStyled
        key={key}
        text={content}
        pointing="top right"
        className="item"
      >
        <Dropdown.Menu>
          {dropdown.map(renderDropdownItem)}
        </Dropdown.Menu>
      </DropdownStyled>
    );
  };

  const renderMenuItem = (item) => {
    const {
      auth, content, icon, avatar, link, showBadge, badgeProps, onClick, isMobileOnly, dropdown,
    } = item;
    if ((auth !== isAuthenticated && auth !== null) || isMobileOnly) {
      return null;
    }
    const itemParams = {
      key: item.content,
      content: (
        <>
          {!!avatar && <AvatarStyled src={avatar} />}
          {(!avatar && !!icon) && <Icon name={icon} />}
          {content}
          {showBadge && renderBadge(badgeProps)}
        </>
      ),
      onClick: link ? () => navigate(link) : (onClick || null),
    };
    return dropdown ? renderDropdownMenu({ ...itemParams, dropdown }) : <ItemStyled {...itemParams} />;
  };

  const renderMenuItems = () => (
    <Menu.Menu position="right">
      {isMobile ? mobileItems.map(renderMenuItem) : menuItems.map(renderMenuItem)}
    </Menu.Menu>
  );

  const renderNavigation = () => (
    <Wrapper>
      <MenuStyled>
        {renderMenuItems()}
      </MenuStyled>
    </Wrapper>
  );

  if (isAuthenticated === null) return null;

  return renderNavigation();
};

export default Navigation;
