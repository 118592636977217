import React, { useRef } from 'react';
import ReactReward from 'react-rewards';

// TODO WIP — need to implement fully
const Reward = ({ renderElement, type, ...rest }) => {
  const rewardRef = useRef(null);

  const handleSuccess = () => {
    rewardRef.rewardMe();
  };

  const handleError = () => {
    rewardRef.punishMe();
  };

  const renderReward = () => (
    <ReactReward
      ref={(ref) => rewardRef = ref}
      type={type}
      {...rest}
    >
      {renderElement({
        onSuccess: handleSuccess,
        onError: handleError,
      })}
    </ReactReward>
  );

  return renderReward();
};

Reward.defaultProps = {
  renderElement: () => console.warn('Missing renderElement in Reward'),
  type: 'confetti', // 'confetti'|'emoji'|'memphis',
};

export default Reward;
