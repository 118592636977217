import React, {
  useReducer, useContext, createContext,
} from 'react';

const globalContext = createContext({});

const INITIAL_STATE = {
  showMobileSidebar: false,
  sidebarMenuItems: null,
  selectedDashboardMenu: null,
  selectedGroupMenu: null,
};

const reducer = (state, action) => ({ ...state, ...action.payload });

export const GlobalContextProvider = ({ children }) => {
  const [globalState, dispatch] = useReducer(reducer, INITIAL_STATE);

  const handleSetGlobalState = (payload) => {
    dispatch({ type: 'update', payload });
  };

  return (
    <globalContext.Provider value={[globalState, handleSetGlobalState]}>
      {children}
    </globalContext.Provider>
  );
};

const useGlobalContext = () => {
  const state = useContext(globalContext);
  return state;
};

export default useGlobalContext;
