import React, {
  useEffect, useState, useContext, createContext,
} from 'react';
import { breakpoints } from '~/styles/Utils';

const viewportContext = createContext({});

export const ViewportProvider = ({ children }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return (
    <viewportContext.Provider value={{ width, height }}>
      {children}
    </viewportContext.Provider>
  );
};

const useViewport = () => {
  const { width, height } = useContext(viewportContext);
  const isMobile = width <= breakpoints.s;
  const isTablet = width > breakpoints.s && width <= breakpoints.m;
  const isDesktop = width > breakpoints.m;
  const isLargeDesktop = width > breakpoints.xl && width <= breakpoints.xxl;
  const isVeryLargeDesktop = width > breakpoints.xxl;
  return {
    width, height, isMobile, isTablet, isDesktop, isLargeDesktop, isVeryLargeDesktop,
  };
};

export default useViewport;
