import { USER_ROLES, VISIBILITY_ENUMS } from 'Utils/Consts';
import { firestore } from './firebase';

const {
  OWNER, VIEWER, DELEGATE, MEMBER,
} = USER_ROLES;

const roles = [OWNER, VIEWER, DELEGATE, MEMBER];

const GroupsCollection = firestore.collection('groups');

export const upsertGroup = async (group, groupId, cb) => {
  const groupRef = groupId
    ? GroupsCollection.doc(groupId)
    : GroupsCollection.doc();
  return groupRef
    .set(group, {
      merge: true,
    })
    .then(() => {
      const data = { ...group, id: groupId || groupRef.id };
      if (cb) cb({ data });
      return { data };
    })
    .catch((err) => console.error(err));
};

export const getGroupById = async (groupId) => GroupsCollection.doc(groupId).get()
  .then((doc) => {
    if (doc.exists) {
      const data = doc.data();
      return data;
    }
  });

export const getGroupByHandle = async (handle) => GroupsCollection.where('handle', '==', handle)
  .where('visibility', '==', VISIBILITY_ENUMS.PUBLIC).get()
  .then((querySnapshot) => {
    if (querySnapshot.empty) return null;
    let data = null;
    querySnapshot.forEach((doc) => {
      data = { id: doc.id, ...doc.data() };
    });
    return data;
  });

export const subscribeGroups = (uid, cb) => {
  if (!uid) return;
  const groupsRef = GroupsCollection.where(`users.${uid}.roles`, 'array-contains-any', roles);
  return new Promise((resolve, reject) => {
    const unsubscribe = groupsRef.onSnapshot(
      (docs) => {
        const data = {};
        docs.forEach((doc) => {
          data[doc.id] = { ...doc.data(), id: doc.id };
        });
        if (cb) cb({ data, unsubscribe });
        return resolve({ data, unsubscribe });
      },
      (err) => {
        console.error('Error subscribing groups', err);
        reject();
      },
    );
  });
};
