import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'unistore/react';
// import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';

import Store from '~/state/Store';
import App from '~/App';
import GlobalStyles from '~/styles/Global';

// Sentry.init({
//   dsn: 'https://ec103791cb474fd5a40c696c6d0e6845@o611013.ingest.sentry.io/5748043',
//   integrations: [new Integrations.BrowserTracing()],
//   release: `${process.env.APP_NAME}`,
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

const AppRoot = () => (
  <Provider store={Store}>
    <>
      <GlobalStyles />
      <App />
    </>
  </Provider>
);

ReactDOM.render(<AppRoot />, document.getElementById('root'));
