import { PRESENCE_STATUSES } from 'Utils/Consts';
import { db, firebase } from './firebase';

const ts = () => firebase.database.ServerValue.TIMESTAMP;

const {
  ONLINE, OFFLINE, AWAY, IDLE,
} = PRESENCE_STATUSES;

// TODO Refactor dupe const
const TIMER_COLLECTIONS = {
  meetings: 'meetings',
  meeting: 'meetings',
  agendas: 'agendas',
  agenda: 'agendas',
};

export const setPresence = (me, meetingId, cb, status = null) => {
  const { uid, ...user } = me;
  if (!user || !meetingId) {
    console.error('Cannot set presence', { user, meetingId });
    return;
  }
  //   const amOnline = db.ref('_info/connected');
  const userRef = db.ref(`${TIMER_COLLECTIONS.meetings}/${meetingId}/presence/${uid}`);
  userRef.set({ ...user, lastSeen: ts(), status: status || ONLINE });
  // userRef.on('value', (snapshot) => {
  //   if (snapshot.val()) {
  //     const lastSeen = ts();
  //     userRef.onDisconnect().set({ ...user, lastSeen, status: status || OFFLINE });
  //     userRef.set({ ...user, lastSeen, status: status || ONLINE });
  //   }
  // });
  userRef
    .onDisconnect()
    .set({ ...user, lastSeen: ts(), status: OFFLINE });
  window.document.onIdle = () => {
    userRef.set({ ...user, lastSeen: ts(), status: IDLE });
  };
  window.document.onAway = () => {
    userRef.set({ ...user, lastSeen: ts(), status: AWAY });
  };
  window.document.onBack = (isIdle, isAway) => {
    userRef.set({ ...user, lastSeen: ts(), status: ONLINE });
  };
  window.document.onUnsubscribePresence = () => {
    userRef.set({ ...user, lastSeen: ts(), status: OFFLINE });
  };
};

export const subscribePresence = (meetingId, cb) => {
  if (!meetingId) {
    console.error('Cannot get presence', { meetingId });
    return;
  }
  const MAX_ATTEMPTS = 30;
  let attempts = 0;
  const onSubscribe = () => {
    attempts += 1;
    if (attempts > MAX_ATTEMPTS) {
      console.error(`Unable to subscribe to presence: ${type} / ${id}`);
      return;
    }

    const status = db.ref(`${TIMER_COLLECTIONS.meetings}/${meetingId}/presence`);
    if (!status) {
      setTimeout(() => {
        onSubscribe();
      }, 200);
      return;
    }
    status.once('value').then((snapshotOnce) => {
      const data = snapshotOnce.val() || {};
      if (cb) cb(data);
      status.on('value', (snapshot) => {
        const s = snapshot.val();
        if (cb) cb(s);
      });
    }).catch(() => {
      setTimeout(() => {
        onSubscribe();
      }, 200);
    });
  };
  onSubscribe();
};

export const unsubscribePresence = (meetingId, cb) => {
  const status = db.ref(`meetings/${meetingId}/presence`);
  if (!status) {
    console.error('Error unsub Presence');
    return;
  }
  status.off();
  if (window.document.onUnsubscribePresence) window.document.onUnsubscribePresence();
  if (cb) cb();
};
