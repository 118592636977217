import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import Common from 'Common';

const {
  Popup, Modal, Button, Grid, Icon,
} = Common;

const POPUP = 'popup';
const MODAL = 'modal';

const Wrapper = styled.div`
  ${({ minw, maxw }) => `min-width: ${minw}; max-width: ${maxw};`}
`;

const ElementWrapper = ({
  children,
  onRenderChildren,
  onRenderTriggerElement,
  minWidth,
  maxWidth,
  heading,
  footer,
  size,
  displayIn,
  defaultOpen,
  triggerEvents,
  triggerParams,
  elementParams,
  onClose,
  onOpen,
  basic,
  elementRef,
}) => {
  const contextRef = useRef();
  const [showElement, setShowElement] = useState(defaultOpen);

  const handleToggleElement = () => {
    setShowElement(!showElement);
  };

  const handleOnClose = () => {
    if (onClose) onClose();
    handleToggleElement();
  };

  const handleOnOpen = () => {
    if (onOpen) onOpen();
  };

  const renderHeading = () => basic ? (
    heading
  ) : (
    <Grid columns={2}>
      <Grid.Column>{heading}</Grid.Column>
      <Grid.Column textAlign="right" verticalAlign="middle">
        <Icon link name="close" onClick={handleOnClose} />
      </Grid.Column>
    </Grid>
  );

  const renderChildren = () => {
    if (minWidth || maxWidth) {
      return (
        <Wrapper minw={minWidth} maxw={maxWidth}>
          {children
            || onRenderChildren({
              onToggle: handleToggleElement,
              show: showElement,
            })}
        </Wrapper>
      );
    }
    return (
      children
      || onRenderChildren({
        onToggle: handleToggleElement,
        show: showElement,
      })
    );
  };

  const renderTriggerElement = () => onRenderTriggerElement ? (
    onRenderTriggerElement({
      onToggle: handleToggleElement,
      show: showElement,
    })
  ) : (
    <Button onClick={handleToggleElement} {...triggerParams} />
  );

  let El = null;
  if (displayIn === POPUP) {
    El = (
      <div ref={contextRef}>
        <Popup
          trigger={renderTriggerElement()}
          content={renderChildren()}
          on={triggerEvents}
          open={showElement}
          onClose={handleOnClose}
          onOpen={handleOnOpen}
          header={renderHeading()}
          size={size}
          context={elementRef || contextRef}
          // position="top center"
          hideOnScroll={false}
          eventsEnabled={false}
          flowing
          wide
          basic
          {...elementParams}
        />
      </div>
    );
  }
  if (displayIn === MODAL) {
    El = (
      <>
        <Modal
          open={showElement}
          onClose={handleOnClose}
          onOpen={handleOnOpen}
          size={size}
          // dimmer="blurring"
          {...elementParams}
        >
          <Modal.Header>{renderHeading()}</Modal.Header>
          <Modal.Content>{renderChildren()}</Modal.Content>
          {footer}
        </Modal>
        {renderTriggerElement()}
      </>
    );
  }
  return El;
};

ElementWrapper.defaultProps = {
  displayIn: POPUP,
  triggerEvents: ['click'],
  size: 'large',
  defaultOpen: false,
  maxWidth: '95%',
  basic: false,
};

ElementWrapper.POPUP = POPUP;
ElementWrapper.MODAL = MODAL;

export default ElementWrapper;
