import React, { useState } from 'react';
import { CopyToClipboard as CTC } from 'react-copy-to-clipboard';
import Button from '../Button';
import Icon from '../Icon';

const CopyToClipboard = ({
  textToCopy, buttonLabel, successLabel, buttonProps, cb,
}) => {
  const [isSuccess, setIsSuccess] = useState(null);

  const handleCopyToClipboard = () => {
    setIsSuccess(true);
    if (cb) cb(true);
  };

  return (
    <CTC
      text={textToCopy}
      onCopy={handleCopyToClipboard}
    >
      <Button
        {...buttonProps}
      >
        <Icon name={isSuccess ? 'clipboard check' : 'clipboard outline'} />
        {' '}
        {isSuccess ? successLabel : buttonLabel}
      </Button>
    </CTC>
  );
};

CopyToClipboard.defaultProps = {
  buttonLabel: 'Copy link',
  successLabel: 'Copied',
};

export default CopyToClipboard;
