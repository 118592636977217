import { USER_ROLES } from 'Utils/Consts';
import { firestore } from './firebase';

const {
  OWNER, VIEWER, DELEGATE, MEMBER,
} = USER_ROLES;

const roles = [OWNER, VIEWER, DELEGATE, MEMBER];

const TasksCollection = firestore.collection('tasks');

export const upsertTask = async (taskId, taskFragment, merge = true) => taskId
  ? TasksCollection.doc(taskId).set(taskFragment, { merge })
  : TasksCollection.doc().set(taskFragment);

export const getTaskById = async (taskId) => TasksCollection.doc(taskId).get()
  .then((doc) => {
    if (doc.exists) {
      const data = doc.data();
      return data;
    }
  });

export const subscribeMeetingTasks = (meetingId, cb) => {
  if (!meetingId) return;
  const tasksRef = TasksCollection.where('meetingId', '==', meetingId);
  return new Promise((resolve, reject) => {
    const unsubscribe = tasksRef.onSnapshot(
      (docs) => {
        const data = {};
        docs.forEach((doc) => {
          data[doc.id] = { ...doc.data(), id: doc.id };
        });
        if (cb) cb({ data, unsubscribe });
        return resolve({ data, unsubscribe });
      },
      (err) => {
        console.log('Error getting tasks by meetingId', err);
        reject();
      },
    );
  });
};

export const subscribeGroupTasks = (groupId, cb) => {
  if (!groupId) return;
  const tasksRef = TasksCollection.where('groupId', '==', groupId);
  return new Promise((resolve, reject) => {
    const unsubscribe = tasksRef.onSnapshot(
      (docs) => {
        const data = {};
        docs.forEach((doc) => {
          data[doc.id] = { ...doc.data(), id: doc.id };
        });
        if (cb) cb({ data, unsubscribe });
        return resolve({ data, unsubscribe });
      },
      (err) => {
        console.log('Error getting tasks by groupId', err);
        reject();
      },
    );
  });
};

export const subscribeMyTasks = (uid, cb) => {
  if (!uid) return;
  const tasksRef = TasksCollection.where(`users.${uid}.roles`, 'array-contains-any', roles);
  return new Promise((resolve, reject) => {
    const unsubscribe = tasksRef.onSnapshot(
      (docs) => {
        const data = {};
        docs.forEach((doc) => {
          data[doc.id] = { ...doc.data(), id: doc.id };
        });
        if (cb) cb({ data, unsubscribe });
        return resolve({ data, unsubscribe });
      },
      (err) => {
        console.log('Error getting my tasks', err);
        reject();
      },
    );
  });
};
