import _findIndex from 'lodash/findIndex';
import _merge from 'lodash/merge';
import _get from 'lodash/get';
import {
  getIsAdmin, processFormData, forEach,
} from 'Utils';
import { fsTimestamp } from '../firebase';
import { getAccountFeatures } from '~/firebase/functions';
import {
  upsertGroup,
  getGroupById,
  getGroupByHandle,
  subscribeGroups,
} from '../firebase/group';
import { handleAsyncUsersBatch } from './user';
import { handleGetProjectsByAccountId } from './project';

const enrichGroup = (g, me) => {
  const isAdmin = getIsAdmin(g, me);
  g.isAdmin = isAdmin;
  return g;
};

const updateMeetings = (meetings, group, groupId) => {
  const uMeetings = { ...meetings };
  forEach(meetings, (m, mId) => {
    if (m.groupId === groupId && m.isAdmin !== group.isAdmin) {
      uMeetings[mId].isAdmin = group.isAdmin;
    }
  });
  return uMeetings;
};

export const handleUpsertGroup = async (
  Store,
  state,
  groupData,
  groupId,
  cb,
) => {
  const {
    me, groups, meetings, accounts, groupHandles,
  } = state;
  const { uid: userId } = me;
  const updateStore = async ({ data }) => {
    const cachedGroup = groups[groupId] || {};
    const g = enrichGroup(_merge(cachedGroup, data), me);
    if (cb) cb({ res: g });
    const { id, handle } = data;
    groups[id] = { ...g };
    groupHandles[handle] = id;
    const uMeetings = updateMeetings(meetings, g, id);
    Store.setState({ groups: { ...groups }, groupHandles, meetings: { ...uMeetings } });
  };
  // TODO add state.prevData param to processFormData()
  const pGroup = processFormData(groupData, userId, fsTimestamp, !!groupId);

  let accountId = groupData.accountId || '';

  if (!accountId) {
    // TODO For now we assume users will have only one active account
    forEach(accounts, (a, aId) => {
      if (a.isActive) accountId = aId;
    });
    pGroup.accountId = accountId;
  }

  await upsertGroup(pGroup, groupId, updateStore);
};

export const handleGetGroupById = async (Store, state, groupId) => {
  const {
    groups, groupHandles, meetings, me, features, projects,
  } = state;
  const group = await getGroupById(groupId);
  const uMeetings = updateMeetings(meetings, group, groupId);
  const savedGroups = {
    groups: {
      ...groups,
      [groupId]: group,
    },
    groupHandles: {
      ...groupHandles,
      [group.handle]: group.id,
    },
    meetings: uMeetings,
  };
  const { accountId } = group;
  if (accountId && !features[accountId]) {
    if (!projects[accountId]) await handleGetProjectsByAccountId(Store, state, accountId);
    savedGroups.features = {
      [accountId]: await getAccountFeatures({ accountId }),
    };
  }
  Store.setState({ ...savedGroups });
  await handleAsyncUsersBatch(Store, state, [group]);
};

export const handleGetGroupByHandle = async (Store, state, handle) => {
  const {
    groups, groupHandles, meetings, features, me, projects,
  } = state;
  const group = await getGroupByHandle(handle);
  if (!group) {
    console.warn('No group returned for handle:', handle);
    return;
  }
  const { id, ...g } = group;
  const uMeetings = updateMeetings(meetings, g, id);
  const savedGroups = {
    groups: {
      ...groups,
      [group.id]: enrichGroup(g, me),
    },
    groupHandles: {
      ...groupHandles,
      [handle]: id,
    },
    meetings: uMeetings,
  };
  const { accountId } = group;
  if (accountId && !features[accountId]) {
    if (!projects[accountId]) await handleGetProjectsByAccountId(Store, state, accountId);
    savedGroups.features = {
      [accountId]: await getAccountFeatures({ accountId }),
    };
  }
  Store.setState({ ...savedGroups });
  await handleAsyncUsersBatch(Store, state, [group]);
};

export const handleSubscribeGroups = async (Store, state, uid, cb) => {
  const { me } = state;
  const userId = uid || me.uid;
  const updateStore = async ({ data, unsubscribe }) => {
    const {
      groups, groupHandles, meetings, features, subscriptions, projects,
    } = Store.getState();
    if (cb) cb({ res: data });
    const accountIds = [];
    subscriptions[`${userId}-groups`] = { unsubscribe };
    let uMeetings = meetings;
    forEach(data, ({ id, ...group }) => {
      const g = enrichGroup(group, me);
      uMeetings = { ...uMeetings, ...updateMeetings(meetings, g, id) };
      groups[id] = g;
      groupHandles[g.handle] = id;
      if (
        g.accountId
        && !features[g.accountId]
        && accountIds.indexOf(g.accountId) < 0
      ) {
        accountIds.push(g.accountId);
      }
    });
    Store.setState({
      groups: { ...groups },
      groupHandles,
      // meetings: { ...uMeetings },
      subscriptions,
    });
    if (accountIds.length > 0) {
      await Promise.all(accountIds.map(async (accountId) => {
        if (!projects[accountId]) await handleGetProjectsByAccountId(Store, state, accountId);
        features[accountId] = await getAccountFeatures({ accountId });
        return Promise.resolve();
      }));
      const { features: latestFeatures } = Store.getState();
      Store.setState({ features: { ...latestFeatures, ...features } });
    }
    await handleAsyncUsersBatch(Store, state, data);
  };
  await subscribeGroups(userId, updateStore);
};
