import TextAreaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';

const TextArea = styled(TextAreaAutosize)`
    font-family: 'Roboto', sans-serif;
    display: inline-flex;
    flex: 1 0 auto;
    padding: 9.5px 14px;
    border: 1px solid rgba(34,36,38,.15);
    line-height: 1.2;
    width: 100%;
    outline: 0;
    &:focus {
        border-color: #85b7d9;
        color: rgba(0,0,0,.8);
        box-shadow: none;
    }
`;

/*
https://react-components.buildo.io/#tab=1&textareaautosize
Props:
    onResize
    rows
    maxRows
    innerRef
*/

export default TextArea;
