import React from 'react';
import { PongSpinner } from 'react-spinners-kit';

import { COLORS } from '~/styles/Consts';

// TODO: Add sleep to set state for null | initial | delayed loaders
const Loader = ({ sleep: sleepMs, ...rest }) => <PongSpinner {...rest} />;

Loader.defaultProps = {
  size: 160,
  color: COLORS.primary,
  sleep: 0,
};

export default Loader;
