import React from 'react';
import styled from 'styled-components';
import { connect } from 'unistore/react';
import { useNavigation } from 'react-navi';
import Common from 'Common';
import useGlobalContext from '~/hooks/useGlobalContext';

const {
  Sidebar, Menu, Avatar, Icon,
} = Common;

const Wrapper = styled.div`
    margin-bottom: 0.5rem;
`;

const ItemStyled = styled(Menu.Item)`
  color: #fff !important;
  border: unset !important;
  &:before {
    background-color: unset !important;
  }
`;

const MobileNavigationSidebar = ({ children, store }) => {
  const { me: { isAuthenticated } } = store;
  const [{ showMobileSidebar, sidebarMenuItems }, setGlobalState] = useGlobalContext();
  const navigation = useNavigation();
  const { navigate } = navigation;

  const handleToggleShowSidebar = (bool) => {
    if (bool !== undefined) {
      setGlobalState({ showMobileSidebar: bool });
    } else {
      setGlobalState({ showMobileSidebar: !showMobileSidebar });
    }
  };

  const handleOnClick = ({ link, onClick }) => {
    setGlobalState({ showMobileSidebar: false });
    if (link) {
      navigate(link);
    } else if (onClick) {
      onClick();
    }
  };

  const renderMenuItem = (item) => {
    const {
      auth, content, icon, avatar, link, onClick,
    } = item;
    if (auth !== isAuthenticated && auth !== null) {
      return null;
    }
    const itemParams = {
      key: item.content,
      content: (
        <>
          {!!avatar && (
            <Wrapper>
              <Avatar src={avatar} avatar={false} size="mini" />
            </Wrapper>
          )}
          {(!avatar && !!icon) && <Icon name={icon} />}
          {content}
        </>
      ),
      onClick: () => handleOnClick({ link, onClick }),
    };
    return <ItemStyled {...itemParams} />;
  };

  const renderMobileSidebar = () => (
    <Sidebar.Pushable>
      <Sidebar
        as={Menu}
        animation="overlay"
        icon="labeled"
        vertical
        inverted
        visible={showMobileSidebar}
        direction="right"
        content={sidebarMenuItems && sidebarMenuItems.map(renderMenuItem)}
        width="thin"
      />
      <Sidebar.Pusher
        dimmed={showMobileSidebar}
        onClick={() => handleToggleShowSidebar(false)}
        style={{ height: '70vh' }}
      >
        {children}
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  );

  return renderMobileSidebar();
};

export default connect((store) => ({ store }))(MobileNavigationSidebar);
